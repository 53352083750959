<template>
  <div class="tab-pane fade w-100 mx-sm-3 active show top-active-tab" id="history">
      <div class="row">
          <div class="col-sm-6 col-12" v-for="(payment, index) in PaymentHistoryList"
               :key="'ph'+index">
              <a class="payment-history-card w-100">
                  <div class="d-flex justify-content-between mb-3">
                      <h5 class="price-title">Rs.{{ payment.amountPaid }}</h5>
                      <!-- <i class="fas fa-ellipsis-v"></i> -->
                  </div>
                  <p class="text-md mb-3">



        <!-- Beginner Course in Spanish | Monthly <br />Payment | April
                  Payment | {{payment.paymentoption}}-->
                        {{ payment.coursetitle }}   <br /> Payment | {{ payment.displaypaymentstartdate }}
                  </p>
                  <p class="text-md success mb-3">successful</p>
                  <p class="text-md">UPI - {{ payment.razorpaymentid }}</p>
                  <!-- <p class="text-md">{{ payment.displaypaymentstartdate }}</p> -->
              </a>
          </div>
      </div>
    <!-- Card End -->
  </div>
</template>
<script>
export default {
  name: "PaymentHistory",
  props: {
    PaymentHistoryList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
                    
<template>
    <div class="modal fade show" id="Openwindow" style="display: block" ref="base">
        <svg class="d-none">
            <defs>
                <g id="chevronBack">
                    <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path>
                </g>
            </defs>
        </svg>
        <div class="modal-dialog multistep-quiz-form">
            <div ref="scrollHookTop"></div>
            <div class="modal-content">
                <div class="modal-header align-items-center">
                    <svg viewBox="0 0 64 64"
                         id="back"
                         class="svg-icon medium d-sm-none back hide">
                        <use xlink:href="#chevronBack"></use>
                    </svg>
                    <h6 class="quiz-popup-title">Vocabulary / Idiom Quiz</h6>
                    <button type="button" class="close" @click="onClickClose">×</button>
                </div>
                <div class="modal-body">
                    <div class="quiz-box">
                        <div class="form">
                            <QuizIntroScreen :introClassName="introClassName"
                                             v-on:onNext="onClickIntroNext">
                            </QuizIntroScreen>
                            <div v-for="(detail, index) in quizDetails"
                                 class="step next-slide"
                                 :key="'step' + index"
                                 id="step2"
                                 :ref="'step' + index">
                                <div class="group">
                                    <div class="quiz-step-start mb-3">
                                        <div class="quiz-questions">
                                            <span class="achieved-score big">{{ index + 1 }}</span>
                                            <span class="outof-score">/{{ quizDetails.length }}</span>
                                        </div>
                                        <div class="cap-count-number">
                                            <span class="tag-item">
                                                <img class="cap-count-image"
                                                     src="https://lscdn.blob.core.windows.net/elearn/cap.png" />
                                                <span class="earn-cap-count">{{ totalCaps }} Points</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="quiz-qustion-box" ref="body">
                                        <!--class = quiz-scroll-y -->
                                        <div class="">

                                            <div class="question-explain">
                                                <!--<p></p>-->
                                                <p v-html="detail.sentence"></p>
                                                <!-- <p>My boss expects everyone in the meeting to be <span class="all-ears-text-color">all ears</span> when he speaks; he does not tolerate interruptions.</p> -->
                                                <h6 v-html="detail.question">
                                                    <!-- {{ detail.question }}
                                                    <span class="all-ears-text-color">{{detail.word}}</span> -->
                                                </h6>
                                            </div>
                                            <ul class="main-questions">
                                                <li class=""
                                                    @click="onSelectOption"
                                                    data-option="A">
                                                    <span class="check-correct-icon d-none" data-id="answer-icon">
                                                        <svg class="correct-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#ffffff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="wrong-icon d-none" data-id="answer-icon">
                                                        <svg class="wrong-close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <line x1="200" y1="56" x2="56" y2="200" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                            <line x1="200" y1="200" x2="56" y2="56" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                        </svg>
                                                    </span>{{ detail.optionA }}
                                                </li>
                                                <li class=""
                                                    @click="onSelectOption"
                                                    data-option="B">
                                                    <span class="check-correct-icon d-none" data-id="answer-icon">
                                                        <svg class="correct-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#ffffff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="wrong-icon d-none" data-id="answer-icon">
                                                        <svg class="wrong-close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <line x1="200" y1="56" x2="56" y2="200" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                            <line x1="200" y1="200" x2="56" y2="56" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                        </svg>
                                                    </span>{{ detail.optionB }}
                                                </li>
                                                <li class=""
                                                    @click="onSelectOption"
                                                    data-option="C">
                                                    <span class="check-correct-icon d-none" data-id="answer-icon">
                                                        <svg class="correct-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#ffffff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="wrong-icon d-none" data-id="answer-icon">
                                                        <svg class="wrong-close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <line x1="200" y1="56" x2="56" y2="200" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                            <line x1="200" y1="200" x2="56" y2="56" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                        </svg>
                                                    </span>{{ detail.optionC }}
                                                </li>
                                                <li class=""
                                                    @click="onSelectOption"
                                                    data-option="D">
                                                    <span class="check-correct-icon d-none" data-id="answer-icon">
                                                        <svg class="correct-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#ffffff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="wrong-icon d-none" data-id="answer-icon">
                                                        <svg class="wrong-close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <line x1="200" y1="56" x2="56" y2="200" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                            <line x1="200" y1="200" x2="56" y2="56" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                        </svg>
                                                    </span>{{ detail.optionD }}
                                                </li>

                                            </ul>

                                        </div>



                                        <div class="question-submit text-center mt-3 mb-3" data-id="submit-btn">
                                            <button type="button"
                                                    id="next"
                                                    class="btn btn-primary next-form-btn"
                                                    tabindex="-1"
                                                    @click="onSubmitclick">
                                                Submit
                                            </button>
                                            <p v-if="showErrorText" class="error-text show" data-id="">
                                                Please select any option
                                            </p>
                                        </div>
                                        <div class="view-explanation-button text-center mt-3 d-none"
                                             data-id="view-explanation-btn">
                                            <button type="button"
                                                    id="next"
                                                    class="btn btn-primary next-form-btn mb-2"
                                                    tabindex="-1"
                                                    @click="onClickViewExplanation"
                                                    ref="viewExplanation">
                                                View Explanation
                                            </button>
                                            <p v-if="showWellDoneMessage"
                                               class="text-center select-caps-winner"
                                               data-id="">
                                                Well Done! You have earned 100 points
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div v-if="currentSlideNumber >= 0"
                                 class="step next-slide"
                                 id="step5"
                                 ref="explanation">
                                <div class="group">
                                    <div class="quiz-step-start mb-3">
                                        <div class="quiz-questions">
                                            <span class="achieved-score big">
                                                {{currentSlideNumber + 1}}
                                            </span>
                                            <span class="outof-score">/{{ quizDetails.length }}</span>
                                        </div>
                                        <div class="cap-count-number">
                                            <span class="tag-item">
                                                <img class="cap-count-image"
                                                     src="https://lscdn.blob.core.windows.net/elearn/cap.png" />
                                                <span class="earn-cap-count">{{ totalCaps }} Points</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="quiz-qustion-box">
                                        <h3>{{ quizDetails[this.currentSlideNumber].word }}</h3>
                                        <div class="row">
                                            <div class="col-12">
                                                <!-- <div class="bookmark-icon" @click="onClickSaveIcon">
                                                  <svg
                                                    class="quiz-bookmark"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="30"
                                                    fill="#000000"
                                                    viewBox="0 0 256 256"
                                                  >
                                                    <rect width="256" height="256" fill="none"></rect>
                                                    <path
                                                      d="M192,224l-64.0074-40L64,224V48a8,8,0,0,1,8-8H184a8,8,0,0,1,8,8Z"
                                                      fill="none"
                                                      stroke="#000000"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="16"
                                                    ></path>
                                                  </svg>
                                                </div> -->
                                            </div>
                                        </div>


                                        <div class="all-explanation mt-4">
                                            <span class="explain-text">Explanation</span>
                                            <p class="mt-2">
                                                {{ quizDetails[this.currentSlideNumber].explanation }}
                                            </p>
                                        </div>
                                        <div class="try-again mt-4 mb-4">
                                            <span @click="onClickTryAgain">
                                                <svg class="try-again-icon"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="20"
                                                     height="20"
                                                     fill=""
                                                     viewBox="0 0 256 256">
                                                    <polyline points="79.833 99.716 31.833 99.716 31.833 51.716"
                                                              fill="none"
                                                              stroke=""
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="20"></polyline>
                                                    <path d="M65.7746,190.2254a88,88,0,1,0,0-124.4508L31.83348,99.71573"
                                                          fill="none"
                                                          stroke=""
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                          stroke-width="20"></path>
                                                </svg>
                                                Try Again
                                            </span>
                                            <button type="button"
                                                    id="next"
                                                    class="btn btn-primary next-form-btn"
                                                    tabindex="-1"
                                                    @click="onClickNext">
                                                Next
                                            </button>
                                        </div>
                                        <div class="learn-histroy"
                                             :class="{ 'd-none': !showSavedMsg }">
                                            <p>Saved you can check under learning history</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="step next-slide" id="step7" ref="congratulation">
                                <div class="group">
                                    <div class="final-quiz-qustion-box">
                                        <div class="question-explain mt-4">
                                            <h3 v-if="congratsMsg" class="final-explanation-title">{{congratsMsg}}</h3>
                                            <p v-if="congratsMsg" class="text-center mt-3">{{ userName }}</p>
                                            <p class='text-center' v-html="getCongratsContent()"></p>
                                            <!-- <p class='text-center' v-if="comparisonText">{{comparisonText}}</p> -->
                                        </div>
                                        

                                        <!-- <div class="ratings-start-icon text-center mt-3 mb-3">
                                        <span>
                                        <svg class="top-star" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#fcc127" viewBox="0 0 256 256">
                                        <rect width="256" height="256" fill="none"></rect>
                                        <path d="M132.41106,190.73266l50.43543,31.95385c6.44693,4.08451,14.45124-1.99032,12.53819-9.51579l-14.57192-57.32241a8.75742,8.75742,0,0,1,2.83756-8.87589l45.2273-37.64345c5.94268-4.9462,2.87542-14.80876-4.75965-15.30428l-59.06388-3.83326a8.41836,8.41836,0,0,1-7.24792-5.3506l-22.02834-55.473a8.31887,8.31887,0,0,0-15.55566,0L98.19383,84.84083a8.41836,8.41836,0,0,1-7.24792,5.3506L31.882,94.02469c-7.63507.49552-10.70233,10.35808-4.75965,15.30428l45.2273,37.64345a8.75742,8.75742,0,0,1,2.83756,8.87589L61.6734,209.00846c-2.29566,9.03056,7.30952,16.32036,15.04583,11.41895l46.86971-29.69475A8.21431,8.21431,0,0,1,132.41106,190.73266Z" fill="#fcc127" stroke="#fcc127" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                                        </svg>
                                        </span>
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#fcc127" viewBox="0 0 256 256">
                                        <rect width="256" height="256" fill="none"></rect>
                                        <path d="M132.41106,190.73266l50.43543,31.95385c6.44693,4.08451,14.45124-1.99032,12.53819-9.51579l-14.57192-57.32241a8.75742,8.75742,0,0,1,2.83756-8.87589l45.2273-37.64345c5.94268-4.9462,2.87542-14.80876-4.75965-15.30428l-59.06388-3.83326a8.41836,8.41836,0,0,1-7.24792-5.3506l-22.02834-55.473a8.31887,8.31887,0,0,0-15.55566,0L98.19383,84.84083a8.41836,8.41836,0,0,1-7.24792,5.3506L31.882,94.02469c-7.63507.49552-10.70233,10.35808-4.75965,15.30428l45.2273,37.64345a8.75742,8.75742,0,0,1,2.83756,8.87589L61.6734,209.00846c-2.29566,9.03056,7.30952,16.32036,15.04583,11.41895l46.86971-29.69475A8.21431,8.21431,0,0,1,132.41106,190.73266Z" fill="#fcc127" stroke="#fcc127" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                                        </svg>
                                        </span>
                                        <span>
                                        <svg class="top-star" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#fcc127" viewBox="0 0 256 256">
                                        <rect width="256" height="256" fill="none"></rect>
                                        <path d="M132.41106,190.73266l50.43543,31.95385c6.44693,4.08451,14.45124-1.99032,12.53819-9.51579l-14.57192-57.32241a8.75742,8.75742,0,0,1,2.83756-8.87589l45.2273-37.64345c5.94268-4.9462,2.87542-14.80876-4.75965-15.30428l-59.06388-3.83326a8.41836,8.41836,0,0,1-7.24792-5.3506l-22.02834-55.473a8.31887,8.31887,0,0,0-15.55566,0L98.19383,84.84083a8.41836,8.41836,0,0,1-7.24792,5.3506L31.882,94.02469c-7.63507.49552-10.70233,10.35808-4.75965,15.30428l45.2273,37.64345a8.75742,8.75742,0,0,1,2.83756,8.87589L61.6734,209.00846c-2.29566,9.03056,7.30952,16.32036,15.04583,11.41895l46.86971-29.69475A8.21431,8.21431,0,0,1,132.41106,190.73266Z" fill="#fcc127" stroke="#fcc127" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                                        </svg>
                                        </span>
                                        </div> -->
                                        <!-- <div class="final-exam-score s-score text-center mt-4">

                                                                <div class="circle-center-score">

                                                                    <p class="earn-title">You Earned</p>

                                                                    <p class="final-achieved-score big">{{ totalCaps }}</p>

                                                                    <p class="points-title">Points</p>



                                                                </div>

                                                            </div> -->

                                        <div v-if="!hasReTriedWholeQuiz" class="final-exam-score s-score text-center mt-4">
                                            <img class="final-screen-cap" src="https://lscdn.blob.core.windows.net/elearn/cap.png">
                                            <div class="circle-center-score">
                                                <p>Rewarded</p>
                                                <p class="final-achieved-score big">{{ totalCaps }}</p>
                                                <p>Points</p>
                                                <!-- <span class="final-achieved-score big">{{
                                                  totalScore
                                                }}</span>
                                                <span class="final-outof-score"
                                                  >/{{ quizDetails.length }}</span
                                                > -->
                                            </div>
                                        </div>
                                        <!-- <p v-if="showTryAgainMsg" class="text-center">Try playing the game again</p> -->
                                        <!-- <div class="final-count-number text-center">
                                          <span class="final-tag-item">
                                            <img
                                              class="final-cap-count-image"
                                              src="https://lscdn.blob.core.windows.net/elearn/cap.png"
                                            />
                                            <span class="final-earn-cap-count"
                                              >Won {{ totalCaps }} points</span
                                            >
                                          </span>
                                        </div> -->
                                        <!-- retry button -->
                                        <div class="try-again final-screen-footer">
                                            <!-- <span @click="onClickRetryQuiz">
                                                <svg class="try-again-icon"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="20"
                                                     height="20"
                                                     fill=""
                                                     viewBox="0 0 256 256">
                                                    <polyline points="79.833 99.716 31.833 99.716 31.833 51.716"
                                                              fill="none"
                                                              stroke=""
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="16"></polyline>
                                                    <path d="M65.7746,190.2254a88,88,0,1,0,0-124.4508L31.83348,99.71573"
                                                          fill="none"
                                                          stroke=""
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                          stroke-width="16"></path>
                                                </svg>
                                                Play Again
                                            </span> -->
                                            <button v-if="playNextCount > 0"
                                                    type="button"
                                                    id="next"
                                                    class="btn btn-primary next-form-btn"
                                                    tabindex="-1"
                                                    @click="getNextQuiz">
                                                Play Next Quiz
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref="scrollHookBottom"></div>
        </div>
    </div>
</template>
<script>
import Loader from '../../wwwroot/js/Loader';
import QuizIntroScreen from "./QuizIntroScreen";

export default {
  data() {
    return {
      currentSlideNumber: -1,
      currentWordId: 0,
      quizDetails: [],
      isClickable: true,
      userName: "",
      userPid: 0,
      totalScore: 0,
      totalCaps: 0,
      showWellDoneMessage: false,
      isAnswerSelected: false,
      showSavedMsg: false,
      showErrorText: false,
      introClassName: 'current-slide',
      hasReTriedWholeQuiz: false,
      hasCompletedQuiz: false,
      nextQuizTopic: '',
      congratsMsg : '',
      showTryAgainMsg: false,
      firstAttemptScore: 0,
      comparisonText: '',
      playNextCount: 0,
    };
  },
  props : {
    playNext : Number,
  },
  components: {
    QuizIntroScreen: QuizIntroScreen,
  },
  beforeMount: function () {
    this.getVocabularyQuiz();
    this.userName = document.getElementById("hdnUserName").value;
    this.userPid = document.getElementById("hdnUserPid").value;
    this.playNextCount = this.playNext;
  },
  methods: {
    moveTo: function (position) {
      let ref = `step${this.currentSlideNumber + 1}`;

      this.$refs[ref][0].classList.remove("next-slide");
      this.$refs[ref][0].classList.add("current-slide");
      this.currentSlideNumber++;
      this.currentWordId = this.quizDetails[this.currentSlideNumber].wordId;
    },
    onClickIntroNext: function() {
        this.introClassName = "previous-slide";
        this.moveTo();
    },
    onSelectOption: function (event) {

      if (this.isClickable) {
        let option = event.currentTarget.getAttribute("data-option");
        // this.scrollIntoView('up');
        if (option == this.quizDetails[this.currentSlideNumber].answer) {
          event.currentTarget.querySelector(".check-correct-icon").classList.remove("d-none");
          event.currentTarget.classList.add("correct-answer");
          if(!this.quizDetails[this.currentSlideNumber].hasReTried) {
            this.totalScore = this.totalScore + 1;
          }

          if (!this.hasReTriedWholeQuiz && !this.quizDetails[this.currentSlideNumber].hasReTried ) {
            // this.totalScore = this.totalScore + 1;
            this.totalCaps = this.totalCaps + 100;
            this.quizDetails[this.currentSlideNumber].isCorrectAnswer = true;
            this.showWellDoneMessage = true;
          }

        } else {
          event.currentTarget
            .querySelector(".wrong-icon")
            .classList.remove("d-none");
          event.currentTarget.classList.add("select-answer");
        }
         if (!this.quizDetails[this.currentSlideNumber].hasReTried) {
             this.quizDetails[this.currentSlideNumber].selectedAnswer = option;
         }
        this.isAnswerSelected = true;
        this.showErrorText = false;
        let ref = `step${this.currentSlideNumber}`;

        this.$refs[ref][0]
          .querySelector("[data-id='submit-btn']")
          .classList.add("d-none");
        this.$refs[ref][0]
          .querySelector("[data-id='view-explanation-btn']")
          .classList.remove("d-none");
        this.isClickable = false;

      }
    },
    getVocabularyQuiz: function () {
      let userPid = document.getElementById("hdnUserPid").value;
      if (userPid) {
        let url = `api/quiz/get-vocabulary-quiz-random?UserPid=${userPid}`;
        Loader.showAboveModal();
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              this.quizDetails = data;
            }
            Loader.hideAboveModal();
          });
      }
    },
    onClickViewExplanation: function () {
      let ref = `step${this.currentSlideNumber}`;
      this.$refs[ref][0].classList.remove("current-slide");
      this.$refs[ref][0].classList.add("previous-slide");
      if (this.$refs.explanation.classList.contains("d-none")) {
        this.$refs.explanation.classList.remove("d-none");
      }
      this.$refs.explanation.classList.remove("next-slide");
      this.$refs.explanation.classList.add("current-slide");
      // this.scrollIntoView('down');
    },
    getFormatedSentence: function (sentence, word) {
      if (sentence) {
        return sentence.replaceAll(
          word,
          `<span class="all-ears-text-color">${word}</span>`
        );
      }
    },
    onClickTryAgain: function () {
      this.quizDetails[this.currentSlideNumber].hasReTried = true;

      this.$refs.explanation.classList.remove("current-slide");
      this.$refs.explanation.classList.add("next-slide");

      let ref = `step${this.currentSlideNumber}`;
      this.$refs[ref][0].classList.remove("previous-slide");
      this.$refs[ref][0].classList.add("current-slide");
      this.$refs[ref][0]
        .querySelector("[data-id='submit-btn']")
        .classList.remove("d-none");
      this.$refs[ref][0]
        .querySelector("[data-id='view-explanation-btn']")
        .classList.add("d-none");
      this.isClickable = true;
      this.hideCorrectOrWrongAnswerIcons(this.$refs[`step${this.currentSlideNumber}`][0]);
      this.removeCorrectOrWrongAnswerClasses(this.$refs[`step${this.currentSlideNumber}`][0]);
      this.showWellDoneMessage = false;
      this.showErrorText = false;
      this.isAnswerSelected = false;
    },
    hideCorrectOrWrongAnswerIcons: function (element) {
      let icons = element.querySelectorAll(
        "[data-id='answer-icon']"
      );
      if (icons) {
        icons.forEach((item) => {
          if (!item.classList.contains("d-none")) {
            item.classList.add("d-none");
          }
        });
      }
    },
    removeCorrectOrWrongAnswerClasses: function (element) {
    //   let ref = `step${this.currentSlideNumber}`;
      let options = element.querySelectorAll("[data-option]");
      if (options) {
        options.forEach((item) => {
          if (item.classList.contains("correct-answer")) {
            item.classList.remove("correct-answer");
          }
          if (item.classList.contains("select-answer")) {
            item.classList.remove("select-answer");
          }
        });
      }
    },
    onClickNext: function () {
      if (this.currentSlideNumber < this.quizDetails.length - 1) {
        this.$refs.explanation.classList.remove("current-slide");
        this.$refs.explanation.classList.add("previous-slide");
        this.showWellDoneMessage = false;
        this.isClickable = true;
        this.moveTo();
        this.resetExplanationScreen();
        this.showErrorText = false;
        this.isAnswerSelected = false;
      } else {
          if(!this.hasReTriedWholeQuiz) {
              this.firstAttemptScore = this.totalScore;
               this.onSubmitQuizResults();
          }
          this.resetExplanationScreen();
        this.$refs.congratulation.classList.remove("next-slide");
        this.$refs.congratulation.classList.add("current-slide");
      }
      this.isAnswerSelected = false;
      //   this.$refs.explanation.classList.remove("d-none");
    },
    onSubmitclick: function () {

      if (!this.isAnswerSelected) {
        this.showErrorText = true;
      }
    },
    resetExplanationScreen : function () {
      setTimeout(() => {
            this.$refs.explanation.classList.add("d-none");
            this.$refs.explanation.classList.remove("previous-slide");
            this.$refs.explanation.classList.add("next-slide");
            this.$refs.explanation.classList.remove("d-none");
        },500) // for proper animation
    },
    onClickSaveIcon: function (event) {
      let url = ""
        if(event.currentTarget.classList.contains("active")) {
            event.currentTarget.classList.remove("active");
        } else {
            event.currentTarget.classList.add("active");
        }
      this.showSavedMsg = true;
      if(this.showSavedMsgTimerId) {
          clearTimeout(this.showSavedMsgTimerId);
      }
      this.showSavedMsgTimerId = setTimeout(() => {
          this.showSavedMsg = false;
      }, 3000)
      this.quizDetails[this.currentSlideNumber].isBookMarked = true;
    },
    onClickClose: function () {
        if(this.hasCompletedQuiz) {
          Loader.show();
          window.location.reload();
        } else {
           document.body.classList.remove("overflow-hidden");
          var vueBaseDiv = document.querySelector("#quiz-base-div");
          vueBaseDiv.innerHTML = "";
          var div = document.createElement("div");
          div.id = "quiz-div";
          vueBaseDiv.appendChild(div);
        }

    },
    onClickRetryQuiz: function () {
        this.quizDetails.forEach((detail) => {
            detail.hasReTried = false;
        })
        this.hasReTriedWholeQuiz = true;
        //this.introClassName = 'previous-slide';
        this.setDefaultStateValues();
        this.setDefaultClassNamesForSlides();
        this.onClickIntroNext();
    },
    setDefaultStateValues: function () {
        this.currentSlideNumber = -1;
      this.currentWordId= 0;
      this.isClickable= true;
      this.totalScore= 0;
      this.totalCaps= 0;
      this.showWellDoneMessage= false;
      this.isAnswerSelected= false;
      this.showSavedMsg= false;
      this.showErrorText= false;
      this.introClassName= 'current-slide';
    },
    setDefaultClassNamesForSlides: function () {
       for(let i=0; i< this.quizDetails.length; i++) {
           this.$refs[`step${i}`][0].classList.remove("previous-slide")
           this.$refs[`step${i}`][0].classList.add("next-slide")
           this.$refs[`step${i}`][0].querySelector("[data-id='submit-btn']").classList.remove("d-none");
           this.$refs[`step${i}`][0].querySelector("[data-id='view-explanation-btn']").classList.add("d-none");
           this.hideCorrectOrWrongAnswerIcons(this.$refs[`step${i}`][0]);
            this.removeCorrectOrWrongAnswerClasses(this.$refs[`step${i}`][0]);
       }
       this.$refs.congratulation.classList.remove("current-slide");
        this.$refs.congratulation.classList.add("next-slide");
    },
    getQuizResultsData: function () {
        let result = [];
        this.quizDetails.forEach((detail,index) => {
            result[index] = {
                userPid : this.userPid,
                WordId : detail.wordId,
                Score : detail.isCorrectAnswer ? 1 : 0,
                answer : detail.selectedAnswer,
                isCorrectAnswer : detail.isCorrectAnswer ? detail.isCorrectAnswer : false,
                QuizSummaryID : detail.quizSummaryID,
            }
        })
        return result;
    },
    onSubmitQuizResults : function () {
        let url = 'api/quiz/store-quiz-result';
        let requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.getQuizResultsData()),
        }
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
          this.hasCompletedQuiz = true;
        });
    },
    getNextQuiz : function () {
        this.playNextCount--;
        this.hasReTriedWholeQuiz = false;
        this.setDefaultStateValues();
        this.setDefaultClassNamesForSlides();
        this.getVocabularyQuiz();
    },
    scrollIntoView : function (direction) {
      let hook = '';
      if(direction == 'up') {
        hook = 'scrollHookBottom';
      } else if (direction == 'down') {
        hook = 'scrollHookTop';
      }
      let div = this.$refs[hook];
      div.scrollIntoView({behavior : "smooth"});
    },
    getCongratsContent : function () {
      let totalCount = this.quizDetails.length;
      const percent = Math.round((this.totalScore / totalCount) * 100);
      if(percent <= 50) {
          this.congratsMsg = "";
          this.showTryAgainMsg = true;
          return `<b>You got ${this.totalScore} out of ${this.quizDetails.length} correct.</b>`
      }
      else if (percent <= 75) {
        this.showTryAgainMsg = false;
          this.congratsMsg = "Great!!!";
          return `<b>You got ${this.totalScore} out of ${this.quizDetails.length} correct.</b>`
      } else {
        this.showTryAgainMsg = false;
        this.congratsMsg = "Congratulations!!!";
        return `<b>You got ${this.totalScore} out of ${this.quizDetails.length} correct.</b>`
      }
      if(this.hasReTriedWholeQuiz) {
          if(this.totalScore < this.firstAttemptScore) {
          this.comparisonText = 'Ohh, you played better the 1st time. Try one more time.';
        } else {
          this.comparisonText = 'Well done you have improved playing the quiz the second time.'
        }
      } else {
        this.comparisonText = '';
      }

    },
  },
};
</script>

<template>
    <div>
        <div class="blurred">
            <div class="modal-title mb-5">
                <h2>Verify your Phone number</h2>
            </div>
            <h4>Enter the OTP</h4>
            <div class="row">
                <h6 class="text-muted col">
                    We sent an OTP to your phone number +91 99388388388
                </h6>
            </div>
            <div class="otp-form">
                <div class="otp-group">
                    <div class="md-field">
                        <input pattern="\d*" maxlength="1" />
                    </div>
                    <div class="md-field">
                        <input pattern="\d*" maxlength="1" />
                    </div>
                    <div class="md-field">
                        <input pattern="\d*" maxlength="1" />
                    </div>
                    <div class="md-field">
                        <input pattern="\d*" maxlength="1" />
                    </div>
                </div>
                <label class="caption">
                    Resending OTP in 00:20 |
                    <a href="#" class="link">Resend now</a>
                </label>
            </div>
        </div>
        <div class="sign-in-alert">
            <h4 class="text-success">
                <i class="fa fa-check-circle"></i>
                OTP Verified successfully.
            </h4>
        </div>
    </div>
</template>
<script>
    export default {
        name: "SignUpOtpVerified",
        props: {

        },
        data() {
            return {
                showpopup: false
            }
        },
        methods: {
            hidepopup() {
                this.showpopup = false;
            }
        },
        mounted: function () {
        },
        components: {
        },
        computed: {
        }
    }
</script>
<template>
    <div v-if="InstructorCard.length > 0" class="carousal-scroll p-1 splide__track">
        <!--<p>Instructor card Vue</p>-->
        <div class="splide__list">
            <template v-for="card in InstructorCard">
                <!-- Card Start -->
                <a class="course-card p-3 mr-lg-4 splide__slide" :href="card.TutorURL">
                    <span v-if="card.IsTopInstructor == 1" class="top-inst-badge"></span>
                    <div class="img-wrapper d-flex">
                        <img :src="card.TutorThumbnailUrl" :alt="card.TutorName" class="fit-cover" />
                    </div>
                    <h3 class="card-title mb-0 mt-3">
                        {{card.TutorName}}
                    </h3>
                    <div class="d-flex py-2 justify-content-between">
                        <div v-if="card.Rating > 0" class="reviews">
                            <div class="ratings medium">
                                <span :class="renderRatingStars(card.Rating)"></span>
                            </div>
                            <span v-if="card.Rating > 0"> {{card.Rating}}({{card.ReviewCount}} reviews) </span>
                        </div>
                    </div>
                    <p class="pb-4">{{card.ShortDesc}}</p>
                </a>
                <!-- Card End -->
            </template>
        </div>
    </div>
</template>


<script>

    import carousel from "../wwwroot/js/Common/carousel.js";

    export default {
        name: "InstructorCard",
        data() {
            return {
                InstructorCard: [],
                starClass: '',
            }
        },
        beforeMount: function () {
            this.InstructorCard = JSON.parse(document.querySelectorAll('#InstructorCardData')[0].innerText);
        },
        mounted: function () {
            carousel.initInstructorCard();
        },
        methods: {
            renderRatingStars: function (rating) {
                switch (rating) {
                    case 0: return "star0"
                        break;
                    case 0.5: return "star0-5"
                        break;
                    case 1: return "star1"
                        break;
                    case 1.5: return "star1-5"
                        break;
                    case 2: return "star2"
                        break;
                    case 2.5: return "star2-5"
                        break;
                    case 3: return "star3"
                        break;
                    case 3.5: return "star3-5"
                        break;
                    case 4: return "star4"
                        break;
                    case 4.5: return "star4-5"
                        break;
                    case 5: return "star5"
                        break;
                }
                
            },
        }
    }

</script>
<template>

<div class="phone-input md-field pincode-value">
    <input  type="number" required="required" placeholder="Search" name="pincode" id="pincode"
    @input="updateValue($event.target.value)"
    v-model="pincodeValue"
    @keydown.up="onKeyUp($event)"
    @keydown.down="onKeyDown($event)"
    @keydown.enter='enter'
    @click="onclickInput"
    @blur="onBlur"
    ref="PincodeSearchInput" tab-index="-1">
    <label class="pl-3" alt="pincode" for="pincode" placeholder="Search">Search Pincode</label>
    <!-- <svg @click="onclickInput" class="drop-arrow-icon-place pincodesvg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="208 96 128 176 48 96" fill="none" stroke="#8d8d8d" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg> -->
    <div class="dropdown-wrapper active" tabindex="-1" ref="pincodeWrapper">
        <div >
        <!--<div class="dropdown-wrapper" :class="{'active':openSuggestion}">-->
        <!-- <div class="search-block">
            <input class="form-control pincodesearch" type="number" :value="value" @input="updateValue($event.target.value)"
                   @keydown.up="onKeyUp($event)"
                   @keydown.down="onKeyDown($event)"
                   @keydown.enter='enter'
                   placeholder="Search" ref="PincodeSearchInput" autofocus tab-index="0" @blur="onBlur">
                   
                   
        </div> -->
        <!-- <input class="pincodeinput" name="pincode"  autocomplete="off" type="number" required=""
         @input="updateValue($event.target.value)"
         v-model="pincodeValue"
         @keydown.up="onKeyUp($event)"
                   @keydown.down="onKeyDown($event)"
                   @keydown.enter='enter'
                   @focus="onFocus"
                   placeholder="Search" ref="PincodeSearchInput" autofocus tab-index="0" > -->
        <!-- <i class="far fa-search"></i> -->
        <ul class="options" :class="{ 'd-none' : !showSuggestions}">
            <li v-for="(suggestion, index) in matches" :key="index"
                v-bind:class="{'active': isActive(index)}" pincodesearchsuggestion="pincodeValue"
                @click="suggestionClick(index)">
                {{ suggestion }}
                
            </li>
        </ul>

        <!--</div>-->
    </div>
 </div>
</div>


    
</template>

<script>
    export default {
        name: "Pincode",
        props: {
            // value: {
            //     type: String,
            //     required: true
            // },
            selectionCallBack: { type: Function },
            onPincodeBlur: {type :Function},
            pincodeData: Array,
            pincodeProp: String,
        },
        data() {
            return {
                open: false,
                current: 0,
                pageX: 0,
                pageY: 0,
                showSuggestions : false,
                pincodeValue :  '',
            }

        },
        mounted: function () {
            document.addEventListener('mousemove', (event) => {
                this.pageX = event.clientX;
                this.pageY = event.clientY;
            })
        },
        computed: {
            // Filtering the suggestion based on the input
            matches() {
                return this.pincodeData.filter(function(obj) {
                    //return obj.indexOf(this.value.toLowerCase()) >= 0 || obj.indexOf(this.value.toLowerCase()) >= 0
                    // return obj.startsWith(this.pincodeValue)
                    if (this.count < 5 && obj.startsWith(this.pincodeValue)) {
                    this.count++;
                    
                    return true;
                    }
                    return false;
                }, {count : 0, pincodeValue : this.pincodeValue})
            },
            openSuggestion() {
                return this.selection !== '' &&
                    this.matches.length !== 0 &&
                    this.open === true
            }
        },
        methods: {
             init:function() { 
                this.pincodeValue = this.pincodeProp  // step1. assign propId to id
            },
            updateValue(value) {
                // if (this.open === false) {
                //     this.open = true
                //     this.current = 0
                // }
                
                if( !this.showSuggestions) {
                    this.showSuggestions = true;
                }
                // this.$emit('input', value)
                this.pincodeValue = value;
                // this.$emit('input', this.pincodeValue);
                this.selectionCallBack(value);
            },
            // When enter pressed on the input
            enter() {
                // this.$emit('input', '')
                // this.open = false,
                this.showSuggestions = false;
                this.selectionCallBack(this.matches[this.current]);
            },
            // When up pressed while suggestions are open
            up() {
                if (this.current > 0) {
                    this.current--
                }
            },
            // When up pressed while suggestions are open
            down() {
                if (this.current < this.matches.length - 1) {
                    this.current++
                }
            },
            // For highlighting element
            isActive(index) {
                return index === this.current
            },
            // When one of the suggestion is clicked
            suggestionClick(index) {
                this.showSuggestions = false;
                // this.$emit('input', '')
                // this.open = false;
                this.pincodeValue = this.matches[index];
                this.selectionCallBack(this.pincodeValue);
            },

            onKeyDown(e) {
                e.preventDefault();
                this.down();
                return false;
            },

            onKeyUp(e) {
                e.preventDefault();
                this.up();
                return false;
            },
            onclickInput: function () {
                this.showSuggestions = !this.showSuggestions;
            },
            onBlur : function() {
                 if (document.elementFromPoint(this.pageX, this.pageY).getAttribute("pincodesearchsuggestion")) {
                        return;
                    }
                this.showSuggestions = false;
            }
        },
            watch: { 
            pincodeProp: function(new_value) { 
                this.init()
            },
        }
    }
</script>
<template>
  <div>
    <!-- Header Section End -->
    <div class="bg-header-pink pt-lg-5 pt-4">
      <!-- progress bar start -->
      <div class="fixed-top d-block d-lg-none">
        <div class="progress ml-auto">
          <div
            class="progress-bar"
            role="progressbar"
            style="width: 40%"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <!-- progress bar end -->
      <div class="container">
        <a data-toggle="tab" class="" href="/MyClasses"><i class="far fa-arrow-left d-sm-none"></i></a>
        <div class="row mx-0 px-2 py-4" v-if="Sessions.length>0">
          <div class="col-12 col-lg-4 px-0" >
            <h6>{{Sessions[0].title}}</h6>
            <h6 class="text-muted">
              <img :src="Sessions[0].courseimageurl"  :alt="Sessions[0].tutorname" class="circle-dp mr-2" />
              Classes by {{Sessions[0].tutorname}}
            </h6>
          </div>
          <div class="col-lg-8 pt-2 px-0">
            <div class="progress ml-auto">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 40%"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body-container bg-grey-pink-light">
      <!-- tab switcher start -->
      <div class="tab-switcher">


          <div class="d-block d-lg-none">
            <div class="container-fluid">
              <ul class="nav nav-tabs row mx-0">
                <li class="nav-item px-0 col-3"  @click="switchtotab('Sessions')">
                  <a :class="ActivePage=='Sessions'?'nav-link active':'nav-link'"  data-toggle="tab">
                    <span class="subtitle">Sessions</span>
                  </a>
                </li>
                <!-- <li class="nav-item px-0 col-5" @click="switchtotab('Material')">
                  <a :class="ActivePage=='Material'?'nav-link active':'nav-link'" data-toggle="tab">
                    <span class="subtitle">Course material</span>
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
        <div class="container">
          <div class="row">
                   
            <div class="col-lg-2 pr-0 border-right d-none d-lg-block">
              <ul class="nav nav-tabs mt-5 w-100 d-flex flex-column">
                <li  @click="switchtotab('Sessions')" :class="ActivePage=='Sessions'?'nav-item active':'nav-item'" >
                  <a :class="ActivePage=='Sessions'?'side-nav-link active':'side-nav-link'" data-toggle="tab">
                    <span class="subtitle">Sessions </span> 
                  </a>
                </li>
                <!-- <li @click="switchtotab('Material')" :class="ActivePage=='Material'?'nav-item active':'nav-item'">
                  <a   :class="ActivePage=='Material'?'side-nav-link active':'side-nav-link'"    data-toggle="tab">
                    <span class="subtitle">Course material</span>
                  </a>
                </li> -->
              </ul>
            </div>

            <div class="col-12 col-lg-10 px-0">
              <div class="tab-content row mx-0 mt-3">

                      
                <CourseSessions  v-if="this.ActivePage=='Sessions'"></CourseSessions>
                <!-- <MaterialList v-if="this.ActivePage=='Material'">  </MaterialList> -->
             
              </div>
            </div>
          </div>
         </div>
        <!-- tab switcher end -->
      </div>
      <!-- body end -->
    </div>

  </div>
</template>
<script>
// import MaterialList from "./MaterialList.vue"
import CourseSessions from "./CourseSessions.vue"
import Loader from "../../wwwroot/js/Loader.js";
export default {
  name: "MyClassContainer",
   components: {
    // MaterialList: MaterialList,
    CourseSessions: CourseSessions
  },
  data() {
    return { CourseId: 0, Sessions: [], Message: "",ActivePage:"Sessions" };
  },
  created: function () {},
  mounted: function () {

    var urlParams = new URLSearchParams(window.location.search);
    var courseid = urlParams.get("Courseid");
   var urlParamsTab = new URLSearchParams(window.location.search);
    var activepage = urlParamsTab.get("activepage");
        var batchid = urlParams.get("batchid");
    // Assign all values for crate course feature
    if (activepage == null || activepage == undefined) {
    } else {
      this.ActivePage = activepage;
    }
    if (courseid == null || courseid == undefined) {
    } else {
      this.CourseId = courseid;
    }
Loader.showAboveModal();
    //var getcourseurl = "/api/Course/getsectiondetails?CourseId=" + courseid;
      var getcourseurl = "/api/Course/getsectiondetails?CourseId=" + courseid+"&batchid="+batchid;
    this.isloading = true;
    fetch(getcourseurl, { method: "GET" })
      .then((responseJson) => responseJson.json())
      .then((response) => {
       Loader.hideAboveModal();
        if (response !== null) {
          this.Sessions = response;
          this.Message = "";
        } else {
          this.Sessions = [];
          this.Message = "Sessions Not Available";
        }
      });
  },
  methods: {
    // viewrecordingurl(session) {
    //   Loader.showAboveModal();
    //   var url = "/api/Course/recording-url?sessionId=" + session.sessionId;
    //   fetch(url, { method: "GET" })
    //     .then((responseJson) => responseJson.json())
    //     .then((response) => {
    //       Loader.hideAboveModal();
    //       if (response !== null) {
    //        window.location.href=response;
    //       } else {            
    //         alert("Recording Not Available");
    //       }
    //     });
    // },
    //   JoinClass(session) {
    //      Loader.showAboveModal();
    //   var url = "/api/Course/ClassRoom?batchId=" + session.batchId+"&courseId=" + session.courseId;  +"&UserId=" + session.userid
    //   fetch(url, { method: "GET" })
    //     .then((responseJson) => responseJson.json())
    //     .then((response) => {
    //     Loader.hideAboveModal();
    //       if (response !== null) {
    //        window.location.href=response;
    //       } else {            
    //         alert("Live Session Not Available");
    //       }
    //     });
    // },
    switchtotab(tab){
            this.ActivePage=tab;
    },
      showimage(imageurl) {
                if (imageurl == undefined || imageurl == null || imageurl == "") {
                    return false;
                } else {
                    return true;
                }
            },
  },
};
</script>
<template>
  <div class="sdialog" style="display: block" id="calendar">
    <div class="socialshare medium sdialog-block sdialog-show">
      <span>i am calendar</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "JoinClass",
  props: {
    courseId: "",
    batchid: "",
    message: "",
    sessions:[]
  },
  data() {
    return {};
  },
  created: function () {},
  mounted: function () {
    // alert(this.courseId);
    // alert(this.batchid);
    
    var getcourseurl="/api/course/getsectiondetails"
    this.isloading = true;
    fetch(getcourseurl, { method: "GET" })
      .then((responseJson) => responseJson.json())
      .then((response) => {
        this.hidecourse();
        if (response !== null) {
          this.sessions=[]
          this.message = "Session Not Available";
        } else {
         // this.message = "No live sessions available now";
          this.sessions=response
           this.message ="";
          // var temp = '<div class="joinclass medium hide"><div class="notavail"><p>' + message + '</p></div></div>';
        }
      });
  },
  methods: {
     hidecourse(){
      var content= document.getElementsByClassName("container")
      if(content.length>0){
        content[0].innerHTML = "";
      }
     //  document.getElementsByClassName(".course-wrap").add("hide")
       this.addclassname('course-wrap','class')
     },

      addclassname:function(id,type) {
  var element, name, arr;
   name = "hide";
  if(type=="id")
  {
  element = document.getElementById(id);
  element.classList.add(name);

      }
       if(type=="class")
  {
  element = document.getElementsByClassName(id);
  element[0].classList.add(name);
  //arr = element.className.split(" ");
  // if (arr.indexOf(name) == -1) {
  //   element.className += " " + name;
  // }
      }
}

  },

  
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal filter-video-popup",
      staticStyle: { display: "block" },
      attrs: { id: "Openwindow" }
    },
    [
      _c("svg", { staticClass: "d-none" }, [
        _c("defs", [
          _c("g", { attrs: { id: "downArrow" } }, [
            _c("path", {
              attrs: {
                d:
                  "M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("svg", { staticClass: "d-none" }, [
        _c("defs", [
          _c("g", { attrs: { id: "chevronBack" } }, [
            _c("path", {
              attrs: {
                d:
                  "m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "scrollable",
          staticClass:
            "modal-dialog modal-dialog-centered modal-dialog-scrollable"
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header align-items-center" }, [
              _c(
                "svg",
                {
                  staticClass: "svg-icon medium d-sm-none",
                  attrs: { viewBox: "0 0 64 64", id: "back" },
                  on: { click: _vm.onclose }
                },
                [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
              ),
              _vm._v(" "),
              _c("h5", [_vm._v(_vm._s(_vm.category))]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: { click: _vm.onclose }
                },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "body",
                staticClass: "modal-body",
                attrs: { "data-id": "popup-body" }
              },
              [
                _c("div", { staticClass: "select-topic-video-popup" }, [
                  _c("iframe", {
                    staticClass: "select-video-filter",
                    attrs: {
                      src: _vm.getYoutubeVideoUrl(
                        _vm.mainVideoDetails.videoURL
                      ),
                      frameborder: "0",
                      allow:
                        "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                      allowfullscreen: ""
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "about-topic-video" }, [
                  _c("p", { staticClass: "about-description mt-2 mb-2" }, [
                    _vm._v(_vm._s(_vm.mainVideoDetails.videoTitle))
                  ])
                ]),
                _vm._v(" "),
                _vm.relatedVideoDetailsProp.length > 1
                  ? _c("div", { staticClass: "related-video-title" }, [
                      _vm._m(0)
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.relatedVideoDetails, function(videoDetails) {
                  return _c(
                    "div",
                    {
                      staticClass: "list-detail-videos",
                      attrs: {
                        "data-video-id": _vm.getVideoId(videoDetails.videoURL),
                        "data-video-url": videoDetails.videoURL
                      },
                      on: { click: _vm.onRelatedVideoClick }
                    },
                    [
                      _c("div", { staticClass: "youtube-video" }, [
                        _c("img", {
                          staticClass: "learn-video-thumbnil",
                          attrs: {
                            src: _vm.getThumbnailLinkFromVideoLink(
                              videoDetails.videoURL
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "play-media",
                          attrs: {
                            src:
                              "https://sulcdn.azureedge.net/content/caps/homepage/play-icon.png"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "youtube-video-details" }, [
                        _c("p", { staticClass: "description-video" }, [
                          _vm._v(_vm._s(videoDetails.videoTitle))
                        ])
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Related Videos")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="section my-account mt-0 active show">
        <div class="">
            <div class="page-bg-1">
                <!-- tab switcher mobile start -->
                <!--<div class="pt-5 pb-4 d-lg-none"></div>-->
                <div class="d-block top-active-tab">
                    <ul class="nav nav-tabs">
                        <li class="nav-item col">
                            <a class="nav-link active" data-toggle="tab">
                                <!--<i
                                  @click="Backtopage()"
                                  class="far fa-arrow-left d-sm-none"
                                ></i>-->
                                <svg @click="Backtopage()" class="svg-icon xlarge mr-2 d-sm-none top-1" viewBox="0 0 64 64">
                                    <use xlink:href="#chevronBack" />
                                </svg>
                                {{ Title }}
                            </a>
                        </li>
                        <!-- <li class="nav-item px-0 col">
                          <a
                            href="http://13.232.157.102/sulekha/my-account/my-account-tabs.html#pastcourses"
                            class="nav-link"
                            data-toggle="tab"
                            >Past Courses</a
                          >
                        </li>
                        <li class="nav-item px-0 col">
                          <a
                            href="http://13.232.157.102/sulekha/my-account/my-account-tabs.html#history"
                            class="nav-link"
                            data-toggle="tab"
                            >Payment history</a
                          >
                        </li>-->
                    </ul>
                </div>
                <!-- tab switcher mobile end -->

                <div class="container d-none d-lg-block">
                    <div class="user-details mt-3 web row">
                        <div class="col-3 col-lg-1 d-flex align-items-center">
                            <!--<div class="circle-mini"
                                 v-if="showimage(UserInfo.ImageURL) == true">
                                <img class="fit-cover" :src="UserInfo.ImageURL" alt="user-dp" />
                            </div>
                            <div class="initial-image"
                                 v-if="showimage(UserInfo.ImageURL) == false">
                                <span>{{ showfirstletter(UserInfo) }}</span>
                            </div>-->
                            <!--<img class="user-dp"
                            :src="UserInfo.ImageURL"
                            alt="user-dp" />-->
                        </div>
                        <div class="col-9 pl-0 d-flex flex-column justify-content-center">
                            <p class="user-name">{{ UserInfo.FirstName }}</p>
                            <p class="user-email">{{ UserInfo.EmailAddress }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- User Details End -->
        <div class="pb-4 d-lg-none"></div>
        <div class="container">
            <div class="row">
                <div class="col-sm-2 pt-3 border-right d-none d-lg-block pr-0">
                    <div class="position-sticky top-80">
                        <h6>Account Activity</h6>
                        <ul class="nav nav-tabs mt-3 w-100 d-flex flex-column">
                            <li :class="
                  ActivePage == 'ActiveCourse' ? 'nav-item active' : 'nav-item'
                ">
                                <a @click="loadactivecourse()"
                                   :class="
                    ActivePage == 'ActiveCourse'
                      ? 'side-nav-link active'
                      : 'side-nav-link'
                  "
                                   :style="
                    MyAccountDetails.activecoursecount == 0
                      ? 'color:#ababab;'
                      : ''
                  "
                                   data-toggle="tab">Courses active ({{ MyAccountDetails.activecoursecount }})</a>
                            </li>
                            <li :class="
                  ActivePage == 'AttandedCourse'
                    ? 'nav-item active'
                    : 'nav-item'
                ">
                                <a @click="loadattandedcourse()"
                                   :class="
                    ActivePage == 'AttandedCourse'
                      ? 'side-nav-link active'
                      : 'side-nav-link'
                  "
                                   :style="
                    MyAccountDetails.paidcourseattandedcount == 0
                      ? 'color:#ababab;'
                      : ''
                  "
                                   data-toggle="tab">
                                    Courses Attended ({{
                    MyAccountDetails.paidcourseattandedcount
                                    }})
                                </a>
                            </li>
                            <li :class="
                  ActivePage == 'DemoCourse' ? 'nav-item active' : 'nav-item'
                ">
                                <a @click="loaddemocourse()"
                                   :class="
                    ActivePage == 'DemoCourse'
                      ? 'side-nav-link active'
                      : 'side-nav-link'
                  "
                                   :style="
                    MyAccountDetails.democourseattandedcount == 0
                      ? 'color:#ababab;'
                      : ''
                  "
                                   data-toggle="tab">
                                    Demo Attended ({{
                    MyAccountDetails.democourseattandedcount
                                    }})
                                </a>
                            </li>

                            <li :class="
                  ActivePage == 'PaymentHistory'
                    ? 'nav-item active'
                    : 'nav-item'
                ">
                                <a @click="loadpaymenthistory()"
                                   :class="
                    ActivePage == 'PaymentHistory'
                      ? 'side-nav-link active'
                      : 'side-nav-link'
                  "
                                   data-toggle="tab">Payments history</a>
                            </li>
                            <!-- <h6 class="pl-2 py-3">Account Settings</h6>
                            <li class="nav-item">
                              <a class="side-nav-link" data-toggle="tab">Change password</a>
                            </li>
                            <li class="nav-item">
                              <a class="side-nav-link" data-toggle="tab">Logout</a>
                            </li> -->
                        </ul>
                    </div>
                </div>

                <div class="col-12 col-sm-10">
                    <div class="tab-content my-demo-card">
                        <!-- active courses tab content start -->

                        <ActiveCourse v-if="ActivePage == 'ActiveCourse'"
                                      :ActiveCourseList="ActiveCourseList"></ActiveCourse>

                        <!-- active courses tab content end -->
                        <!-- past courses tab content start -->
                        <ActiveAttandedCourse v-if="ActivePage == 'AttandedCourse'"
                                              :AttandedCourseList="AttandedCourseList"></ActiveAttandedCourse>

                        <AttandedDemoCourse v-if="ActivePage == 'DemoCourse'"
                                            :AttandedDemoCourseList="AttandedDemoCourseList"></AttandedDemoCourse>
                        <!-- pasr courses tab content end -->
                        <!-- recordings tab content start -->

                        <PaymentHistory v-if="ActivePage == 'PaymentHistory'"
                                        :PaymentHistoryList="PaymentHistoryList"></PaymentHistory>
                        <!-- recordings tab content end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ActiveAttandedCourse from "./ActiveAttandedCourse.vue";
    import ActiveCourse from "./ActiveCourse";
    import AttandedDemoCourse from "./AttandedDemoCourse.vue";
    import PaymentHistory from "./PaymentHistory.vue";
    import Loader from "../../wwwroot/js/Loader.js";
    export default {
        name: "MyAccountDetails",
        props: {
            UserId: "",
        },
        data() {
            return {
                ActiveCourseList: [],
                AttandedCourseList: [],
                MyAccountDetails: [],
                AttandedDemoCourseList: [],
                PaymentHistoryList: [],
                ActivePage: "ActiveCourse",
                UserInfo: {},
                Title: "",
            };
        },
        created: function () { },
        mounted: function () {
            var UserInfo = document.querySelector("#myinfo");
            if (UserInfo != null) {
                if (UserInfo != null) {
                    this.UserInfo = JSON.parse(UserInfo.innerHTML);
                    this.UserId = this.UserInfo.Pid;
                }
            }

            var urlParams = new URLSearchParams(window.location.search);
            var activepage = urlParams.get("activepage");

            // var userid = urlParams.get("userid");
            // this.UserId = userid;
            // Assign all values for crate course feature
            if (activepage == null || activepage == undefined) {
            } else {
                this.ActivePage = activepage;
            }

            this.loadmyaccontdetails(); // load left side menu details
        },

        methods: {
            // gettitle(){
            //       return this.Title
            // },
            Backtopage() {
                window.location.href = "/MyAccount";
            },
            showimage(imageurl) {
                if (imageurl == undefined || imageurl == null || imageurl == "") {
                    return false;
                } else {
                    return true;
                }
            },
            showfirstletter(UserInfo) {
                var letter = "";
                if (UserInfo != null) {
                    if (UserInfo.FirstName != undefined) {
                        letter = UserInfo.FirstName.substring(0, 1);
                    } else {
                    }
                }
                return letter;
            },

            loadpaymenthistory() {
                this.Title = "Payment History";
                Loader.showAboveModal();
                var url = "/api/MyAccount/FetchPaymentHistory?UserId=" + this.UserId;
                this.isloading = true;
                fetch(url, { method: "GET" })
                    .then((responseJson) => responseJson.json())
                    .then((response) => {
                        if (response !== null) {
                            Loader.hideAboveModal();
                            this.PaymentHistoryList = response;
                            this.ActivePage = "PaymentHistory";
                        }
                    });
            },
            loaddemocourse() {
                this.Title = "Demo Course";
                if (this.MyAccountDetails.democourseattandedcount > 0) {
                    Loader.showAboveModal();
                    var url =
                        "/api/MyAccount/FetchAttandedCourseDetails?UserId=" +
                        this.UserId +
                        "&IsPaid=false";
                    this.isloading = true;
                    fetch(url, { method: "GET" })
                        .then((responseJson) => responseJson.json())
                        .then((response) => {
                            if (response !== null) {
                                Loader.hideAboveModal();
                                this.AttandedDemoCourseList = response;
                                this.ActivePage = "DemoCourse";
                            }
                        });
                }
            },
            loadattandedcourse() {
                this.Title = "Attended Course";
                if (this.MyAccountDetails.paidcourseattandedcount > 0) {
                    Loader.showAboveModal();
                    var url =
                        "/api/MyAccount/FetchAttandedCourseDetails?UserId=" +
                        this.UserId +
                        "&IsPaid=true";
                    this.isloading = true;
                    fetch(url, { method: "GET" })
                        .then((responseJson) => responseJson.json())
                        .then((response) => {
                            if (response !== null) {
                                Loader.hideAboveModal();
                                this.AttandedCourseList = response;
                                this.ActivePage = "AttandedCourse";
                            }
                        });
                }
            },
            loadactivecourse() {
                this.Title = "Active Course";

                if (this.MyAccountDetails.activecoursecount > 0) {
                    Loader.showAboveModal();
                    var url =
                        "/api/MyAccount/FetchActiveCourseDetails?UserId=" + this.UserId;
                    this.isloading = true;
                    fetch(url, { method: "GET" })
                        .then((responseJson) => responseJson.json())
                        .then((response) => {
                            if (response !== null) {
                                Loader.hideAboveModal();
                                this.ActiveCourseList = response;
                                this.ActivePage = "ActiveCourse";
                            }
                        });
                }
            },

            loadmyaccontdetails() {
                Loader.showAboveModal();
                var url =
                    "/api/MyAccount/GetMyAccountCourseDetails?UserId=" + this.UserId;
                this.isloading = true;
                fetch(url, { method: "GET" })
                    .then((responseJson) => responseJson.json())
                    .then((response) => {
                        if (response !== null) {
                            Loader.hideAboveModal();
                            this.MyAccountDetails = response;

                            if (this.ActivePage == "ActiveCourse") {
                                this.loadactivecourse();
                            }

                            if (this.ActivePage == "PaymentHistory") {
                                this.loadpaymenthistory();
                            }

                            if (this.ActivePage == "DemoCourse") {
                                this.loaddemocourse();
                            }

                            if (this.ActivePage == "AttandedCourse") {
                                this.loadattandedcourse();
                            }
                        }
                    });
            },
        },

        components: {
            ActiveAttandedCourse: ActiveAttandedCourse,
            ActiveCourse: ActiveCourse,
            AttandedDemoCourse: AttandedDemoCourse,
            PaymentHistory: PaymentHistory,
        },
    };
</script>
<template>
    <div>
            <!--<div class="modal-header-mobile d-sm-none">
                    <a class="h2 m-2" @click="closePopup()">
                        <i class="far fa-arrow-left"></i>
                    </a>
                </div>
                <div class="close" @click="closePopup()">
                    <span>&times;</span>
                </div>-->
            <!--<h2>Verify your Phone number</h2>-->
            <h5>Enter the OTP</h5>
            <div class="row">
                <p class="text-muted col-sm-8 col-12 caption">
                    We sent an OTP to your phone number <br/> {{ userDetails.CountryCode }} {{ userDetails.MobileNumber }}
                </p>
                <span class="link caption col-sm-4 col-12 text-right" @click="changemobileno()">Edit this number</span>
            </div>
            <div class="otp-form">
                <div class="otp-group otp">
                    <div class="single-input">
                        <div class="inputOtpCenter">
                            <div class="inputOtp-group phone-input" >
                                <span class="box-1"></span>
                                <span class="box-2"></span>
                                <span class="box-3"></span>
                                <span class="box-4"></span>
                                <span class="lastbar"></span>
                                <input type="number" @keydown="onKeyDown($event)" ref="code1" v-model="verificationCode" maxlength="4" />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="md-field">
                    <input pattern="\d*"
                           ref="code1"
                           @keyup="goToNextInput($event, 1)"
                           @keydown="onKeyDown($event)"
                           tabindex="1"
                           maxlength="1"
                           v-model="code1" />
                </div>
                <div class="md-field">
                    <input pattern="\d*"
                           ref="code2"
                           @keyup="goToNextInput($event, 2)"
                           @keydown="onKeyDown($event)"
                           tabindex="2"
                           maxlength="1"
                           v-model="code2" />
                </div>
                <div class="md-field">
                    <input pattern="\d*"
                           ref="code3"
                           @keyup="goToNextInput($event, 3)"
                           @keydown="onKeyDown($event)"
                           tabindex="3"
                           maxlength="1"
                           v-model="code3" />
                </div>
                <div class="md-field">
                    <input pattern="\d*"
                           ref="code4"
                           @keyup="goToNextInput($event, 4)"
                           @keydown="onKeyDown($event)"
                           tabindex="4"
                           maxlength="1"
                           v-model="code4" />
                </div> -->

                </div>
                <span :class="(invalidOtp && !userDetails.IsMissedCallVerfication)?'error-text show':'error-text'"> Please enter a valid OTP </span>
                <label class="caption" v-if="!resetotp">
                    Resend OTP in {{countDown}} Seconds
                </label>
                <a v-if="resetotp" class="link" @click="resendotp()">Resend now</a>
            </div>

            <!--<div class="my-4">
                <div class="text-center separator">
                    <h6>
                        <span>&nbsp; OR &nbsp;</span>
                    </h6>
                </div>
            </div>
            <div class="p-3 text-center">
                <label class="mb-2">
                    Give us a missed call from {{ userDetails.MobileNumber }} to <br />
                    <a class="link" href="tel:1800-120-457-896">  1800-120-457-896 </a>
                </label>
                <a v-on:click="missedCallClick()" class="btn btn-outline-primary">Click here after call</a>
                <span :class="(invalidOtp && userDetails.IsMissedCallVerfication)?'error-text show':'error-text'"> We have not received your missed call. Please try again. </span>
            </div>-->
    </div>
</template>
<script>
    export default {
        name: "CommonVerification",
        props: {
            userDetails: {},
            nextStep: { type: Function },
            invalidOtp: { type: Boolean },
            hidepopup: { type: Function }
        },
        data() {
            return {
                verificationCode: "",
                code1: "",
                code2: "",
                code3: "",
                code4: "",
                verificationCodeErrorText: "",
                isFormvalid: true,
                inValidOtpErrorText: "Please enter a valid OTP",
                countDown: 120,
                resetotp: false
            };
        },
        mounted() {
            this.$refs.code1.focus();
            this.countDownTimer()
        },
        watch: {
            verificationCode: function (vcode) {
                /*debugger;*/
                if (vcode.length >= 4) {
                    this.onSubmit();
                }
            }
        },
        methods: {
            closePopup() {
                this.hidepopup();
            },
            countDownTimer() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        if (this.countDown == 1) {
                            this.resetotp = true;
                        }
                        this.countDownTimer()
                    }, 1000)
                }
            },
            proceedNextStep() {
                this.nextStep();
            },
            // goToNextInput(e, code) {
            //     var key = e.which;
            //     if (key == 8 || key == 46) {
            //         if (key == 8) {
            //             // back space
            //             if (code == 1) {
            //                 // this.$refs.code2.focus();
            //             } else if (code == 2) {
            //                 this.$refs.code1.focus();
            //             } else if (code == 3) {
            //                 this.$refs.code2.focus();
            //             } else if (code == 4) {
            //                 this.$refs.code3.focus();
            //             }
            //         }
            //         return true;
            //     }

            //     if (key != 9 && (key < 48 || key > 57)) {
            //         e.preventDefault();
            //         return false;
            //     }

            //     if (code == 1) {
            //         this.$refs.code2.focus();
            //     } else if (code == 2) {
            //         this.$refs.code3.focus();
            //     } else if (code == 3) {
            //         this.$refs.code4.focus();
            //     } else if (code == 4) {
            //         this.onSubmit();
            //     }

            //     if (key === 9) {
            //         return true;
            //     }
            // },


            onKeyDown(evt) {
                if (this.verificationCode.length <= 4) {
                    var key = evt.keyCode;
                    if (key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
                        return true;
                    }
                    evt.preventDefault();
                    return;
                }
            },
            // onKeyDown(e) {
            //     var key = e.which;
            //     if (key == 8 || key == 46) {
            //         return true;
            //     }
            //     if (key === 9 || (key >= 48 && key <= 57)) {
            //         return true;
            //     }

            //     e.preventDefault();
            //     return false;
            // },

            changemobileno() {
                this.resetUserDetails();
                this.userDetails.IsChangeMobile = true;
                this.$emit("post-validateCode", this.userDetails);
            },
            resendotp() {
                this.resetUserDetails();
                this.userDetails.IsReSendCode = true;
                this.countDown = 120;
                this.resetotp = false
                this.$emit("post-validateCode", this.userDetails);
            },

            missedCallClick() {
                this.resetUserDetails();
                this.userDetails.IsMissedCallVerfication = true;
                this.$emit("post-validateCode", this.userDetails);
            },
            onSubmit() {
                //  this.verificationCode = `${this.code1.trim()}${this.code2.trim()}${this.code3.trim()}${this.code4.trim()}`;
                if (this.verificationCode.length == 4) {
                    this.resetUserDetails();
                    this.userDetails.VerificationCode = this.verificationCode.trim();
                    this.$emit("post-validateCode", this.userDetails);
                } else {
                    this.isFormvalid = false;
                    this.verificationCodeErrorText = "Enter a valid 4 digit OTP";
                }
                if (this.verificationCode.length == 0) {
                    this.isFormvalid = false;
                    this.verificationCodeErrorText = "Please enter a OTP";
                }
            },
            resetUserDetails() {
                this.userDetails.VerificationCode = '';
                this.userDetails.IsChangeMobile = false;
                this.userDetails.IsReSendCode = false;
                this.userDetails.IsMissedCallVerfication = false;
            }
        },
    };
</script>
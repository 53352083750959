<template>
    <div id="Openwindow" class="modal filter-video-popup" style="display: block;">
        <svg class="d-none">
            <defs>
                <g id="downArrow">
                    <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                </g>
            </defs>
        </svg> <svg class="d-none">
            <defs>
                <g id="chevronBack">
                    <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path>
                </g>
            </defs>
        </svg>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" ref="scrollable">
            <div class="modal-content">
                <div class="modal-header align-items-center">
                    <svg viewBox="0 0 64 64" id="back" class="svg-icon medium d-sm-none" @click="onclose"><use xlink:href="#chevronBack"></use></svg>
                    <h5>{{ category }}</h5> <button type="button" class="close" @click="onclose">×</button>
                </div>
                <div class="modal-body" ref="body" data-id="popup-body">
                    <div class="select-topic-video-popup" >
                        <iframe class="select-video-filter" :src="getYoutubeVideoUrl(mainVideoDetails.videoURL)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                    </div>
                    <div class="about-topic-video">
                        <p class="about-description mt-2 mb-2">{{mainVideoDetails.videoTitle}}</p>
                        <!-- <p class="viewers">{{mainVideoDetails.viewsCount}} views</p> -->
                    </div>
                    <!-- <div class="icon-details-like">
                        <span class="icons-center-alignment"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#959199" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M31.99414,104h48a0,0,0,0,1,0,0V208a0,0,0,0,1,0,0h-48a8,8,0,0,1-8-8V112A8,8,0,0,1,31.99414,104Z" fill="none" stroke="#959199" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M79.99414,104l40-80a32,32,0,0,1,32,32V80h61.87549a16,16,0,0,1,15.87644,17.98456l-12,96A16,16,0,0,1,201.86963,208H79.99414" fill="none" stroke="#959199" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg><p class="icon-content-show">{{mainVideoDetails.likesCount}} K</p></span>
                        <span class="icons-center-alignment"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#959199" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M31.99414,48h48a0,0,0,0,1,0,0V152a0,0,0,0,1,0,0h-48a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8Z" fill="none" stroke="#959199" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M79.99414,152l40,80a32,32,0,0,0,32-32V176h61.87549a16,16,0,0,0,15.87644-17.98456l-12-96A16,16,0,0,0,201.86963,48H79.99414" fill="none" stroke="#959199" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg><p class="icon-content-show">100</p></span>
                        <span class="icons-center-alignment"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#959199" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="176 152 224 104 176 56" fill="none" stroke="#959199" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><path d="M192,216H40a8,8,0,0,1-8-8V88" fill="none" stroke="#959199" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M75.0245,175.99967A96.04041,96.04041,0,0,1,168,104h56" fill="none" stroke="#959199" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg><p class="icon-content-show">Share</p></span>
                        <span class="icons-center-alignment"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#959199" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="128" r="96" fill="none" stroke="#959199" stroke-miterlimit="10" stroke-width="16"></circle><polyline points="128 72 128 128 184 128" fill="none" stroke="#959199" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg><p class="icon-content-show">Watch later</p></span>
                    </div> -->
                    <div class="related-video-title" v-if="relatedVideoDetailsProp.length > 1">
                        <p><b>Related Videos</b></p>
                    </div>
                    <div v-for="videoDetails in relatedVideoDetails" class="list-detail-videos"  @click="onRelatedVideoClick" :data-video-id="getVideoId(videoDetails.videoURL)" :data-video-url="videoDetails.videoURL">
                        <div class="youtube-video">
                            <img class="learn-video-thumbnil" :src="getThumbnailLinkFromVideoLink(videoDetails.videoURL)">
                            <img class="play-media" src="https://sulcdn.azureedge.net/content/caps/homepage/play-icon.png">
                        </div>
                        <div class="youtube-video-details">
                            <p class="description-video">{{videoDetails.videoTitle}}</p>
                            <!-- <p class="views-video">58 views</p>
                            <p><span class="like-counts"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M31.99414,104h48a0,0,0,0,1,0,0V208a0,0,0,0,1,0,0h-48a8,8,0,0,1-8-8V112A8,8,0,0,1,31.99414,104Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M79.99414,104l40-80a32,32,0,0,1,32,32V80h61.87549a16,16,0,0,1,15.87644,17.98456l-12,96A16,16,0,0,1,201.86963,208H79.99414" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg> 10K</span></p> -->
                        </div>
                    </div>
                    <!--<div class="list-detail-videos">
                        <div class="youtube-video">
                            <iframe class="list-video-filter" src="https://www.youtube.com/embed/uzakZP_gKUM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                        <div class="youtube-video-details">
                            <p class="description-video">How to Introduce yourself in a social Conversation</p>
                            <p class="views-video">58 views</p>
                            <p><span class="like-counts"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M31.99414,104h48a0,0,0,0,1,0,0V208a0,0,0,0,1,0,0h-48a8,8,0,0,1-8-8V112A8,8,0,0,1,31.99414,104Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M79.99414,104l40-80a32,32,0,0,1,32,32V80h61.87549a16,16,0,0,1,15.87644,17.98456l-12,96A16,16,0,0,1,201.86963,208H79.99414" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg> 10K</span></p>
                        </div>
                    </div>
                    <div class="list-detail-videos">
                        <div class="youtube-video">
                            <iframe class="list-video-filter" src="https://www.youtube.com/embed/1AockS-yrAc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                        <div class="youtube-video-details">
                            <p class="description-video">How to Introduce yourself in a social Conversation</p>
                            <p class="views-video">58 views</p>
                            <p><span class="like-counts"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M31.99414,104h48a0,0,0,0,1,0,0V208a0,0,0,0,1,0,0h-48a8,8,0,0,1-8-8V112A8,8,0,0,1,31.99414,104Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M79.99414,104l40-80a32,32,0,0,1,32,32V80h61.87549a16,16,0,0,1,15.87644,17.98456l-12,96A16,16,0,0,1,201.86963,208H79.99414" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg> 10K</span></p>
                        </div>
                    </div>
                    <div class="list-detail-videos">
                        <div class="youtube-video">
                            <iframe class="list-video-filter" src="https://www.youtube.com/embed/WbZ-fnRF5mI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                        <div class="youtube-video-details">
                            <p class="description-video">How to Introduce yourself in a social Conversation</p>
                            <p class="views-video">58 views</p>
                            <p><span class="like-counts"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M31.99414,104h48a0,0,0,0,1,0,0V208a0,0,0,0,1,0,0h-48a8,8,0,0,1-8-8V112A8,8,0,0,1,31.99414,104Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M79.99414,104l40-80a32,32,0,0,1,32,32V80h61.87549a16,16,0,0,1,15.87644,17.98456l-12,96A16,16,0,0,1,201.86963,208H79.99414" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg> 10K</span></p>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import scrollToTop from '../../wwwroot/js/Common/ScrollToTop';
    import ScrollToTop from "../../wwwroot/js/Common/ScrollToTop";


    export default {
        name: "VideoPlayerPopup",
        props: {
            relatedVideoDetailsProp: Array,
            category: String,
            mainVideoURL: String,
        },
        data() {
            return {
               
                mainVideoDetails: this.mainVideoDetailsProp,
                relatedVideoDetails: this.relatedVideoDetailsProp,
            }
        },
         beforeMount: function () {
                 for(let i =0; i< this.relatedVideoDetails.length; i++) {
                         if(this.relatedVideoDetails[i].videoURL == this.mainVideoURL) {
                            this.mainVideoDetails = this.relatedVideoDetails[i];
                            this.relatedVideoDetails.splice(i, 1);
                        }
                    }
            },
        methods: {
            onclose: function ()  {
                document.body.classList.remove("overflow-hidden");
                var vueBaseDiv = document.querySelector("#video-player-popup-base-div");
                vueBaseDiv.innerHTML = "";
                var div = document.createElement("div");
                div.id = "video-player-popup-div";
                vueBaseDiv.appendChild(div);
                this.$emit('onClose');
            },
            getMainVideoUrl: (videoId) => {
                return 'https://www.youtube.com/embed/' + videoId + '?autoplay=1';
            },
            getYoutubeVideoUrl: function (url) {
                return 'https://www.youtube.com/embed/' + this.getVideoId(url) + '?autoplay=1';
            },
            onRelatedVideoClick: function (event) {
                console.log("clicked");
                let videoId = event.currentTarget.getAttribute("data-video-id");
                let url = event.currentTarget.getAttribute("data-video-url");
                
                this.relatedVideoDetails.push(this.mainVideoDetails);
                for(let i =0; i< this.relatedVideoDetails.length; i++) {
                     if(this.relatedVideoDetails[i].videoURL == url) {
                        this.mainVideoDetails = this.relatedVideoDetails[i];
                        this.relatedVideoDetails.splice(i, 1);
                    }
                }
                var options = {
                        top:       0,        // Number of pixels along the Y axis to scroll the window or element
                        left:      0,        // Number of pixels along the X axis to scroll the window or element.
                        behavior:  'smooth'  // ('smooth'|'auto') - animate smoothly, or move in a single jump
                    }
                //document.querySelector("[data-id='popup-body']").scroll(options);
                this.$refs.body.scroll(options);
                // this.scrollTo(document.querySelector("[data-id='popup-body']"), 0, 3000);
            },
            getVideoId: function (url) {
                if(url) {
                    return url.replace("https://youtu.be/","");
                }   
            },
            getThumbnailLinkFromVideoLink: function (videoLink) {
                 if(videoLink) {
                    return `https://img.youtube.com/vi/${this.getVideoId(videoLink)}/sddefault.jpg`;
                }
            },
            scrollTo: function (element, to, duration) {
                if (duration <= 0) return;
                var difference = to - element.scrollTop;
                var perTick = difference / duration * 10;

                setTimeout(function () {
                    element.scrollTop = element.scrollTop + perTick;
                    if (element.scrollTop == to) return;
                    scrollTo(element, to, duration - 10);
                }, 10);
            },
        }
    }
</script>
<template>
  <div>
    <svg class="d-none">
      <defs>
        <g id="downArrow">
          <path
            d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
          ></path>
        </g>
      </defs>
    </svg>

    <button type="button" class="close cabso mr-2 mt-2" @click="close()">
      &times;
    </button>

    <!-- Content goes here -->
    <div class="row mx-0">
      <!-- Left block start -->
      <DisplayUserInfor :instructorDetails="instructorDetails" :enableInstantLiveOffline="enableInstantLiveOffline" />
      <!-- Right block start -->
      <div class="col col-lg-6">
          <div class="form course-sign">
              <!-- Title start -->

              <h5>Enter your name and phone number</h5>
              <p class="sub-text">
                  This phone number will be used for all course-related
                  communications. We do not share numbers with any third party
              </p>

              <div class="md-field mb-3">
                  <input type="text"
                         @blur="validateUserInfoOnKeyPress('username')"
                         @focus="validateUserInfoOnKeyPress('username')"
                         @keyup="validateUserInfoOnKeyPress('username')"
                         name="name"
                         id="name"
                         required=""
                         placeholder="First and last name"
                         v-model="userName" />
                  <label alt="name" for="name" placeholder="First and last name">
                      First and last name
                  </label>
                  <span class="error-text"
                        v-bind:class="{ show: nameError != '' && isFormvalid == false }">{{ nameError }}</span>
              </div>

              <div class="md-field mb-3">
                  <input type="email"
                         @blur="validateUserInfoOnKeyPress('email')"
                         @focus="validateUserInfoOnKeyPress('email')"
                         @keyup="validateUserInfoOnKeyPress('email')"
                         name="email"
                         id="email"
                         required=""
                         placeholder="Email ID"
                         v-model="userEmail" />
                  <label alt="email" for="email"> Email ID </label>
                  <span class="error-text"
                        v-bind:class="{ show: emailError != '' && isFormvalid == false }">{{ emailError }}</span>
              </div>

              <div class="phone-input md-field mb-3">
                  <!--<select class="country" @click="openCountrySearch()" >-->
                  <!--<option value="91" selected="">91</option>-->
                  <!--</select>-->
                  <span class="country" @click="openCountrySearch()">
                      + {{ +this.courseSignUpClientData.CountryCode }}
                      <small>
                          <svg class="svg-icon medium" viewBox="0 0 448 512">
                              <use xlink:href="#downArrow"></use>
                          </svg>
                          <i class="far fa-chevron-down"></i>
                      </small>
                  </span>
                  <input name="mobile"
                         type="number"
                         @keydown="onKeyDown($event)"
                         @keyup="validateUserInfoOnKeyPress('mobile')"
                         class="mobile"
                         @focus="validateUserInfoOnKeyPress('mobile')"
                         @blur="validateUserInfoOnKeyPress('mobile')"
                         id="mobile"
                         required=""
                         placeholder="Your phone number"
                         v-model="mobileNumber" />
                  <label alt="email" for="mobile"> Your phone number </label>
                  <span class="error-text"
                        v-bind:class="{ show: mobileError != '' && isFormvalid == false }">{{ mobileError }}</span>

                  <div class="dropdown-wrapper"
                       :class="{ active: showcountrysearch }">
                      <CountryCode v-model="selection" value=""
                                   :selectionCallBack="countryCodeSelectionCallBack"></CountryCode>
                  </div>
              </div>

              <div  class="md-field mb-3" v-if="!hidePincode">
                      <!-- v-if="!hidePincode" -->
                      <!-- <input class="pincodeinput" name="pincode" @click="openPincodeSearch()" autocomplete="off" type="number" required="" readOnly="readOnly"
                             @keydown="onKeyDownPincode($event)"
                             @keyup="validateUserInfoOnKeyPress('pincode')"
                             @focus="validateUserInfoOnKeyPress('pincode')"
                             @blur="validateUserInfoOnKeyPress('pincode')"
                             v-model="pincode"> -->
                      <!-- <svg @click="openPincodeSearch()" class="drop-arrow-icon-place pincodesvg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="208 96 128 176 48 96" fill="none" stroke="#8d8d8d" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg> -->
                  <!-- <label @click="openPincodeSearch()" class="pincodelabel" v-if="pincode == '' || pincode == null" alt="pincode" for="pincode">Pincode</label> -->
                <Pincode :pincodeProp="pincode" :pincodeData="pincodeData" @input="pincodeSelectionCallBack"
                               :selectionCallBack="pincodeSelectionCallBack"></Pincode>
                  <span class="error-text"
                        v-bind:class="{ show: pincodeError != '' && isFormvalid == false }">{{ pincodeError }}</span>
              </div>

              <!--<div class="form-check d-flex align-items-center py-2" v-if="instructorDetails.singuptype!='oneonone'">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" v-on:click="whatsappOpted()">
        <label class="form-check-label" for="inlineCheckbox1">
            <img src="https://lscdn.blob.core.windows.net/elearn/whatsapp-duotone.svg" alt="WhatsApp" width="20">
            Opt-in for Whatsapp notifications
        </label>
    </div>-->
              <div class="material-checkbox small"
                   v-if="instructorDetails.singuptype != 'oneonone'"
                   v-on:click="whatsappOpted()">
                  <input type="checkbox" id="inlineCheckbox1" checked="checked" />
                  <label for="inlineCheckbox1">
                      <img src="https://lscdn.blob.core.windows.net/elearn/whatsapp-duotone.svg"
                           class="mr-1"
                           alt="WhatsApp"
                           width="20" />
                      Opt-in for Whatsapp notifications
                  </label>
              </div>

              <p class="caption py-2 terms-condition">
                  By clicking next you are accepting our
                  <a href="/terms" target="_blank" class="link">
                      Terms and Conditions
                  </a>
              </p>

              <div class="bottom-sticky-btn">
                  <a class="btn btn-block btn-primary"
                     v-on:click="submitUserInfo()">Next</a>
              </div>
              
              <!-- <div class="my-5">
      <div class="text-center separator">
        <h6>
          <span>&nbsp; OR &nbsp;</span>
        </h6>
      </div>
    </div> -->
              <p class="caption mt-2" v-if="showlink()">
                  Already have an account?
                  <a class="link" id="CoursesignInWeb" @click="signinclick()">Log in</a>
              </p>
          </div>
      </div>
      <!-- Right  block end -->
    </div>

    <!-- Modal end -->
  </div>
</template>
<script>
    import DisplayUserInfor from "./DisplayUserInfor.vue";
    import CountryCode from "../Common/CountryCode.vue";
    import SulGA from "../../wwwroot/js/Ga.js";
    import Pincode from "../Common/Pincode.vue";

    export default {
        name: "UserSignUp",
        props: {
            instructorDetails: {},
            courseSignUpClientData: {},
            userInfo: {},
            selectedDate: "",
            pincodeData: Array,
            isValidName: { type: Function },
            isValidEMail: { type: Function },
            isValidMobileNumber: { type: Function },
            isValidPincode: { type: Function },
            closePopup: { type: Function },
            SignUpType: "",
            errormsg: "",
            enableInstantLiveOffline: false,
            hidePincode: false,
        },
        data() {
            return {
                mobileNumber: "",
                userName: "",
                userEmail: "",
                mobileError: "",
                nameError: "",
                emailError: "",
                pincodeError: "",
                isFormvalid: true,
                date: "",
                time: "",
                day: "",
                month: "",
                whatsapp: true,
                CountryCode: "91",
                selection: "",
                selectionPincode: "",
                showcountrysearch: false,
                showpincodesearch: false,
                pincode: "",
            };
        },
        watch: {
            userInfo: function (userInfo) {
                this.mobileNumber =
                    userInfo.MobileNumber != null || userInfo.MobileNumber != ""
                        ? userInfo.MobileNumber
                        : "";
                this.userName =
                    userInfo.FirstName != null || userInfo.FirstName != ""
                        ? userInfo.FirstName
                        : "";
                this.userEmail =
                    userInfo.EmailAddress != null || userInfo.EmailAddress != ""
                        ? userInfo.EmailAddress
                        : "";
                this.pincode =
                    userInfo.Pincode != null || userInfo.Pincode != ""
                        ? userInfo.Pincode
                        : "";
                this.CountryCode =
                    userInfo.CountryCode != null || userInfo.CountryCode != ""
                        ? userInfo.CountryCode
                        : "91";
                this.pincode =
                    userInfo.Pincode != null || userInfo.Pincode != ""
                    ? userInfo.Pincode
                        : "";
            },
            errormsg: function (errormsg) {
                if (errormsg != "") {
                    var msg = "";
                    var msgarray = errormsg.split(",");
                    if (msgarray.length > 0) {
                        msg = msgarray[0];
                    }
                    this.isFormvalid = false;
                    this.mobileError = msg;
                } else {
                    this.isFormvalid = true;
                    this.mobileError = "";
                }
            },
            instructorDetails: function (instructorDetails) {
                //  alert(6)
            },
        },
        mounted() {
            // SulGA.init();
            // SulGA.pushPageView();
            this.mobileNumber =
                this.userInfo.MobileNumber != null || this.userInfo.MobileNumber != ""
                    ? this.userInfo.MobileNumber
                    : "";
            this.userName =
                this.userInfo.FirstName != null || this.userInfo.FirstName != ""
                    ? this.userInfo.FirstName
                    : "";
            this.userEmail =
                this.userInfo.EmailAddress != null || this.userInfo.EmailAddress != ""
                    ? this.userInfo.EmailAddress
                    : "";
            this.pincode =
                this.userInfo.Pincode != null || this.userInfo.Pincode != ""
                    ? this.userInfo.Pincode
                    : "";
            if (this.courseSignUpClientData.CountryCode != "+91" && this.courseSignUpClientData.CountryCode != "91")
                this.hidePincode = true;
            else
                this.hidePincode = false;
            this.CountryCode =
                this.courseSignUpClientData.CountryCode != null && this.courseSignUpClientData.CountryCode != "" && this.courseSignUpClientData.CountryCode != undefined
                    ? this.courseSignUpClientData.CountryCode
                    : this.CountryCode;

        },
        methods: {
            close() {
                this.closePopup();
            },
            submitUserInfo() {
                if (this.validateUserInfo() == true) {
                    this.courseSignUpClientData.Email = this.userEmail;
                    this.courseSignUpClientData.Name = this.userName;
                    this.courseSignUpClientData.MobileNo = this.mobileNumber;
                    this.courseSignUpClientData.CourseTitle = this.instructorDetails.CourseName;
                    this.courseSignUpClientData.IsConsentedToWhatsapp = this.whatsapp;
                    this.courseSignUpClientData.SignUpType = this.SignUpType; //'Demo Payment';
                    this.courseSignUpClientData.CountryCode = this.CountryCode;
                    this.courseSignUpClientData.Pincode = this.pincode;

                    // to reatin the value when edit the
                    this.userInfo.FirstName = this.userName;
                    this.userInfo.EmailAddress = this.userEmail;
                    this.userInfo.CountryCode = this.CountryCode;
                    this.userInfo.Pincode = this.pincode;

                    if (this.CountryCode == "91") {
                        this.courseSignUpClientData.IsNri = false;
                    } else {
                        this.courseSignUpClientData.IsNri = true;
                    }

                    SulGA.pushEvent(
                        "student-details",
                        "student details saved",
                        "student-details-next"
                    );

                    this.$emit("post-postCourseSignUp", this.courseSignUpClientData);
                }
            },
            whatsappOpted() {
                this.whatsapp = this.whatsapp ? false : true;
                SulGA.pushEvent(
                    "student-details-whatsapp",
                    "whatsapp selected",
                    "student-details-whatsapp-click"
                );
            },

            onKeyDown(e) {
                var key = e.which;

                if (
                    key == 8 ||
                    key == 9 ||
                    key == 46 ||
                    (key >= 48 && key <= 57) ||
                    (key >= 96 && key <= 105)
                ) {
                    return true;
                }
                e.preventDefault();
                return false;
            },

            onKeyDownPincode(e) {
                e.preventDefault();
                return false;
            },

            validateUserInfoOnKeyPress(source) {
                this.showcountrysearch = false;

                this.isFormvalid = false;
                if (source == "mobile") {
                    if (
                        this.mobileNumber == null ||
                        this.mobileNumber == "" ||
                        this.mobileNumber == undefined
                    )
                        this.mobileError = "Mobile number is mandatory";
                    else if (!this.isValidMobileNumber(this.mobileNumber, this.CountryCode))
                        this.mobileError = "Enter a 10 digit mobile number";
                    else this.mobileError = "";

                    return;
                }

                if (source == "username") {
                    if (
                        this.userName == null ||
                        this.userName == "" ||
                        this.userName == undefined
                    )
                        this.nameError = "User name is mandatory";
                    else if (!this.isValidName(this.userName))
                        this.nameError = "Enter full name";
                    else this.nameError = "";

                    return;
                }

                if (source == "email") {
                    if (
                        this.userEmail == null ||
                        this.userEmail == "" ||
                        this.userEmail == undefined
                    )
                        this.emailError = "Email is mandatory";
                    else if (!this.isValidEMail(this.userEmail))
                        this.emailError = "Enter a valid email id";
                    else this.emailError = "";

                    return;
                }

                if (source == "pincode") {
                    if (
                        this.pincode == null ||
                        this.pincode == "" ||
                        this.pincode == undefined
                    )
                        this.pincodeError = "Pincode is mandatory";
                    else if (!this.isValidPincode(this.pincode))
                        this.pincodeError = "Enter a valid pincode";
                    else this.pincodeError = "";

                    return;
                }
            },

            validateUserInfo() {
                if (
                    this.mobileNumber == null ||
                    this.mobileNumber == "" ||
                    this.mobileNumber == undefined
                )
                    this.mobileError = "Mobile number is mandatory";
                else if (!this.isValidMobileNumber(this.mobileNumber, this.CountryCode))
                    this.mobileError = "Not a valid Mobile number";
                else this.mobileError = "";

                if (
                    this.userName == null ||
                    this.userName == "" ||
                    this.userName == undefined
                )
                    this.nameError = "User name is mandatory";
                else if (!this.isValidName(this.userName))
                    this.nameError = "Not a valid User name";
                else this.nameError = "";

                if (
                    this.userEmail == null ||
                    this.userEmail == "" ||
                    this.userEmail == undefined
                )
                    this.emailError = "Email is mandatory";
                else if (!this.isValidEMail(this.userEmail))
                    this.emailError = "Not a valid Email";
                else this.emailError = "";

                if (this.CountryCode == '+91' || this.CountryCode == '91') {
                    if (
                        this.pincode == null ||
                        this.pincode == "" ||
                        this.pincode == undefined
                    )
                        this.pincodeError = "Pincode is mandatory";
                    else if (!this.isValidPincode(this.pincode))
                        this.pincodeError = "Not a valid Pincode";
                    else this.pincodeError = "";
                }
                else { this.pincodeError = ""; this.pincode = ""; }

                if (
                    this.mobileNumber != "" &&
                    this.userName != "" &&
                    this.userEmail != "" &&
                    this.isValidEMail(this.userEmail) &&
                    this.isValidName(this.userName) &&
                    this.isValidMobileNumber(this.mobileNumber, this.CountryCode)
                ) {
                    if (this.CountryCode == '+91' || this.CountryCode == '91') {
                        if (
                            this.pincode == "" ||
                            !this.isValidPincode(this.pincode)
                        ) {
                            this.isFormvalid = false;
                            return false;
                        }
                    }
                    this.nameError = "";
                    this.mobileError = "";
                    this.emailError = "";
                    this.pincodeError = "";
                    this.locationError = "";
                    this.isFormvalid = true;
                    return true;
                } else {
                    this.isFormvalid = false;
                    return false;
                }
            },
            showlink() {
                //    if(this.userInfo.MobileNumber==undefined){
                //        return true;
                //    }
                //    if(this.userInfo.MobileNumber != null || this.userInfo.MobileNumber != ''){
                //        return false;
                //    }

                return false;
            },
            signinclick() {
                document.getElementById("signInWeb").click();
            },
            openCountrySearch() {
                SulGA.pushEvent(
                    "student-details-ccode",
                    "country code",
                    "student-details-ccode-click"
                );
                this.showcountrysearch = true;
                this.showpincodesearch = false;

            },
            openPincodeSearch() {
                SulGA.pushEvent(
                    "student-details-pincode",
                    "pincode",
                    "student-details-pincode-click"
                );
                this.showpincodesearch = true;
                this.showcountrysearch = false;
            },
            countryCodeSelectionCallBack(countrycode) {
                this.showcountrysearch = false;
                this.CountryCode = countrycode;
                this.courseSignUpClientData.CountryCode = countrycode;
                if (countrycode != "+91" && countrycode != "91")
                    this.hidePincode = true;
                else
                    this.hidePincode = false;
                var taglabel = countrycode + "country code";
                SulGA.pushEvent(
                    "student-details-ccode-select",
                    taglabel,
                    "student-details-ccode-click"
                );
            },
            pincodeSelectionCallBack(code) {
                this.showpincodesearch = false;
                this.pincode = code;
                this.userInfo.Pincode = code;
                this.courseSignUpClientData.Pincode = code;
                this.validateUserInfoOnKeyPress('pincode');
                var taglabel = code + "pincode";
                SulGA.pushEvent(
                    "student-details-pincode-select",
                    taglabel,
                    "student-details-pincode-click"
                );
            },
            onDropDownFocus () {
                console.log("drop down focus");
            },
            onDropDownBlur () {
                console.log("drop Down blur");
            }
        },
        components: {
            DisplayUserInfor: DisplayUserInfor,
            CountryCode: CountryCode,
            Pincode: Pincode,
        },
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "search-block" }, [
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "text",
          "data-id": "country-code-input",
          placeholder: "Search"
        },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            return _vm.updateValue($event.target.value)
          },
          blur: _vm.onBlur,
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              return _vm.up($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              return _vm.down($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.enter($event)
            }
          ]
        }
      })
    ]),
    _vm._v(" "),
    _c("i", { staticClass: "far fa-search" }),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "options" },
      _vm._l(_vm.matches, function(suggestion, index) {
        return _c(
          "li",
          {
            key: index,
            class: { active: _vm.isActive(index) },
            attrs: { countrycodesuggestion: "countrycodeinput" },
            on: {
              click: function($event) {
                return _vm.suggestionClick(index)
              }
            }
          },
          [
            _vm._v(
              "\n            " + _vm._s(suggestion.countrycode) + "\n        "
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
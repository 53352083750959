<template>
    <div class="form">
        <div class="md-field mb-3" :class="{invalid: nameErrorText != ''}">
            <input type="text" name="name" id="name" @keyup="onKeyUpFun($event,'name')" required placeholder="First and last name" v-model="userDetails.UserName" />
            <label alt="name" for="name" placeholder="First and last name">
                First and last name
            </label>
            <span class="error-text">{{nameErrorText}}</span>
        </div>
        <div class="md-field mb-3" :class="{invalid: emailErrorText != ''}">
            <input type="email" name="email" id="email" @keyup="onKeyUpFun($event,'email')" required placeholder="Email ID" v-model="userDetails.EmailId" />
            <label alt="email" for="email"> Email ID </label>
            <span class="error-text">{{emailErrorText}}</span>
        </div>
        <div class="phone-input md-field mb-3" :class="{invalid: mobileErrorText != ''}">
            <h6 class="country" @click="openCountrySearch()">
                <!--@click="openCountrySearch()"-->
                {{ userDetails.CountryCode }}
                <small><i class="far fa-chevron-down"></i></small>
            </h6>
            <input type="number" id="mobile" name="mobile" class="mobile" @keydown="onKeyDownFun($event,'mobileno')" required placeholder="Your phone number" v-model="userDetails.MobileNumber" />
            <label alt="mobile" for="mobile"> Your phone number </label>
            <span class="error-text">{{mobileErrorText}}</span>
            <div class="dropdown-wrapper" :class="{ active: showcountrysearch }">
                <CountryCode v-model="selection" :selectionCallBack="countryCodeSelectionCallBack"></CountryCode>
            </div>
        </div>
        <!--<div v-if="!hidePincode" class="md-field mb-3" :class="{invalid: pincodeErrorText != ''}">
            <input type="number" name="pincode" id="pincode" @keydown="onKeyDownFun($event,'pincode')" required placeholder="Pincode" v-model="userDetails.Pincode" />
            <label alt="pincode" for="pincode"> Pincode </label>
            <span class="error-text">{{pincodeErrorText}}</span>
        </div>-->

        <!--<div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
        <label class="form-check-label" for="inlineCheckbox1">
            Receive important notifications on
            <img src="/images/icons/whatsapp-duotone.svg" alt="WhatsApp" width="20" />
            WhatsApp
        </label>
    </div>-->
        <div class="my-3 d-lg-block">
            <label>
                By clicking next you are accepting our
                <a href="/terms" target="_blank" class="link"> Terms and Conditions </a>
            </label>
        </div>
        <button type="button" class="btn btn-primary btn-block" @click="submit()">Next</button>

        <div class="md-field mb-3" :class="{invalid: errorText != ''}">
            <span class="error-text">{{errorText}}</span>
        </div>

        <div class="my-5">
            <div class="text-center separator">
                <h6>
                    <span>&nbsp; OR &nbsp;</span>
                </h6>
            </div>
        </div>
        <label class="caption">
            Already have an account?
            <a href="#" class="link" @click="openSignInFun()">Log in</a>
        </label>
    </div>
</template>
<script>
    import CountryCode from "../Common/CountryCode.vue";

    export default {
        name: "SignUp",
        props: {
            nameErrorText: { type: String },
            emailErrorText: { type: String },
            mobileErrorText: { type: String },
            //pincodeErrorText: { type: String },
            errorText: { type: String },
            userDetails: {},
            signUpSubmit: { type: Function },
            onKeyUp: { type: Function },
            onKeyDown: { type: Function },
            hidepopup: { type: Function },
            openSignIn: { type: Function }
        },
        data() {
            return {
                showpopup: false,
                selection: '',
                showcountrysearch: false,
                //hidePincode: false,
            }
        },
        methods: {
            closePopup() {
                this.hidepopup();
            },
            onKeyUpFun(e, refid) {
                this.onKeyUp(e, refid);
            },
            onKeyDownFun(e, refid) {
                this.onKeyDown(e, refid);
            },
            submit() {
                this.signUpSubmit();
            },
            openSignInFun() {
                this.openSignIn();
            },
            openCountrySearch() {
                this.selection = '';
                this.showcountrysearch = true;
            },
            countryCodeSelectionCallBack(countrycode) {
                this.showcountrysearch = false;
                this.userDetails.CountryCode = countrycode;
                //if (countrycode != "+91")
                //    this.hidePincode = true;
                //else
                //    this.hidePincode = false;
            }
        },
        mounted: function () {
        },
        components: {
            CountryCode
        },
        computed: {
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "container pt-5 mt-5" },
    [
      _vm.showprocessing()
        ? _c("div", { staticClass: "pay-thank" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.showme
        ? _c("ThankYouForPayment", {
            attrs: {
              batchdate: _vm.instructorDetails.scheduledisplaytime,
              instructorDetails: _vm.instructorDetails,
              closePopup: _vm.closePopup,
              ispaid: true,
              paymentdetails: _vm.paymentdetails
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "thankmain text-center" }, [
      _c("div", { staticClass: "text-success thankdemo" }, [
        _c("i", { staticClass: "fad fa-check-circle" }),
        _vm._v(" "),
        _c("b", { staticClass: "mb-1" }, [_vm._v("Processing, please wait…")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
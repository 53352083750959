﻿var FormElements = {
    init: function () {
        this.bindDropDownClick();
        this.bindTextBoxClick();
        this.bindRadioClick();
    },
    bindDropDownClick: function () {
        var dropdownElements = document.querySelectorAll("[formelemdropdown]");
        dropdownElements.forEach((clickitem) => {
            clickitem.addEventListener('click', function () {
                clickitem.classList.add("open");
                clickitem.classList.remove("invalid");
            });
            clickitem.addEventListener('mouseleave', function () {
                clickitem.classList.remove("open");
            });
            clickitem.querySelectorAll("ul > li").forEach((liitem) => {
                liitem.addEventListener('click', function (evt) {
                    clickitem.classList.remove("open");
                    var inp = clickitem.querySelector("input[type='text']");
                    inp.setAttribute("data-value", liitem.innerHTML);
                    inp.setAttribute("placeholder", liitem.innerHTML);
                    clickitem.querySelector("label").innerHTML = liitem.innerHTML;
                    evt.stopPropagation();
                });
            });
        });
    },
    bindTextBoxClick: function () {
        var textboxElements = document.querySelectorAll("[formelemtextbox]");
        textboxElements.forEach((clickitem) => {
            clickitem.querySelector("input").addEventListener('click', function () {
                clickitem.classList.remove("invalid");
            });
            if (clickitem.getAttribute("data-type") == "number")
                clickitem.querySelector("input").onkeydown = this.allowNumbersAlone;
        });
    },
    bindRadioClick: function () {
        var textboxElements = document.querySelectorAll(".material-radio input[type='radio']");
        textboxElements.forEach((clickitem) => {
            clickitem.addEventListener('click', function () {
                if (document.querySelector("#" + this.name.replace("rd", "div")))
                    document.querySelector("#" + this.name.replace("rd", "div")).classList.remove("invalid");
            });
        });
    },
    validateFormFields: function (type, name, showerror) {
        var isValid = false;
        var elemAttr = "formelemtextbox=" + name;
        if (type == "dropdown")
            elemAttr = "formelemdropdown=" + name;

        var formElement = document.querySelector("[" + elemAttr + "]");
        if (formElement) {
            if (type == "textbox" && document.querySelector("#inp" + name).value != "")
                isValid = true;
            else if (type == "dropdown" && document.querySelector("#inp" + name).getAttribute("data-value"))
                isValid = true;

            if (!isValid && showerror)
                this.showError(type, name);
        }
        return isValid;
    },
    showError: function (type, name) {
        var elemAttr = "formelemtextbox=" + name;
        if (type == "dropdown")
            elemAttr = "formelemdropdown=" + name;
        var formElement = document.querySelector("[" + elemAttr + "]");
        if (formElement) {
            if (type == "textbox") {
                var errSpan = formElement.querySelector("span.error-text")
                if (document.querySelector("#inp" + name).value != "") {
                    errSpan.innerHTML = formElement.getAttribute("InvalidErrorText")
                }
                else {
                    errSpan.innerHTML = formElement.getAttribute("EmptyErrorText")
                }
            }
            formElement.classList.add("invalid");
            formElement.removeAttribute("disabled");
        }
    },
    allowNumbersAlone: function (e) {
        var key = e.which;
        if (key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
            return true;
        }

        e.preventDefault();
        return false;
    }
}
FormElements.init();

export default FormElements;
<template>
    <div>
        <svg class="d-none">
            <defs>
                <g id="bookmarkIcon">
                    <path d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"></path>
                </g>
                <g id="tagsIcon">
                    <g>
                        <path d="M340.38,23.224H206.396c-8.48,0-16.624,3.376-22.624,9.376L9.372,206.968c-12.496,12.496-12.496,32.752,0,45.264
			l133.984,133.984c12.496,12.496,32.752,12.496,45.248,0l174.4-174.368c6-6.016,9.376-14.16,9.376-22.656V55.224
			C372.38,37.544,358.06,23.224,340.38,23.224z M284.38,135.224c-13.248,0-24-10.752-24-24s10.752-24,24-24s24,10.752,24,24
			S297.628,135.224,284.38,135.224z" />
                    </g>
                    <g>
                        <path d="M404.38,55.224l-0.016,148.944c0,7.376-2.928,14.464-8.16,19.68L218.988,401.064l2.72,2.72
			c12.496,12.496,32.752,12.496,45.248,0l160.032-160c6.016-6,9.392-14.144,9.392-22.624V87.224
			C436.38,69.544,422.06,55.224,404.38,55.224z" />
                    </g>
                </g>
            </defs>
        </svg>

        <template v-for="(recoCard,index) in RecomendationCard">
            <div class="row w-100 mb-4 mx-auto">
                <div class="course-card d-flex flex-wrap w-100" v-on:click="onCardClick(index)">
                    <div class="col-lg-4 px-0">
                        <div class="img-wrapper">
                            <img :src="recoCard.PhotoThumbnailURL"
                                 alt=""
                                 class="fit-cover fit-cover-right" />
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="d-flex pr-3 pt-3 pb-2 justify-content-between" v-if="recoCard.AverageRating > 0">
                            <div class="reviews">
                                <div class=" ratings medium">
                                    <span :class="renderRatingStars(recoCard.AverageRating)"></span>
                                </div>
                                <span> {{recoCard.AverageRating}} ({{recoCard.ReviewCount}} review(s)) </span>
                            </div>
                        </div>
                        <div v-else></div>
                        <div class="p-lg-3 py-3">
                            <div class="d-flex justify-content-between align-items-start align-items-lg-center">
                                <h3 class="card-title">
                                    {{recoCard.CourseTitle}}
                                </h3>
                                <div :data-shortlist="recoCard.CourseID" class="circle-bookmark shadow-sm" :class="{active: recoCard.Shortlist}" data-hasnoclickevent="true">
                                    <svg class="svg-icon medium c-pointer" viewBox="0 0 384 512">
                                        <use xlink:href="#bookmarkIcon" />
                                    </svg>
                                </div>
                            </div>
                            <p class="text-muted">Classes by {{recoCard.TutorNameShort}}</p>
                            <p class="text-muted pt-2 sub-title">
                                {{recoCard.DurationinHr}} hr/session • {{recoCard.NumberofSessions}} sessions • {{recoCard.CourseDuration}} weeks
                            </p>

                            <div class="d-flex pt-2 align-items-center">
                                <p v-if="recoCard.CourseFees != recoCard.AfterDiscountCourseFees" class="tbody-lg mr-2">
                                    <strike> ₹{{recoCard.CourseFees.toLocaleString()}}</strike>
                                </p>
                                <h5 class="sub-title-lg mr-2 mb-0" v-if="recoCard.CourseFees != recoCard.AfterDiscountCourseFees">₹{{recoCard.AfterDiscountCourseFees.toLocaleString()}}</h5>
                                <h5 class="sub-title-lg mr-2 mb-0" v-if="recoCard.CourseFees == recoCard.AfterDiscountCourseFees">₹{{recoCard.CourseFees.toLocaleString()}}</h5>
                                <label class="caption mb-0">(Rs. {{recoCard.AmountPerSession}}/session)</label>
                            </div>

                            <div class="discount-tag mt-2 caption" v-if="recoCard.DiscountPercentage > 0">
                                <svg class="svg-icon large green-fill" viewBox="0 0 436.38 436.38">
                                    <use xlink:href="#tagsIcon" />
                                </svg>
                                {{recoCard.DiscountPercentage}}% off
                            </div>
                            <!--<p class="text-muted no-wrap mt-2 caption">
        Highlights of the course
    </p>
    <div class="overflow-slider pt-1">
        <label class="rounded-pill-dark" v-for="courseHighLights in recoCard.CourseHighlightsList">
            {{courseHighLights}}
        </label>
    </div>-->
                            <!--<a class="btn btn-primary my-3 bookfreedemoclk" :data-courseid="recoCard.CourseID">
        Join Demo For Free
    </a>-->
                            <a v-if="recoCard.IsInstantLiveDemo == true" class="btn btn-success my-4 bookfreedemoclk" :class="disabledClass(recoCard.IsActive,recoCard.CourseEnrolledStatus)" :data-courseid="recoCard.CourseID" :data-instantDemoEnabled="recoCard.IsInstantLiveDemo">
                                <span class="live-badge">LIVE</span>
                                Join Instant Demo
                            </a>
                            <p v-if="recoCard.IsInstantLiveDemo == true && disabledClass(recoCard.IsActive,recoCard.CourseEnrolledStatus) ==''" class="info-card-recommendation live-inst">Tutor is live for instant demo booking for free</p>
                            <a v-if="recoCard.IsInstantLiveDemo == false" class="btn btn-primary my-4 bookfreedemoclk" :class="disabledClass(recoCard.IsActive,recoCard.CourseEnrolledStatus)" :data-courseid="recoCard.CourseID" :data-instantDemoEnabled="recoCard.IsInstantLiveDemo">
                                <span class="free-badge">FREE</span>
                                Book Demo
                            </a>
                            <p v-if="recoCard.IsInstantLiveDemo == false && disabledClass(recoCard.IsActive,recoCard.CourseEnrolledStatus) ==''" class="info-card-recommendation free-course">Join free demo class absolutely free... Hurry!</p>
                            <p v-if="recoCard.CourseEnrolledStatus == 5" class="info-card-recommendation text-danger">Please attend the demo courses you have booked so far, before booking another demo.</p>
                            <p v-if="recoCard.CourseEnrolledStatus == 1 || recoCard.CourseEnrolledStatus == 2" class="info-card-recommendation text-danger">Already enrolled for this course</p>
                            <p v-if="recoCard.IsActive == 11" class="info-card-recommendation text-danger">Course is Full. Please check back later</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!--<a v-else-if="this.IsMobile" class="course-card pr-0 mr-0 no-shadow" href="#">
            <div class="flex">
                <div class="img-wrapper d-flex">
                    <img :src="this.RecomendationCard.PhotoThumbnailURL"
                         alt=""
                         class="img-fluid bg-img w-100 h-100" />
                </div>
                <p class="card-top-left-title caption-sm">Matches 4/5 needs stated</p>
                <div class="d-flex px-3 pt-3 pb-2 justify-content-between">
                    <label class="reviews caption">
                        <span v-html="renderRatingStars(this.RecomendationCard.AverageRating)"></span>
                        <span> {{this.RecomendationCard.AverageRating}} • {{this.RecomendationCard.ReviewCount}} reviews </span>
                    </label>-->
        <!--<i class="far fa-bookmark"
           data-toggle="tooltip"
           data-placement="bottom"
           title="Added to your shortlist"></i>-->
        <!--</div>
            </div>
            <h3 class="px-3 card-title sub-title1">{{this.RecomendationCard.CourseTitle}}</h3>
            <p class="text-muted px-3"> <i class="fas fa-user-circle pr-2"></i> Classes by {{this.RecomendationCard.TutorNameShort}}</p>
            <p class="text-muted pt-2 px-3">
                <i class="far fa-calendar-alt pr-2 sub-title"></i> {{this.RecomendationCard.DurationinHr}} hr/session • {{this.RecomendationCard.NumberofSessions}} sessions • {{this.RecomendationCard.CourseDuration}} weeks
            </p>
            <div class="d-flex px-3 pt-2 pb-3 align-items-center">
                <p v-if="this.RecomendationCard.CourseFees != this.RecomendationCard.AfterDiscountCourseFees" class="text-muted pr-1">
                    <strike> ₹{{this.RecomendationCard.CourseFees.toLocaleString()}}</strike>
                </p>
                <p v-if="this.RecomendationCard.CourseFees != this.RecomendationCard.AfterDiscountCourseFees" class="text-dark-bold pr-1 text-dark">₹{{this.RecomendationCard.AfterDiscountCourseFees.toLocaleString()}}</p>
                <p v-if="this.RecomendationCard.CourseFees == this.RecomendationCard.AfterDiscountCourseFees" class="text-dark-bold pr-1 text-dark">₹{{this.RecomendationCard.CourseFees.toLocaleString()}}</p>
                <p class="text-muted">(Rs. {{this.RecomendationCard.AmountPerSession}}/session)</p>
            </div>
            <div class="classes-by-block py-3 container">
                <div class="row mx-1">
                    <div class="col-8">
                        <div class="row">
                            <div class="col-1 px-0 mr-2">
                                <i class="fas fa-user-circle"></i>
                            </div>
                            <div class="col-10 px-0 pl-2">
                                <div class="d-flex flex-column">
                                    <p class="sub-title1">Classes by {{this.RecomendationCard.TutorNameShort}}</p>
                                    <div class="about-teacher pt-2 sub-title2 fw-400">
                                        {{this.RecomendationCard.TeachingExperience}} years experience <br />
                                        • {{this.RecomendationCard.NoOfStudentsTaught}} students • {{this.RecomendationCard.Livecoursecount}} courses
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 px-0 text-right">
                        <img :src="this.RecomendationCard.ImageUrl"
                             alt=""
                             class="img-fluid w-75 teacher-img" />
                    </div>
                </div>
            </div>
        </a>-->
    </div>
</template>
<script>
    import ShortList from "/wwwroot/js/common/ShortList.js";
    export default {
        name: "CourseRecomendation",
        props: {
        },
        data() {
            return {
                IsMobile: false,
                RecomendationCard: [],
                isShortlisted: false,
            }
        },
        beforeMount: function () {
            this.IsMobile = JSON.parse(document.getElementById('IsRecMobile').getAttribute('data-IsMobile').toLowerCase());
            this.RecomendationCard = JSON.parse(document.querySelectorAll('#RecomendationData')[0].innerText);
            //console.log(this.RecomendationCard)
        },
        mounted: function () {
            //if (this.RecomendationCard.Shortlist)
            //    this.isShortlisted = true;
            ShortList.initShortlist();
            if (document.querySelectorAll(".sim_courses .course-card").length == 0)
                document.querySelector(".similar-courses-container").classList.add("d-none");
        },
        computed: {
        },
        methods: {
            onCardClick: function (index) {
                window.location.href = this.RecomendationCard[index].CourseURL;
            },
            renderRatingStars: function (rating) {
                switch (rating) {
                    case 0: return "star0"
                        break;
                    case 0.5: return "star0-5"
                        break;
                    case 1: return "star1"
                        break;
                    case 1.5: return "star1-5"
                        break;
                    case 2: return "star2"
                        break;
                    case 2.5: return "star2-5"
                        break;
                    case 3: return "star3"
                        break;
                    case 3.5: return "star3-5"
                        break;
                    case 4: return "star4"
                        break;
                    case 4.5: return "star4-5"
                        break;
                    case 5: return "star5"
                        break;
                }
            },
            disabledClass: function (IsActive, EnrolledStatus) {
                var className = "";
                if (IsActive != 1 || EnrolledStatus > 0)
                    className = "disabled";
                return className;
            },
        },
    }
</script>
import { render, staticRenderFns } from "./KnowMorePop.vue?vue&type=template&id=e9847f00&"
import script from "./KnowMorePop.vue?vue&type=script&lang=js&"
export * from "./KnowMorePop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\eLearn.Student\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e9847f00')) {
      api.createRecord('e9847f00', component.options)
    } else {
      api.reload('e9847f00', component.options)
    }
    module.hot.accept("./KnowMorePop.vue?vue&type=template&id=e9847f00&", function () {
      api.rerender('e9847f00', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "VueComponents/KnowMorePop.vue"
export default component.exports
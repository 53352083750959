var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.InstructorCard.length > 0
    ? _c("div", { staticClass: "carousal-scroll p-1 splide__track" }, [
        _c(
          "div",
          { staticClass: "splide__list" },
          [
            _vm._l(_vm.InstructorCard, function(card) {
              return [
                _c(
                  "a",
                  {
                    staticClass: "course-card p-3 mr-lg-4 splide__slide",
                    attrs: { href: card.TutorURL }
                  },
                  [
                    card.IsTopInstructor == 1
                      ? _c("span", { staticClass: "top-inst-badge" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "img-wrapper d-flex" }, [
                      _c("img", {
                        staticClass: "fit-cover",
                        attrs: {
                          src: card.TutorThumbnailUrl,
                          alt: card.TutorName
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("h3", { staticClass: "card-title mb-0 mt-3" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(card.TutorName) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex py-2 justify-content-between" },
                      [
                        card.Rating > 0
                          ? _c("div", { staticClass: "reviews" }, [
                              _c("div", { staticClass: "ratings medium" }, [
                                _c("span", {
                                  class: _vm.renderRatingStars(card.Rating)
                                })
                              ]),
                              _vm._v(" "),
                              card.Rating > 0
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(card.Rating) +
                                        "(" +
                                        _vm._s(card.ReviewCount) +
                                        " reviews) "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "pb-4" }, [
                      _vm._v(_vm._s(card.ShortDesc))
                    ])
                  ]
                )
              ]
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
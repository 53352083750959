var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {},
    [
      _c("div", { staticClass: "modal", attrs: { id: "EditPreferenceVue" } }, [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-header align-items-center" }, [
              _c(
                "svg",
                {
                  staticClass: "svg-icon medium d-sm-none",
                  attrs: { viewBox: "0 0 64 64" },
                  on: { click: _vm.ClosePreference }
                },
                [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("Edit your preferences")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: { click: _vm.ClosePreference }
                },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body" },
              _vm._l(_vm.MappedQuesAndAns, function(QuesAndAns) {
                return _c(
                  "div",
                  { staticClass: "wiz-edit-wrapper" },
                  [
                    _c("h6", { staticClass: "pb-2" }, [
                      _vm._v(_vm._s(QuesAndAns[0].toString()))
                    ]),
                    _vm._v(" "),
                    _vm._l(QuesAndAns[1], function(AnsEach) {
                      return _c("button", { staticClass: "ml-2" }, [
                        _c("span", [_vm._v(_vm._s(AnsEach))])
                      ])
                    })
                  ],
                  2
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "button btn btn-primary",
                  on: { click: _vm.onEditClick }
                },
                [_vm._v("Edit")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.CurrentStep > 0
        ? _c("LCFSecondLevel", {
            attrs: {
              CurrentQuestion: _vm.CurrentQuestion,
              C1Name: _vm.C1Name,
              PageType: _vm.PageType,
              showQuesPopup: _vm.showQuesPopup,
              SetSelectedDefault: _vm.SetSelectedDefault,
              onLCFAnsSelect: _vm.onLCFAnsSelect,
              AnswerSubmit: _vm.AnswerSubmit,
              onLCFBackClick: _vm.onLCFBackClick,
              CloseLCFPopup: _vm.CloseLCFPopup
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "progress d-none mt-0" }, [
      _c("div", {
        staticClass: "progress-bar",
        staticStyle: { width: "50%" },
        attrs: {
          role: "progressbar",
          "aria-valuenow": "50",
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
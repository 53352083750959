import Vue from "vue"
import KnowMorePopup from "../../../VueComponents/KnowMorePop.vue";
let UpcomingClubSchedule = {
    init : function () {
        console.log("upcoming club schedule init");
        this.bindEvents();
    },
    bindEvents: function () {

        

        if(document.querySelector("[data-id='upcoming-clubdetails-knowmore']")) {
            document.querySelectorAll("[data-id='upcoming-clubdetails-knowmore']").forEach((item) => {
                console.log("the btn ", item);
                item.addEventListener('click', (event) => {
                    console.log(event.currentTarget.getAttribute("data-timeData"));
                    let timeData = event.currentTarget.getAttribute("data-timeData");
                    this.showKnowMorePopup(timeData);
                    // if(!document.querySelector("[data-id='know-more-modal']").classList.contains("show") ) {
                    //     // console.log("doc ", document);
                    //     // console.log(document.querySelector("[data-id='know-more-modal']").classList);
                    //     // document.querySelector("[data-id='know-more-modal']").classList.add("show");
                    //     // console.log(document.querySelector("[data-id='know-more-modal']").classList);
                    //     // console.log("clicked ");
                        

                    //     // if(document.querySelector("[data-id='know-more-ok-btn']")) {
                    //     //     document.querySelectorAll("[data-id='know-more-ok-btn']").forEach(() => {
                    //     //         addEventListener('click', (event) => {
                    //     //             if(document.querySelector("[data-id='know-more-modal']")) {
                    //     //                 document.querySelector("[data-id='know-more-modal']").classList.remove("show");
                    //     //             }
                    //     //         })
                    //     //     })
                    //     // }
                    // }
                })
            })
        }

       
        
    },
    showKnowMorePopup: function (timeData) {
        document.body.classList.add("overflow-hidden");
        if(document.querySelector("#know-more-base-div")) {
            new Vue({
                el: "#know-more-div",
                components: {
                    KnowMorePopup,
                },
                render: function (h) {
                    return h(KnowMorePopup, {
                        props: {
                            timeData : timeData,
                        }
                    });
                }
            })
        }
        // if(document.querySelector("[data-id='know-more-modal']")) {
        //     let description = `In Capshine talk-time we create smaller groups of rooms of 4 to 10 members so that all the members can participate in the discussion and the 
        //     coaches are also able to help you with the conversation. For this we need all the members to join the session before the cut-off time ie. 
        //     ${timeData}. Please join the session early to so that you do not miss the meet.`
        //     document.querySelector("[data-id='know-more-description']").innerText = description;
            
            

        // }
    }
}

 UpcomingClubSchedule.init();
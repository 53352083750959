<template>
    <div class="form signin-form">
        <svg class="d-none">
            <defs>
                <g id="downArrow">
                    <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                </g>
            </defs>
        </svg>
        <div class="phone-input md-field mb-3" :class="{ invalid: invalidMobileNo }">
            <h6 class="country" @click="openCountrySearch()">
                <!--@click="openCountrySearch()"-->
                {{ userDetails.CountryCode }}
                <svg class="svg-icon medium" viewBox="0 0 448 512">
                    <use xlink:href="#downArrow" />
                </svg>
                <!--<small><i class="far fa-chevron-down"></i></small>-->
            </h6>
            <input type="number" name="mobile" class="mobile" id="mobile" @keydown="onKeyDownFun($event,'mobileno')" required placeholder="Your phone number" v-model="userDetails.MobileNumber" />
            <label alt="mobile" for="mobile"> Your phone number </label>
            <span class="error-text">{{ mobileNoErrorText }}</span>
            <div class="dropdown-wrapper" :class="{ active: showcountrysearch }">
                <CountryCode v-model="selection" :selectionCallBack="countryCodeSelectionCallBack"></CountryCode>
            </div>
        </div>
        <!--<div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
        <label class="form-check-label" for="inlineCheckbox1">
            Receive important notifications on
            <img src="/images/icons/whatsapp-duotone.svg" alt="WhatsApp" width="20" />
            WhatsApp
        </label>
    </div>-->
        <div class="my-3 d-lg-block">
            <label class="font-size-sm">
                By clicking next you are accepting our
                <a href="/terms" target="_blank" class="link"> Terms and Conditions </a>
            </label>
        </div>
        <button type="button" class="btn btn-primary btn-block" @click="submit()">Next</button>

        <div class="md-field mb-3" :class="{invalid: errorText != ''}">
            <span class="error-text">{{errorText}}</span>
        </div>

        <div class="my-5">
            <div class="text-center separator">
                <h6>
                    <span>&nbsp; OR &nbsp;</span>
                </h6>
            </div>
        </div>
        <label class="caption">
            Don't have an account?
            <a href="#" class="link" @click="openSignUpFun()">Signup</a>
        </label>
    </div>
</template>
<script>
    import CountryCode from "../Common/CountryCode.vue";

    export default {
        name: "SignIn",
        props: {
            invalidMobileNo: { type: Boolean },
            mobileNoErrorText: { type: String },
            errorText: { type: String },
            userDetails: {},
            signInSubmit: { type: Function },
            onKeyDown: { type: Function },
            hidepopup: { type: Function },
            openSignUp: { type: Function }
        },
        data() {
            return {
                selection: '',
                showcountrysearch: false,
            }
        },
        methods: {
            closePopup() {
                this.hidepopup();
            },
            onKeyDownFun(e, refid) {
                this.onKeyDown(e, refid);
            },
            submit() {
                this.signInSubmit();
            },
            openSignUpFun() {
                this.openSignUp();
            },
            openCountrySearch() {
                this.selection = '';
                this.showcountrysearch = true;
            },
            countryCodeSelectionCallBack(countrycode) {
                this.showcountrysearch = false;
                this.userDetails.CountryCode = countrycode;
            }
        },
        mounted: function () {
        },
        components: {
            CountryCode
        },
        computed: {
        }
    }
</script>
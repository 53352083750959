<template>
  <div class="step show" :class="introClassName" id="step1">
    <div class="group">
      <div class="quiz-qustion-box mt-5">
        <div class="question-explain-title">
          <h5 v-if="introName == 'FillTheGap'" class="quiz-title">Fill the Gap contains</h5>
          <h5 v-else class="quiz-title">Quiz contains</h5>
          <ul>
            <li v-if="introName == 'FillTheGap'">A sentence with a word blank to be chosen</li>
            <li v-else>A sentence with a word boldened</li>
            <li>A question</li>
            <li>4 Options</li>
            <!-- <li>
              You can Bookmark the word with explanation of later reference
            </li> -->
          </ul>
        </div>

        <div class="right-answer-box mt-3">
          <div class="right-answer-title">
            <h5 v-if="introName == 'FillTheGap'">Every right answer you earn minimum of 50 points</h5>
            <h5 v-else>Every right answer you earn 100 points</h5>
            <p>No points for wrong answer</p>
          </div>
          <div class="caps-image-count">
            <img class="right-cap-icon" src="https://lscdn.blob.core.windows.net/elearn/quiz-cap.png" />
            <span v-if="introName == 'FillTheGap'" class="quiz-badge-count-box">50</span>
            <span v-else class="quiz-badge-count-box">100</span>
          </div>
        </div>

        <div class="text-center mt-4">
          <button
            type="button"
            id="next"
            class="btn btn-primary next-form-btn mt-3 mb-3"
            @click="onClickStart"
            tabindex="-1"         
          >
            Start
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        introClassName : String,
        introName : String,
    },
    data () {
        return {
            className : this.introClassName,
        }
    },
    methods : {
        onClickStart: function() {
            //this.className = "previous-slide";
            this.$emit('onNext');
        },
    },
    // watch: {
    //   introClassName: function (val) {
    //     this.className = val;
    //     console.log("the new val");
    //   }
    // }

}
</script>

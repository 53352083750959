<template>
  <!-- session tab content start -->
  <div class="tab-pane fade show active w-100" id="sessions">
    <!-- card -->
    <div
      class="row border-bottom pb-3 mx-0"
      v-for="(session, index) in Sessions"
      :key="index"
    >
      <div class="col-2 date caption active">{{ session.displayday }}</div>
      <div class="col-9 pr-0">
        <div
          class="row mx-0 flex-column py-3 rounded-4 px-3 bg-white shadow-sm"
        >
          <div
            class="caption mb-3"
            v-if="session.nextsessionindex != session.sessionno"
          >
            Session {{ session.sessionno }}
          </div>

          <div
            class="caption mb-3"
            v-if="session.nextsessionindex == session.sessionno"
          >
            Next Session {{ session.sessionno }}
          </div>
          <h4 class="mb-2">{{ session.title }}</h4>
          <div class="caption text-muted mb-2">
            <!-- The part of the language that is getting to the part
                        go the lore Epsom that is the as...    v-if="
                          session.enablejoinclass == 0 &&
                          session.nextsessionindex > session.sessionno && session.sessionId>0
                        " -->
          </div>
          <hr />
          <a
            class="text-primary d-flex align-items-center"
            @click="viewrecordingurl(session)"
            v-if="
              session.enablejoinclass == 0 &&
              session.sessionId > 0 &&
              (session.nextsessionindex > session.sessionno ||
                session.nextsessionindex == 0)
            "
          >
            <i class="far fa-play-circle fa-2x"></i>
            <span class="ml-2">
              View Session Recording
              <p class="caption text-primary"></p>
            </span>
          </a>
          <div
            class="join-card"
            v-if="session.nextsessionindex == session.sessionno"
          >
            <div class="session-in px-3 py-2">
              <p class="subtitle fw-500">
                <!-- {{ session.displayhourdifference
                              }} -->
                <span class="fw-400">
                  {{ session.displaydatedayhrs }}<br />
                </span>
              </p>
            </div>
            <div
              :class="
                session.enablejoinclass == 1 ? 'join-link active' : 'join-link'
              "
            >
              <a @click="JoinClass(session)"> Join</a>
              <i class="fas fa-ellipsis-v"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal start -->
    <!-- remove .show to hide modal -->
    <div :class="showpopup ? 'modal show' : 'modal'">
      <svg class="d-none">
        <defs>
          <g id="chevronBack">
            <path
              d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"
            />
          </g>
        </defs>
      </svg>
      <!-- add .modal-sm/modal-lg/modal-xl after .modal-dialog for width change -->
      <div class="modal-dialog modal-dialog-scrollable">
        <!-- Modal content -->
        <div class="modal-content">
          <div class="modal-header align-items-center">
            <svg
              viewBox="0 0 64 64"
              class="svg-icon medium d-sm-none"
              @click="closepopup()"
            >
              <use xlink:href="#chevronBack"></use>
            </svg>
            <h5>Session recording</h5>
            <button type="button" class="close" @click="closepopup()">
              &times;
            </button>
          </div>
          <!--<div class="close" @click="closepopup()">
              <span @click="closepopup()">&times;</span>
            </div>-->
          <div class="modal-body ses-record" style="height: 20rem">
            <!-- Content goes here -->
            <div v-for="(record, index) in recordingdetails" :key="index">

              <div> Session Video -  {{index+1}}
              <video controls :src="record.url" preload="none"></video>
 </div>
            </div>
            <!-- <video controls :src="recrod.url" v-if="showvideo"></video> -->
            <div v-if="showmsg">Recorded Session Not Available</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal end -->
  </div>
</template>
<script>
import MaterialList from "./MaterialList.vue";
import Loader from "../../wwwroot/js/Loader.js";
export default {
  name: "CourseSessions",
  components: {
    MaterialList: MaterialList,
  },
  props: {
    courseid: "",
    userid: "",
  },
  data() {
    return {
      CourseId: 0,
      Sessions: [],
      Message: "",
      ActivePage: "Sessions",
      showpopup: false,
      videourl: "",
      showvideo: false,
      showmsg: false,
      recordingdetails: [],
    };
  },
  created: function () {},
  mounted: function () {
    var urlParams = new URLSearchParams(window.location.search);
    var courseid = urlParams.get("Courseid");
    // var urlParamsTab = new URLSearchParams(window.location.search);
    var activepage = urlParams.get("activepage");
    // Assign all values for crate course feature
    var batchid = urlParams.get("batchid");

    if (activepage == null || activepage == undefined) {
    } else {
      this.ActivePage = activepage;
    }

    if (courseid == null || courseid == undefined) {
    } else {
      this.CourseId = courseid;
    }
    Loader.showAboveModal();
    var getcourseurl =
      "/api/Course/getsectiondetails?CourseId=" +
      courseid +
      "&batchid=" +
      batchid;
    this.isloading = true;
    fetch(getcourseurl, { method: "GET" })
      .then((responseJson) => responseJson.json())
      .then((response) => {
        Loader.hideAboveModal();

        if (response !== null) {
          this.Sessions = response;
          this.Message = "";
        } else {
          this.Sessions = [];
          this.Message = "Sessions Not Available";
        }
      });
  },
  methods: {
    viewrecordingurl(session) {
      Loader.showAboveModal();
      var url =
        "/api/Course/recording-url?sessionId=" +
        session.sessionId +
        "&platform=" +
        session.coursedeliveryplatform;
      fetch(url, { method: "GET" })
        .then((responseJson) => responseJson.json())
        .then((response) => {
          Loader.hideAboveModal();
          this.showpopup = true;

          if (response !== null) {
            //window.location.href=response;


   if (response.length == 0) {
              this.showmsg = true;
              this.showvideo = false;
              this.videourl = "";
            } else if (response.length > 0) {
              this.videourl = response;
              this.recordingdetails = response;
              this.showvideo = true;
              this.showmsg = false;
            }
            // if (response.length == 1) {
            //   if ((response[0].url = "" || response[0].url==null)) {
            //     this.showmsg = true;
            //     this.showvideo = false;
            //     this.videourl = "";
            //   }
            // } else {
            //   this.videourl = response;
            //   this.recordingdetails = response;
            //   this.showvideo = true;
            //   this.showmsg = false;
            // }
          } else {
            this.showmsg = true;
            this.showvideo = false;
            this.videourl = "";
          }
        });
    },
    JoinClass(session) {
      Loader.showAboveModal();
      var url =
        "/api/Course/ClassRoom?batchId=" +
        session.batchId +
        "&courseId=" +
        session.courseId;
      +"&UserId=" + session.userid;
      fetch(url, { method: "GET" })
        .then((responseJson) => responseJson.json())
        .then((response) => {
          Loader.hideAboveModal();
          if (response !== null) {
            window.location.href = response;
          } else {
            alert("Live Session Not Available");
          }
        });
    },
    switchtotab(tab) {
      this.activepage = tab;
    },
    closepopup() {
      this.showpopup = false;
    },
  },
};
</script>
﻿import Vue from "vue";
import ResponseComments from "../../../VueComponents/DailyActivity/ResponseComments.vue";
import Loader from "../Loader.js";

let DailyActivityResponse = {
    init : function () {
        if (document.getElementById("hdnUserPid").value == "0" || document.getElementById("hdnUserPid").value == "")
        {
            const urlParams = new URLSearchParams(window.location.search);
            const activityid = urlParams.get('activityid');
            if(activityid)
                document.getElementById("hdnLoginNextUrl").value = "/DailyActivityResponse?activityid=" + activityid;
            else
                document.getElementById("hdnLoginNextUrl").value = "/DailyActivity";
             setTimeout(function () {
                    document.getElementById("signInMobile").click();
                    document.querySelector("#signin-base-div .close").style.display = 'none';
                    document.querySelector("#signin-base-div .signinbk").style.display = 'none';
            }, 200);
        }
        else {
            this.bindEvents();
        }
        if (document.querySelector('footer'))
            if (!document.querySelector('footer').classList.contains('d-none'))
                document.querySelector('footer').classList.add('d-none');
    },
    bindEvents: function () {
        let commentsBtn = document.querySelectorAll("[data-comments]");
        if(commentsBtn) {
                commentsBtn.forEach((btn) => { 
                     btn.addEventListener("click", function (e) {
                         DailyActivityResponse.commentsBtnClick(btn);
                     });
                });
        }
        let likeBtn = document.querySelectorAll("[data-activityLike]");
        if(likeBtn) {
                likeBtn.forEach((btn) => { 
                     btn.addEventListener("click", function (e) {
                         if (!document.getElementById("activityLike-" + btn.getAttribute('data-activityLike')).classList.contains('active'))
                         {
                            DailyActivityResponse.submitLike(btn.getAttribute('data-activityLike'));
                            let likeContent = document.getElementById("like-" + btn.getAttribute('data-activityLike'));
                            if(likeContent.innerHTML.split(' ').length > 1)
                            {
                                let val = parseInt(likeContent.innerHTML.split(' ')[1].trim().replace('(', '').replace(')', ''));
                                if((val+ 1) == 2)
                                    likeContent.innerHTML = 'Likes (2)';
                                else
                                    likeContent.innerHTML = likeContent.innerHTML.replace(val, (val+ 1));
                            }
                            else
                                likeContent.innerHTML = "Like (1)";
                            if(likeContent.classList.contains('d-none'))
                                likeContent.classList.remove('d-none');
                         }
                     });
                });
        }
    },
    commentsBtnClick: function (dataTarget) {
        if (document.getElementById('div-response-comments-main')) {
            let answersDiv = document.getElementById('response-page-answers');
            if (answersDiv) {
                if (!answersDiv.classList.contains('d-none')) {
                    answersDiv.classList.add('d-none')
                }
            }
            let answersDivHeader = document.getElementById('response-page-answers-header');
            if (answersDivHeader) {
                if (!answersDivHeader.classList.contains('d-none')) {
                    answersDivHeader.classList.add('d-none')
                }
            }
            let newDiv = document.createElement("div");
            newDiv.id = "div-response-comments";
            document.getElementById('div-response-comments-main').appendChild(newDiv);
            let isCorrectAnswer = document.getElementById('answerElement-' + dataTarget.getAttribute('data-comments')).getAttribute('data-iscorrectanswer');
            new Vue({
                el: "#div-response-comments",
                components: {
                    ResponseComments
                },
                render: function (h) {
                    return h('div', [h(ResponseComments, {
                        props: {
                            activityResponseId: dataTarget.getAttribute('data-comments'),
                            answeredName: dataTarget.getAttribute('data-answeredname'),
                            answeredTime: dataTarget.getAttribute('data-answereddate'),
                            playButtonBlobUrl: dataTarget.getAttribute('data-audiourl'),
                            answer: dataTarget.getAttribute('data-answer'),
                            userPid: document.getElementById("hdnUserPid").value,
                            activityTypeId: parseInt(document.getElementById("responseActivityTypeId").value),
                            isCorrectAnswer: parseInt(isCorrectAnswer)
                        }
                    })]);
                }
            })
        }
    },
            submitLike: function (activityResponseId) {
                Loader.show();
                var url = `/api/dailyactivity/save-dailyactivity-responsereply`;
                let requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            ActivityResponseId: activityResponseId,
                            ReplyType: 'L',
                            UserPid: document.getElementById("hdnUserPid").value,
                            Comments: '',
                        })
                }
                fetch(url, requestOptions)
                    .then(response => response.json())
                    .then((responseJson) => {
                        if (responseJson == 1) {
                           if(document.getElementById('activityLike-'+activityResponseId).classList.contains('active'))
                                document.getElementById('activityLike-'+activityResponseId).classList.remove('active');
                            else
                                document.getElementById('activityLike-'+activityResponseId).classList.add('active');
                        } else {
                        }
                        Loader.hide();
                    })
           },
}
export default DailyActivityResponse;
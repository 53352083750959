<template>
    <div class="tab-pane fade w-100 active mx-sm-3  show top-active-tab" id="pastcourses">
        <!-- Card Start -->
        <svg class="d-none">
            <defs>
                <g id="share">
                    <path d="m389.332031 160c-44.09375 0-80-35.882812-80-80s35.90625-80 80-80c44.097657 0 80 35.882812 80 80s-35.902343 80-80 80zm0-128c-26.453125 0-48 21.523438-48 48s21.546875 48 48 48 48-21.523438 48-48-21.546875-48-48-48zm0 0" />
                    <path d="m389.332031 512c-44.09375 0-80-35.882812-80-80s35.90625-80 80-80c44.097657 0 80 35.882812 80 80s-35.902343 80-80 80zm0-128c-26.453125 0-48 21.523438-48 48s21.546875 48 48 48 48-21.523438 48-48-21.546875-48-48-48zm0 0" />
                    <path d="m80 336c-44.097656 0-80-35.882812-80-80s35.902344-80 80-80 80 35.882812 80 80-35.902344 80-80 80zm0-128c-26.453125 0-48 21.523438-48 48s21.546875 48 48 48 48-21.523438 48-48-21.546875-48-48-48zm0 0" />
                    <path d="m135.703125 240.425781c-5.570313 0-10.988281-2.902343-13.910156-8.0625-4.375-7.679687-1.707031-17.453125 5.972656-21.824219l197.953125-112.855468c7.65625-4.414063 17.449219-1.726563 21.800781 5.976562 4.375 7.679688 1.707031 17.449219-5.972656 21.824219l-197.953125 112.851563c-2.496094 1.40625-5.203125 2.089843-7.890625 2.089843zm0 0" />
                    <path d="m333.632812 416.425781c-2.6875 0-5.398437-.683593-7.894531-2.109375l-197.953125-112.855468c-7.679687-4.371094-10.34375-14.144532-5.972656-21.824219 4.351562-7.699219 14.125-10.367188 21.804688-5.972657l197.949218 112.851563c7.679688 4.375 10.347656 14.144531 5.976563 21.824219-2.945313 5.183594-8.363281 8.085937-13.910157 8.085937zm0 0" />
                </g>
            </defs>
        </svg>
        <a v-for="(course,index) in AttandedCourseList"
           :key="'aac'+index"
           class="course-card w-100 d-flex mb-4">
            <div class="col-12 col-lg-5 px-0">
                <div class="img-wrapper web-2 d-flex h-100">
                    <img :src="course.courseimageurl"
                         alt=""
                         class="fit-cover" />
                    <!--<div class="overlay-imgs">
                  <div class="position-relative h-100">
                    <img
                      :src="course.courseimageurl"
                      alt=""
                      class="img-fluid course-img"
                    />
                    <img
                      :src="course.tutorimageurl"
                      alt=""
                      class="img-fluid teacher-img"
                    />
                  </div>
                </div>-->
                </div>
                <div class="progress w-100 d-lg-none">
                    <div class="progress-bar"
                         role="progressbar"
                         style="width: 40%"
                         aria-valuenow="25"
                         aria-valuemin="0"
                         aria-valuemax="100"></div>
                </div>
            </div>
            <div class="col-12 col-lg-7">
                <div class="py-4">
                    <h3 class="card-title">{{ course.coursetitle }}</h3>
                    <p>Classes by {{ course.tutorname }}</p>
                    <hr />
                    <p class="text-success mb-3">
                        {{getcourseenddatlabel(course.displaycourseenddate)}} : {{ course.displaycourseenddate }}
                    </p>
                    <p>
                        Enrolled on {{ course.displaycoursestartdate }}
                    </p>
                    <hr />
                    <div class="d-flex align-items-center">
                        <svg class="svg-icon medium" viewBox="-21 0 512 512">
                            <use xlink:href="#share" />
                        </svg>
                        <span class="text-orange ml-2" @click="opensocialsharepopup(course.courseid)">Refer to a friend</span>
                    </div>
                </div>
            </div>
        </a>
        <SocialShare v-if="showpopup" :callback="hidepopup" :courseId="selectedcourseid" :UserId="userid"></SocialShare>

        <!-- Card End -->
    </div>
</template>
<script>
import SocialShare from "../Common/SocialShare.vue";

export default {
  name: "AttandedDemoCourse",
  props: {
    AttandedCourseList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      CourseList: "",
      userid:0,
      selectedcourseid:0,
      showpopup:false
    };
  },
   methods: {
     getcourseenddatlabel(edate){
       debugger;
       var q = new Date();
var m = q.getMonth()+1;
var d = q.getDay();
var y = q.getFullYear();

var cdate = new Date(y,m,d);

var mydate=new Date(edate);
console.log(cdate);
console.log(mydate)

if(cdate<mydate)
{
    return "Active till"
}
else
{
    return("Completed On")
}

     },
    opensocialsharepopup: function (courseid) {
     debugger
      this.selectedcourseid = courseid;
       this.showpopup=true;
    },
    hidepopup(){
       this.showpopup=false;
    }
   },
  mounted: function () {
    debugger;
      // var urlParams = new URLSearchParams(window.location.search);
      //     var userid = urlParams.get("userid");
      //     this.userid = userid;
         var UserInfo = document.querySelector("#myinfo");
    if (UserInfo != null) {    
      if (UserInfo != null) {
             this.UserInfo = JSON.parse(UserInfo.innerHTML);
           this.userid = this.UserInfo.Pid;
      }
    }
  },
    components: {
    SocialShare: SocialShare,
    }
};
</script>
                    
<template>
  <div class="modal show">
    <!-- add .modal-sm/modal-lg/modal-xl after .modal-dialog for width change -->
    <div class="modal-dialog modal-dialog-scrollable">
      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header align-items-center">
          <svg viewBox="0 0 64 64" class="svg-icon medium d-sm-none">
            <use xlink:href="#chevronBack"></use>
          </svg>
          <h5 v-if="clubScheduleId">Update club schedule details</h5>
          <h5 v-else>Enter club schedule details</h5>
          <button type="button" class="close" @click="onClickClose">×</button>
        </div>
        <div class="modal-body">
          <div class="form">
            <label>Club Level</label>
            <div
              class="md-field mb-3"
              clubScheduledropdown="addForm"
              @click="openDropDown"
              @mouseleave="onMouseLeave"
            >
              <input
                type="text"
                name="clubScheduledropdown"
                required="required"
                id="clubScheduledropdown"
                placeholder="select club level"
                :data-value="selectedValues.clubLevel"
                :value="selectedValues.clubLevel"
                @change="onValueChange"
              />
              <svg
                class="drop-arrow-icon-place"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#000000"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <polyline
                  points="208 96 128 176 48 96"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></polyline>
              </svg>
              <label for="clubScheduledropdown">{{
                placeHolders.clubLevel
              }}</label>
              <span class="error-text d-block" v-if="invalidInput.clubLevel"
                >select club level
              </span>
              <ul class="form-dropdown-data">
                <li
                  v-for="value in clubLevel"
                  @click="onDropDownValueSelect"
                  data-id="clubLevel"
                >
                  {{ value }}
                </li>
              </ul>
            </div>

            <label>Club Name</label>
            <div
              class="md-field mb-3"
              clubScheduledropdown="addForm"
              @click="openDropDown"
              @mouseleave="onMouseLeave"
            >
              <input
                type="text"
                required="required"
                placeholder="select club name"
                data-value=""
                :value="selectedValues.clubName"
              />
              <svg
                class="drop-arrow-icon-place"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#000000"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <polyline
                  points="208 96 128 176 48 96"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></polyline>
              </svg>
              <label>{{ placeHolders.clubName }}</label>
              <span class="error-text d-block" v-if="invalidInput.clubName"
                >select club name
              </span>
              <ul class="form-dropdown-data">
                <li
                  v-for="value in availableClubNames"
                  @click="onDropDownValueSelect"
                  data-id="clubName"
                  :data-club-id="value.id"
                >
                  {{ value.name }}
                </li>
              </ul>
            </div>

            <label>Meeting Type</label>
            <div
              class="md-field mb-3"
              clubScheduledropdown="addForm"
              @click="openDropDown"
              @mouseleave="onMouseLeave"
            >
              <input
                type="text"
                required="required"
                placeholder="select meeting type"
                data-value=""
                :value="selectedValues.meetingType"
              />
              <svg
                class="drop-arrow-icon-place"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#000000"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <polyline
                  points="208 96 128 176 48 96"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></polyline>
              </svg>
              <label>{{ placeHolders.meetingType }}</label>
              <span class="error-text d-block" v-if="invalidInput.meetingType"
                >select meeting type
              </span>
              <ul class="form-dropdown-data">
                <li
                  v-for="value in availableMeetingTypes"
                  @click="onDropDownValueSelect"
                  data-id="meetingType"
                  :data-meetingType-id="value.id"
                >
                  {{ value.name }}
                </li>
              </ul>
            </div>

            <label>Date and Time</label>
            <datetime
              type="datetime"
              zone="local"
              value-zone="IST"
              v-model="datetime"
              use12-hour
            ></datetime
            ><!--format="DateTime.DATETIME_SHORT_WITH_SECONDS"-->
            <span class="error-text d-block" v-if="invalidInput.dateTime"
              >select date and time
            </span>

            <label>Duration</label>
            <div
              class="md-field mb-3"
              clubScheduledropdown="addForm"
              @click="openDropDown"
              @mouseleave="onMouseLeave"
            >
              <input
                type="text"
                required="required"
                placeholder="select meeting type"
                data-value=""
                :value="selectedValues.duration"
              />
              <svg
                class="drop-arrow-icon-place"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#000000"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <polyline
                  points="208 96 128 176 48 96"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></polyline>
              </svg>
              <label>{{ placeHolders.duration }}</label>
              <span class="error-text d-block" v-if="invalidInput.duration"
                >select duration
              </span>
              <ul class="form-dropdown-data">
                <li
                  v-for="value in duration"
                  @click="onDropDownValueSelect"
                  data-id="duration"
                >
                  {{ value }}
                </li>
              </ul>
            </div>

            <!-- <label>Topic</label>
                        <div class="md-field mb-3">
                            <input type="text" name="topic" id="topic" required="" placeholder="topic" v-model="selectedValues.topic">
                            <label alt="name" for="topic" placeholder="topic">
                                Topic <sup>*</sup>
                            </label>
                            <span class="error-text d-block" v-if="invalidInput.topic">Enter topic</span>
                        </div> -->

            <label>Topic</label>
            <div class="md-field mb-3">
                <!-- {{selectedTopic}} -->
              <AutoComplete
                :items="liveClassTopic"
                :setValue="setSelectedTopic"
                :selectedTopic="selectedTopic"
              />
                 <span class="error-text d-block" v-if="invalidInput.topic">Enter topic</span>
            </div>

            <label>Coach Name</label>
            <div
              class="md-field mb-3"
              clubScheduledropdown="addForm"
              @click="openDropDown"
              @mouseleave="onMouseLeave"
            >
              <input
                type="text"
                required="required"
                placeholder="select meeting type"
                data-value=""
                :value="selectedValues.coachName"
              />
              <svg
                class="drop-arrow-icon-place"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#000000"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <polyline
                  points="208 96 128 176 48 96"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></polyline>
              </svg>
              <label>{{ placeHolders.coachName }}</label>
              <span class="error-text d-block" v-if="invalidInput.coachName"
                >select coach name
              </span>
              <ul class="form-dropdown-data">
                <li
                  v-for="value in coaches"
                  @click="onDropDownValueSelect"
                  data-id="coachName"
                  :data-coach-id="value.id"
                >
                  {{ value.name }}
                </li>
              </ul>
            </div>

            <label>Host Name</label>
            <div
              class="md-field mb-3"
              clubScheduledropdown="addForm"
              @click="openDropDown"
              @mouseleave="onMouseLeave"
            >
              <input
                type="text"
                required="required"
                placeholder="select meeting type"
                data-value=""
                :value="selectedValues.hostName"
              />
              <svg
                class="drop-arrow-icon-place"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#000000"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <polyline
                  points="208 96 128 176 48 96"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></polyline>
              </svg>
              <label>{{ placeHolders.hostName }}</label>
              <span class="error-text d-block" v-if="invalidInput.hostName"
                >select Host name
              </span>
              <ul class="form-dropdown-data">
                <li
                  v-for="value in hosts"
                  @click="onDropDownValueSelect"
                  data-id="hostName"
                  :data-host-id="value.id"
                >
                  {{ value.name }}
                </li>
              </ul>
            </div>

            <div id="">
              <h5>Co-Hosts</h5>
              <ul id="inpgrpClubLevel" class="list-group">
                
                <li v-for="value in coHosts">
                  <div class="material-checkbox">
                    <input
                      class="manage-club-coach-chkbox"
                      v-model="selectedValues.coHosts"
                      :id="'club-schedule-chkbox-' + value.name"
                      type="checkbox"
                      name=""
                      :value="value.name"
                    />
                    <label :for="'club-schedule-chkbox-' + value.name">{{
                      value.name
                    }}</label>
                  </div>
                </li>
              </ul>
              <span class="error-text d-block" v-if="invalidInput.coHosts"
                >select co-hosts</span
              >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="clubScheduleId"
            class="btn btn-primary btn-block"
            @click="onClickSubmit"
          >
            Update
          </button>
          <button
            v-else
            class="btn btn-primary btn-block"
            @click="onClickSubmit"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Datetime } from "vue-datetime";
/* import 'vue-datetime/dist/vue-datetime.css'*/
import Loader from "../../wwwroot/js/Loader.js";

import AutoComplete from "./AutoComplete.vue";

export default {
  props: {
    onClose: {
      type: Function,
    },
    clubScheduleDetails: {
      type: Object,
    },
    availableClubNames: {
      type: Array,
    },
    availableMeetingTypes: {
      type: Array,
    },
    coaches: {
      type: Array,
    },
    hosts: {
      type: Array,
    },
    coHosts: {
      type: Array,
    },
  },
  data() {
    return {
      name: "AddNewClubSchedule",

      clubLevel: ["Green", "Blue", "Both"],
      meetingType: [
        "Club Meet",
        "Live Class",
        "talk-time",
        "New Member",
        "Corporate",
      ],
      duration: ["30", "45", "60", "75", "90"],
      liveClassTopic: [],
      /*coaches: ["Ajeeth F", "Sanya Pal", "Deepika", "Nandakumar"],*/
      selectedValues: {
        clubLevel: "",
        clubName: "",
        meetingType: "",
        meetingTypeId: "",
        meetingDate: "",
        meetingTime: "",
        duration: "",
        topic: "",
        coachName: "",
        coachId: "",
        hostName: "",
        hostId: "",
        cohost: "",
        clubId: "",
        coHosts: [],
        coHostIds: [],
        liveclasstopicId: 0      
      },
     selectedTopic: { label: "", value: 0 },
      placeHolders: {
        clubLevel: "Club Level",
        clubName: "Club Name",
        meetingType: "Meeting Type",
        duration: "Duration",
        topic: "Topic",
        coachName: "Coach Name",
      },
      invalidInput: {
        clubLevel: false,
        clubName: false,
        meetingType: false,
        dateTime: false,
        duration: false,
        topic: false,
        coachName: false,
        hostName: false,
        cohost: false,
        clubId: false,
        coHosts: false,
      },
      datetime: "",
      clubScheduleId: 0,
    };
  },
  components: {
    Datetime: Datetime,
    AutoComplete: AutoComplete,
  },

  beforeMount: async function () {
    if (this.clubScheduleDetails) {
      this.selectedValues.clubLevel = this.clubScheduleDetails.clubLevel;
      this.selectedValues.clubName = this.clubScheduleDetails.clubName;
      this.selectedValues.meetingType = this.clubScheduleDetails.meetingType;
      this.datetime = this.clubScheduleDetails.scheduleDateTime;
      this.selectedValues.duration = this.clubScheduleDetails.duration;
      this.selectedValues.topic = this.clubScheduleDetails.meetingTopic;
      this.selectedValues.coachName = this.clubScheduleDetails.coachName;
      this.selectedValues.coachId = this.clubScheduleDetails.coachUserId;
      this.selectedValues.hostName = this.clubScheduleDetails.host;
      this.selectedValues.hostId = this.clubScheduleDetails.hostUserId;
      this.selectedValues.clubId = this.clubScheduleDetails.clubId;
      this.selectedValues.meetingTypeId =
        this.clubScheduleDetails.meetingTypeId;
      this.clubScheduleId = this.clubScheduleDetails.clubScheduleId;
      this.selectedValues.coHosts = this.clubScheduleDetails.coHostNames
        ? this.clubScheduleDetails.coHostNames.split(",")
        : [];

      this.placeHolders.clubLevel = "";
      this.placeHolders.clubName = "";
      this.placeHolders.meetingType = "";
      this.placeHolders.duration = "";
      this.placeHolders.topic = "";
      this.placeHolders.coachName = "";
      this.selectedTopic.value=0
      this.selectedTopic.label="";
    }
  },
  mounted() {
    if(this.selectedValues.meetingTypeId==""){
     this.getLiveClassTopic(1);
    }
     else {
         this.getLiveClassTopic(this.selectedValues.meetingTypeId);
     }
      setTimeout(() => {
         this.selectedTopic.value=this.clubScheduleDetails.topicid;
         this.selectedTopic.label=this.clubScheduleDetails.meetingTopic;
      },1000);
     
  },
  methods: {
    setSelectedTopic: function (selectedTopic) {
      console.log("selectedTopic is ");
      console.log(selectedTopic);
      this.selectedTopic = selectedTopic;
    },
    openDropDown: function (event) {
      event.currentTarget.classList.add("open");
      event.stopPropagation();
    },
    onMouseLeave: function (event) {
      event.currentTarget.classList.remove("open");
    },
    onDropDownValueSelect: function (event) {
      let valueName = event.currentTarget.getAttribute("data-id");

      this.selectedValues[valueName] = event.currentTarget.innerText;
      if (valueName == "clubName") {
        this.selectedValues.clubId =
          event.currentTarget.getAttribute("data-club-id");
      }
      if (valueName == "meetingType") {
        this.selectedValues.meetingTypeId = event.currentTarget.getAttribute(
          "data-meetingType-id"
        );
        this.selectedTopic = { label: "", value: 0 };
        this.getLiveClassTopic(this.selectedValues.meetingTypeId);
      }
      if (valueName == "coachName") {
        this.selectedValues.coachId =
          event.currentTarget.getAttribute("data-coach-id");
      }
      if (valueName == "hostName") {
        this.selectedValues.hostId =
          event.currentTarget.getAttribute("data-host-id");
      }
      this.placeHolders[valueName] = "";
      event.currentTarget.parentNode.parentNode.classList.remove("open");
      event.stopPropagation();
    },

    //   MeetingTopic: this.selectedTopic.label,//this.selectedValues.topic,
    //   HostUserId: this.selectedValues.hostId,
    //   CoachUserId: this.selectedValues.coachId,
    //   HostName: this.selectedValues.hostName,
    //   CoHoastIds: this.selectedValues.coHostIds.join(),
    //   MeetingTopicId:this.selectedTopic.value

    insertOrUpdateDetails: function () {
      let url = "/api/admin/insert-update-club-schedule";
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ClubScheduleId: this.clubScheduleId,
          ClubLevel:
            this.selectedValues.clubLevel == "Both"
              ? ""
              : this.selectedValues.clubLevel,
          ClubId:
            this.selectedValues.clubName == "All"
              ? "0"
              : this.selectedValues.clubId,
          ScheduleDateTime: this.datetime,
          Duration: this.selectedValues.duration,
          MeetingTypeId: this.selectedValues.meetingTypeId,
          MeetingTopic: this.selectedTopic.label,//this.selectedValues.topic,
          HostUserId: this.selectedValues.hostId,
          CoachUserId: this.selectedValues.coachId,
          HostName: this.selectedValues.hostName,
          MeetingTopicId:this.selectedTopic.value,
          CoHoastIds: this.selectedValues.coHostIds.join(),
        }),
      };

      Loader.showAboveModal();
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data > 0) {
            if (this.clubScheduleId)
              alert("Club schedule updated successfully");
            else alert("Club schedule added successfully");
          } else {
            if (this.clubScheduleId) alert("Club schedule updation failed");
            else alert("Club schedule insertion failed");
          }
          Loader.hideAboveModal();
          this.onClickClose();
        });
    },
    onClickClose: function () {
      if (this.onClose) {
        this.onClose();
      }
    },
    isValidForm: function () {
      let isValidForm = true;
      if (!this.selectedValues.clubLevel) {
        this.invalidInput.clubLevel = true;
        isValidForm = false;
      } else {
        this.invalidInput.clubLevel = false;
      }

      if (!this.selectedValues.clubName) {
        this.invalidInput.clubName = true;
        isValidForm = false;
      } else {
        this.invalidInput.clubName = false;
      }
      if (!this.selectedValues.meetingType) {
        isValidForm = false;
        this.invalidInput.meetingType = true;
      } else {
        this.invalidInput.meetingType = false;
      }
     
      if (!this.datetime) {
        isValidForm = false;
        this.invalidInput.dateTime = true;
      } else {
        this.invalidInput.dateTime = false;
      }

      if (this.selectedTopic.value == 0) {
        isValidForm = false;
        this.invalidInput.topic = true;
      } else {
        this.invalidInput.topic = false;
      }
      //   if (!this.selectedValues.topic) {
      //     isValidForm = false;
      //     this.invalidInput.topic = true;
      //   } else {
      //     this.invalidInput.topic = false;
      //   }

      if (!this.selectedValues.coachName) {
        isValidForm = false;
        this.invalidInput.coachName = true;
      } else {
        this.invalidInput.coachName = false;
      }
      if (!this.selectedValues.duration) {
        isValidForm = false;
        this.invalidInput.duration = true;
      } else {
        this.invalidInput.duration = false;
      }
      if (this.selectedValues.coHosts.length <= 0) {
        isValidForm = false;
        this.invalidInput.coHosts = true;
      } else {
        this.invalidInput.coHosts = false;
      }
      return isValidForm;
    },
    onClickSubmit: function () {
   
      if (this.isValidForm()) {
   
        this.insertOrUpdateDetails();
      }
    },
    onValueChange: function (event) {
      console.log("event target ", event.currentTarget);
    },

    getLiveClassTopic(meetingtypeid) {
      let url =
        "/api/admin/get-live-class-topics?meetingtypeid=" + meetingtypeid;
      debugger;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          debugger;
          if (data) {
            this.liveClassTopic = data;
          }
        });
    },
  },
  watch: {
    "selectedValues.clubLevel": function () {
      this.invalidInput.clubLevel = false;
    },
    "selectedValues.clubName": function () {
      this.invalidInput.clubName = false;
    },
    "selectedValues.meetingType": function () {
      this.invalidInput.meetingType = false;
    },
    "selectedValues.dateTime": function () {
      this.invalidInput.dateTime = false;
    },
    "selectedValues.duration": function () {
      this.invalidInput.duration = false;
    },
    // "selectedValues.topic": function () {
    //   this.invalidInput.topic = false;
    // },
    "selectedValues.coachName": function () {
      this.invalidInput.coachName = false;
    },
    "selectedValues.hostName": function () {
      this.invalidInput.hostName = false;
    },
    "selectedValues.cohost": function () {
      this.invalidInput.cohost = false;
    },
    "selectedValues.clubId": function () {
      this.invalidInput.clubId = false;
    },
     "selectedTopic.value": function () {
        if(this.selectedTopic.value==0){
            this.invalidInput.topic = true;            
        }
        else {
             this.invalidInput.topic = false;
        }
    },    
    "selectedValues.coHosts": function () {
      this.invalidInput.coHosts = false;

      let coHostsIdArray = this.selectedValues.coHosts.map(
        (selectedCoHostName) => {
          let coHostId = "";
          for (let i = 0; i < this.coHosts.length; i++) {
            if (this.coHosts[i].name == selectedCoHostName) {
              coHostId = this.coHosts[i].id;

              break;
            }
          }
          return coHostId;
        }
      );
      this.selectedValues.coHostIds = coHostsIdArray;
    },
    items: function (val, oldValue) {
      // actually compare them
      if (val.length !== oldValue.length) {
        this.results = val;
        this.isLoading = false;
      }
    },
  },
};
</script>


﻿var review = {
    init: function () {
        if (document.querySelectorAll("[data-review-showmore]")) {
            var showMoreButtons = document.querySelectorAll("[data-review-showmore]");
            showMoreButtons.forEach(function (button) {
                if (button.previousElementSibling.scrollHeight > button.previousElementSibling.clientHeight)
                    button.classList.remove("d-none");
                button.addEventListener('click', function (event) {
                    event.preventDefault();
                    if (this.previousElementSibling.classList.contains('line-clamp')) {
                        this.previousElementSibling.classList.remove('line-clamp');
                        this.style.display = 'none';
                    }
                })
            })
        }        
    }
}
if (document.querySelectorAll("[data-review-showmore]"))
    review.init();
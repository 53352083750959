<template>
               
                 <div class="tab-pane fade course-tab w-100  show active " id="material">
                    <div class="search-input-wrapper rounded mx-3 py-2">
                      
                      
                    <!-- card -->
                    <div class="row pb-3 mx-0 mt-3" title="Click here to visit to course management" @click="redirecttolms()"  v-for="(material, index) in materiallist"
                    :key="index">
                      <div class="col-12">
                        <div class="row mx-0 flex-column py-4 px-3 rounded-3 bg-white shadow-sm">
                          <div class="row">
                            <i  @click="showfile(material.filetype,material.filePath)" :class="material.filetype=='Video'?'col-2 mt-2 fab text-center fa-youtube':'col-2 mt-2 fas text-center fa-file-pdf'"></i>


                            <h4 class="col-8 px-0 mb-2">
                            {{material.bookname}}
                            </h4>
                            <div class="col-2 d-flex align-items-center justify-content-center justify-content-md-end pr-3">
                              <button class="btn-reset" aria-label="options">
                                <i class=""></i>
                              </button>
                            </div>
                            <div class="col-2"></div>
                            <div class="col-8 mb-3 pl-0 caption text-muted" >
                              <!-- 9 m | Watch on Youtube   -->
                              {{material.filetype}}
                            </div>
                          </div>
                          <div class="row mt-2" >
                            <h6 class="offset-2 pl-0 col-8">
                              <!-- Watch the video and practice with your friends   @click="downloadfile(material.filePath,material.id)"-->
                            
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card end -->
                    <!-- card -->
                  
                    <!-- card end -->
                  </div>

                  <!-- Modal start -->
          <!-- remove .show to hide modal -->
          <div :class="showpopup?'modal show':'modal'">
            <!-- add .modal-sm/modal-lg/modal-xl after .modal-dialog for width change -->
            <div class="modal-dialog">
              <!-- Modal content -->
              <div class="modal-content">
                <div class="close" @click="closepopup()">
                  <span @click="closepopup()">&times;</span>
                </div>
                <div class="modal-body" style="height: 20rem">
                  <!-- Content goes here -->

                  <video width="450" controls :src="videourl" v-if="showvideo"></video>
                  
                   <img v-if="showimage"
            :src="imageurl"
            alt=""
            class="img-fluid bg-img w-100 h-100"
          />
                </div>
              </div>
            </div>
          </div>
          <!-- Modal end -->
                  <!-- course tab content end -->

                </div>

     
</template>
<script>
// import {
//     BlobServiceClient,
//     AnonymousCredential,
//     newPipeline
//   } from "@azure/storage-blob";
//   import { saveAs } from "file-saver";
import Loader from "../../wwwroot/js/Loader.js";
export default {
  name: "MaterialList",
  props: {
    
   },
  data() {
    return {
     materiallist:[],
     message:"Test",
     courseId: "",
      UserId:"",
      videourl:"",
      showvideo:false,
      showimage:false,
      showpopup:false,
       imageurl:""
    };
  },
  created: function () {},

  mounted: function () {
 var urlParams = new URLSearchParams(window.location.search);
 var courseid = urlParams.get("Courseid");
  // var urlParamsTab = new URLSearchParams(window.location.search);
 var activepage = urlParams.get("activepage");
    // Assign all values for crate course feature
 var Userid = urlParams.get("Userid");

this.courseId=courseid;
this.UserId=Userid;
Loader.showAboveModal();
    var getcourseurl="/api/course/GetMyMaterials?CourseId=" +this.courseId+"&UserId"+this.UserId;
     
    this.isloading = true;
    fetch(getcourseurl, { method: "GET" })
      .then((responseJson) => responseJson.json())
      .then((response) => {
           Loader.hideAboveModal();
        if (response !== null) {

         this.materiallist=response;
         
        } else {
        this.materiallist=[]
        }
      });


  },
  methods: {

//  async downloadfile(filepath,id) {

//          var filename = filepath.replace(/^.*[\\\/]/, "");
//        var  Extension = filename.split(".")[1];
//        if(Extension == "mp4" ||
//           Extension == "mov" ||
//          Extension == "webm" ||
//          Extension == "ogv"){

//               //  var vid=  "videoelement" + id;
//               //   const vidobj = document.getElementById(vid);
//               //   if(vidobj!=null){
//               //      vidobj.play();
//               //   }
//          }

//       const accountName = "lscdn";

//       var  response= await this.GetSastoken() 
//       const sasresponse = await response.json();
//       var sastoken = sasresponse.sastoken;

//       const containerName = "coursematerial";

//       const pipeline = newPipeline(new AnonymousCredential(), {
//         retryOptions: { maxTries: 4 }, // Retry options
//         userAgentOptions: { userAgentPrefix: "AdvancedSample V1.0.0" }, // Customized telemetry string
//         keepAliveOptions: {
//           // Keep alive is enabled by default, disable keep alive by setting false
//           enable: false
//         }
//       });

//       const blobServiceClient = new BlobServiceClient(
//         `https://${accountName}.blob.core.windows.net?${sastoken}`,
//         pipeline
//       );
//       var containerClient = blobServiceClient.getContainerClient(containerName);
//       if (!containerClient.exists()) {
//         console.log("the container does not exit");
//         await containerClient.create();
//       }

//       var blockBlobClient = containerClient.getBlockBlobClient(filepath);
//       const downloadBlockBlobResponse = await blockBlobClient.download(0);

//            const data = await downloadBlockBlobResponse.blobBody;
//       console.log(data);

//       var filename = blockBlobClient.name.replace(/^.*[\\\/]/, "");
//       saveAs(data,filename);
      
   
//     },

    redirecttolms(){
      var url="http://lms.sulekha.com/coursemanagement?courseid="+this.courseId;
      window.open(url, "_blank");     
    },

   async GetSastoken(){
        var getsasurl=
      "/api/course/GetSasToken";
     
    this.isloading = true;
  return fetch(getsasurl, { method: "GET" })
      // .then((responseJson) => responseJson.json()).bind()
      // .then((response) => {
      //   if (response !== null) {
      //      return response.sastoken
      //   } else {
      //     return "";
      //   }
      // })

    },
    showfile(filetype,filepath){
   var url =
        "https://lscdn.blob.core.windows.net/coursematerial/" +
        filepath;
        this.showimage=false;
         this.showvideo=false;

        if(filetype=="Video"){
          this.showpopup=true;
         this.videourl=url;
         this.showvideo=true;
        } 
        else if(filetype=="Image"){
            this.showpopup=true;
         this.videourl="";
           this.imageurl=url;
         this.showimage=true;
        }
        else {
        this.videourl="";
        this.showpopup=false;
        window.location.href=url;
        }
  },
      closepopup(){
       this.showpopup=false;
    }

  },
};
</script>
<template>
    <!--<div class="modal-backdrop fade" :class="{show: showpopup}"></div>-->
    <div class="modal" :class="{show: showpopup}">
        <svg class="d-none">
            <defs>
                <g id="chevronBack">
                    <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" />
                </g>
            </defs>
        </svg>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header align-items-center">
                    <svg @click="hidepopup()" class="svg-icon medium d-sm-none" viewBox="0 0 64 64">
                        <use xlink:href="#chevronBack" />
                    </svg>
                    <h5>{{headerText}}</h5>
                    <button type="button" class="close" @click="hidepopup()">&times;</button>
                </div>
                <!--<div class="bg-header-wizard">
                    <div class="container position-relative">
                        <div class="header-between">
                            <div class="d-flex flex-column ml-1">
                                <h2 class="lang-title">{{headerText}}</h2>
                            </div>
                            <a class="mr-2" @click="hidepopup()">
                                <i class="far fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>-->
                <div class="modal-body">
                    <SignUp v-if="currentStep == 'SignUp'" :hidepopup="hidepopup" :onKeyUp="onKeyUp" :onKeyDown="onKeyDown" :userDetails="userDetails" :signUpSubmit="signUpSubmit" :openSignIn="openSignIn"
                            :nameErrorText="nameErrorText" :emailErrorText="emailErrorText" :mobileErrorText="mobileErrorText" :errorText="errorText">
                        <!--:nameErrorText="nameErrorText" :emailErrorText="emailErrorText" :mobileErrorText="mobileErrorText" :pincodeErrorText="pincodeErrorText" :errorText="errorText">-->

                    </SignUp>
                    <CommonVerification v-if="currentStep == 'Otp'" :hidepopup="hidepopup" :userDetails="userDetails" :invalidOtp="invalidOtp" @post-validateCode="validateCode">

                    </CommonVerification>
                    <SignUpOtpVerified v-if="currentStep == 'OtpVerified'">

                    </SignUpOtpVerified>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import SignUp from "./SignUp.vue";
    import CommonVerification from "../Otp/CommonVerification.vue";
    import SignUpOtpVerified from "./OtpVerified.vue";
    import Loader from "../../wwwroot/js/Loader.js";

    export default {
        name: "SignUpModal",
        props: {

        },
        data() {
            return {
                currentStep: 'SignUp',
                showpopup: true,
                invalidOtp: false,
                nameErrorText: "",
                emailErrorText: "",
                mobileErrorText: "",
                //pincodeErrorText: "",
                errorText: "",
                regexp: {
                    alpha: /^[a-z0-9\.\s]+$/i,
                    number: /[^0-9]/g,
                    email: /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i,
                    mobile1: /^[9|7|6|8]\d{8}[\d{1}]$/i,
                    internationalmobile: /^[0-9]{6,14}$/,
                    //pincode: /^[0-9]{6,6}$/,
                },
                userDetails: {
                    isSignUp: true,
                    UserName: '',
                    EmailId: '',
                    MobileNumber: '',
                    CountryCode: '+91',
                    VerificationCode: '',
                    IsMissedCallVerfication: false,
                    IsReSendCode: false,
                    IsChangeMobile: false,
                    //Pincode: '',
                }
            }
        },
        methods: {
            isValidName(name) {
                return (this.regexp.alpha.test(name) || (name.match(/\./g) != null && name.match(/\./g).length > 3) || (name.replace(/[^a-zA-Z]+/g, "").length <= 2));
            },
            isValidEMail(email) {
                return this.regexp.email.test(email);
            },
            isValidMobileNumber(mobileno, countrycode) {
                if (countrycode == undefined || countrycode == "" || countrycode == "91" || countrycode == "+91") {
                    return (this.regexp.mobile1.test(mobileno));
                }
                else {
                    return (this.regexp.internationalmobile.test(mobileno));
                }
            },
            //isValidPincode(pincode) {
            //    return this.regexp.pincode.test(pincode);
            //},
            hidepopup() {
                this.showpopup = false;
                document.body.classList.remove("overflow-hidden");
                var vueBaseDiv = document.querySelector("#signup-base-div");
                vueBaseDiv.innerHTML = "";
                var div = document.createElement("div");
                div.id = "SignUpDiv";
                vueBaseDiv.appendChild(div);
                document.getElementById("hdnLoginUrlHash") ? document.getElementById("hdnLoginUrlHash").value = "" : "";
                document.getElementById("hdnShortlist") ? document.getElementById("hdnShortlist").value = "" : "";
            },
            onKeyUp(e, refid) {
                if (refid != '' && refid == 'name') {
                    if (this.userDetails.UserName.length > 0)
                        this.nameErrorText = "";
                }
                else if (refid != '' && refid == 'email') {
                    if (this.userDetails.EmailId.length > 0)
                        this.emailErrorText = "";
                }
            },
            onKeyDown(e, refid) {
                if (refid != '' && refid == 'mobileno') {
                    if (this.userDetails.MobileNumber.length > 0)
                        this.mobileErrorText = "";
                }
                //else if (refid != '' && refid == 'pincode') {
                //    if (this.userDetails.Pincode.length > 0)
                //        this.pincodeErrorText = "";
                //}
                var key = e.which;
                if (key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
                    return true;
                }

                e.preventDefault();
                return false;
            },
            signUpSubmit() {
                var allValuesEntered = true;

                // alert(JSON.stringify(this.userDetails));
                if (this.userDetails.UserName == "") {
                    this.nameErrorText = "Please enter your first and last name !";
                    allValuesEntered = false;
                }
                else if (!this.isValidName(this.userDetails.UserName)) {
                    this.nameErrorText = "Please enter valid first and last name !";
                    allValuesEntered = false;
                }

                if (this.userDetails.EmailId == "") {
                    this.emailErrorText = "Please enter your emailid !";
                    allValuesEntered = false;
                }
                else if (!this.isValidEMail(this.userDetails.EmailId)) {
                    this.emailErrorText = "Please enter valid emailid !";
                    allValuesEntered = false;
                }

                if (this.userDetails.MobileNumber == "") {
                    this.mobileErrorText = "Please enter your phone number !";
                    allValuesEntered = false;
                }
                else if (!this.isValidMobileNumber(this.userDetails.MobileNumber, this.userDetails.CountryCode)) {
                    this.mobileErrorText = "Please enter valid phone number !";
                    allValuesEntered = false;
                }

                //if (this.userDetails.CountryCode == "+91") {
                //    if (this.userDetails.Pincode == "") {
                //        this.pincodeErrorText = "Please enter your pincode !";
                //        allValuesEntered = false;
                //    }
                //    else if (!this.isValidPincode(this.userDetails.Pincode)) {
                //        this.pincodeErrorText = "Please enter valid pincode !";
                //        allValuesEntered = false;
                //    }
                //}
                //else
                //    this.userDetails.Pincode = "";

                if (allValuesEntered) {
                    this.nameErrorText = "";
                    this.emailErrorText = "";
                    this.mobileErrorText = "";
                    //this.pincodeErrorText = "";
                    this.signUpApi(this.userDetails);
                }
            },
            validateCode(clientData) {
                if (clientData.IsReSendCode) {
                    this.reSendCodeApi(clientData);
                }
                else if (clientData.IsChangeMobile) {
                    this.userDetails.MobileNumber = "";
                    this.mobileErrorText = "";
                    this.currentStep = "SignUp";
                }
                else {
                    this.verifyMobileApi(clientData);
                }
                //alert(JSON.stringify(clientData));
            },
            signUpApi(clientData) {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(clientData)
                }
                Loader.showAboveModal();
                fetch("/api/userlogin/SignUp", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if (data.mobileVerificationStatus == "VerificationCodeSent") {
                            this.currentStep = "Otp";
                        }
                        else if (data.message != null && data.message != "") {
                            this.errorText = data.message;
                        }
                        Loader.hideAboveModal();
                    });
            },
            verifyMobileApi(clientData) {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(clientData)
                }
                Loader.showAboveModal();
                fetch("/api/userlogin/VerifyMobile", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if (data.mobileVerificationStatus == "ValidNumber") {
                            
                            this.currentStep = "OtpVerified";
                            let hdnLoginUrlHash = "";
                            let shortlistCourseId = "";
                            if (document.getElementById("hdnLoginUrlHash") != null && document.getElementById("hdnLoginUrlHash").value != "")
                                hdnLoginUrlHash = document.getElementById("hdnLoginUrlHash").value;
                            if (document.getElementById("hdnShortlist").value != null) {
                                shortlistCourseId = document.getElementById("hdnShortlist").value
                            }
                            this.hidepopup();
                            if (document.getElementById("hdnUserPid"))
                                document.getElementById("hdnUserPid").value = data.userPid;
                            Loader.hideAboveModal();
                            
                            if (hdnLoginUrlHash) {
                                if (hdnLoginUrlHash == "writereview")
                                    document.querySelector('.write-review-btn').click();
                                if (hdnLoginUrlHash == "clubsignup") {
                                    var inpName = document.querySelector("#inpName");
                                    inpName.value = data.userInfo.firstName;
                                    if (inpName.value != "")
                                        inpName.disabled = true;
                                    var inpMobile = document.querySelector("#inpMobile");
                                    inpMobile.value = data.userInfo.mobileNumber;
                                    if (inpMobile.value != "")
                                        inpMobile.disabled = true;
                                    var inpEmail = document.querySelector("#inpEmail");
                                    inpEmail.value = data.userInfo.emailAddress;
                                    if (inpEmail.value != "")
                                        inpEmail.disabled = true;
                                    document.querySelector("#ClubSignUpForm").classList.add("show");
                                }
                            }

                            else if (shortlistCourseId) {
                                let shortlistBtn = document.querySelector(`[data-shortlist='${shortlistCourseId}']`);
                                shortlistBtn.click();
                            }

                            else
                                window.location.href = this.getRedirectUrl();
                        } else {
                            this.invalidOtp = true;
                        }
                    });
            },
            reSendCodeApi(clientData) {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(clientData)
                }
                fetch("/api/userlogin/ReSendCode", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if (data.mobileVerificationStatus == "VerificationCodeSent") {
                            this.currentStep = "Otp";
                        }
                    });
            },
            openSignIn() {
                var hdnLoginUrlHash = document.getElementById("hdnLoginUrlHash").value;
                this.hidepopup();
                document.getElementById("hdnLoginUrlHash").value = hdnLoginUrlHash;
                document.getElementById("signInMobile").click();
            },
            getRedirectUrl() {
                var url = window.location.href;
                if (document.getElementById("hdnLoginNextUrl") != null && document.getElementById("hdnLoginNextUrl").value != null) {
                    url = document.getElementById("hdnLoginNextUrl").value;
                }
                if (document.getElementById("hdnLoginUrlHash") != null && document.getElementById("hdnLoginUrlHash").value != null) {
                    if (url.indexOf("?") > -1) {
                        url = url.replace('?', '#' + document.getElementById("hdnLoginUrlHash").value + '?');
                    }
                    else {
                        url += '#' + document.getElementById("hdnLoginUrlHash").value;
                    }
                }
                return url;
            },
            getHeaderText() {
                if (this.currentStep == "SignUp") {
                    return "Enter your details to sign up"
                }
                if (this.currentStep == "Otp") {
                    return "Verify your Phone number"
                }
            }
        },
        mounted: function () {
        },
        components: {
            SignUp: SignUp,
            CommonVerification: CommonVerification,
            SignUpOtpVerified: SignUpOtpVerified
        },
        computed: {
            headerText: function () {
                return this.getHeaderText();
            }
        }
    }
</script>
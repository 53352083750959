var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "pay-thank" }, [
      _c("div", { staticClass: "thankmain text-center" }, [
        _c("div", { staticClass: "text-success thankdemo" }, [
          _c("i", { staticClass: "fad fa-check-circle" }),
          _vm._v(" "),
          _vm.instructorDetails.singuptype == "demo"
            ? _c("b", { staticClass: "mb-1" }, [
                _vm._v("Demo scheduled succesfully")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.instructorDetails.singuptype == "regular" &&
          _vm.instructorDetails.CourseName != "One On One Sessions"
            ? _c("b", { staticClass: "mb-1" }, [
                _vm._v("Regular course scheduled succesfully")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.instructorDetails.singuptype == "regular" &&
          _vm.instructorDetails.CourseName == "One On One Sessions"
            ? _c("b", { staticClass: "mb-1" }, [
                _vm._v("One On One Session scheduled succesfully")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.batchdate))]),
          _vm._v(" "),
          _vm.instructorDetails.singuptype == "demo"
            ? _c("p", { staticClass: "caption text-muted" }, [
                _vm._v(
                  "\n                    The instructor will be present to offer you a live demo class.\n                "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.instructorDetails.singuptype == "regular"
            ? _c("p", { staticClass: "caption text-muted" }, [
                _vm._v(
                  "\n                    The instructor will be present to offer you a live  class.\n                "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "alert alert-primary" }, [
          _c("p", { staticClass: "caption fw-700 mb-2" }, [
            _vm._v(
              "\n                    The instructors time is valuable just as yours is\n                "
            )
          ]),
          _vm._v(" "),
          _vm.instructorDetails.singuptype == "demo"
            ? _c("p", { staticClass: "caption mb-2" }, [
                _vm._v(
                  "\n                    Please be present for the demo a few minutes before the start time\n                    to ensure your audio and video are functioning well.\n                "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.instructorDetails.singuptype == "regular"
            ? _c("p", { staticClass: "caption  mb-2" }, [
                _vm._v(
                  "\n                    Please be present for the session a few minutes before the start time\n                    to ensure your audio and video are functioning well.\n                "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "caption mb-2" }, [_vm._v("Thank you.")])
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                return _vm.gotomycourse()
              }
            }
          },
          [_vm._v("\n\n                Go to my courses\n            ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
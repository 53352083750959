<template>


  <!-- <section>
    <div class="container-fluid container-md mt-5">
      <div class="row justify-content-center"> -->
        <!-- Modal start -->
        <!-- remove .show to hide modal -->
        <div :class="showme ? 'modal show' : 'modal'">
          <!-- add .modal-sm/modal-lg/modal-xl after .modal-dialog for width change -->
          <div class="modal-dialog modal-xl">
            <!-- Modal content -->
              <div class="modal-content">
                  <div class="progress">
                      <div class="progress-bar"
                           role="progressbar"
                           style="width: 50%"
                           aria-valuenow="50"
                           aria-valuemin="0"
                           aria-valuemax="100"></div>
                  </div>
                  <!-- progress bar end -->
                  <div class="row mx-0" v-if="currentStep == 'payment'">
                      <div class="col-lg-6 primary-bg-color"></div>
                      <div class="close-btn col-lg-6 d-none d-lg-flex">
                          <a @click="closePopup()">&times;</a>
                      </div>
                  </div>
                  <div class="modal-header align-items-center d-sm-none" v-if="currentStep != 'thankYou'">
                      <svg @click="closePopup()" class="svg-icon medium d-sm-none" viewBox="0 0 64 64">
                          <use xlink:href="#chevronBack" />
                      </svg>
                  </div>
                  <div class="modal-body p-0 overflow-y">

                      <!--<div class="modal-header-mobile d-sm-none">
                          <a class="h2 m-2" @click="closePopup()">
                              <i class="far fa-arrow-left" @click="closePopup()"></i>
                          </a>
                      </div>-->

                      <UserSignUp v-if="currentStep == 'mobileVerify'"
                                  :instructorDetails="instructorDetails"
                                  :userInfo="UserInfo"
                                  :pincodeData="PincodeData"
                                  :nextStep="nextStep"
                                  :courseSignUpClientData="CourseSignUpClientData"
                                  :isValidName="isValidName"
                                  :isValidEMail="isValidEMail"
                                  :isValidMobileNumber="isValidMobileNumber"
                                :isValidPincode="isValidPincode"
                                  @post-postCourseSignUp="postCourseSignUp"
                                  :selectedDate="selectedDate"
                                  :closePopup="closePopup"
                                  SignUpType="signup payment"
                                  sessiontype="oneonone"
                                  :errormsg="errormessage" />

                      <OTPVerification ref="child"
                                       v-if="currentStep == 'otpVerify'"
                                       :instructorDetails="instructorDetails"
                                       :nextStep="nextStep"
                                       :invalidOtp="invalidOtp"
                                       :courseSignUpClientData="CourseSignUpClientData"
                                       :selectedDate="selectedDate"
                                       @post-postCourseSignUp="postCourseSignUp"
                                       :closePopup="closePopup"
                                       :resendotptime="resendotptime" />
                  </div>
              </div>
          </div>
        </div>
        <!-- Modal end -->
      <!-- </div>
    </div>
  </section> -->
 
  
</template>
<script>
    import UserSignUp from "../CourseSignup/UserSignUp.vue"; //"./UserSignUp.vue";
    import OTPVerification from "../CourseSignup/OTPVerification.vue";
    import Loader from "../../wwwroot/js/Loader.js";
    import SulGA from "../../wwwroot/js/Ga.js";
    export default {
        name: "OneOnOneSignup",
        props: {
            courseId: "",
            businessid: "",
            numberofsession: "",
            coursefees: "",
            tutorname: "",
            image: "",
            initialPopup: "",
            showpopup: false
        },
        data() {
            return {
                currentStep: "mobileVerify",
                getInstructorApiUrl: '/api/coursesignup/GetOneOnOneCourseSignupInfo?businessid=' + this.businessid,
                postCourseSignUpApiUrl: `/api/coursesignup/oneonone/register`,
                paymentUrl: "",
                vMaxMissedCheck: 0,
                vMaxMissedCheckDefault: 12,
                instructorDetails: {},
                CourseSignUpClientData: {},
                UserInfo: {},
                PincodeData: [],
                regexp: {
                    alpha: /^[a-z0-9\.\s]+$/i,
                    number: /[^0-9]/g,
                    email: /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i,
                    mobile1: /^[9|7|6]\d{8}[\d{1}]$/i,
                    mobile2: /^[8]\d{8}[\d{1}]$/i,
                    othermobile: /^[1-9]\d{9}$/,
                    internationalmobile: /^[0-9]{6,14}$/,
                    pincode: /^[1-9]{1}[0-9]{2}[0-9]{3}$/,
                },
                invalidOtp: false,
                popUp: "",
                showme: false,
                coursesignupdetails: {},
                resendotptime: new Date(),
                selectedDate: new Date(),
                errormessage: ""
            };
        },
        created() {
            var body = document.body;
            body.classList.add("slkno-scroll");
        },
        mounted: function () {
            //SulGA.init();
            //  SulGA.pushPageView();
            var trackinglabel = "Join Demo " + this.courseId;
            SulGA.pushEvent(
                "one-on-one-book",
                this.numberofsession + " One-on-One Sessions Book",
                "one-on-one-book-click"
            );

            Loader.showAboveModal();
            fetch(this.getInstructorApiUrl, { method: 'GET' })
                .then(responseJson => responseJson.json())
                .then(response => {
                    Loader.hideAboveModal();
                    this.instructorDetails = response.Instructor;
                    this.instructorDetails.singuptype = "oneonone"
                    this.instructorDetails.TutorName = this.tutorname
                    this.instructorDetails.ImageURL = this.image
                    this.instructorDetails.CourseName = "One On One Session"
                    this.CourseSignUpClientData = response.CourseSignUpClientData;
                    this.CourseSignUpClientData.NoOfSessions = this.numberofsession;
                    this.CourseSignUpClientData.Fees = this.coursefees;
                    this.CourseSignUpClientData.TutorName = this.tutorname
                    this.CourseSignUpClientData.AdId = this.businessid
                    this.UserInfo = response.UserInfoModel
                    this.PincodeData = response.Pincode;
                })
            this.showme = this.showpopup;

        },
        methods: {
            nextStep() {
                // switch (this.currentStep) {
                //     case 'slotBooking':
                //         if (this.QuestionAndAnswers && this.QuestionAndAnswers !== null) {
                //             this.QuestionAndAnswers.forEach((item) => {
                //                 let questionAndAnswer = {
                //                     CourseId: this.courseId,
                //                     mobileno: '',
                //                     Pk_QuestionId: item.QuestionId,
                //                     Pk_AnswerId: ''
                //                 }
                //                 this.SelectedAnswers.push(questionAndAnswer);
                //             });
                //             this.currentStep = 'questionAndAnswer'
                //             break;
                //         } else {
                //             this.currentStep = 'mobileVerify'
                //             break;
                //         }
                //     case 'questionAndAnswer':
                //         this.currentStep = 'mobileVerify'
                //         break;
                // }
            },
            closePopup() {
                //  this.popUp = 'close'

                SulGA.pushEvent(
                    "pay-popup-close",
                    "Pay popup close",
                    "Pay-popup-close-click"
                );
                this.showme = false;
                var body = document.body;
                body.classList.remove("slkno-scroll");
                var demobooking = document.getElementById("demo-booking");
                demobooking.innerHTML = "";
                var div = document.createElement("div");
                div.id = "demo-signup";
                demobooking.appendChild(div);
            },

            postCourseSignUp(clientData) {
                var today = new Date();
                if (this.currentStep == "otpVerify") {
                    if (clientData.verifycode == "resetmobilenumber") {
                        this.currentStep = "mobileVerify";
                        clientData.verifycode = null;
                        return;
                    } else if (clientData.verifycode == "resendotp") {
                        this.currentStep = "otpVerify";
                        clientData.verifycode = null;
                        var date =
                            today.getFullYear() +
                            "-" +
                            (today.getMonth() + 1) +
                            "-" +
                            today.getDate();

                        this.resendotptime =
                            date +
                            today.getHours() +
                            ":" +
                            today.getMinutes() +
                            ":" +
                            today.getSeconds();
                    }
                }
                Loader.showAboveModal();
                fetch(this.postCourseSignUpApiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(clientData),
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        Loader.hideAboveModal();
                        this.signupSuccessResponse(responseJson, clientData);
                    });
            },

            signupSuccessResponse(response, clientData) {
                let status = response.Status.toLowerCase();
                // if (status === "success" || status === "validnumber" || status === "verified"
                //     || status === "duplicate") {
                //     this.currentStep = 'thankYou';
                // }
                this.errormessage = "";
                if (status == 'already exists') {
                    alert('Already signed up for this course.');
                    this.closePopup();
                }
                if (status === "verificationcodesent" || status === "verificationcode") {
                    this.currentStep = "otpVerify";
                }

                if (status === "payment required" || status == "validnumber") {
                    var urlParams = new URL(response.sPaymentURL);
                    var oid = urlParams.searchParams.get("oid");
                    SulGA.pushEvent("confirm-otp", "otp verified", "confirm-otp-verify");
                    window.location = "/PaymentGateWay?oid=" + oid;
                    // window.location = response.sPaymentURL;
                }
                if (status == "invalidcode") {
                    this.invalidOtp = true;
                }
                if (status == "invalidinput") {
                    this.invalidOtp = true;
                    this.errormessage = response.Message
                }
                if (status == "0") {
                    if (clientData.missedcall) {
                        SulGA.pushEvent(
                            "confirm-missed-call",
                            "Missed call verified",
                            "confirm-missed-call-click"
                        );
                        setTimeout(() => {
                            if (this.vMaxMissedCheck < this.vMaxMissedCheckDefault) {
                                this.postCourseSignUp(clientData);
                                this.vMaxMissedCheck = this.vMaxMissedCheck + 1;
                            }
                        }, 4000);
                    }
                }
            },
            isValidName(name) {
                return (
                    this.regexp.alpha.test(name) ||
                    (name.match(/\./g) != null && name.match(/\./g).length > 3) ||
                    name.replace(/[^a-zA-Z]+/g, "").length <= 2
                );
            },
            isValidEMail(email) {
                return this.regexp.email.test(email);
            },
            isValidMobileNumber(mobileno, CountryCode) {
                if (CountryCode == undefined || CountryCode == "" || CountryCode == "91" || CountryCode == "+91") {
                    return (
                        this.regexp.mobile1.test(mobileno) || this.regexp.mobile2.test(mobileno)
                    );
                }
                else {
                    return (
                        this.regexp.internationalmobile.test(mobileno)
                    );
                }
            },
            isValidPincode(pincode) {
                return this.regexp.pincode.test(pincode);
            },
        },
        components: {
            UserSignUp: UserSignUp,
            OTPVerification: OTPVerification,
        },
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "base",
      staticClass: "modal fade show",
      staticStyle: { display: "block" },
      attrs: { id: "Openwindow" }
    },
    [
      _c("svg", { staticClass: "d-none" }, [
        _c("defs", [
          _c("g", { attrs: { id: "chevronBack" } }, [
            _c("path", {
              attrs: {
                d:
                  "m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-dialog multistep-quiz-form" }, [
        _c("div", { ref: "scrollHookTop" }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header align-items-center" }, [
            _c(
              "svg",
              {
                staticClass: "svg-icon medium d-sm-none back hide",
                attrs: { viewBox: "0 0 64 64", id: "back" }
              },
              [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
            ),
            _vm._v(" "),
            _c("h6", { staticClass: "quiz-popup-title" }, [
              _vm._v("Fill the Gap")
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: { click: _vm.onClickClose }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "quiz-box" }, [
              _c(
                "div",
                { staticClass: "form" },
                [
                  _c("QuizIntroScreen", {
                    attrs: {
                      introClassName: _vm.introClassName,
                      introName: _vm.introName
                    },
                    on: { onNext: _vm.onClickIntroNext }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.quizDetails, function(detail, index) {
                    return _c(
                      "div",
                      {
                        key: "step" + index,
                        ref: "step" + index,
                        refInFor: true,
                        staticClass: "step next-slide",
                        attrs: { id: "step2" }
                      },
                      [
                        _c("div", { staticClass: "group" }, [
                          _c("div", { staticClass: "quiz-step-start mb-3" }, [
                            _c("div", { staticClass: "quiz-questions" }, [
                              _c(
                                "span",
                                { staticClass: "achieved-score big" },
                                [_vm._v(_vm._s(index + 1))]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "outof-score" }, [
                                _vm._v("/" + _vm._s(_vm.quizDetails.length))
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "cap-count-number" }, [
                              _c("span", { staticClass: "tag-item" }, [
                                _c("img", {
                                  staticClass: "cap-count-image",
                                  attrs: {
                                    src:
                                      "https://lscdn.blob.core.windows.net/elearn/cap.png"
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "earn-cap-count" }, [
                                  _vm._v(_vm._s(_vm.totalCaps) + " Points")
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              ref: "body",
                              refInFor: true,
                              staticClass: "quiz-qustion-box"
                            },
                            [
                              _c("div", {}, [
                                _c("div", { staticClass: "question-explain" }, [
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(detail.sentence)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("h6", {
                                    domProps: {
                                      innerHTML: _vm._s(detail.question)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.totalAnsCount(detail.answer) > 1
                                    ? _c("p", { staticClass: "text-primary" }, [
                                        _vm._v(
                                          "Multiple words match, choose the right options"
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("ul", { staticClass: "main-questions" }, [
                                  _c(
                                    "li",
                                    {
                                      attrs: { "data-option": "A" },
                                      on: { click: _vm.onSelectOption }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "check-correct-icon d-none",
                                          attrs: { "data-id": "answer-icon" }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "correct-icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "15",
                                                height: "15",
                                                fill: "#ffffff",
                                                viewBox: "0 0 256 256"
                                              }
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  width: "256",
                                                  height: "256",
                                                  fill: "none"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("polyline", {
                                                attrs: {
                                                  points:
                                                    "216 72.005 104 184 48 128.005",
                                                  fill: "none",
                                                  stroke: "#ffffff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "wrong-icon d-none",
                                          attrs: { "data-id": "answer-icon" }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "wrong-close-icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "15",
                                                height: "15",
                                                fill: "#fff",
                                                viewBox: "0 0 256 256"
                                              }
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  width: "256",
                                                  height: "256",
                                                  fill: "none"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "200",
                                                  y1: "56",
                                                  x2: "56",
                                                  y2: "200",
                                                  stroke: "#fff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "200",
                                                  y1: "200",
                                                  x2: "56",
                                                  y2: "56",
                                                  stroke: "#fff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(detail.optionA) +
                                          "\n                                            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      attrs: { "data-option": "B" },
                                      on: { click: _vm.onSelectOption }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "check-correct-icon d-none",
                                          attrs: { "data-id": "answer-icon" }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "correct-icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "15",
                                                height: "15",
                                                fill: "#ffffff",
                                                viewBox: "0 0 256 256"
                                              }
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  width: "256",
                                                  height: "256",
                                                  fill: "none"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("polyline", {
                                                attrs: {
                                                  points:
                                                    "216 72.005 104 184 48 128.005",
                                                  fill: "none",
                                                  stroke: "#ffffff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "wrong-icon d-none",
                                          attrs: { "data-id": "answer-icon" }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "wrong-close-icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "15",
                                                height: "15",
                                                fill: "#fff",
                                                viewBox: "0 0 256 256"
                                              }
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  width: "256",
                                                  height: "256",
                                                  fill: "none"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "200",
                                                  y1: "56",
                                                  x2: "56",
                                                  y2: "200",
                                                  stroke: "#fff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "200",
                                                  y1: "200",
                                                  x2: "56",
                                                  y2: "56",
                                                  stroke: "#fff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(detail.optionB) +
                                          "\n                                            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      attrs: { "data-option": "C" },
                                      on: { click: _vm.onSelectOption }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "check-correct-icon d-none",
                                          attrs: { "data-id": "answer-icon" }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "correct-icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "15",
                                                height: "15",
                                                fill: "#ffffff",
                                                viewBox: "0 0 256 256"
                                              }
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  width: "256",
                                                  height: "256",
                                                  fill: "none"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("polyline", {
                                                attrs: {
                                                  points:
                                                    "216 72.005 104 184 48 128.005",
                                                  fill: "none",
                                                  stroke: "#ffffff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "wrong-icon d-none",
                                          attrs: { "data-id": "answer-icon" }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "wrong-close-icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "15",
                                                height: "15",
                                                fill: "#fff",
                                                viewBox: "0 0 256 256"
                                              }
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  width: "256",
                                                  height: "256",
                                                  fill: "none"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "200",
                                                  y1: "56",
                                                  x2: "56",
                                                  y2: "200",
                                                  stroke: "#fff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "200",
                                                  y1: "200",
                                                  x2: "56",
                                                  y2: "56",
                                                  stroke: "#fff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(detail.optionC) +
                                          "\n                                            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      attrs: { "data-option": "D" },
                                      on: { click: _vm.onSelectOption }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "check-correct-icon d-none",
                                          attrs: { "data-id": "answer-icon" }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "correct-icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "15",
                                                height: "15",
                                                fill: "#ffffff",
                                                viewBox: "0 0 256 256"
                                              }
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  width: "256",
                                                  height: "256",
                                                  fill: "none"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("polyline", {
                                                attrs: {
                                                  points:
                                                    "216 72.005 104 184 48 128.005",
                                                  fill: "none",
                                                  stroke: "#ffffff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "wrong-icon d-none",
                                          attrs: { "data-id": "answer-icon" }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "wrong-close-icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "15",
                                                height: "15",
                                                fill: "#fff",
                                                viewBox: "0 0 256 256"
                                              }
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  width: "256",
                                                  height: "256",
                                                  fill: "none"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "200",
                                                  y1: "56",
                                                  x2: "56",
                                                  y2: "200",
                                                  stroke: "#fff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "200",
                                                  y1: "200",
                                                  x2: "56",
                                                  y2: "56",
                                                  stroke: "#fff",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "16"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(detail.optionD) +
                                          "\n                                            "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "view-answers mt-2" }, [
                                _vm.correctAnswers.length > 0
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "select-caps-winner",
                                        attrs: { "data-id": "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            Correct answer is " +
                                            _vm._s(
                                              _vm.correctAnswers.join(", ")
                                            ) +
                                            "\n                                        "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "view-explanation-button text-center mt-2 d-none",
                                  attrs: { "data-id": "next-btn" }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      ref: "nextPage",
                                      refInFor: true,
                                      staticClass:
                                        "btn btn-primary next-form-btn mb-2",
                                      attrs: {
                                        type: "button",
                                        id: "next",
                                        tabindex: "-1"
                                      },
                                      on: { click: _vm.onClickNext }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            Next\n                                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.showWellDoneMessage
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-center select-caps-winner"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            You have earned " +
                                              _vm._s(_vm.wellDonePoints) +
                                              " points\n                                        "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "congratulation",
                      staticClass: "step next-slide",
                      attrs: { id: "step7" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _c("div", { staticClass: "final-quiz-qustion-box" }, [
                          _c("div", { staticClass: "question-explain mt-4" }, [
                            _vm.congratsMsg
                              ? _c(
                                  "h3",
                                  { staticClass: "final-explanation-title" },
                                  [_vm._v(_vm._s(_vm.congratsMsg))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.congratsMsg
                              ? _c("p", { staticClass: "text-center mt-3" }, [
                                  _vm._v(_vm._s(_vm.userName))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "text-center",
                              domProps: {
                                innerHTML: _vm._s(_vm.getCongratsContent())
                              }
                            })
                          ]),
                          _vm._v(" "),
                          !_vm.hasReTriedWholeQuiz
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "final-exam-score s-score text-center mt-4"
                                },
                                [
                                  _c("img", {
                                    staticClass: "final-screen-cap",
                                    attrs: {
                                      src:
                                        "https://lscdn.blob.core.windows.net/elearn/cap.png"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "circle-center-score" },
                                    [
                                      _c("p", [_vm._v("Rewarded")]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "final-achieved-score big"
                                        },
                                        [_vm._v(_vm._s(_vm.totalCaps))]
                                      ),
                                      _vm._v(" "),
                                      _c("p", [_vm._v("Points")])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "try-again final-screen-footer" },
                            [
                              _vm.playNextCount > 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary next-form-btn",
                                      attrs: {
                                        type: "button",
                                        id: "next",
                                        tabindex: "-1"
                                      },
                                      on: { click: _vm.getNextQuiz }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            Play Next Game\n                                        "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ],
                2
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { ref: "scrollHookBottom" })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
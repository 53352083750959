var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal show" }, [
    _c("svg", { staticClass: "d-none" }, [
      _c("g", { attrs: { id: "fileUploadIcon" } }, [
        _c("path", {
          attrs: {
            d:
              "M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header align-items-center" }, [
          _c(
            "svg",
            {
              staticClass: "svg-icon medium d-sm-none",
              attrs: { viewBox: "0 0 64 64" },
              on: { click: _vm.onClose }
            },
            [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
          ),
          _vm._v(" "),
          _c("h5", [_vm._v("Do you love teaching English?")]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: { click: _vm.onClose }
            },
            [_vm._v("×")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _vm.currentStep == "stepOne"
            ? _c(
                "form",
                {
                  staticClass: "form-onboard",
                  attrs: { novalidate: "", action: "" }
                },
                [
                  _c("p", [
                    _vm._v(
                      "Check out the star attractions of becoming a tutor on Capshine!"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form" }, [
                    _c(
                      "div",
                      {
                        staticClass: "md-field mb-3",
                        class: { invalid: _vm.errorTexts.nameErrorText != "" }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.name,
                              expression: "name"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "fullName",
                            id: "fullName",
                            required: "",
                            placeholder: "Full name"
                          },
                          domProps: { value: _vm.name },
                          on: {
                            keyup: function($event) {
                              return _vm.onKeyUp($event, "name")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.name = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm._m(0),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-text" }, [
                          _vm._v(_vm._s(_vm.errorTexts.nameErrorText))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "phone-input md-field mb-3",
                        class: { invalid: _vm.errorTexts.mobileErrorText != "" }
                      },
                      [
                        _c("h6", { staticClass: "country" }, [
                          _vm._v(
                            "\n                                + 91\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phoneNumber,
                              expression: "phoneNumber"
                            }
                          ],
                          staticClass: "mobile",
                          attrs: {
                            type: "number",
                            name: "mobNum",
                            id: "mobNum",
                            required: "",
                            placeholder: "Mobile number"
                          },
                          domProps: { value: _vm.phoneNumber },
                          on: {
                            keydown: function($event) {
                              return _vm.onKeyDown($event, "mobileno")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.phoneNumber = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-text" }, [
                          _vm._v(_vm._s(_vm.errorTexts.mobileErrorText))
                        ]),
                        _vm._v(" "),
                        _vm._m(2)
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "md-field mb-3",
                        class: { invalid: _vm.errorTexts.emailErrorText != "" }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email"
                            }
                          ],
                          attrs: {
                            type: "email",
                            name: "emailId",
                            id: "emailId",
                            required: "",
                            placeholder: "Full name"
                          },
                          domProps: { value: _vm.email },
                          on: {
                            keyup: function($event) {
                              return _vm.onKeyUp($event, "email")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm._m(3),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-text" }, [
                          _vm._v(_vm._s(_vm.errorTexts.emailErrorText))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "md-field file-upload mb-3",
                        class: { invalid: _vm.errorTexts.fileErrorText != "" }
                      },
                      [
                        _c("input", {
                          ref: "fileInput",
                          attrs: {
                            type: "file",
                            name: "fileInput",
                            id: "fileInput",
                            required: ""
                          },
                          on: { change: _vm.readFile }
                        }),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass: "svg-icon medium",
                            attrs: { viewBox: "0 0 384 512" }
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#fileUploadIcon" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(4)
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "file-name",
                        class: { "d-block": _vm.currentFileName != "" }
                      },
                      [_vm._v(_vm._s(_vm.currentFileName))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "error-text pos-top",
                        class: { "d-block": _vm.errorTexts.fileErrorText != "" }
                      },
                      [_vm._v(_vm._s(_vm.errorTexts.fileErrorText))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "md-field mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.youtubeLink,
                            expression: "youtubeLink"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "ytLink",
                          id: "ytLink",
                          required: "",
                          placeholder: "Youtube video link of your teaching:"
                        },
                        domProps: { value: _vm.youtubeLink },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.youtubeLink = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          attrs: {
                            for: "ytLink",
                            placeholder: "Youtube video link of your teaching:"
                          }
                        },
                        [
                          _vm._v(
                            "\n                                Youtube video link of your teaching\n                            "
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStep == "stepTwo"
            ? _c(
                "div",
                {
                  staticClass: "alert alert-success",
                  attrs: { role: "alert" }
                },
                [
                  _vm._v("\n                    Thank you "),
                  _c("b", [_vm._v(_vm._s(this.name))]),
                  _vm._v(
                    ", you will hear from us in in the next 48 hours\n                "
                  )
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.currentStep == "stepOne"
          ? _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-block",
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("\n                    Submit\n                ")]
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { attrs: { alt: "name", for: "fullName", placeholder: "Full name" } },
      [
        _vm._v("\n                                Full name "),
        _c("sup", [_vm._v("*")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { alt: "mobNum", for: "mobNum" } }, [
      _vm._v(" Mobile number "),
      _c("sup", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dropdown-wrapper" }, [
      _c("div", { staticClass: "search-block" }, [
        _c("input", {
          attrs: { type: "text", placeholder: "Search for country code" }
        })
      ]),
      _vm._v(" "),
      _c("i", { staticClass: "far fa-search" }),
      _vm._v(" "),
      _c("ul", { staticClass: "options" }, [
        _c("li", [_vm._v("Australia (+61)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Austria (+43)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Albania (+335)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Algeria (+213)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Angola (+224)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Argentina (+994)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Austria (+43)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Albania (+335)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Algeria (+213)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Angola (+224)")]),
        _vm._v(" "),
        _c("li", [_vm._v("Argentina (+994)")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { attrs: { for: "emailId", placeholder: "Full name" } },
      [
        _vm._v("\n                                Email ID"),
        _c("sup", [_vm._v("*")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("Upload CV "), _c("sup", [_vm._v("*")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./VideoPlayerPopup.vue?vue&type=template&id=1c10da5b&"
import script from "./VideoPlayerPopup.vue?vue&type=script&lang=js&"
export * from "./VideoPlayerPopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\eLearn.Student\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c10da5b')) {
      api.createRecord('1c10da5b', component.options)
    } else {
      api.reload('1c10da5b', component.options)
    }
    module.hot.accept("./VideoPlayerPopup.vue?vue&type=template&id=1c10da5b&", function () {
      api.rerender('1c10da5b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "VueComponents/ClubVideo/VideoPlayerPopup.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "close cabso mr-2 mt-2",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      },
      [_vm._v("×")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row mx-0" },
      [
        _c("DisplayUserInfor", {
          attrs: { instructorDetails: _vm.instructorDetails }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col col-lg-6" }, [
          _c("div", { staticClass: "form course-sign" }, [
            _c("h4", { staticClass: "pb-3" }, [
              _vm._v("Verify your Phone number")
            ]),
            _vm._v(" "),
            _c("h5", [_vm._v("Enter the OTP")]),
            _vm._v(" "),
            _c("h6", { staticClass: "text-muted" }, [
              _vm._v(
                "\n                    We sent an OTP to your phone number"
              ),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(_vm.courseSignUpClientData.CountryCode) +
                  "-" +
                  _vm._s(_vm.courseSignUpClientData.MobileNo) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "link caption",
                on: {
                  click: function($event) {
                    return _vm.editnumber()
                  }
                }
              },
              [_vm._v("Change Number")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "otp-form" }, [
              _c("div", { staticClass: "otp-group otp" }, [
                _c("div", { staticClass: "single-input" }, [
                  _c("div", { staticClass: "inputOtpCenter" }, [
                    _c("div", { staticClass: "inputOtp-group" }, [
                      _c("span", { staticClass: "box-1" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "box-2" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "box-3" }),
                      _c("span", { staticClass: "box-4" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "lastbar" }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.verificationCode,
                            expression: "verificationCode"
                          }
                        ],
                        ref: "code1",
                        attrs: { type: "number", maxlength: "4" },
                        domProps: { value: _vm.verificationCode },
                        on: {
                          keydown: function($event) {
                            return _vm.onKeyDown($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.verificationCode = $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { class: _vm.invalidOtp ? "error-text show" : "error-text" },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.verificationCodeErrorText) +
                        "\n                        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "caption" }, [
                _vm._v(
                  "\n                        Resending OTP in 00:" +
                    _vm._s(_vm.countDown) +
                    "\n                        "
                ),
                _vm.resetotp
                  ? _c(
                      "a",
                      {
                        staticClass: "link",
                        on: {
                          click: function($event) {
                            return _vm.resendotp()
                          }
                        }
                      },
                      [_vm._v("| Resend now")]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.courseSignUpClientData.CountryCode == 91
              ? _c("div", { staticClass: "my-4" }, [_vm._m(0)])
              : _vm._e(),
            _vm._v(" "),
            _vm.courseSignUpClientData.CountryCode == 91
              ? _c("div", { staticClass: "shadow-sm rounded p-3" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline-primary",
                      on: {
                        click: function($event) {
                          return _vm.missedCallClick()
                        }
                      }
                    },
                    [_vm._v("Click here after call")]
                  )
                ])
              : _vm._e()
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center separator" }, [
      _c("h6", [_c("span", [_vm._v("  OR  ")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "mb-2" }, [
      _vm._v(
        "\n                        Give a missed call from your phone to "
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "link", attrs: { href: "tel:1800-120-457-896" } },
        [_vm._v("  1800-120-457-896 ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
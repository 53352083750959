<template>
    <section class="">
        <!-- Modal start -->
        <!-- remove .show to hide modal -->
        <div id="EditPreferenceVue" class="modal">
            <!-- add .modal-sm/modal-lg/modal-xl after .modal-dialog for width change -->
            <div class="modal-dialog modal-dialog-scrollable">
                <!-- Modal content -->
                <div class="modal-content">
                    <!-- progress bar -->
                    <div class="progress d-none mt-0">
                        <div class="progress-bar"
                             role="progressbar"
                             style="width: 50%"
                             aria-valuenow="50"
                             aria-valuemin="0"
                             aria-valuemax="100"></div>
                    </div>
                    <!-- Header mobile Start -->
                    <div class="modal-header align-items-center">
                        <svg v-on:click="ClosePreference" viewBox="0 0 64 64" class="svg-icon medium d-sm-none">
                            <use xlink:href="#chevronBack"></use>
                        </svg>
                        <h5>Edit your preferences</h5>
                        <button v-on:click="ClosePreference" type="button" class="close">×</button>
                    </div>
                    <!--<div class="bg-header-wizard">
                        <div class="container position-relative">
                            <div class="header-between">
                                <div class="d-flex flex-column ml-1">
                                    <p>Edit your preferences</p>
                                </div>
                                <a class="mr-2" href="#"><i class="far fa-times"></i></a>
                            </div>
                        </div>
                    </div>-->
                    <!-- header mobile end -->
                    <!-- progress bar end -->
                    <div class="modal-body">
                        <!-- Content goes here -->
                        <!--<div class="d-none d-lg-block">
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="body2">Edit your preferences</p>
                                <a href="#"><i class="far fa-times pt-3" v-on:click="ClosePreference"></i></a>
                            </div>
                        </div>-->
                        <div class="wiz-edit-wrapper" v-for="QuesAndAns in MappedQuesAndAns">
                            <h6 class="pb-2">{{QuesAndAns[0].toString()}}</h6>
                            <button v-for="AnsEach in QuesAndAns[1]" class="ml-2">
                                <span>{{AnsEach}}</span> <!--<span class="ml-2">&times;</span>-->
                            </button>
                        </div>

                    </div>
                    <div class="modal-footer"> <button class="button btn btn-primary" v-on:click="onEditClick">Edit</button> </div>

                    <!--  <div class="skip-btn-container">
                    add active to show orange color
                               <span class="btn next-btn active sub-title" v-on:click="onEditClick">Edit</span>
                           </div> -->
                </div>
            </div>
        </div>
        <!-- Modal end -->

        <LCFSecondLevel v-if="CurrentStep > 0"
                        :CurrentQuestion="CurrentQuestion"
                        :C1Name="C1Name"
                        :PageType="PageType"
                        :showQuesPopup="showQuesPopup"
                        :SetSelectedDefault="SetSelectedDefault"
                        :onLCFAnsSelect="onLCFAnsSelect"
                        :AnswerSubmit="AnswerSubmit"
                        :onLCFBackClick="onLCFBackClick"
                        :CloseLCFPopup="CloseLCFPopup">
        </LCFSecondLevel>

        <!-- question secction ends -->
    </section>
</template>

<script>
    import LCFSecondLevel from "./LCFSecondLevel";
    import Loader from "../../wwwroot/js/Loader.js";
    import SulGA from "../../wwwroot/js/Ga.js";

    export default {
        name: "LCFEditPreference",
        components: {
            LCFSecondLevel,
        },
        data() {
            return {
                CurrentStep: 0,
                C1ID: { type: String },
                C2ID: { type: String },
                C1Name: { type: String },
                Questions: [],
                NextQuestionID: 0,
                CookieeSelAnswerID: [],
                CurrentQuestion: [],
                QuesAndAnsArray: [],
                selectedQues: [],
                selectedAns: [],
                QuesAndAnsObj: [],
                showQuesPopup: false,
                QuesAnsMapping: [],
                SelectedAnswerID: [],
                ShowRecPage: false,
                PageType: "EditPrefQues",
                SelectedQuesID: [],
            }
        },
        beforeMount: function () {
            var cookie = document.cookie.split(';');
            var C1IDCookie = cookie.find(c1ID => c1ID.includes("C1ID"));
            if (C1IDCookie != null) {
                var c1IDArr = C1IDCookie.split('=');
                if (c1IDArr != null && c1IDArr.length > 1) {
                    this.C1ID = c1IDArr[1];
                }
            }
            var C2IDCookie = cookie.find(c2ID => c2ID.includes("C2ID"));
            if (C2IDCookie != null) {
                var c2IDArr = C2IDCookie.split('=');
                if (c1IDArr != null && c2IDArr.length > 1) {
                    this.C2ID = c2IDArr[1];
                }
            }
            var QuesAndAnsCookie = cookie.find(quesAndAns => quesAndAns.includes("QuesAnsMapping"));
            if (QuesAndAnsCookie != null) {
                var QuesAndAns = QuesAndAnsCookie.split('=');
                if (QuesAndAns != null && QuesAndAns.length > 0) {
                    this.QuesAndAnsArray = QuesAndAns[1].split('-');
                }
            }
            var getLCFData = "//sulcdn.azureedge.net/content/caps/lcf/" + this.C1ID + ".json";
            Loader.showAboveModal();
            fetch(getLCFData)
                .then(response => response.json())
                .then(responseJson => {
                    if (this.C1ID == 11) {
                        responseJson.questions.forEach(ques => {
                            if (this.C2ID > 0) {
                                if (ques.questionId == 21) {
                                    ques.isFirstQuestion = false;
                                }
                                else if (ques.questionId == 23 && this.C2ID == 65) {
                                    ques.isFirstQuestion = true;
                                }
                                else if (ques.questionId == 22 && this.C2ID != 65) {
                                    ques.isFirstQuestion = true;
                                }
                            }
                        });
                    }
                    this.Questions = responseJson.questions;
                    this.C1Name = responseJson.c1Name;
                    Loader.hideAboveModal();
                });
        },
        computed: {
            MappedQuesAndAns: function () {
                this.QuesAndAnsObj.length = 0;
                this.QuesAndAnsArray.forEach((value) => {
                    var QuesAnsArray = value.split(':');
                    if (QuesAnsArray != null && QuesAnsArray.length > 0 && this.Questions != null && this.Questions.length > 0) {
                        this.Questions.forEach((Question) => {
                            if (Question.questionId == QuesAnsArray[0]) {
                                this.selectedQues.push(Question.question);
                            }
                            if (QuesAnsArray.length > 1) {
                                var selAnswers = QuesAnsArray[1].split(',');
                                Question.answers.forEach((Answer) => {
                                    selAnswers.forEach((selAns) => {
                                        if (Answer.answerId == selAns) {
                                            this.selectedAns.push(Answer.answer);
                                        }
                                    })
                                })
                            }
                        })
                    }
                    var mappedQuesAns = new Array(this.selectedQues, this.selectedAns);
                    this.QuesAndAnsObj.push(mappedQuesAns);
                    this.selectedQues = [];
                    this.selectedAns = [];
                })
                return this.QuesAndAnsObj;
            }
        },
        mounted: function () {
            document.querySelector('#EditPreferenceVue').classList.add('show');
        },
        methods: {
            onEditClick: function () {
                if (this.QuesAndAnsArray.length > 0) {
                    var selQuesAnsArr = this.QuesAndAnsArray[0].split(':');
                    if (selQuesAnsArr.length > 0) {
                        this.NextQuestionID = selQuesAnsArr[0];
                        if (selQuesAnsArr.length > 1) {
                            this.CookieeSelAnswerID = selQuesAnsArr[1].split(',');
                        }
                    }
                }
                this.CurrentQuestion = this.Questions.filter(Question => Question.questionId == this.NextQuestionID);
                this.showQuesPopup = true;
                this.CurrentStep = 1;
                this.QuesAnsMapping.length = 0;
                this.SelectedAnswerID.length = 0;

            },
            ClosePreference: function () {
                if (document.querySelector('#EditPreferenceVue').className.includes('show')) {
                    document.querySelector('#EditPreferenceVue').classList.remove('show');
                }
                var PrefBaseDiv = document.querySelector("#LCFEditPreferenceBase");
                PrefBaseDiv.innerHTML = "";
                var div = document.createElement("div");
                div.id = "LCFEditPreference";
                PrefBaseDiv.appendChild(div);
            },
            onLCFAnsSelect: function (isMultiSelect, answer) {
                var inputs = document.querySelectorAll('#LCFTwo input');
                inputs.forEach((input, index) => {
                    if (input.checked) {
                        input.parentElement.parentElement.className = "selected";
                    }
                    else {
                        input.parentElement.parentElement.className = "";
                    }
                });

                var checked = document.querySelectorAll('#LCFTwo input:checked');
                if (checked.length > 0) {
                    document.getElementById('NextButton2').disabled = false;
                }
                else {
                    document.getElementById('NextButton2').disabled = true;
                }
                this.NextQuestionID = answer.nextQuestionId;
                //if (this.NextQuestionID == 0)
                //    document.getElementById('NextButton2').innerText = "Submit";
            },
            AnswerSubmit: function (isMultiSelect) {
                this.SelectedAnswerID.length = 0;
                var inputs = document.querySelectorAll('#LCFTwo input:checked');
                inputs.forEach((input, index) => {
                    if (input.getAttribute('id') != null) {
                        var id = input.getAttribute('id').split('ans');
                        this.SelectedAnswerID.push(id[1]);
                    }
                });
                var checked = document.querySelectorAll('#LCFTwo input:checked');
                if (checked.length > 0) {
                    var quesFound = false;
                    this.QuesAnsMapping.forEach((QuesAns, index) => {
                        if (QuesAns.toString().includes(this.CurrentQuestion[0].questionId + ":") && this.SelectedAnswerID.length > 0) {
                            var QuesAnsArr = QuesAns.split(':');
                            if (QuesAnsArr.length > 1) {
                                var ansIDArr = QuesAnsArr[1].split(',');
                                if (JSON.stringify(ansIDArr) != JSON.stringify(this.SelectedAnswerID)) {
                                    this.QuesAnsMapping[index] = this.CurrentQuestion[0].questionId + ':' + this.SelectedAnswerID;
                                    this.QuesAnsMapping.length = index + 1;
                                    /*this.SelectedQuesID.length = index + 1;*/
                                }
                            }
                            quesFound = true;
                        }
                    })
                    if (!quesFound && this.SelectedAnswerID.length > 0) {
                        this.QuesAnsMapping.push(this.CurrentQuestion[0].questionId + ':' + this.SelectedAnswerID);
                    }
                    if (this.NextQuestionID == 0) {
                        this.ShowRecPage = true;
                        var cookieeString = "";
                        this.QuesAnsMapping.forEach((input) => {
                            if (cookieeString == "") {
                                cookieeString = "QuesAnsMapping=" + input;
                            }
                            else {
                                cookieeString = cookieeString + "-" + input;
                            }
                        })
                        document.cookie = "QuesAnsMapping" + '=; expires=Sun, 17 Jan 1970 00:00:01 GMT;';
                        document.cookie = cookieeString;
                        //this.ClosePreference();
                        SulGA.pushEvent("lcf-q" + this.CurrentStep + "-next", 'LCF ' + this.C1ID + ' q' + this.CurrentStep, "lcf-q" + this.CurrentStep + "-next-click");
                        Loader.showAboveModal();
                        window.location.reload();
                    }
                    else {
                        this.CurrentQuestion = this.Questions.filter(Question => Question.questionId == this.NextQuestionID);
                        this.QuesAnsMapping.forEach((quesAns) => {
                            if (quesAns.toString().includes(this.CurrentQuestion[0].questionId + ":")) {
                                var quesAnsArr = quesAns.split(':');
                                if (quesAnsArr.length > 1) {
                                    var ansIDArr = quesAnsArr[1].split(',');
                                    ansIDArr.forEach((ansID) => {
                                        this.CookieeSelAnswerID.push(ansID);
                                    })
                                }
                            }
                        })
                        if (this.QuesAndAnsArray.length > 0 && this.CookieeSelAnswerID.length == 0) {
                            this.QuesAndAnsArray.forEach((value) => {
                                if (value.includes(this.CurrentQuestion[0].questionId + ":")) {
                                    var QuesAnsArray = value.split(':');
                                    if (QuesAnsArray.length > 1) {
                                        var ansIDArr = QuesAnsArray[1].split(',');
                                        ansIDArr.forEach((ansID) => {
                                            this.CookieeSelAnswerID.push(ansID);
                                        })
                                    }
                                }
                            })
                        }
                        SulGA.pushEvent("lcf-q" + this.CurrentStep + "-next", 'LCF ' + this.C1ID + ' q' + this.CurrentStep, "lcf-q" + this.CurrentStep + "-next-click");
                        this.showQuesPopup = true;
                        this.CurrentStep = this.CurrentStep + 1;
                    }
                }
            },
            onLCFBackClick: function () {
                this.SelectedAnswerID.length = 0;
                var inputs = document.querySelectorAll('#LCFTwo input:checked');
                inputs.forEach((input, index) => {
                    if (input.getAttribute('id') != null) {
                        var id = input.getAttribute('id').split('ans');
                        this.SelectedAnswerID.push(id[1]);
                    }
                });
                var quesFound = false;
                this.QuesAnsMapping.forEach((QuesAns, index) => {
                    if (QuesAns.toString().includes(this.CurrentQuestion[0].questionId + ":")) {
                        this.QuesAnsMapping[index] = this.CurrentQuestion[0].questionId + ':' + this.SelectedAnswerID;
                        quesFound = true;
                    }
                })
                if (!quesFound) {
                    this.QuesAnsMapping.push(this.CurrentQuestion[0].questionId + ':' + this.SelectedAnswerID);
                }
                this.CookieeSelAnswerID.length = 0;
                this.QuesAnsMapping.forEach((quesAns, index) => {
                    if (quesAns.toString().includes(this.CurrentQuestion[0].questionId + ":")) {
                        if (this.QuesAnsMapping[index - 1] != null) {
                            var PrevQuesArr = this.QuesAnsMapping[index - 1].split(':');
                            this.CurrentQuestion = this.Questions.filter(Question => Question.questionId == PrevQuesArr[0]);
                            if (PrevQuesArr.length > 1) {
                                var ansIDArr = PrevQuesArr[1].split(',');
                                ansIDArr.forEach((ansID) => {
                                    this.CookieeSelAnswerID.push(ansID);
                                })
                            }
                        }
                    }
                })

                if (this.CurrentStep > 1)
                    this.CurrentStep = this.CurrentStep - 1;
                else
                    this.CurrentStep = 1;
            },
            CloseLCFPopup: function () {
                this.showQuesPopup = false;
                var LCF2inputs = document.querySelectorAll('#LCFTwo input');
                LCF2inputs.forEach((input, index) => {
                    input.checked = false;
                    input.parentElement.parentElement.classList.remove('selected');
                })
                document.body.classList.remove('overflow-hidden');
                SulGA.pushEvent("lcf-close", "LCF Close", "lcf-close-click");
            },
            SetSelectedDefault: function () {
                var LCFTwo = document.querySelectorAll('#LCFTwo input');
                LCFTwo.forEach((input, index) => {
                    input.checked = false;
                    input.parentElement.parentElement.className = "";
                });
                this.CookieeSelAnswerID.forEach((selAns) => {
                    if (document.querySelector('#LCFTwo' + " #ans" + selAns)) {
                        document.querySelector('#LCFTwo' + " #ans" + selAns).checked = true;
                        document.querySelector('#LCFTwo' + " #ans" + selAns).parentElement.parentElement.className = "selected";
                    }
                    if (this.CurrentQuestion != null && this.CurrentQuestion.length > 0) {
                        this.CurrentQuestion[0].answers.forEach((answer) => {
                            if (answer.answerId == selAns) {
                                this.NextQuestionID = answer.nextQuestionId;
                            }
                        })
                    }
                });
                var checked = document.querySelectorAll('#LCFTwo input:checked');
                if (checked.length > 0 && document.getElementById('NextButton2') != null) {
                    document.getElementById('NextButton2').disabled = false;
                }
                else {
                    document.getElementById('NextButton2').disabled = true;
                }
                this.CookieeSelAnswerID.length = 0;
                if (document.getElementById('BackButton') != null) {
                    if (!this.CurrentQuestion[0].isFirstQuestion && document.getElementById('BackButton').className.includes('d-none')) {
                        document.getElementById('BackButton').classList.remove('d-none');
                    }
                    if (this.CurrentQuestion[0].isFirstQuestion && !document.getElementById('BackButton').className.includes('d-none')) {
                        document.getElementById('BackButton').classList.add('d-none');
                    }
                }
                //if (this.SelectedQuesID[0].toString() == this.CurrentQuestion[0].questionId) {
                //    document.getElementById('BackButton').disabled = true;
                //}
                //else {
                //    document.getElementById('BackButton').disabled = false;
                //}
            },
        },
    };

</script>
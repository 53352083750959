var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", [_vm._v("Enter the OTP")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("p", { staticClass: "text-muted col-sm-8 col-12 caption" }, [
        _vm._v("\n                We sent an OTP to your phone number "),
        _c("br"),
        _vm._v(
          " " +
            _vm._s(_vm.userDetails.CountryCode) +
            " " +
            _vm._s(_vm.userDetails.MobileNumber) +
            "\n            "
        )
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "link caption col-sm-4 col-12 text-right",
          on: {
            click: function($event) {
              return _vm.changemobileno()
            }
          }
        },
        [_vm._v("Edit this number")]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "otp-form" }, [
      _c("div", { staticClass: "otp-group otp" }, [
        _c("div", { staticClass: "single-input" }, [
          _c("div", { staticClass: "inputOtpCenter" }, [
            _c("div", { staticClass: "inputOtp-group phone-input" }, [
              _c("span", { staticClass: "box-1" }),
              _vm._v(" "),
              _c("span", { staticClass: "box-2" }),
              _vm._v(" "),
              _c("span", { staticClass: "box-3" }),
              _vm._v(" "),
              _c("span", { staticClass: "box-4" }),
              _vm._v(" "),
              _c("span", { staticClass: "lastbar" }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.verificationCode,
                    expression: "verificationCode"
                  }
                ],
                ref: "code1",
                attrs: { type: "number", maxlength: "4" },
                domProps: { value: _vm.verificationCode },
                on: {
                  keydown: function($event) {
                    return _vm.onKeyDown($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.verificationCode = $event.target.value
                  }
                }
              })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          class:
            _vm.invalidOtp && !_vm.userDetails.IsMissedCallVerfication
              ? "error-text show"
              : "error-text"
        },
        [_vm._v(" Please enter a valid OTP ")]
      ),
      _vm._v(" "),
      !_vm.resetotp
        ? _c("label", { staticClass: "caption" }, [
            _vm._v(
              "\n                Resend OTP in " +
                _vm._s(_vm.countDown) +
                " Seconds\n            "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.resetotp
        ? _c(
            "a",
            {
              staticClass: "link",
              on: {
                click: function($event) {
                  return _vm.resendotp()
                }
              }
            },
            [_vm._v("Resend now")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
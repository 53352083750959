﻿import scrollToTop from "../common/ScrollToTop.js";
import CourseSignup from "../../../VueComponents/CourseSignup/CourseSignup.vue";
import Vue from "vue"
var CourseListing = {
    IsMobile: false,
    init: function () {
        //if (document.getElementById("TotalCourses") && document.getElementById("hdnTotalCourses"))
        //    document.getElementById("TotalCourses").innerText = document.getElementById("hdnTotalCourses").value;
        //if (document.getElementById("TotalLanguages") && document.getElementById("hdnTotalLanguages"))
        //    document.getElementById("TotalLanguages").innerText = document.getElementById("hdnTotalLanguages").value;
        //if (document.getElementById("TotalInstructors") && document.getElementById("hdnTotalInstructors"))
        //    document.getElementById("TotalInstructors").innerText = document.getElementById("hdnTotalInstructors").value;

        if (document.getElementById('filterSticky')) {
            if (document.getElementById('filterSticky') != null && document.querySelectorAll('#filterSticky').length > 0) {
                var filterStickyDiv = document.getElementById('filterSticky');
                window.addEventListener('scroll', function () {
                    var stikcyDivTop = filterStickyDiv.getBoundingClientRect();
                    if (stikcyDivTop.top >= window.scrollY) {
                        filterStickyDiv.classList.remove('sticky-position-filter')
                    } else {
                        filterStickyDiv.classList.add('sticky-position-filter')
                    }
                })
            }
        }

        //if (document.querySelector('[data-mobilefilter]')) {
        //    document.querySelector('[data-mobilefilter]').addEventListener('click', function () {
        //        if (document.getElementById('FilterMobile') != null) {
        //            setTimeout(function () {
        //                document.querySelector('#FilterMobile #popup').classList.add('open-filter');
        //            }, 500);
        //            document.getElementsByTagName("body")[0].classList.add('slkno-scroll');
        //        }
        //    });
        //}

        this.IsMobile = document.getElementById('IsMobile').getAttribute('data-IsMobile');

        if (document.querySelector('[data-filtergroupby]')) {
            var selecAll = document.querySelectorAll('[data-filtergroupby]');
            selecAll.forEach((clickitem) => {
                clickitem.addEventListener('click', this.GroupByCLick.bind(this));
            });
        }

        if (document.querySelector('[data-filterclear]')) {
            var selecAll = document.querySelectorAll('[data-filterclear]');
            selecAll.forEach((clickitem) => {
                clickitem.addEventListener('click', this.clearClicked.bind(this));
            });
        }

        if (document.querySelector('[data-closeFilterIcon]')) {
            document.querySelector('[data-closeFilterIcon]').addEventListener('click', this.closeFilter.bind('icon'));
        }

        let demoBtns = document.querySelectorAll("[data-bookdemobtn][data-hasnoclickevent='true']");
        if (demoBtns) {
            demoBtns.forEach((demoBtn) => {
                if (!demoBtn.classList.contains('disabled')) {
                    demoBtn.setAttribute("data-hasnoclickevent", "");
                    demoBtn.addEventListener('click', (event) => {
                        event.stopPropagation();
                        var courseId = event.currentTarget.getAttribute("data-courseid");
                        var instantDemoEnabled = event.currentTarget.getAttribute("data-instantDemoEnabled");
                        if (document.getElementById('demo-signup') != null) {
                            var body = document.body;
                            body.classList.add("slkno-scroll");

                            new Vue({
                                el: "#demo-signup",
                                components: {
                                    CourseSignup
                                },
                                render: function (h) {
                                    return h('div', [h(CourseSignup, {
                                        props: {
                                            courseId: courseId,
                                            initialPopup: 'open',
                                            showpopup: true,
                                            instantDemoEnabled: instantDemoEnabled == true ? 1 : instantDemoEnabled == "true" ? 1 : 0
                                        }
                                    })]);
                                }
                            });
                        }
                    })
                }
            })
        }

        this.mounted();
    },
    mounted: function () {
        if (this.IsMobile == "True") {
            document.querySelectorAll("#webFooter")[0].classList.remove('d-flex');
            document.querySelectorAll("#webFooter")[0].style.display = 'none';
            var CourseCards = document.querySelectorAll('#filterCard .col-sm-6');
            document.querySelector('#ApplyBtn').innerText = "See " + CourseCards.length + " courses";
            document.querySelector('#ApplyBtn').addEventListener('click', this.closeFilter);
        }
        else {
            document.querySelectorAll("#mobileFooter")[0].style.display = "none";
            //document.querySelectorAll("#closeIcon")[0].style.display = "none";
        }
    },
    RangeID: function (range) {
        if (range != null && !range.includes('<')) {
            return "range" + range.split(" ").join("");
        }
        else if (range != null && range.includes('<')) {
            return "range" + range.replace('<', '-').split(" ").join("");
        }
    },
    RemoveSpace: function (data) {
        if (data != null)
            return data.split(" ").join("");
    },
    addClass: function () {
        var checkBox = document.querySelectorAll('#Filter .checkboxselect input[type="checkbox"]');
        checkBox.forEach((value) => {
            if (value.checked && !value.parentElement.className.includes('active'))
                value.parentElement.classList.add('active');
            else if (!value.checked && value.parentElement.className.includes('active'))
                value.parentElement.classList.remove('active');
        })
    },
    closeFilter: function (trigger) {
        if (document.getElementById('FilterMobile').className == "d-lg-none") {
            document.getElementById('FilterMobile').className = "d-none";
        }
        setTimeout(function () {
            document.querySelector('#FilterMobile #popup').classList.remove('open-filter');
        }, 500);
        if (trigger == 'icon') {
            this.clearClicked();
        }
        document.getElementsByTagName("body")[0].classList.remove('slkno-scroll');
    },
    GroupByCLick: function (event) {
        var clickevtdata = event.currentTarget.getAttribute('data-filtergroupby');
        if (clickevtdata != null && clickevtdata != "") {
            var groupByDiv = document.getElementById('cls' + this.RemoveSpace(clickevtdata));
            var checkBox = document.getElementById(this.RemoveSpace(clickevtdata));
            if (checkBox.checked && !groupByDiv.className.includes('active')) {
                groupByDiv.classList.add('active');
            }
            else if (!checkBox.checked && groupByDiv.className.includes('active')) {
                groupByDiv.classList.remove('active');
            }
        }
    },
    clearClicked: function () {
        var selectedFilters = document.querySelectorAll('#Filter .checkboxselect input[type="checkbox"]:checked');
        selectedFilters.forEach((selectedFilter, index) => {
            selectedFilter.checked = false;
        });
        this.addClass();
        var selvalues = document.querySelectorAll('.filter-pill-wrapper.active');
        selvalues.forEach((selValue) => {
            selValue.classList.remove('active');
        })
        var allCourseCards = document.querySelectorAll('#filterCard .col-sm-6');
        allCourseCards.forEach((card, index) => {
            card.style.display = "block";
        });
        if (this.IsMobile == "True") {
            var CourseCards = document.querySelectorAll('#filterCard .col-sm-6');
            document.querySelector('#ApplyBtn').innerText = "See " + CourseCards.length + " courses";
            document.querySelector('#ApplyBtn').addEventListener('click', this.closeFilter);
            document.querySelector('#NoRecords').innerText = "";
            if (!document.querySelector('#ApplyBtn').className.includes('active')) {
                document.querySelector('#ApplyBtn').classList.add('active');
            }
        }
        if (!document.getElementById("NoResults").className.includes('d-none')) {
            document.getElementById("NoResults").classList.add('d-none');
        }
    },
    filterClicked: function () {
        this.addClass();
        var checked = document.querySelectorAll('#Filter .checkboxselect input[type="checkbox"]:checked');
        if (checked.length > 0) {
            var allCourseCards = document.querySelectorAll('#filterCard .col-sm-6');
            allCourseCards.forEach((card, index) => {
                card.style.display = "none";
            });
            var filters = ["GroupByLable", "languageFtr", "ratings", "range"];
            var isFiltered = false;
            for (var i = 0; i < filters.length; i++) {
                var currentFilter = "";
                var checkedele = document.querySelectorAll('#Filter ' + '#' + filters[i] + ' .checkboxselect input[type="checkbox"]:checked');
                var filteredData = document.querySelectorAll('#filterCard .col-sm-6[style*="block"]');
                if (checkedele.length > 0 && filteredData.length > 0) {
                    filteredData.forEach((filteredcard, index) => {
                        filteredcard.style.display = "none";
                    });
                }
                for (var j = 0; j < checkedele.length; j++) {
                    var name = checkedele[j].getAttribute('id').trim().toLowerCase();
                    if (filteredData.length > 0 && currentFilter != filters[i]) {
                        for (var k = 0; k < filteredData.length; k++) {
                            isFiltered = true;
                            var dataValue = this.RemoveSpace(filteredData[k].firstElementChild.getAttribute('data-' + filters[i]));
                            if (filters[i] == 'languageFtr') {
                                var datavalues = dataValue.split(',');
                                if (datavalues.length > 1) {
                                    datavalues.forEach((data, index) => {
                                        if ((checkedele[j].getAttribute('id').trim().includes(data))) {
                                            filteredData[k].style.display = "block";
                                        }
                                    })
                                }
                                else if ((checkedele[j].getAttribute('id').trim().includes(dataValue))) {
                                    filteredData[k].style.display = "block";
                                }
                            }
                            else {
                                if ((checkedele[j].getAttribute('id').trim().includes(dataValue))) {
                                    filteredData[k].style.display = "block";
                                }
                            }
                        }
                    }
                    else if (!isFiltered) {
                        currentFilter = filters[i];
                        var CourseCardData = document.querySelectorAll('#filterCard .col-sm-6');
                        for (var k = 0; k < CourseCardData.length; k++) {
                            if (CourseCardData[k].firstElementChild.className.includes(name)) {
                                CourseCardData[k].style.display = "block";
                            }
                        }
                    }
                }
            }
        }
        else {
            var allCourseCards = document.querySelectorAll('#filterCard .col-sm-6');
            allCourseCards.forEach((card, index) => {
                card.style.display = "block";
            });
        }
        var filteredCards = document.querySelectorAll('#filterCard .col-sm-6[style*="block"]');
        var noRecDiv = document.getElementById('NoRecordsDiv');
        if (filteredCards.length == 0) {
            noRecDiv.classList.remove('d-none');
            document.querySelector('#NoRecords').innerText = "There are no courses available for the set filters";
            document.querySelector('#ApplyBtn').innerText = "See " + filteredCards.length + " courses";
            document.querySelector('#ApplyBtn').classList.remove('active');
            document.querySelector('#ApplyBtn').removeEventListener('click', this.closeFilter);
            if (document.getElementById("NoResults").className.includes('d-none')) {
                document.getElementById("NoResults").classList.remove('d-none');
            }
            scrollToTop.scrollTo(noRecDiv.getBoundingClientRect(), 500, 300);
        }
        else if (filteredCards.length > 0) {
            noRecDiv.classList.add('d-none');
            document.querySelector('#ApplyBtn').innerText = "See " + filteredCards.length + " courses";
            document.querySelector('#NoRecords').innerText = "";
            if (!document.querySelector('#ApplyBtn').className.includes('active')) {
                document.querySelector('#ApplyBtn').classList.add('active');
            }
            document.querySelector('#ApplyBtn').addEventListener('click', this.closeFilter);
            if (!document.getElementById("NoResults").className.includes('d-none')) {
                document.getElementById("NoResults").classList.add('d-none');
            }
            scrollToTop.scrollTo(filteredCards[0].getBoundingClientRect(), 500, 300);
        }
    },
}
if (document.getElementById('hdnPageType') != null && document.getElementById('hdnPageType').value == "Listing") {
    CourseListing.init();
    var filteredCards = document.querySelectorAll('#filterCard .col-sm-6');
    if (filteredCards.length < 10) {
        document.getElementById("showmorelistbtn").style.display = "none";
    }
    if (document.getElementById("listingPageSchema")) {
        if (document.getElementById("listingPageSchema").innerText) {
            var schemaJson = document.getElementById('listingPageSchema').innerText;
            if (schemaJson.length > 0) {
                var schema = JSON.parse(schemaJson);
                if (document.getElementById("AverageRatingVal")) {
                    if (document.getElementById("AverageRatingVal").value != null && document.getElementById("AverageRatingVal").value != '') {
                        schema.aggregateRating.ratingValue = document.getElementById("AverageRatingVal").value;
                    }
                }
                if (document.getElementById("TotalReviewCountVal")) {
                    if (document.getElementById("TotalReviewCountVal").value != null && document.getElementById("TotalReviewCountVal").value != '') {
                        schema.aggregateRating.reviewCount = document.getElementById("TotalReviewCountVal").value;
                    }
                }
                var schemaUpdated = JSON.stringify(schema);
                document.getElementById("listingPageSchema").innerText = schemaUpdated;
            }
        }
    }
}

export default CourseListing;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "row mx-0" },
      [
        _c("DisplayUserInfor", {
          attrs: { instructorDetails: _vm.instructorDetails }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 py-3" }, [
          _c("div", { staticClass: "thankmain" }, [
            _c(
              "div",
              {
                staticClass: "text-success thankdemo",
                staticStyle: { color: "#FF0000!important" }
              },
              [
                _c("i", { staticClass: "fad fa-check-circle" }),
                _vm._v(" "),
                _c("b", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.errorScreenDetails.errorHeadingText))
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "alert alert-primary" }, [
              _vm.errorScreenDetails.errorType == "TIME_CONFLICT"
                ? _c("p", { staticClass: "caption mb-2" }, [
                    _vm._v(
                      "\n                        You have booked a demo class for another course at the same time " +
                        _vm._s(_vm.errorScreenDetails.selectedDateTime) +
                        ".\n                        Choose another time slot convenient to you to complete the demo booking.\n                    "
                    )
                  ])
                : _c("p", { staticClass: "caption mb-2" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errorScreenDetails.errorContent) +
                        "\n                    "
                    )
                  ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "next-button" }, [
            _c("div"),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "btn btn-primary", on: { click: _vm.onBtnClick } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.errorScreenDetails.NextButtonText) +
                    "\n                "
                )
              ]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "course-details-block d-flex align-items-center" },
      [
        _c("div", { staticClass: "img-wrapper" }, [
          _c("img", {
            staticClass: "fit-cover",
            attrs: { src: _vm.businessImgUrl, alt: "" }
          })
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "pl-3 " }, [
          _vm._v("Classes by " + _vm._s(_vm.businessName))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "course-rlist" }, [
      _c("b", [_vm._v("choose the course you are writing the review for")]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.courses, function(course) {
          return _c(
            "li",
            {
              staticClass: "d-flex",
              attrs: {
                "data-courseId": course.courseId,
                "data-courseName": course.courseName,
                "data-courseImageUrl": course.photoThumbnailUrl
              },
              on: { click: _vm.onCourseSelected }
            },
            [
              _c("div", { staticClass: "col-3 px-0" }, [
                _c("div", { staticClass: "img-wrapper d-flex" }, [
                  _c("img", {
                    staticClass: "fit-cover",
                    attrs: {
                      src: course.photoThumbnailUrl,
                      alt: "course.courseName"
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col d-flex align-items-center justify-content-between"
                },
                [
                  _c("h6", [_vm._v(_vm._s(course.courseName))]),
                  _c("i", {
                    staticClass: "far fa-angle-right text-primary pl-2"
                  })
                ]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal", class: { show: _vm.showpopup } }, [
    _c("svg", { staticClass: "d-none" }, [
      _c("defs", [
        _c("g", { attrs: { id: "chevronBack" } }, [
          _c("path", {
            attrs: {
              d:
                "m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "modal-dialog modal-dialog-centered modal-dialog-scrollable"
      },
      [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header align-items-center" }, [
            _c(
              "svg",
              {
                staticClass: "svg-icon medium d-sm-none",
                attrs: { viewBox: "0 0 64 64" },
                on: {
                  click: function($event) {
                    return _vm.hidepopup()
                  }
                }
              },
              [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
            ),
            _vm._v(" "),
            _c("h5", [_vm._v(_vm._s(_vm.headerText))]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.hidepopup()
                  }
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm.currentStep == "SignUp"
                ? _c("SignUp", {
                    attrs: {
                      hidepopup: _vm.hidepopup,
                      onKeyUp: _vm.onKeyUp,
                      onKeyDown: _vm.onKeyDown,
                      userDetails: _vm.userDetails,
                      signUpSubmit: _vm.signUpSubmit,
                      openSignIn: _vm.openSignIn,
                      nameErrorText: _vm.nameErrorText,
                      emailErrorText: _vm.emailErrorText,
                      mobileErrorText: _vm.mobileErrorText,
                      errorText: _vm.errorText
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep == "Otp"
                ? _c("CommonVerification", {
                    attrs: {
                      hidepopup: _vm.hidepopup,
                      userDetails: _vm.userDetails,
                      invalidOtp: _vm.invalidOtp
                    },
                    on: { "post-validateCode": _vm.validateCode }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep == "OtpVerified"
                ? _c("SignUpOtpVerified")
                : _vm._e()
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="response-page" id="response-page-comments">
        <div class="response-header">
            <a @click="closeComments()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" viewBox="0 0 256 256">
                    <use xlink:href="#backarrowm"></use>
                </svg>
            </a> Comments
        </div>

        <div class="activity-responses">
            <div class="comments-page">
                <h5>{{ answeredName }}</h5>
                <div class="hour">{{ answeredTime }}</div>
                <p v-if="playButtonBlobUrl != ''" class="answer">
                    Answered: Play Audio
                    <svg onclick="document.getElementById('playAnswerAudio').play()" class="response-speaker" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ff7a21" viewBox="0 0 256 256">
                        <use xlink:href="#iconspeaker"></use>
                    </svg>
                    <audio controls hidden id="playAnswerAudio">
                        <source :src="playButtonBlobUrl" type="audio/mp3">
                    </audio>
                </p>
                <p v-else class="answer">
                    Answered: {{ answer }}
                    <svg v-if="[4,6,7].includes(activityTypeId) && (isCorrectAnswer == 1)" class="right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff">
                        <use xlink:href="#iconcheckanswer"></use>
                    </svg>
                    <svg v-if="[4,6,7].includes(activityTypeId) && (isCorrectAnswer == 0)" class="wrong" xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 256 256">
                        <use xlink:href="#iconcross"></use>
                    </svg>
                </p>
                <div class="like-reply">
                    <svg class="chat-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <use xlink:href="#iconcomment"></use>
                    </svg>
                    <a @click="replyClick(answeredName,'0')">Comment</a>
                </div>
            </div>

            <div class="comments-infor">
                <h5 v-if="totalComments > 1">{{ totalComments }} Comments</h5>
                <h5 v-if="totalComments == 1">{{ totalComments }} Comment</h5>
                <h5 v-else hidden></h5>
                <div v-for="(result, index) in commentsData" :id="'comments-box-'.concat(result.rowId)" class="comments-box" :class="result.level > 0 ? 'reply-comment' : ''">
                    <div class="message-comment" :class="result.isCoach ? 'coach-comment' : ''">
                        <span><b>{{ result.isCoach ? 'Coach' : '' }} {{ result.name }}</b></span>
                        <span class="hour">{{ result.dateChatFormat }}</span>
                        <p>{{ result.comments }}</p>
                    </div>
                    <div class="like-comments">
                        <a @click="submitLike(result.rowId, index)">
                            <div class="like-heart" :class="result.isLiked ? 'active' : ''">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                                    <use xlink:href="#like"></use>
                                </svg>
                            </div>
                            <span>{{ result.likeCount>0?result.likeCount:'' }} {{ result.likeCount>1?'Likes':'Like' }}</span>
                        </a>
                        <a @click="replyClick(result.name,result.rowId)">
                            <svg class="chat-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                <use xlink:href="#iconcomment"></use>
                            </svg>
                            <span>{{ result.commentsCount>0?result.commentsCount:'' }} {{ result.commentsCount>1?'Replies':'Reply' }}</span>
                        </a>
                    </div>
                </div>
                <div class="response-bottom-sheet">
                    <div class="bottom-comment">
                        <p><b>Replying to {{ replyingName }}</b></p>
                        <span class="type-comment">
                            <textarea id="reply-comment" placeholder="Add your comment"></textarea>
                            <a @click="submitComments()">
                                <svg class="comment-submit" xmlns="http://www.w3.org/2000/svg" fill="#ff7a21" viewBox="0 0 256 256">
                                    <use xlink:href="#iconentercomment"></use>
                                </svg>
                            </a>
                        </span>
                        <p class="text-danger mt-2" :class="showError ? '' : 'd-none'">Please Enter Reply and Send...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from "../../wwwroot/js/Loader.js";
    export default {
        name: "ResponseComments",
        data() {
            return {
                commentsData: [],
                showError: false,
                replyingName: '',
                parentCommentId: 0,
                totalComments: 0,
            }
        },
        props: {
            activityResponseId: 0,
            activityTypeId: 0,
            isCorrectAnswer: 0,
            userPid: 0,
            answeredName: '',
            answeredTime: '',
            answerHtml: '',
            playButtonBlobUrl: '',
            answer: '',
        },
        beforeMount: function () {
            this.replyingName = this.answeredName;
            if (this.activityResponseId > 0) {
                this.getComments(this.activityResponseId);
            }
            else {
            }
        },
        mounted: function () {
        },
        updated: function () {
        },
        methods: {
            getComments: function (id) {
                Loader.show();
                var completeUrl = '/api/dailyactivity/get-dailyactivity-response-comments?activityResponseId=' + id + '&userPid=' + this.userPid;
                fetch(completeUrl)
                    .then(response => response.json())
                    .then((responseJson) => {
                        if (responseJson.length > 0) {
                            this.commentsData = responseJson;
                            this.totalComments = responseJson.filter(res => res.level == 0).length;
                        } else {
                        }
                        Loader.hide();
                    })
            },
            submitComments: function () {
                let inputReply = document.getElementById("reply-comment").value.trim();
                if (inputReply != '') {
                    this.showError = false;
                    Loader.show();
                    var url = `/api/dailyactivity/save-dailyactivity-responsereply`;
                    let requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            ActivityResponseId: this.activityResponseId,
                            ReplyType: 'C',
                            UserPid: this.userPid,
                            Comments: inputReply,
                            ParentCommentId: this.parentCommentId,
                        })
                    }
                    fetch(url, requestOptions)
                        .then(response => response.json())
                        .then((responseJson) => {
                            if (responseJson == 1) {
                                document.getElementById("reply-comment").value = '';
                                if (this.parentCommentId == 0) {
                                    let commentsContent = document.getElementById("comments-" + this.activityResponseId);
                                    if (commentsContent.innerHTML.split(' ').length > 1) {
                                        let val = parseInt(commentsContent.innerHTML.split(' ')[1].trim().replace('(', '').replace(')', ''));
                                        if ((val + 1) == 2)
                                            commentsContent.innerHTML = 'Comments (2)';
                                        else
                                            commentsContent.innerHTML = commentsContent.innerHTML.replace(val, (val + 1));
                                    }
                                    else
                                        commentsContent.innerHTML = "Comment (1)";
                                }
                                this.getComments(this.activityResponseId);
                            } else {

                            }
                            Loader.hide();
                        })
                }
                else {
                    this.showError = true;
                }
            },
            closeComments: function () {
                document.getElementById('div-response-comments-main').innerHTML = '';
                let answersDiv = document.getElementById('response-page-answers');
                if (answersDiv) {
                    if (answersDiv.classList.contains('d-none')) {
                        answersDiv.classList.remove('d-none')
                    }
                }
                let answersDivHeader = document.getElementById('response-page-answers-header');
                if (answersDivHeader) {
                    if (answersDivHeader.classList.contains('d-none')) {
                        answersDivHeader.classList.remove('d-none')
                    }
                }
            },
            replyClick: function (userName, commentId) {
                this.parentCommentId = commentId;
                this.replyingName = userName;
                let isMobile = document.getElementById('IsMobile').getAttribute('data-ismobile').toLowerCase() == 'true' ? 1 : 0;
                let elementToScroll;
                if (commentId > 0) {
                    if (isMobile == 1) {
                        let element = document.getElementById('comments-box-' + commentId);
                        if (element.previousElementSibling.tagName.toLowerCase() == 'h5')
                            elementToScroll = element.previousElementSibling;
                        else
                            elementToScroll = element.previousElementSibling.lastElementChild;
                        elementToScroll.scrollIntoView(true);
                    }
                }
                setTimeout(function () { document.getElementById('reply-comment').focus(); }, 200);
                if (isMobile == 1)
                    setTimeout(function () { elementToScroll.scrollIntoView(true); }, 400);
            },
            submitLike: function (commentId, index) {
                if (this.commentsData[index].isLiked == false) {
                    Loader.show();
                    var url = `/api/dailyactivity/save-dailyactivity-responsereply`;
                    let requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            ActivityResponseId: this.activityResponseId,
                            ReplyType: 'L',
                            UserPid: this.userPid,
                            CommentId: commentId,
                        })
                    }
                    fetch(url, requestOptions)
                        .then(response => response.json())
                        .then((responseJson) => {
                            if (responseJson == 1) {
                                this.getComments(this.activityResponseId);
                            } else {
                            }
                            Loader.hide();
                        })
                }
            },
        },
    }
</script>
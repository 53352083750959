<template>
    <!-- Modal body start -->
    <div>
        <svg class="d-none">
            <g id="ratingStarIcon">
                <path d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0" />
            </g>
        </svg>
        <!-- Left block start -->
        <div class="course-details-block d-flex align-items-center">
            <div class="img-wrapper"><img :src="selectedCourseImgUrl" alt="" class="fit-cover"></div>
            <h6 class="pl-3 "> {{selectedCourseName}}</h6>
        </div>
        <!-- Left block end -->
        <!-- Right block start -->
        <div class="py-3">
            <div class="review-container">
                <h6>
                    Please give a rating
                </h6>
                <!--<div class="d-flex">
                    <i v-for="i in 5" :id="i" class="fal fa-star" @mouseleave="onStarLeave" @click="onStarClick" @mouseover="onStarHover"></i>
                </div>-->
                <div class="d-flex align-items-center star-option">
                    <svg v-for="i in 5" :id="i" class="svg-icon" viewBox="0 -10 511.98685 511" data-svg="star-icon">
                        <use xlink:href="#ratingStarIcon" @mouseleave="onStarLeave" @click="onStarClick" @mouseover="onStarHover" />    
                    </svg>
                    

                </div>
                <div class="review-labels d-flex justify-content-between mt-3">
                    <span class="caption text-center">
                        Needs improvement
                    </span>
                    <span class="caption">Excellent</span>
                </div>

                <hr class="dashed my-4">
                <h6>
                    Please write a review
                </h6>

                <textarea name=""
                          id=""
                          cols="30"
                          rows="5"
                          v-model="reviewDescription"></textarea>

                <span class="caption pt-3">
                    Min character count 25 and max 2500
                </span>
            </div>
        </div>
    </div>
    <!-- Modal body end -->
    <!-- Modal end -->
</template>
<script>
    export default {
        props: {
            selectedCourseName: String,
            selectedCourseImgUrl: String,
            selectedRatingFromRoot: Number,
            selectedDescriptionFromRoot: String,
            isFromCourseDetailsPage: Boolean,
        },
        data() {
            return {
                reviewDescription: "",
                ratingStars: null,
                currentRating: 0,
                backButtonClass: "",
            }
        },
        beforeMount: function () {

        },
        mounted: function () {
            this.ratingStars = document.querySelectorAll('[data-svg="star-icon"]');

            if (this.selectedRatingFromRoot > 0) {
                this.currentRating = this.selectedRatingFromRoot;
                this.setRating(this.currentRating);
            }
            if (this.selectedDescriptionFromRoot) {
                this.reviewDescription = this.selectedDescriptionFromRoot;
            }
            if (this.isFromCourseDetailsPage) {
                this.backButtonClass = "d-none";
            }
        },
        methods: {
            onStarHover: function (event) {
                this.disableStar();
                var position = event.currentTarget.parentNode.getAttribute('id');

                this.setRating(position);
            },
            onStarLeave: function (event) {
                
                this.disableStar();
            },
            onStarClick: function (event) {
                var position = parseInt(event.currentTarget.parentNode.getAttribute('id'));

                this.currentRating = position;
                this.setRating(position);

                this.$emit('starClick', this.currentRating);
            },
            setRating: function (position) {
                this.ratingStars.forEach(function (star) {
                    //star.classList.remove('fa');
                    //star.classList.add("fal");
                    star.classList.remove('active');
                })
                for (let i = 0; i < position; i++) {
                    //this.ratingStars[i].classList.remove('fal');
                    //this.ratingStars[i].classList.add('fa');
                    this.ratingStars[i].classList.add('active');
                }
            },
            disableStar: function () {
                this.setRating(this.currentRating);
            },
            onClickSubmit: function (event) {

                if (event.currentTarget.classList.contains('disabled'))
                    return;
                this.$emit('reviewSubmit');
            },
            onClickBack: function () {
                if (this.isFromCourseDetailsPage)
                    return;
                this.$emit('onClickBack');
            },
            checkSubmitToggle: function () {
                if (this.currentRating > 0 && this.reviewDescription.length > 24) {
                    this.$emit('submitButtonToggle');

                } else {
                    this.$emit('submitButtonToggle', 'disabled');

                }
            }
        },
        watch: {
            reviewDescription: function () {
                this.checkSubmitToggle();
                this.$emit('reviewDescriptionEntered', this.reviewDescription);
            },
            currentRating: function () {
                this.checkSubmitToggle();
            }
        }
    }
</script>
﻿let navBar = {
  
    initNavBar: function () {
        const listLength = document.querySelectorAll('.course-nav-tab ul li a');

        listLength.forEach((element, index) => {
            const headerHeight = document.querySelector('.header-section').clientHeight;
            const navBarHeight = document.querySelector('.course-nav-tab').clientHeight;
            element.addEventListener('click', function (e) {

                e.preventDefault();
                const offsetId = element.getAttribute('href');
                const targetDiv = document.querySelector(offsetId);              
                window.scrollTo(0, (window.pageYOffset + targetDiv.getBoundingClientRect().top) - (headerHeight + navBarHeight + 20));
                
            })
        })
    }
}
navBar.initNavBar();
﻿import '../splide-2.4.21/dist/js/splide.min.js';

let carousel = {
    nodes: '',
    isMobile: false,
    pageType: '',
    init: function () {
        this.nodes = document.querySelectorAll(".splide");
        if(document.getElementById("hdnIsMobile"))
        {
            if (document.getElementById("hdnIsMobile").getAttribute("data-isMobile") == "True") {
                this.isMobile = true;
            }
        }
    },
    bindCarousel: function () {
       
        if (this.isMobile) {
            this.nodes.forEach((node) => {
                let sectionType = node.getAttribute("data-carousel");

                if (sectionType == "home-upcoming-clubmeets" || sectionType == "today-clubmeet" || sectionType == "club-videos") {
                    new Splide(node, {
                        /*type: 'loop',*/
                        /*autoWidth: true,*/
                        gap: 15,
                        perMove: 1,
                        /*arrows: false,*/
                        //autoplay: true,
                        //interval: 3000,
                        /*type: 'loop',*/
                        pagination: false,
                        arrows: false,
                        autoWidth: true,
                        //classes: {
                        //    arrow: 'splide__arrow arrows__up '
                        //}
                    }).mount();
                }
                else if (sectionType != "instructor-cards-vue") {
                    new Splide(node, {
                        perMove: 1,
                        autoWidth: true,
                        gap: 13,
                        classes: {
                            arrow: 'splide__arrow arrows__up '
                        }
                    }).mount();
                }


            })
        } else {
            this.pageType = document.getElementById("hdnPageType").value;

            this.nodes.forEach((node) => {
                let sectionType = node.getAttribute("data-carousel");
                if (sectionType == "today-clubmeet" || sectionType == "club-videos") {
                    new Splide(node, {
                        pagination: false,
                        perPage: 3,
                        autoWidth: true,
                        gap: 15,
                    }).mount();
                }
                else
                if (sectionType == "home-upcoming-clubmeets") {
                    
                    new Splide(node, {
                        gap: 15,
                        perPage: 3,
                        perMove: 3,
                        autoWidth: true,
                        pagination: false,
                    }).mount();
                }
                else if (sectionType == "course-card") {
                    
                    let perPageValue = 0;
                    if (this.pageType == "Home")
                        perPageValue = 3;
                    else
                        perPageValue = 2;
                    new Splide(node, {
                        perPage: perPageValue,
                        autoWidth: true,
                        gap: 13,
                        classes: {
                            arrow: 'splide__arrow arrows__up '
                        }
                    }).mount();
                }
                else if (sectionType == "goals") {
                    
                    new Splide(node, {
                        perMove: 1,
                        autoWidth: true,
                        gap: 13,
                        classes: {
                            arrow: 'splide__arrow arrows__up '
                        }
                    }).mount();
                }
                else if (sectionType == "videos") {
                    
                    new Splide(node, {
                        perPage: 2,
                        autoWidth: true,
                        gap: 13,
                        classes: {
                            arrow: 'splide__arrow'
                        }
                    }).mount();
                }
                else if (sectionType == "instructor-cards") {
                    let perPageValue = 0;
                    if (this.pageType == "CourseDetail" || 
                        this.pageType == "InstituteProfile")
                        perPageValue = 2;
                    else
                        perPageValue = 3;

                    new Splide(node, {
                        perPage: perPageValue,
                        autoWidth: true,
                        gap: 13,
                        classes: {
                            arrow: 'splide__arrow'
                        }
                    }).mount();
                }
                else if (sectionType == "capshine-clubs") {
                    new Splide(node, {
                        perPage: 1,
                        autoWidth: true,
                        gap: 13,
                        classes: {
                            arrow: 'splide__arrow arrows__up '
                        }
                    }).mount();
                }
                else if (sectionType == "home-club-videos") {
                    new Splide(node, {
                        perPage: 3,
                        perMove : 3,
                        gap: 13,
                        classes: {
                            arrow: 'splide__arrow arrows__up '
                        }
                    }).mount();
                }
                else {
                    if (sectionType != "instructor-cards-vue") {
                        new Splide(node, {
                            perPage: 3,
                            autoWidth: true,
                            gap: 13,
                            classes: {
                                arrow: 'splide__arrow arrows__up '
                            }
                        }).mount();
                    }
                }
            })
        }
    },
    initInstructorCard: function () {
        this.init();
        this.nodes.forEach((node) => {
            
            let sectionType = node.getAttribute("data-carousel");
            
            if (sectionType == "instructor-cards-vue") {

                if (this.isMobile) {
                    new Splide(node, {
                        perMove: 1,
                        autoWidth: true,
                        gap: 13,
                        classes: {
                            arrow: 'splide__arrow arrows__up '
                        }
                    }).mount();
                }
                else {
                    let perPageValue = 0;
                    if (this.pageType == "CourseDetail" ||
                        this.pageType == "InstituteProfile")
                        perPageValue = 2;
                    else
                        perPageValue = 3;

                    new Splide(node, {
                        perPage: perPageValue,
                        autoWidth: true,
                        gap: 13,
                        classes: {
                            arrow: 'splide__arrow'
                        }
                    }).mount();
                }  
            }
        })
    }

}
if (document.querySelectorAll(".splide").length > 0) {
    carousel.init();
    carousel.bindCarousel();
}
export default carousel;



<template>
    
    <!-- Modal start -->
    <!-- remove .show to hide modal -->
    <div class="review-screen">
        <div class="modal review show">  <!--:class="showOrHide"-->
            <!--add .modal-sm/modal-lg/modal-xl after .modal-dialog for width change-->
            <div class="modal-dialog modal-dialog-scrollable">
                <!--Modal content-->
                <div class="modal-content">
                    <!--progress bar-->
                    <div class="progress">
                        <div class="progress-bar"
                             role="progressbar"
                             style="width: 50%"
                             aria-valuenow="50"
                             aria-valuemin="0"
                             aria-valuemax="100"></div>
                    </div>
                    <!--progress bar end-->
                    
                    <div class="modal-header align-items-center">
                        <!--<i class="far fa-arrow-left d-sm-none" @click="onClose"></i>-->
                        <svg @click="onClose" class="svg-icon medium d-sm-none" viewBox="0 0 64 64">
                            <use xlink:href="#chevronBack" />
                        </svg>
                        <h5>Write a Review</h5>
                        <button type="button" class="close" @click="onClose">&times;</button>
                    </div>

                    <div class="modal-body">
                        
                        <div v-if="currentStep == 'stepOne'">
                            <WriteReviewStepOne :businessName="businessName"
                                                :courses="courses"
                                                :businessImageUrl="businessImageUrl"
                                                v-on:courseSelected="onCourseSelected">
                            </WriteReviewStepOne>
                        </div>

                        <div v-if="currentStep == 'stepTwo'">
                            <WriteReviewStepTwo :selectedCourseName="selectedCourseName"
                                                :selectedCourseImgUrl="selectedCourseImgUrl"
                                                :selectedRatingFromRoot="courses[selectedCourseId] ? courses[selectedCourseId].selectedRating : 0"
                                                :selectedDescriptionFromRoot="courses[selectedCourseId] ? courses[selectedCourseId].enteredDescription : ''"
                                                :isFromCourseDetailsPage="isFromCourseDetailsPage"
                                                v-on:starClick="onStarClick"
                                                v-on:reviewDescriptionEntered="onReviewEntered"
                                                v-on:onClickBack="onClickBack"
                                                v-on:reviewSubmit="onSubmit"
                                                v-on:submitButtonToggle="submitButtonToggle">

                            </WriteReviewStepTwo>
                        </div>

                        <div v-if="currentStep == 'stepThree'">
                            <WriteReviewStepThree :selectedCourseName="selectedCourseName"
                                                  :selectedCourseImgUrl="selectedCourseImgUrl"
                                                  :selectedRating="selectedRating"
                                                  :reviewDescription="reviewDescription"
                                                  v-on:onCloseEmit="onClose">
                            </WriteReviewStepThree>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button v-if="currentStep == 'stepTwo' && showFooterButton && !isFromCourseDetailsPage" class="button btn link" v-on:click="onClickBack">Back</button>
                        <button v-if="currentStep == 'stepTwo' && showFooterButton" class="button btn btn-primary" :class="submitButtonClass" v-on:click="onClickSubmit">Submit</button>
                        <button v-if="currentStep == 'stepThree' && showFooterButton" class="button btn btn-primary" v-on:click="onClose">
                            Done
                        </button>
                    </div>
            </div>
        </div>
    </div>
    <!-- Modal end -->
    </div>
</template>
<script>
    import WriteReviewStepOne from "./WriteReviewStepOne.vue";
    import WriteReviewStepTwo from "./WriteReviewStepTwo.vue";
    import WriteReviewStepThree from "./WriteReviewStepThree.vue";
    import Loader from "../../wwwroot/js/Loader.js";
    export default {
        components: {
            "WriteReviewStepOne": WriteReviewStepOne,
            "WriteReviewStepTwo": WriteReviewStepTwo,
            "WriteReviewStepThree": WriteReviewStepThree,
        },
        props: {
            courseDetailsCourseId: String,
            courseDetailsCourseName: String,
            courseDetailsCourseImageUrl: String,
            courseDetailsBusinessId: String,
            isAuthenticated: Boolean,
        },
        data() {
            return {
                courses: {},
                selectedCourseId: null,
                businessId: null,
                businessName: null,
                businessImageUrl: null,
                currentStep: 'stepOne',
                isFromCourseDetailsPage: false,
                selectedCourseName: null,
                selectedCourseImgUrl: null,
                selectedRating: 0,
                reviewDescription: null,
                progressBar: null,
                writeReviewButton: null,
                showFooterButton: false,
                submitButtonClass: 'disabled',
                showOrHide: 'hide',

            }
        },
        beforeMount: function () {
            if (this.courseDetailsCourseId) {
                this.isFromCourseDetailsPage = true;
            }

            if (this.isFromCourseDetailsPage) {
                
                this.selectedCourseId = this.courseDetailsCourseId;
                this.selectedCourseName = this.courseDetailsCourseName;
                this.selectedCourseImgUrl = this.courseDetailsCourseImageUrl;
                this.businessId = this.courseDetailsBusinessId;
                this.currentStep = 'stepTwo';
                this.showFooterButton = true;
            } else {
                
                var courseCards = document.querySelectorAll('#courses .course-card[course-card]');
                courseCards.forEach((card, index) => {

                    this.courses[card.getAttribute('data-courseIdForReviews')] = {

                        "courseId": card.getAttribute('data-courseIdForReviews'),
                        "courseName": card.getAttribute('data-CourseTitle'),
                        "photoThumbnailUrl": card.getAttribute('data-Thumbnail'),
                        "selectedRating": 0,
                        "enteredDescription": "",

                    }
                })
                
            }
            
        },
        mounted: function () {
            
            if (!this.courseDetailsCourseId) {
                this.businessId = document.getElementById('hdnBusinessId').value;
                this.businessName = document.getElementById('hdnBusinessName').value;
                this.businessImageUrl = document.getElementById('hdnBusinessImageUrl').value;
            }
            
            this.progressBar = document.querySelector('.modal .progress .progress-bar');
            this.progressBar.style.width = '33.33%';
            
            //document.querySelector('.course-reviews .btn.btn-outline-primary').addEventListener('click', (event) => {
            //    event.preventDefault();
                
            //    //let writeReviewButton = document.querySelector('.modal.review');
            //    //if (writeReviewButton.classList.contains('hide')) {
            //    //    writeReviewButton.classList.remove('hide')
            //    //    writeReviewButton.classList.add('show')
            //    //}
                
                
            //})

        },
        methods: {
            onCourseSelected(selectedCourseDetails) {
                
                this.selectedCourseId = selectedCourseDetails.courseId;
                this.selectedCourseName = selectedCourseDetails.courseName;
                this.selectedCourseImgUrl = selectedCourseDetails.courseImageUrl;
                this.currentStep = 'stepTwo';
                this.showFooterButton = true;
                this.setProgressBar();
            },
            onSubmit(event) {
                
                
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        UserPid: 0,
                        Review: this.reviewDescription,
                        Rating: this.selectedRating,
                        NeedMetBusinessId: this.businessId,
                        CourseId: this.selectedCourseId,
                        CourseTitle: this.selectedCourseName,
                    }) 
                }
                console.log("The request options ", requestOptions);
                Loader.showAboveModal();
                fetch("/api/reviews/save-course-review", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        console.log("the response", data);
                        Loader.hideAboveModal();
                    });
                this.currentStep = "stepThree";
                this.setProgressBar();
                
            },
            setProgressBar() {
                switch (this.currentStep) {
                    case 'stepOne': this.progressBar.style.width = '33.33%';
                        break;
                    case 'stepTwo': this.progressBar.style.width = '66.66%';
                        break;
                    case 'stepThree': this.progressBar.style.width = '100%';
                        break;
                }
            },
            onClose(event) {
                
                document.body.classList.remove("slkno-scroll");
                
                this.showFooterButton = false;
                var writeReviewBaseDiv = document.querySelector("#writereview-base-div");
                writeReviewBaseDiv.innerHTML = "";
                var div = document.createElement("div");
                div.id = "write-review-div";
                writeReviewBaseDiv.appendChild(div);
                if (document.getElementById("hdnIsAuthenticated")) {
                    if (document.getElementById("hdnIsAuthenticated").getAttribute("data-isAuthenticated") == "False")
                        location.reload();
                }
            },
            onStarClick(currentRating) {
                if (!this.isFromCourseDetailsPage) {
                    this.courses[this.selectedCourseId].selectedRating = currentRating;
                }
                this.selectedRating = currentRating;
                
            },
            onReviewEntered(enteredReviewDescription) {
                if (!this.isFromCourseDetailsPage) {
                    this.courses[this.selectedCourseId].enteredDescription = enteredReviewDescription;
                }
                
                this.reviewDescription = enteredReviewDescription;
                
            },
            resetState() {
                if (this.isFromCourseDetailsPage) {
                    this.currentStep = 'stepTwo';
                } else {
                    Object.values(this.courses).forEach(course => {
                        
                        course.selectedRating = 0;
                        course.enteredDescription = "";
                    })
                    this.currentStep = 'stepOne';
                }    
                
            },
            onClickBack() {
                this.currentStep = 'stepOne';
                this.showFooterButton = false;
            },
            submitButtonToggle(className) {
                
                this.submitButtonClass = className;
            },
            onClickSubmit(event) {
                if (event.currentTarget.classList.contains('disabled'))
                    return;
                this.onSubmit();
            }
        },
        watch: {
            currentStep: function () {
                this.setProgressBar();
            }
        },
    }
</script>
<template>
    <div>
        <button type="button"
                class="close cabso mr-2 mt-2"
                style="display: block; padding: 0rem"
                @click="close()">
            &times;
        </button>

        <div class="row mx-0">
            <!-- Left block start -->
            <DisplayUserInfor :instructorDetails="instructorDetails" />

            <!-- Left block end -->
            <!-- Right block start -->
            <div class="col-lg-6 py-3">
                <div class="thankmain">
                    <div class="text-primary thankdemo">
                        <i class="fad fa-check-circle"></i>

                        <b class="mb-1">Reconfirming Demo</b>
                    </div>
                    <div class="alert alert-danger">
                        <p class="caption mb-2">
                            We have reconfirmed with the instructor is currently busy and not available at the moment.
                            You can continue to book the demo by selecting another demo slot time .
                        </p>
                    </div>
                </div>
               
                <div class="next-button">
                    <div></div>
                    <a class="btn btn-primary" @click="next()">                        
                        Next
                    </a>
                </div>
            </div>

            <!-- <SocialShare v-if="showpopup" :callback="hidepopup" :courseId="selectedcourseid" :UserId="userid"></SocialShare> -->
        </div>
    </div>
</template>
<script>
    import DisplayUserInfor from "./DisplayUserInfor.vue";
    import SulGA from "../../wwwroot/js/Ga.js";
    export default {
        name: "ConfirmInstantLiveDemoOffline",
        props: {
            instructorDetails: {},
            closePopup: { type: Function },
            nextStep: { type: Function },
        },
        methods: {
            close() {
                this.closePopup();
            },
            next() {
                this.nextStep();
            },
        },
        mounted() {
        },
        components: {
            DisplayUserInfor: DisplayUserInfor,
        },
    };
</script>
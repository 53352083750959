<template>

  
<div class="row mx-0">
                      <!-- Left block start -->
                      <!-- <LeftSidePanel :closePopup="close" :instructorDetails="instructorDetails">   </LeftSidePanel> -->
<DisplayUserInfor
             :instructorDetails="instructorDetails"
             />
                      <!-- Left block end -->
                      <!-- Right block start -->
              <div class="col-lg-6 px-lg-5 pb-5">
                    <div class="section payment-content">
                      <div class="container">
                        <div class="row">
                          <div class="col px-lg-5">
                        <div class="hero-wrapper">
                          <img src="https://lscdn.blob.core.windows.net/elearn/hero.jpg" class="w-50" alt="hero">
                        </div>
                        <h4 class="mb-3">Please pay a refundable deposit for the demo</h4>
                        <p class="caption text-success mb-3">This security deposit allows us to give you the best experience with the tutor.</p>
                        <ul class="pl-3">
                          <li class="body2 mb-3">The token reservation fee of Rs. 100 will be either adjusted against the purchase of a course or refunded in full upon completion of the demo within 48 hours.</li>
                          <li class="body2 mb-5">Upon the completion of the demo, you will receive a 15% off (max of Rs. 500) discount coupon against the booking of this course or any other course.</li>
                        </ul>
                        <div class="p-2">
                          <a class="btn btn-lg caption btn-primary btn-block" v-on:click="redirectToPaymentPage()">Pay Rs.100</a>
                        </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
          
                    </div>


</template>
<script>
import DisplayUserInfor from "./DisplayUserInfor.vue";
    export default {
        name: "DemoPayProcess",
        props: {
            instructorDetails: {},
            selectedDate: {},
            paymentUrl: '',
            closePopup: { type: Function },
                   },
        methods: {
            close() {
                this.closePopup();
            },
            redirectToPaymentPage() {
                if (this.paymentUrl != null && this.paymentUrl != '' & this.paymentUrl != undefined) {
                    window.location = this.paymentUrl;
                }
            }
        },
         components:{
          "DisplayUserInfor":DisplayUserInfor
        }
    }
</script>
﻿import Vue from "vue"
import AutoComplete from "../VueComponents/AutoComplete.vue"
import AdminScript from "../wwwroot/js/pages/Admin/Admin.js"
import Loader from "../wwwroot/js/Loader.js";
import CourseSignup from "../VueComponents/CourseSignup/CourseSignup.vue";
import PaidCourseSignup from "../VueComponents/PaidCourseSignup/PaidCourseSignup.vue";
import OneOnOneSignup from "../VueComponents/PaidCourseSignup/OneOnOneSignup.vue";
import WriteReview from "../VueComponents/WriteReview.vue";
import WriteReviewNew from "../VueComponents/WriteReviewNew/WriteReviewNew.vue"
import CourseFilter from "../VueComponents/CourseFilter/CourseFilter.vue";
import Review from "../VueComponents/Review.vue";
import MyAccountDetails from "../VueComponents/MyAccount/MyAccountDetails.vue";
import MyClassContainer from "../VueComponents/MyClass/MyClassContainer.vue";
import InstructorCard from "../VueComponents/InstructorCard.vue";
import DemoThankyouWrapper from "../VueComponents/CourseSignup/DemoThankyouWrapper.vue";
import SignInModal from "../VueComponents/SignIn/BaseModal.vue";
import SignUpModal from "../VueComponents/SignUp/BaseModal.vue";
import LCFFirstLevel from "../VueComponents/LCF/LCFFirstLevel.vue";
import CourseRecomendation from "../VueComponents/LCF/CourseRecomendation.vue";
import LCFEditPreference from "../VueComponents/LCF/LCFEditPreference.vue"
import PaymentGateway from "../VueComponents/PaidCourseSignup/PaymentGateway.vue";
import Splide from "../wwwroot/js/splide-2.4.21/dist/js/splide.min.js";
import LazyLoading from "../wwwroot/js/Common/LazyLoading.js";
import Carousel from "../wwwroot/js/Common/carousel.js";
import SulGA from "../wwwroot/js/Ga.js";
import review from "../wwwroot/js/common/review.js";
import Hamburger from "../wwwroot/js/common/Hamburger.js";
import NavBar from "../wwwroot/js/common/NavBar.js";
//import ScrollToTop from "../wwwroot/js/common/ScrollToTop.js";
import ShortList from "../wwwroot/js/common/ShortList.js";
import Faq from "../wwwroot/js/pages/Faq.js";
import CourseListing from "../wwwroot/js/pages/CourseListing.js";
import HomePage from "../wwwroot/js/pages/HomePage.js";
import DemoFeedback from "../wwwroot/js/common/DemoFeedback.js";
import OnboardingDetails from "../VueComponents/OnboardingDetails/OnboardingDetails.vue";
import CourseListingVue from "../VueComponents/CourseListing.vue";
import FormElements from "../wwwroot/js/Common/FormElements.js";
import ClubRegistration from "../VueComponents/ClubRegistration/BaseModal.vue";
import AddNewClubSchedule from "../VueComponents/Admin/AddNewClubSchedule.vue";
import ManageClubSchedule from "../VueComponents/Admin/ManageClubSchedule.vue";
import MyClub from "../wwwroot/js/Pages/MyClub.js";
import UpcomingClubSchedules from "../wwwroot/js/Common/UpcomingClubSchedule.js";
import VideoPlayerPop from "../VueComponents/ClubVideo/VideoPlayerPopup.vue";
import LearningVideos from "../wwwroot/js/Pages/LearningVideos.js";
import LearningVideoSection from "../VueComponents/ClubVideo/LearningVideoSection.vue";
import QuizPopup from "../VueComponents/Quiz/QuizPopup.vue";
import QuizListing from "../wwwroot/js/Common/QuizListing.js";
import Utility from "../wwwroot/js/Common/Utility";
// import Payments from "../wwwroot/js/pages/Payments.js"
import DailyActivity from "../wwwroot/js/Pages/DailyActivity.js";
import DailyActivityResponse from "../wwwroot/js/Pages/DailyActivityResponse.js";



//autocomplete start
if (document.getElementById("course-search") != null) {
    new Vue({
        el: "#course-search",
        components: {
            AutoComplete
        },
        data: {
            isMobile: false,
        },
        beforeMount: function () {
            let mobileOrWeb = document.getElementById('IsMobile');
            if (mobileOrWeb) {
                if (mobileOrWeb.getAttribute("data-ismobile") == "False")
                    this.isMobile = false;
                else
                    this.isMobile = true;
            }
        },
        render: function (h) {
            return h('div', [h(AutoComplete, {
                props: {
                    isMobile: this.isMobile,
                }
            })]);
        }
    })
}
//autocomplete end

//CourseRecomendation
if (document.getElementById("CourseRecomendationWeb") != null) {
    new Vue({
        el: "#CourseRecomendationWeb",
        components: {
            CourseRecomendation
        },
        render: function (h) {
            return h('div', [h(CourseRecomendation, {

            })]);
        }
    });
}


////demo slot sign up --> start 
//// Mobile view
//if (document.getElementById("btnmobbookfreedemo") != null
//    && !document.getElementById("btnmobbookfreedemo").classList.contains('disabled')) {
//    document.getElementById("btnmobbookfreedemo").addEventListener("click", function (e) {
//        //  if (e.target.innerHTML.trim() === "Book a Free Demo Session") {
//        // alert(6)
//        e.stopPropagation();
//        if (document.getElementById('demo-signup') != null) {
//            // var path=window.location.pathname;

//            //   //var matches = path.match(/(\d+)/);

//            //   var courseid = path.replace(/[\D]/g, '');

//            var body = document.body;
//            body.classList.add("slkno-scroll");
//            new Vue({
//                el: "#demo-signup",
//                components: {
//                    CourseSignup
//                },
//                render: function (h) {
//                    return h('div', [h(CourseSignup, {
//                        props: {
//                            courseId: e.target.getAttribute('data-courseid'),
//                            initialPopup: 'open',
//                            showpopup: true
//                        }
//                    })]);
//                }
//            });
//        }
//    })

//}

////  web view
//if (document.getElementById("btnbookfreedemo") != null) {
//    document.querySelectorAll("#btnbookfreedemo").forEach((btn) => {
//        if (!btn.classList.contains('disabled')) {
//            btn.addEventListener("click", function (e) {
//                e.stopPropagation();
//                if (document.getElementById('demo-signup') != null) {
//                    // var body = document.body;    
//                    // body.classList.toggle('slkno-scroll');   
//                    var body = document.body;
//                    body.classList.add("slkno-scroll");

//                    new Vue({
//                        el: "#demo-signup",
//                        components: {
//                            CourseSignup
//                        },
//                        render: function (h) {
//                            return h('div', [h(CourseSignup, {
//                                props: {
//                                    courseId: e.target.getAttribute('data-courseid'),
//                                    initialPopup: 'open',
//                                    showpopup: true
//                                }
//                            })]);
//                        }
//                    });
//                }

//            })
//        }
//    })

//}

document.querySelectorAll(".bookfreedemoclk").forEach((btn) => {
    if (!btn.classList.contains('disabled')) {
        btn.addEventListener("click", function (e) {
            e.stopPropagation();
            var instantDemoEnabled = e.target.getAttribute('data-instantDemoEnabled');
            if (document.getElementById('demo-signup') != null) {
                var body = document.body;
                body.classList.add("slkno-scroll");

                new Vue({
                    el: "#demo-signup",
                    components: {
                        CourseSignup
                    },
                    render: function (h) {
                        return h('div', [h(CourseSignup, {
                            props: {
                                courseId: e.target.getAttribute('data-courseid'),
                                initialPopup: 'open',
                                showpopup: true,
                                instantDemoEnabled: instantDemoEnabled
                            }
                        })]);
                    }
                });
            }
        })
    }
    else {
        btn.addEventListener("click", function (e) { e.stopPropagation(); });
    }
})

if (document.getElementById('divdemothankyou') != null) {

    new Vue({
        el: "#divdemothankyou",
        components: {
            DemoThankyouWrapper
        },
        render: function (h) {
            return h('div', [h(DemoThankyouWrapper, {
                props: {
                    showpopup: true
                }
            })]);
        }
    });

}


if (document.getElementById('divpaymentgateway') != null) {
    var Ismobile = document.getElementById('hdnIsMobile').getAttribute('data-isMobile');
    if (document.getElementById('hdnIsMobile') != null && Ismobile == "True") {
        document.getElementById('mobileStickyFooter').style.display = "none";
    }
    new Vue({
        el: "#divpaymentgateway",
        components: {
            PaymentGateway
        },
        render: function (h) {
            return h('div', [h(PaymentGateway, {
                props: {

                }
            })]);
        }
    });

}


//Paid booking --> start 
if (document.getElementById("btnpaidbooking") != null) {
    document.querySelectorAll('#btnpaidbooking').forEach((btn) => {
        if (!btn.classList.contains('disabled')) {
            btn.addEventListener("click", function (e) {
                console.log("binded using id ");
                if (document.getElementById('demo-signup') != null) {
                    new Vue({
                        el: "#demo-signup",
                        components: {
                            PaidCourseSignup
                        },
                        render: function (h) {
                            return h('div', [h(PaidCourseSignup, {
                                props: {
                                    courseId: e.target.getAttribute('data-courseid'),
                                    initialPopup: 'open',
                                    showpopup: true,
                                    isoneonone: false
                                }
                            })]);
                        }
                    });
                }

            });
        }
        else {
            btn.addEventListener("click", function (e) { e.stopPropagation() });
        }
    })
}

//Free Immediate course booking --> start 
if (document.getElementById("btnfreebooking") != null) {
    document.getElementById("btnfreebooking").addEventListener("click", function (e) {
        debugger;
        if (document.getElementById('demo-signup') != null) {
            new Vue({
                el: "#demo-signup",
                components: {
                    PaidCourseSignup
                },
                render: function (h) {
                    return h('div', [h(PaidCourseSignup, {
                        props: {
                            courseId: e.target.getAttribute('data-courseid'),
                            initialPopup: 'open',
                            showpopup: true,
                            isoneonone: false,
                            courseduration: e.target.getAttribute('data-CourseDuration'),
                            sessionstartdatetime: e.target.getAttribute('data-ImmSessionStartDateTime'),
                            sessionstartdisplaydate: e.target.getAttribute('data-ImmSessionStartDisplayDate')

                        }
                    })]);
                }
            });
        }

    });
}



// One on One Course Booking

document.querySelectorAll(".oneonone").forEach(item => {
    if (item) {
        item.addEventListener("click", function (e) {
            ;
            new Vue({
                el: "#demo-signup",
                components: {
                    OneOnOneSignup
                },
                render: function (h) {
                    return h('div', [h(OneOnOneSignup, {
                        props: {
                            businessid: e.target.getAttribute('data-businessid'),
                            numberofsession: e.target.getAttribute('data-numberofsession'),
                            coursefees: e.target.getAttribute('data-coursefees'),
                            tutorname: e.target.getAttribute('data-tutorname'),
                            image: e.target.getAttribute('data-image'),
                            initialPopup: 'open',
                            showpopup: true,
                            isoneonone: true
                        }
                    })]);
                }
            });
        })

    }

})


document.querySelectorAll(".btnpaymentclass").forEach(item => {
    if (item) {
        item.addEventListener("click", function (e) {

            console.log("btnpaymentclass clicked");
            new Vue({
                el: "#demo-signup",
                components: {
                    PaidCourseSignup
                },
                render: function (h) {
                    return h('div', [h(PaidCourseSignup, {
                        props: {
                            courseId: e.target.getAttribute('data-courseid'),
                            initialPopup: 'open',
                            showpopup: true,
                            isoneonone: true
                        }
                    })]);
                }
            });
        })

    }

})


//My Demo Classes Course booking 




//



//demo slot sign up --> end

//write-review-start
if (document.getElementById('write-review-div') != null) {

    if (document.querySelector('.write-review-btn')) {
        document.querySelector('.write-review-btn').addEventListener('click', function () {
            let isAuthenticated = false;
            if (document.getElementById("hdnUserPid").value && document.getElementById("hdnUserPid").value != "0")
                isAuthenticated = true;
            console.log("write-review");
            event.preventDefault();
            if (isAuthenticated) {
                document.body.classList.add("slkno-scroll");
                new Vue({
                    el: "#write-review-div",
                    data() {
                        return {
                            courseId: null,
                            courseName: null,
                            courseImageUrl: null,
                            businessId: null,
                            isAuthenticated: false,
                        }
                    },
                    components: {
                        WriteReviewNew
                    },
                    beforeMount: function () {
                        if (document.getElementById('course-details-page')) {
                            let courseDetails = document.getElementById('course-details-page');
                            this.courseId = courseDetails.getAttribute('data-courseId');
                            this.courseName = courseDetails.getAttribute('data-courseName');
                            this.courseImageUrl = courseDetails.getAttribute('data-courseImageUrl');
                            this.businessId = courseDetails.getAttribute('data-businessId');
                        }
                    },
                    render: function (h) {
                        return h('div', [h(WriteReviewNew, {
                            props: {
                                courseDetailsCourseId: this.courseId,
                                courseDetailsCourseName: this.courseName,
                                courseDetailsCourseImageUrl: this.courseImageUrl,
                                courseDetailsBusinessId: this.businessId,
                                isAuthenticated: this.isAuthenticated,
                            }
                        })]);
                    }
                })
            } else {
                document.getElementById("hdnLoginUrlHash").value = "writereview";
                document.getElementById("signInMobile").click()
            }
        })
    }
}

//write review end

if (document.getElementById('showmorereview')) {
    new Vue({
        el: '#showmorereview',
        data() {
            return {
                reviews: [],
                businessId: 0,
                pageNo: null,
                hasMore: true,
                RowsToFetch: 4,
                TotalReviewsLoaded: 0,
                TotalReviewCount: 0,
                courseId: 0,
                c1Id: 0,
                c2Id: 0,
                c3Id: '',
            }
        },
        components: {
            Review
        },
        beforeMount: function () {
            this.populateRequestParamters();
            let pageNumber = document.getElementById('rvwpagenr').value;
            this.pageNo = parseInt(pageNumber);
            if (document.getElementById('showmorereviewbtn')) {
                document.getElementById('showmorereviewbtn').addEventListener('click', () => {
                    if (this.hasMore) {
                        this.fetchReviews();
                    }
                });
            }
        },
        mounted: function () {
            this.TotalReviewsLoaded = this.RowsToFetch;
        },
        methods: {
            populateRequestParamters: function () {
                var pageType = '';
                if (document.getElementById('hdnPageType')) {
                    pageType = document.getElementById('hdnPageType').value;
                }
                if (pageType.toLowerCase() == 'listing') {
                    if (document.getElementById('hdnC1Id')) {
                        this.c1Id = document.getElementById('hdnC1Id').value;
                    }
                    if (document.getElementById('hdnC2Id')) {
                        this.c2Id = document.getElementById('hdnC2Id').value;
                    }
                    if (document.getElementById('hdnC3Id')) {
                        this.c3Id = document.getElementById('hdnC3Id').value;
                    }
                }
                else {
                    if (document.getElementById('hdnBusinessId')) {
                        this.businessId = document.getElementById('hdnBusinessId').value;
                    }
                    if (document.getElementById('hdnCourseId')) {
                        this.courseId = document.getElementById('hdnCourseId').value;
                    }
                }
            },
            fetchReviews: function () {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        BusinessId: this.businessId,
                        CourseID: this.courseId,
                        C1ID: this.c1Id,
                        C2ID: this.c2Id,
                        C3ID: this.c3Id,
                        PageNr: this.pageNo + 1,
                        Rowstofetch: this.RowsToFetch,
                    })
                }
                Loader.showAboveModal();
                fetch('/api/reviews/get-review-list', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        console.log("review response", data);
                        this.pageNo = data.PageNr;
                        data.reviews.forEach((review, index) => {
                            this.reviews.push(review);
                        })
                        this.TotalReviewCount = this.reviews[0].TotalReviewCount;
                        this.TotalReviewsLoaded += data.reviews.length;
                        Loader.hideAboveModal();
                    });
            },
        },
        updated: function () {
            if (this.TotalReviewsLoaded >= this.TotalReviewCount) {
                document.getElementById("showmorereviewbtn").style.display = "none";
            }
        },
        render: function (h) {
            return h('div', [h(Review, {
                props: {
                    reviews: this.reviews,
                }
            })]);
        }
    })
}


if (document.getElementById('divcoursesession') != null) {

    new Vue({
        el: "#divcoursesession",
        components: {
            MyClassContainer
        },
        render: function (h) {
            return h('div', [h(MyClassContainer, {
                props: {

                }
            })]);
        }
    });

}



//My Course Page end --> end

//My Account Page Start

if (document.getElementById('divmyaccountdetails') != null) {

    new Vue({
        el: "#divmyaccountdetails",
        components: {
            MyAccountDetails
        },
        render: function (h) {
            return h('div', [h(MyAccountDetails, {
                props: {

                }
            })]);
        }
    });

}

//My Account Page End
//My Course Page end --> end

//CourseListing Filter
//if (document.getElementById("CourseFilter") != null && document.querySelector("#filterCard .course-card") != null) {
//    new Vue({
//        el: "#CourseFilter",
//        components: {
//            CourseFilter
//        },
//        render: function (h) {
//            return h('div', [h(CourseFilter, {

//            })]);
//        }
//    });
//}
//if (document.getElementById("CourseFilterMobile") != null && document.querySelector("#filterCard .course-card") != null) {
//    new Vue({
//        el: "#CourseFilterMobile",
//        components: {
//            CourseFilter
//        },
//        render: function (h) {
//            return h('div', [h(CourseFilter, {

//            })]);
//        }
//    });
//}
//CourseListing Filter

//Instructor Card
if (document.getElementById("InstructorCard") != null) {
    new Vue({
        el: "#InstructorCard",
        components: {
            InstructorCard
        },
        render: function (h) {
            return h('div', [h(InstructorCard, {

            })]);
        }
    });
}
//Instructor Card


//SignIn

if (document.getElementById("signInWeb") != null) {

    document.querySelectorAll("#signInWeb").forEach((item) => {
        item.addEventListener("click", function (e) {
            if (document.getElementById("SignInDiv") != null) {
                document.body.classList.add("overflow-hidden");
                new Vue({
                    el: "#SignInDiv",
                    components: {
                        SignInModal
                    },
                    render: function (h) {
                        return h('div', [h(SignInModal, {
    
                        })]);
                    }
                });
            }
        });
    })
    
    // document.getElementById("signInWeb").addEventListener("click", function (e) {
    //     if (document.getElementById("SignInDiv") != null) {
    //         document.body.classList.add("overflow-hidden");
    //         new Vue({
    //             el: "#SignInDiv",
    //             components: {
    //                 SignInModal
    //             },
    //             render: function (h) {
    //                 return h('div', [h(SignInModal, {

    //                 })]);
    //             }
    //         });
    //     }
    // });
}

if (document.getElementById("signInMobile") != null) {
    document.getElementById("signInMobile").addEventListener("click", function (e) {
        if (document.getElementById("SignInDiv") != null) {
            document.body.classList.add("overflow-hidden");
            document.body.classList.remove('mobile-menu-open');
            new Vue({
                el: "#SignInDiv",
                components: {
                    SignInModal
                },
                render: function (h) {
                    return h('div', [h(SignInModal, {

                    })]);
                }
            });
        }
    });
}
//SignIn


//SignUp
if (document.getElementById("signUpWeb") != null) {
    document.getElementById("signUpWeb").addEventListener("click", function (e) {
        if (document.getElementById("SignUpDiv") != null) {
            document.body.classList.add("overflow-hidden");
            new Vue({
                el: "#SignUpDiv",
                components: {
                    SignUpModal
                },
                render: function (h) {
                    return h('div', [h(SignUpModal, {

                    })]);
                }
            });
        }
    });
}
if (document.getElementById("signUpMobile") != null) {
    document.getElementById("signUpMobile").addEventListener("click", function (e) {
        if (document.getElementById("SignUpDiv") != null) {
            document.body.classList.add("overflow-hidden");
            document.body.classList.remove('mobile-menu-open');
            new Vue({
                el: "#SignUpDiv",
                components: {
                    SignUpModal
                },
                render: function (h) {
                    return h('div', [h(SignUpModal, {

                    })]);
                }
            });
        }
    });
}
//SignUp

//LCF
if (document.getElementById("LCFFirstLevel") != null && document.getElementById("hdnC1Id") != null &&
    (document.getElementById("hdnC1Id").value == "4" || document.getElementById("hdnC1Id").value == "6" || document.getElementById("hdnC1Id").value == "11")) {
    new Vue({
        el: "#LCFFirstLevel",
        components: {
            LCFFirstLevel
        },
        render: function (h) {
            return h('div', [h(LCFFirstLevel, {

            })]);
        }
    });
}


//if (document.getElementById("CourseRecomendationMob") != null) {
//    new Vue({
//        el: "#CourseRecomendationMob",
//        components: {
//            CourseRecomendation
//        },
//        render: function (h) {
//            return h('div', [h(CourseRecomendation, {

//            })]);
//        }
//    });
//}
//CourseRecomendation

//LCFEditPreference
if (document.getElementById("LCFEditPreference") != null) {
    document.getElementById("EditPreferenceBtn").addEventListener("click", function (e) {
        new Vue({
            el: "#LCFEditPreference",
            components: {
                LCFEditPreference
            },
            render: function (h) {
                return h('div', [h(LCFEditPreference, {

                })]);
            }
        });
    })
}


//if (document.getElementById("LCFEditMobPreference") != null) {
//    document.getElementById("EditPreferenceMobBtn").addEventListener("click", function (e) {
//        new Vue({
//            el: "#LCFEditMobPreference",
//            components: {
//                LCFEditPreference
//            },
//            render: function (h) {
//                return h('div', [h(LCFEditPreference, {

//                })]);
//            }
//        });
//    })
//}
if (document.getElementById("onboarding-div")) {
    if (document.querySelector("[data-button='onboarding-getstarted']")) {
        document.querySelectorAll("[data-button='onboarding-getstarted']").forEach((button) => {
            button.addEventListener('click', () => {
                new Vue({
                    el: "#onboarding-div",
                    components: {
                        OnboardingDetails
                    },
                    render: function (h) {
                        return h(OnboardingDetails, {
                        });
                    }
                })
            })
        })
    }
}

let bindGA = {
    init: function () {
        SulGA.init();
        SulGA.pushPageView();

        // GA Event tracking
        var sulGaTrackingElements = document.querySelectorAll("[sulga]");
        sulGaTrackingElements.forEach((clickitem) => {
            clickitem.addEventListener('click', this.onClickGATrack.bind(this));
        });
    },
    onClickGATrack: function (evt) {
        var clickevtdata = evt.currentTarget.getAttribute('sulga');
        var gaEvtParams = clickevtdata.split('^');
        if (gaEvtParams && gaEvtParams.length > 0) {
            SulGA.pushEvent(gaEvtParams[0], gaEvtParams[1], gaEvtParams[2]);
        }
    }
}
bindGA.init();


if (document.getElementById('showmorelist')) {
    new Vue({
        el: '#showmorelist',
        data() {
            return {
                courseCards: [],
                businessId: 0,
                pageNo: null,
                hasMore: true,
                RowsToFetch: 10,
                TotalListingLoaded: 0,
                TotalListingCount: 0,
                courseId: 0,
                c1Id: 0,
                c2Id: 0,
                c3Id: '',
                ListingType: 1,
                ListingTypeParam: '',
                HasGroupBy: false,
                GroupByName: '',
                filterClick: 0,
                userId: '',
            }
        },
        components: {
            CourseListingVue
        },
        beforeMount: function () {
            this.populateRequestParamters();
            let pageNumber = document.getElementById('listpagenr').value;
            this.pageNo = parseInt(pageNumber);
            if (document.getElementById('showmorelistbtn')) {
                document.getElementById('showmorelistbtn').addEventListener('click', () => {
                    if (this.hasMore) {
                        this.fetchList();
                    }
                });
            }
            if (document.querySelector('[data-filterapply]')) {
                var selecAll = document.querySelectorAll('[data-filterapply]');
                selecAll.forEach((clickitem) => {
                    clickitem.addEventListener('click', () => {
                        document.getElementById('hdnFilterClick').value = 1;
                        if (this.filterClick == 0) {
                            document.getElementById('hdnFilterClick').value = 1;
                            this.filterClick = document.getElementById('hdnFilterClick').value;
                            this.pageNo = 0;
                            this.RowsToFetch = 250;
                            this.fetchList();
                        }
                        else {
                            bindCourseListing.init();
                        }
                    });
                });
            }
            if (document.querySelector('[data-mobilefilter]')) {
                document.querySelector('[data-mobilefilter]').addEventListener('click', () => {
                    document.getElementById('hdnFilterClick').value = 1;
                    if (this.filterClick == 0) {
                        document.getElementById('hdnFilterClick').value = 1;
                        this.filterClick = document.getElementById('hdnFilterClick').value;
                        this.pageNo = 0;
                        this.RowsToFetch = 250;
                        this.fetchList();
                    }
                    else {
                        bindCourseListing.init();
                    }
                    if (document.getElementById('FilterMobile') != null) {
                        setTimeout(function () {
                            document.querySelector('#FilterMobile #popup').classList.add('open-filter');
                        }, 500);
                        document.getElementsByTagName("body")[0].classList.add('slkno-scroll');
                    }
                });
            }
        },
        mounted: function () {
        },
        methods: {
            populateRequestParamters: function () {
                var pageType = '';
                if (document.getElementById('hdnPageType')) {
                    pageType = document.getElementById('hdnPageType').value;
                }
                if (pageType.toLowerCase() == 'listing') {
                    if (document.getElementById('hdnC1Id')) {
                        this.c1Id = document.getElementById('hdnC1Id').value;
                    }
                    if (document.getElementById('hdnC2Id')) {
                        this.c2Id = document.getElementById('hdnC2Id').value;
                    }
                    if (document.getElementById('hdnC3Id')) {
                        this.c3Id = document.getElementById('hdnC3Id').value;
                    }
                    if (document.getElementById('hdnFilterClick')) {
                        this.filterClick = document.getElementById('hdnFilterClick').value;
                    }
                }
                else {
                    if (document.getElementById('hdnBusinessId')) {
                        this.businessId = document.getElementById('hdnBusinessId').value;
                    }
                    if (document.getElementById('hdnCourseId')) {
                        this.courseId = document.getElementById('hdnCourseId').value;
                    }
                }
                if (document.getElementById('requestListingType')) {
                    this.ListingType = document.getElementById('requestListingType').value;
                    //console.log("ListingType", this.ListingType);
                }
                if (document.getElementById('requestListingTypeParam')) {
                    this.ListingTypeParam = document.getElementById('requestListingTypeParam').value;
                }
                if (document.getElementById('requestHasGroupBy')) {
                    if (document.getElementById('requestHasGroupBy').value) {
                        var val = document.getElementById('requestHasGroupBy').value == 1 ? true : false;
                        this.HasGroupBy = val;
                    }
                    //console.log("HasGroupBy", this.HasGroupBy);
                }
                if (document.getElementById('requestGroupByName')) {
                    this.GroupByName = document.getElementById('requestGroupByName').value;
                }
                if (document.getElementById('hdnUserPid')) {
                    this.userId = document.getElementById('hdnUserPid').value;
                }
            },
            fetchList: function () {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        BusinessId: this.businessId,
                        CourseID: this.courseId,
                        C1ID: this.c1Id,
                        C2ID: this.c2Id,
                        C3ID: this.c3Id,
                        pageno: this.pageNo + 1,
                        Rowstofetch: this.RowsToFetch,
                        isCourseListing: true,
                        ListingType: parseInt(this.ListingType),
                        ListingTypeParam: this.ListingTypeParam,
                        HasGroupBy: this.HasGroupBy,
                        GroupByName: this.GroupByName,
                        UserId: this.userId,
                    })
                }
                Loader.showAboveModal();
                fetch('/api/courselisting/get-course-listing-list', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if (this.filterClick == 1) {
                            document.getElementById('filterCard').innerHTML = '';
                            document.getElementById("showmorelistbtn").style.display = "none";
                        }
                        this.pageNo += 1;
                        //console.log("course listing response", data);
                        if (data.length > 0) {
                            data.forEach((cards, index) => {
                                this.courseCards.push(cards);
                            });
                        }
                        if (data.length < 10) { document.getElementById("showmorelistbtn").style.display = "none"; }
                        Loader.hideAboveModal();
                    });
            },
        },
        updated: function () {
            if (document.getElementsByClassName('filterCardShowMore')) {
                let demoBtns = document.querySelectorAll("[data-bookdemobtn][data-hasnoclickevent='']");
                if (demoBtns) {
                    demoBtns.forEach((demoBtn) => {
                        demoBtn.setAttribute("data-hasnoclickevent", "true");
                    })
                };
                var filterCard = document.querySelectorAll('.filterCardShowMore');
                filterCard.forEach((card, index) => {
                    if (card.innerHTML != '' && card.innerHTML != null)
                        document.getElementById('filterCard').innerHTML += card.innerHTML;
                });
                document.querySelectorAll('.filterCardShowMore').forEach(e => e.innerHTML = '');
                CourseListing.init();
            }
            if (this.filterClick == 1) {
                bindCourseListing.init();
            }
            ShortList.initShortlist();
            //console.log("updated");
        },
        render: function (h) {
            //console.log("render");
            return h(CourseListingVue, {
                props: {
                    courseCards: this.courseCards
                }
            });
        }
    })
}

if (document.querySelectorAll(".openClubForm")) {
    document.querySelectorAll(".openClubForm").forEach((item) => {
        item.addEventListener('click', () => {
            document.body.classList.add("overflow-hidden");
            document.body.classList.remove('mobile-menu-open');
            new Vue({
                el: "#club-registration-div",
                components: {
                    ClubRegistration
                },
                render: function (h) {
                    return h('div', [h(ClubRegistration, {

                    })]);
                }
            });
        })
    })
}

//if (document.getElementById("addClubSchedule-base-div")) {
//    let addScheduleBtn = document.getElementById("addClubScheduleOpenForm");
//    if (addScheduleBtn) {
//        addScheduleBtn.addEventListener('click', () => {
//            document.body.classList.add("overflow-hidden");
//            new Vue({
//                el: "#addClubSchedule-base-div",
//                components: {
//                    AddNewClubSchedule
//                },
//                render: function (h) {
//                    return h(AddNewClubSchedule, {});
//                }
//            })
//        })
//    }
//}

if (document.getElementById("manageclubschedule-base-div")) {
debugger;
    new Vue({
        el: "#manageclubschedule-div",
        components: {
            ManageClubSchedule,
        },
        render: function (h) {
            return h(ManageClubSchedule, {});
        }
    })
}

if (document.querySelector("[data-id='club-video-thumb']")) {
    document.querySelectorAll("[data-id='club-video-thumb']").forEach((videoThumb) => {
        videoThumb.addEventListener('click', (event) => {
            
            let bucket = event.currentTarget.getAttribute("data-bucket");
            let videoURL = event.currentTarget.getAttribute("data-videoURL");
            
            let videoDetailsString = document.getElementById("club-videos-data-json").innerText;
            console.log(JSON.parse(videoDetailsString));
            let videoDetails = JSON.parse(videoDetailsString);
            let relatedVideoDetails = [];
            for(let i=0; i<videoDetails.length; i++) {
                if(videoDetails[i].bucket == bucket) {
                    relatedVideoDetails = videoDetails[i].clubVideos;
                }
            }
            console.log("related videos structure", relatedVideoDetails);
            // videoDetails.forEach((item) => {
            //     if(item[0].Bucket == bucket) {
            //         relatedVideoDetails = item;
            //     }
            // })
            document.body.classList.add("overflow-hidden");
                new Vue({
                    el: "#video-player-popup-div",
                    components: {
                        VideoPlayerPop,
                    },
                    render: function (h) {
                        return h(VideoPlayerPop, {
                            props: {
                                relatedVideoDetailsProp: relatedVideoDetails,
                                category: bucket,
                                mainVideoURL: videoURL,
                            }
                        });
                    }
                })
        })
    })
}

if(document.getElementById("learning-videos-base-div")) {
    console.log("vue component loaded");
    let bucket = document.querySelector("[data-base-bucket]").getAttribute("data-base-bucket");
    new Vue({
        el: "#learning-videos-div",
        components: {
            LearningVideoSection :LearningVideoSection,
        },
        render: function (h) {
            return h(LearningVideoSection, {
                props: {
                    bucket : bucket,
                }
            });
        }
    })
}

// if(document.getElementById("quiz-base-div")) {
//     document.body.classList.add("overflow-hidden");
//     new Vue({
//         el: "#quiz-div",
//         components: {
//             QuizPopup: QuizPopup,
//         },
//         render: function (h) {
//             return h(QuizPopup, {
               
//             });
//         }
//     })
// }


let bindCourseListing = {
    init: function () {
        CourseListing.init();
        CourseListing.filterClicked();
    }
}

if (document.getElementById('hdnPageType') != null && document.getElementById('hdnPageType').value == "CourseDetail") {
    let pageTypeCookie = Utility.getCookie('strSEOTrack');
    let showBackAndSearchIcon = true;
   
    if(pageTypeCookie && pageTypeCookie.toLowerCase() == 'sem') {
        showBackAndSearchIcon = false;
    }

    var Ismobile = document.getElementById('hdnIsMobile').getAttribute('data-isMobile');
    if (document.getElementById('hdnIsMobile') != null && Ismobile == "True") {
        document.getElementById('mobileStickyFooter').style.display = "none";
        document.getElementById('mobileHeaderBack').style.display = "block";
        document.getElementById('mobileHeaderBack').addEventListener('click', () => {
            if (document.getElementById('hdnC3Url')) {
                window.location = document.getElementById('hdnC3Url').value;
            }
        });
    }

    if(!showBackAndSearchIcon && Ismobile) {
        document.getElementById('mobileHeaderBack').style.display = "none";
        let a = document.getElementById('mobile-header-search');
       
        document.getElementById('mobile-header-search').style.display = "none";
    }
}
if( document.querySelector("#mobileStickyFooter")) {
    if (document.getElementById('hdnPageType') != null) {
        var elem = document.querySelectorAll("#mobileStickyFooter ul li");
        Array.from(elem).forEach(el => el.classList.remove("active"));
        if (document.getElementById('hdnPageType').value == "Home") {
            document.getElementById('discoverStickyli').classList.add("active");
        }
        // else if (document.getElementById('hdnPageType').value == "CambridgeEnglishTestJourney") {
        //     document.getElementById('journeyStickyli').classList.add("active");
        // }
        else if (document.getElementById('hdnPageType').value == "Listing") {
            document.getElementById('coursesStickyli').classList.add("active");
        }
        else if (document.getElementById('hdnPageType').value == "MyClass") {
            document.getElementById('coursesStickyli').classList.add("active");
        }
        else if (document.getElementById('hdnPageType').value == "MyClub") {
            document.getElementById('shortlistStickyli').classList.add("active");
        }
        else if (document.getElementById('hdnPageType').value == "LearningVideos") {
            document.getElementById('videosStickyli').classList.add("active");
        }
    }
}



var CambridgeEnglishTest = {
    init: function () {
        if (document.getElementById('hdnPageType').value == "CambridgeEnglishTestJourney") {
            document.querySelector(".CambridgeEnglishTestButton").addEventListener("click", function () {
                if (document.getElementById("hdnUserPid").value != "0")
                    window.location.href = "/CambridgeEnglishTest";
                else {
                    document.getElementById("hdnLoginNextUrl").value = "/CambridgeEnglishTest";
                    document.getElementById("signInMobile").click();
                }
            });
        }
    }
};
CambridgeEnglishTest.init();

if (document.getElementById("testModal")) {
    var pageType = '';
    if (document.getElementById('hdnPageType')) {
        pageType = document.getElementById('hdnPageType').value;
    }
    if (pageType.toLowerCase() == 'listing' || pageType.toLowerCase() == 'home') {
        document.getElementById('testModal').classList.add('show');
        // Set a timeout to hide the element again
        setTimeout(function () { document.getElementById('testModal').classList.remove('show'); }, 10000);
        if (document.querySelector("#testModal .close"))
            document.querySelector("#testModal .close").addEventListener("click", function () { document.getElementById('testModal').classList.remove('show'); });
        if (document.getElementsByClassName('goTestButton')) {
            if (pageType.toLowerCase() == 'listing')
                document.querySelector(".goTestButton").style.marginTop = "20px";
            document.querySelector(".goTestButton").addEventListener("click", function () {
                if (document.getElementById("hdnUserPid").value != "0")
                    window.location.href = "/CambridgeEnglishTest";
                else {
                    document.getElementById("hdnLoginNextUrl").value = "/CambridgeEnglishTest";
                    document.getElementById('testModal').classList.remove('show');
                    document.getElementById("signInMobile").click();
                }
            });
        }
    }
}

if (document.getElementById('hdnPageType') != null && (document.getElementById('hdnPageType').value == "MyClub" || document.getElementById('hdnPageType').value == "Home"))
    MyClub.init();
    

function ready(callback) {
    // in case the document is already rendered
    if (document.readyState != 'loading') callback();
    // modern browsers
    else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
    // IE <= 8
    else document.attachEvent('onreadystatechange', function () {
        if (document.readyState == 'complete') callback();
    });
};

function showSignInPopup() {
    setTimeout(function () {
        if (document.getElementById("hdnIsAuthenticated").getAttribute("data-isAuthenticated") == "False") {
            document.getElementById("signInMobile").click();
            document.querySelector("#signin-base-div .close").style.display = 'none';
            document.querySelector("#signin-base-div .signinbk").style.display = 'none';
        }
    }, 200);
}
if(document.getElementById('hdnPageType'))
{
    if (document.getElementById('hdnPageType').value == "DailyActivityResponse")
    {
        DailyActivityResponse.init();
    }
    if (document.getElementById('hdnPageType').value == "DailyActivity" ||
        document.getElementById('hdnPageType').value == "Home")
    {
        DailyActivity.init();
    }
}
<template>
    <div>
        <div class="modal-body filter-container shadow-sm p-4">
            <!--mobile px-3 pb-5-->
            <div id="Filter">
                <svg class="d-none">
                    <defs>
                        <g id="starIcon">
                            <path d="M48.856,22.731c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
	                    c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
	                    L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.623C1.71,16.82,0.603,17.753,0.178,19.06
	                    c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.702
	                    c-0.232,1.353,0.313,2.694,1.424,3.502c1.11,0.809,2.555,0.914,3.772,0.273l10.814-5.686c0.461-0.242,1.011-0.242,1.472,0
	                    l10.815,5.686c0.528,0.278,1.1,0.415,1.669,0.415c0.739,0,1.475-0.231,2.103-0.688c1.111-0.808,1.656-2.149,1.424-3.502
	                    L39.651,32.66c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.731z M37.681,32.998l2.065,12.042c0.104,0.606-0.131,1.185-0.629,1.547
	                    c-0.499,0.361-1.12,0.405-1.665,0.121l-10.815-5.687c-0.521-0.273-1.095-0.411-1.667-0.411s-1.145,0.138-1.667,0.412l-10.813,5.686
	                    c-0.547,0.284-1.168,0.24-1.666-0.121c-0.498-0.362-0.732-0.94-0.629-1.547l2.065-12.042c0.199-1.162-0.186-2.348-1.03-3.17
	                    L2.48,21.299c-0.441-0.43-0.591-1.036-0.4-1.621c0.19-0.586,0.667-0.988,1.276-1.077l12.091-1.757
	                    c1.167-0.169,2.176-0.901,2.697-1.959l5.407-10.957c0.272-0.552,0.803-0.881,1.418-0.881c0.616,0,1.146,0.329,1.419,0.881
	                    l5.407,10.957c0.521,1.058,1.529,1.79,2.696,1.959l12.092,1.757c0.609,0.089,1.086,0.491,1.276,1.077
	                    c0.19,0.585,0.041,1.191-0.4,1.621l-8.749,8.528C37.866,30.65,37.481,31.835,37.681,32.998z" />
                        </g>
                    </defs>
                </svg>
                <div class="d-flex justify-content-between">
                    <h5 class="body-lg fw-500 text-dark mb-4 mb-lg-0">Apply Filters</h5>
                <!--<i id="closeIcon" class="far fa-times" ></i>-->
                <span v-on:click="closeFilter('icon')" class="fw-600 h4 d-lg-none">&times;</span>
                </div>
                <form action="/" method="POST" class="container px-0">
                    <!-- frequency block start -->
                    <p class="my-2 caption" v-if="this.courseCards.Classification.length > 0 && (this.courseCards.groupByName == 'c2' || this.courseCards.groupByName == 'c3')">Classification</p>
                    <div id="GroupByLable" v-if="this.courseCards.groupByName == 'c2'" class="d-flex border-bottom pb-3 pt-2 flex-wrap">
                        <div class="filter-pill-wrapper" v-for="(value, key, index) in this.GroupByNamesc2" :id="'cls' + RemoveSpace(key)" v-on:click="GroupByCLick(key)">
                            <template>
                                <label class="btn-filter-pill caption text-muted mr-2">
                                    {{key}}
                                    <!--{{getTempValue(value)}}-->
                                    <input :id="RemoveSpace(key)" :value="key" :name="key" type="checkbox"
                                           class="form-control w-20 mr-2" />
                                </label>
                                <template v-for="c3Value in Object.keys(value)">
                                    <div class="checkboxselect">
                                        <input :id="RemoveSpace(c3Value)" type="checkbox" class="checkbox-primary" v-on:click="filterClicked()">
                                        <label :for="RemoveSpace(c3Value)" class="caption">{{c3Value}}</label>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                    <div id="GroupByLable" v-else-if="this.courseCards.groupByName == 'c3'" class="d-flex border-bottom pb-3 pt-2 flex-wrap">
                        <template v-for="(value, key, index) in this.GroupByNamesc3" :id="'cls' + RemoveSpace(key)">
                            <label class="checkboxselect btn-filter-pill caption text-muted mr-2">
                                {{key}}
                                <input :id="RemoveSpace(key)" :value="key" :name="key" type="checkbox" v-on:click="filterClicked()"
                                       class="form-control w-20 mr-2" />
                            </label>
                        </template>
                    </div>
                    <!-- frequency block end -->
                    <!-- Price block start -->
                    <p class="my-2 caption" v-if="this.courseCards.Ranges.length > 0">Course Fees (in ₹)</p>
                    <div class="d-flex border-bottom pb-3 pt-2 flex-wrap" id="range" v-if="this.courseCards.Ranges.length > 0">
                        <template class="temp" v-for="(range,idx) in this.courseCards.Ranges">
                            <label class="checkboxselect btn-filter-pill caption text-muted mr-2">
                                {{range}}
                                <input :id="RangeID(range)" :value="range" v-on:click="filterClicked()"
                                       name="prices[]"
                                       type="checkbox"
                                       class="form-control w-20 mr-2" />
                            </label>
                        </template>
                    </div>
                    <!-- Price block end -->
                    <!-- Class strength block start -->
                    <p class="my-2 caption" v-if="this.courseCards.Languages.length > 0">Medium of Instruction</p>
                    <div class="d-flex border-bottom pb-3 pt-2 flex-wrap" v-if="this.courseCards.Languages.length > 0" id="languageFtr">
                        <template class="temp" v-for="(language,idx) in this.courseCards.Languages">
                            <label class="checkboxselect btn-filter-pill caption text-muted mr-2">
                                {{language}}
                                <input :id="RemoveSpace(language)" :value="language" v-on:click="filterClicked()"
                                       name="class-strengths[]"
                                       type="checkbox"
                                       class="form-control w-20 mr-2" />
                            </label>
                        </template>
                    </div>
                    <!-- Class strength block end -->
                    <!-- Course ratings block start -->
                    <p class="my-2 caption" v-if="this.courseCards.Ratings.length > 0">Course Ratings</p>
                    <div class="d-flex pb-3 pt-2 flex-wrap" v-if="this.courseCards.Ratings.length > 0" id="ratings">
                        <template class="temp" v-for="(rating,idx) in this.courseCards.Ratings">
                            <label class="checkboxselect btn-filter-pill caption text-muted mr-2">
                                {{ratingSplit(rating)}}
                                <svg class="svg-icon medium star-icon" viewBox="0 0 49.94 49.94">
                                    <use xlink:href="#starIcon" />
                                </svg>
                                <!--<i class="fal fa-star body3"></i>-->
                                <input :id="RemoveSpace(rating)" :value="rating" v-on:click="filterClicked()"
                                       name="course-ratingss[]"
                                       type="checkbox"
                                       class="form-control w-20 mr-2" />
                            </label>
                        </template>
                    </div>
                    <!-- add click action here to reset filter -->
                    <!--<div id="webFooter" class="bottom-nav d-flex align-items-center">
        <div class="d-flex caption text-info" v-on:click="clearClicked">
        Clear all filters
        </div>
        </div>-->
                </form>
                <a id="webFooter" v-on:click="clearClicked" class="text-info caption">Clear all filters</a>
            </div>
        </div>
        <div id="mobileFooter" class="w-100 sticky-footer px-0 mx-0">
            <div id="NoRecordsDiv" class="d-none">
                <div class="bottom-nav d-flex align-items-center py-3">
                    <p id="NoRecords" class="d-flex caption text-danger"></p>
                </div>
            </div>
            <div class="bottom-nav d-flex align-items-center d-lg-none py-3">
                <div class="d-flex caption text-info" v-on:click="clearClicked">
                    Clear all filters
                </div>
                <button type="button" id="ApplyBtn" class="btn btn-primary active"></button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CourseFilter",
        data() {
            return {
                courseCards: [],
                GroupByNamesc2: [],
                GroupByNamesc3: [],
                tempvalue: [],
                DeviceType: null,
            }
        },
        beforeMount: function () {
            this.courseCards = JSON.parse(document.querySelectorAll('#FilterData')[0].innerText);
            if (this.courseCards.groupByName == "c2") {
                this.GroupByNamesc2 = this.courseCards.Classification.reduce((c2, c2classification) => {
                    if (!c2[c2classification.C2Name]) {
                        c2[c2classification.C2Name] = [];
                    }
                    c2[c2classification.C2Name].push(c2classification);
                    return c2;
                }, {});
                let tempArr = this.GroupByNamesc2;
                Object.keys(tempArr).forEach((ele, index) => {
                    let uniqueArr = [];
                    this.GroupByNamesc2[ele] = this.GroupByNamesc2[ele].reduce((c3, c3classification) => {
                        if (!c3[c3classification.C3Name]) {
                            c3[c3classification.C3Name] = [];
                        }
                        c3[c3classification.C3Name].push(c3classification);
                        return c3;
                    }, {});
                });
            }
            else if (this.courseCards.groupByName == "c3") {
                this.GroupByNamesc3 = this.courseCards.Classification.reduce((c3, c3classification) => {
                    if (!c3[c3classification.C3Name]) {
                        c3[c3classification.C3Name] = [];
                    }
                    c3[c3classification.C3Name].push(c3classification);
                    return c3;
                }, {});
            }
            this.IsMobile = document.getElementById('IsMobile').getAttribute('data-IsMobile');
        },
        mounted: function () {
            if (this.IsMobile == "True") {
                document.querySelectorAll("#webFooter")[0].classList.remove('d-flex');
                document.querySelectorAll("#webFooter")[0].style.display = 'none';
                var CourseCards = document.querySelectorAll('#filterCard .col-sm-6');
                document.querySelector('#ApplyBtn').innerText = "See " + CourseCards.length + " courses";
                document.querySelector('#ApplyBtn').addEventListener('click', this.closeFilter);
            }
            else {
                document.querySelectorAll("#mobileFooter")[0].style.display = "none";
                //document.querySelectorAll("#closeIcon")[0].style.display = "none";
            }
        },
        computed: {

        },
        watch: {

        },
        methods: {
            getTempValue: function (key) {
                this.tempvalue = Object.keys(key);
            },
            RemoveSpace: function (data) {
                if (data != null)
                    return data.split(" ").join("");
            },
            ratingSplit: function (rating) {
                return rating.split("ratings")[1];
            },
            RangeID: function (range) {
                if (range != null && !range.includes('<')) {
                    return "range" + range.split(" ").join("");
                }
                else if (range != null && range.includes('<')) {
                    return "range" + range.replace('<', '-').split(" ").join("");
                }
            },
            filterClicked: function () {
                this.addClass();
                var checked = document.querySelectorAll('#Filter .checkboxselect input[type="checkbox"]:checked');
                if (checked.length > 0) {
                    var allCourseCards = document.querySelectorAll('#filterCard .col-sm-6');
                    allCourseCards.forEach((card, index) => {
                        card.style.display = "none";
                    });
                    var filters = ["GroupByLable", "languageFtr", "ratings", "range"];
                    var isFiltered = false;
                    for (var i = 0; i < filters.length; i++) {
                        var currentFilter = "";
                        var checkedele = document.querySelectorAll('#Filter ' + '#' + filters[i] + ' .checkboxselect input[type="checkbox"]:checked');
                        var filteredData = document.querySelectorAll('#filterCard .col-sm-6[style*="block"]');
                        if (checkedele.length > 0 && filteredData.length > 0) {
                            filteredData.forEach((filteredcard, index) => {
                                filteredcard.style.display = "none";
                            });
                        }
                        for (var j = 0; j < checkedele.length; j++) {
                            var name = checkedele[j].getAttribute('id').trim().toLowerCase();
                            if (filteredData.length > 0 && currentFilter != filters[i]) {
                                for (var k = 0; k < filteredData.length; k++) {
                                    isFiltered = true;
                                    var dataValue = this.RemoveSpace(filteredData[k].firstElementChild.getAttribute('data-' + filters[i]));
                                    if (filters[i] == 'languageFtr') {
                                        var datavalues = dataValue.split(',');
                                        if (datavalues.length > 1) {
                                            datavalues.forEach((data, index) => {
                                                if ((checkedele[j].getAttribute('id').trim().includes(data))) {
                                                    filteredData[k].style.display = "block";
                                                }
                                            })
                                        }
                                        else if ((checkedele[j].getAttribute('id').trim().includes(dataValue))) {
                                            filteredData[k].style.display = "block";
                                        }
                                    }
                                    else {
                                        if ((checkedele[j].getAttribute('id').trim().includes(dataValue))) {
                                            filteredData[k].style.display = "block";
                                        }
                                    }
                                }
                            }
                            else if (!isFiltered) {
                                currentFilter = filters[i];
                                var CourseCardData = document.querySelectorAll('#filterCard .col-sm-6');
                                for (var k = 0; k < CourseCardData.length; k++) {
                                    if (CourseCardData[k].firstElementChild.className.includes(name)) {
                                        CourseCardData[k].style.display = "block";
                                    }
                                }
                            }
                        }
                    }
                }
                else {
                    var allCourseCards = document.querySelectorAll('#filterCard .col-sm-6');
                    allCourseCards.forEach((card, index) => {
                        card.style.display = "block";
                    });
                }
                var filteredCards = document.querySelectorAll('#filterCard .col-sm-6[style*="block"]');
                if (filteredCards.length == 0) {
                    document.getElementById('NoRecordsDiv').classList.remove('d-none');
                    document.querySelector('#NoRecords').innerText = "There are no courses available for the set filters";
                    document.querySelector('#ApplyBtn').innerText = "See " + filteredCards.length + " courses";
                    document.querySelector('#ApplyBtn').classList.remove('active');
                    document.querySelector('#ApplyBtn').removeEventListener('click', this.closeFilter);
                    if (document.getElementById("NoResults").className.includes('d-none')) {
                        document.getElementById("NoResults").classList.remove('d-none');
                    }
                }
                else if (filteredCards.length > 0) {
                    document.getElementById('NoRecordsDiv').classList.add('d-none');
                    document.querySelector('#ApplyBtn').innerText = "See " + filteredCards.length + " courses";
                    document.querySelector('#NoRecords').innerText = "";
                    if (!document.querySelector('#ApplyBtn').className.includes('active')) {
                        document.querySelector('#ApplyBtn').classList.add('active');
                    }
                    document.querySelector('#ApplyBtn').addEventListener('click', this.closeFilter);
                    if (!document.getElementById("NoResults").className.includes('d-none')) {
                        document.getElementById("NoResults").classList.add('d-none');
                    }
                }
            },
            closeFilter: function (trigger) {
                if (document.getElementById('FilterMobile').className == "d-lg-none") {
                    document.getElementById('FilterMobile').className = "d-none";
                }
                setTimeout(function () {
                    document.querySelector('#FilterMobile #popup').classList.remove('open-filter');
                }, 500);
                if (trigger == 'icon') {
                    this.clearClicked();
                }
                document.getElementsByTagName("body")[0].classList.remove('slkno-scroll');
            },
            addClass: function () {
                var checkBox = document.querySelectorAll('#Filter .checkboxselect input[type="checkbox"]');
                checkBox.forEach((value) => {
                    if (value.checked && !value.parentElement.className.includes('active'))
                        value.parentElement.classList.add('active');
                    else if (!value.checked && value.parentElement.className.includes('active'))
                        value.parentElement.classList.remove('active');
                })
            },
            GroupByCLick: function (key) {
                if (key != null && key != "") {
                    var groupByDiv = document.getElementById('cls' + this.RemoveSpace(key));
                    var checkBox = document.getElementById(this.RemoveSpace(key));
                    if (checkBox.checked && !groupByDiv.className.includes('active')) {
                        groupByDiv.classList.add('active');
                    }
                    else if (!checkBox.checked && groupByDiv.className.includes('active')) {
                        groupByDiv.classList.remove('active');
                    }
                }
            },
            clearClicked: function () {
                var selectedFilters = document.querySelectorAll('#Filter .checkboxselect input[type="checkbox"]:checked');
                selectedFilters.forEach((selectedFilter, index) => {
                    selectedFilter.checked = false;
                });
                this.addClass();
                var selvalues = document.querySelectorAll('.filter-pill-wrapper.active');
                selvalues.forEach((selValue) => {
                    selValue.classList.remove('active');
                })
                var allCourseCards = document.querySelectorAll('#filterCard .col-sm-6');
                allCourseCards.forEach((card, index) => {
                    card.style.display = "block";
                });
                if (this.IsMobile == "True") {
                    var CourseCards = document.querySelectorAll('#filterCard .col-sm-6');
                    document.querySelector('#ApplyBtn').innerText = "See " + CourseCards.length + " courses";
                    document.querySelector('#ApplyBtn').addEventListener('click', this.closeFilter);
                    document.querySelector('#NoRecords').innerText = "";
                    if (!document.querySelector('#ApplyBtn').className.includes('active')) {
                        document.querySelector('#ApplyBtn').classList.add('active');
                    }
                }
                if (!document.getElementById("NoResults").className.includes('d-none')) {
                    document.getElementById("NoResults").classList.add('d-none');
                }
            },
        }
    }

</script>
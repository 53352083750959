var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section my-account mt-0 active show" }, [
    _c("div", {}, [
      _c("div", { staticClass: "page-bg-1" }, [
        _c("div", { staticClass: "d-block top-active-tab" }, [
          _c("ul", { staticClass: "nav nav-tabs" }, [
            _c("li", { staticClass: "nav-item col" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link active",
                  attrs: { "data-toggle": "tab" }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "svg-icon xlarge mr-2 d-sm-none top-1",
                      attrs: { viewBox: "0 0 64 64" },
                      on: {
                        click: function($event) {
                          return _vm.Backtopage()
                        }
                      }
                    },
                    [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
                  ),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.Title) +
                      "\n                        "
                  )
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container d-none d-lg-block" }, [
          _c("div", { staticClass: "user-details mt-3 web row" }, [
            _c("div", {
              staticClass: "col-3 col-lg-1 d-flex align-items-center"
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-9 pl-0 d-flex flex-column justify-content-center"
              },
              [
                _c("p", { staticClass: "user-name" }, [
                  _vm._v(_vm._s(_vm.UserInfo.FirstName))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "user-email" }, [
                  _vm._v(_vm._s(_vm.UserInfo.EmailAddress))
                ])
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pb-4 d-lg-none" }),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-2 pt-3 border-right d-none d-lg-block pr-0" },
          [
            _c("div", { staticClass: "position-sticky top-80" }, [
              _c("h6", [_vm._v("Account Activity")]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "nav nav-tabs mt-3 w-100 d-flex flex-column" },
                [
                  _c(
                    "li",
                    {
                      class:
                        _vm.ActivePage == "ActiveCourse"
                          ? "nav-item active"
                          : "nav-item"
                    },
                    [
                      _c(
                        "a",
                        {
                          class:
                            _vm.ActivePage == "ActiveCourse"
                              ? "side-nav-link active"
                              : "side-nav-link",
                          style:
                            _vm.MyAccountDetails.activecoursecount == 0
                              ? "color:#ababab;"
                              : "",
                          attrs: { "data-toggle": "tab" },
                          on: {
                            click: function($event) {
                              return _vm.loadactivecourse()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "Courses active (" +
                              _vm._s(_vm.MyAccountDetails.activecoursecount) +
                              ")"
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class:
                        _vm.ActivePage == "AttandedCourse"
                          ? "nav-item active"
                          : "nav-item"
                    },
                    [
                      _c(
                        "a",
                        {
                          class:
                            _vm.ActivePage == "AttandedCourse"
                              ? "side-nav-link active"
                              : "side-nav-link",
                          style:
                            _vm.MyAccountDetails.paidcourseattandedcount == 0
                              ? "color:#ababab;"
                              : "",
                          attrs: { "data-toggle": "tab" },
                          on: {
                            click: function($event) {
                              return _vm.loadattandedcourse()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                Courses Attended (" +
                              _vm._s(
                                _vm.MyAccountDetails.paidcourseattandedcount
                              ) +
                              ")\n                            "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class:
                        _vm.ActivePage == "DemoCourse"
                          ? "nav-item active"
                          : "nav-item"
                    },
                    [
                      _c(
                        "a",
                        {
                          class:
                            _vm.ActivePage == "DemoCourse"
                              ? "side-nav-link active"
                              : "side-nav-link",
                          style:
                            _vm.MyAccountDetails.democourseattandedcount == 0
                              ? "color:#ababab;"
                              : "",
                          attrs: { "data-toggle": "tab" },
                          on: {
                            click: function($event) {
                              return _vm.loaddemocourse()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                Demo Attended (" +
                              _vm._s(
                                _vm.MyAccountDetails.democourseattandedcount
                              ) +
                              ")\n                            "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class:
                        _vm.ActivePage == "PaymentHistory"
                          ? "nav-item active"
                          : "nav-item"
                    },
                    [
                      _c(
                        "a",
                        {
                          class:
                            _vm.ActivePage == "PaymentHistory"
                              ? "side-nav-link active"
                              : "side-nav-link",
                          attrs: { "data-toggle": "tab" },
                          on: {
                            click: function($event) {
                              return _vm.loadpaymenthistory()
                            }
                          }
                        },
                        [_vm._v("Payments history")]
                      )
                    ]
                  )
                ]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-10" }, [
          _c(
            "div",
            { staticClass: "tab-content my-demo-card" },
            [
              _vm.ActivePage == "ActiveCourse"
                ? _c("ActiveCourse", {
                    attrs: { ActiveCourseList: _vm.ActiveCourseList }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.ActivePage == "AttandedCourse"
                ? _c("ActiveAttandedCourse", {
                    attrs: { AttandedCourseList: _vm.AttandedCourseList }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.ActivePage == "DemoCourse"
                ? _c("AttandedDemoCourse", {
                    attrs: {
                      AttandedDemoCourseList: _vm.AttandedDemoCourseList
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.ActivePage == "PaymentHistory"
                ? _c("PaymentHistory", {
                    attrs: { PaymentHistoryList: _vm.PaymentHistoryList }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
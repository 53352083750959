<template>
    <div v-if="showComponent" class="search-panel bg-secondary-two">
        <svg class="d-none">
            <defs>
                <g id="searchIcon">
                    <path d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667
			S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6
			c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z
			 M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z" />
                </g>
                <g id="chevronBack">
                    <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" />
                </g>
            </defs>
        </svg>
        <!-- mobile search-->
        <div v-if="isMobile" class="py-2">
            <div class="container position-relative">
                <div class="row">
                    <!--Header Logo Start-->
                    <div class="col-2 d-flex align-items-center">
                        <a v-on:click="onBlur">
                            <!--<i class="far fa-arrow-left body-lg"></i>-->
                            <svg class="svg-icon linear-medium mr-2" viewBox="0 0 64 64">
                                <use xlink:href="#chevronBack" />
                            </svg>
                        </a>
                    </div>
                    <!--Header Logo End
                    Header Right Start-->
                    <div class="col">
                        <div class="search-field">
                            <form action="#">
                                <input id="search-input-mobile" type="text" placeholder="Search..." v-on:keyup="onKeyUp" prefix="searchInputMobile">
                                <!--<i class="fas fa-search body2"></i>-->
                                <svg class="svg-icon large" viewBox="0 0 512.005 512.005">
                                    <use xlink:href="#searchIcon" />
                                </svg>
                            </form>
                        </div>
                    </div>
                    <!--Header Right End
                    Header Section End-->
                </div>
            </div>
        </div>

        <!-- desktop search-->

        <div v-if="showPopularSearches" class="popular-dropdown">
            <div class="col-12 py-4">
                <h6 class="caption mb-3">Popular searches</h6>
                <div class="d-flex flex-wrap">
                    <a href="/online-spoken-english-training-for-beginners" class="border rounded p-2 mr-2 mb-2 bg-light" data-popular-search="data-popular-search">
                        <h6 class="text-dark mb-0" data-popular-search="data-popular-search">Spoken English For Beginners</h6>
                    </a>
                    <a href="/online-ielts-exam-coaching" class="border rounded p-2 mr-2 mb-2 bg-light" data-popular-search="data-popular-search">
                        <h6 class="text-dark mb-0" data-popular-search="data-popular-search">IELTS </h6>
                    </a>
                    <a href="/online-english-public-speaking-training" class="border rounded p-2 mr-2 mb-2 bg-light" data-popular-search="data-popular-search">
                        <h6 class="text-dark mb-0" data-popular-search="data-popular-search">Public Speaking</h6>
                    </a>
                    <a href="/online-english-interview-training" class="border rounded p-2 mr-2 mb-2 bg-light" data-popular-search="data-popular-search">
                        <h6 class="text-dark mb-0" data-popular-search="data-popular-search">Interviews</h6>
                    </a>
                    <a href="/online-gre-coaching" class="border rounded p-2 mr-2 mb-2 bg-light" data-popular-search="data-popular-search">
                        <h6 class="text-dark mb-0" data-popular-search="data-popular-search">English GRE</h6>
                    </a>
                </div>
            </div>
        </div>
        <ul v-if="showSearchResults" class="search-ul">
            <li v-for="result in searchResults">
                <a :href="domain +'/'+ result.surl" data-search="search-results">
                    {{result.title}}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "AutoComplete",
        data() {
            return {

                matches: [],
                searchResults: [],
                showSearchResults: false,
                showPopularSearches: false,
                showComponent: false,
                triggeredFromElement: null,
                searchElement: null,
                acDataToSearch: null,
                domain: null,
            }
        },
        props: {

            isMobile: Boolean,
        },
        beforeMount: function () {
            this.triggeredFromElement = document.getElementById("input-course-search");
            if (this.triggeredFromElement) {
                this.triggeredFromElement.addEventListener('click', () => {
                    this.showComponent = true;
                    if (!this.showSearchResults && this.acDataToSearch <= 0)
                        this.showPopularSearches = true;
                    if (this.isMobile) {
                        document.body.classList.add("slkno-scroll");
                        document.getElementsByClassName("header-section")[0].classList.add("minus-z-index");
                        if (document.querySelector(".scroll-top")) {
                            if (document.querySelector(".scroll-top").classList.contains('show'))
                                document.querySelector(".scroll-top").classList.remove('show')
                        }
                    }

                })
            }
            

            if (!this.isMobile && this.triggeredFromElement) {
                this.searchElement = this.triggeredFromElement;
                this.searchElement.addEventListener('keyup', (event) => {
                    this.acDataToSearch = event.currentTarget.value;

                })
                var pageX;
                var pageY;
                window.onmousemove = (event) => {

                    pageX = event.clientX;
                    pageY = event.clientY;
                }
                this.searchElement.addEventListener('focusout', (event) => {
                    console.log("from point ", document.elementFromPoint(pageX, pageY).getAttribute("data-popular-search"))
                    if (!document.elementFromPoint(pageX, pageY).getAttribute("data-search") &&
                        !document.elementFromPoint(pageX, pageY).getAttribute("data-popular-search")) {
                        this.onBlur();
                    }

                })
            }
        },
        mounted: function () {
            this.domain = document.location.origin;

        },
        updated: function () {
            if (document.getElementById("search-input-mobile"))
                document.getElementById("search-input-mobile").focus();
        },
        methods: {
            renderItem: function (searchResults, acDataToSearch) {

                this.matches = searchResults.map(function (searchResult) {
                    acDataToSearch = acDataToSearch.replace(/[^a-z0-9\s]/gi, '').replace(/\s+/g, ' ');
                    acDataToSearch = acDataToSearch.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

                    var queryList = acDataToSearch.toLowerCase().replace("/", "").split(" ");
                    var mytitlevalue = searchResult.title;
                    for (var kk = 0; kk < queryList.length; kk++) {
                        var filter = queryList[kk];
                        var matcher = new RegExp("(" + filter + ")".toLowerCase(), "gi");
                        if (filter.trim().length > 0) {
                            mytitlevalue = mytitlevalue.replace(matcher, "<<>>$1<</>>");
                        }
                    }
                    var matcher1 = new RegExp("(<<>>)".toLowerCase(), "gi");
                    var matcher2 = new RegExp("(<</>>)".toLowerCase(), "gi");
                    mytitlevalue = mytitlevalue.replace(matcher1, "<b>").replace(matcher2, "</b>");
                    var finalvalue = ((mytitlevalue.length > 0) ? mytitlevalue : searchResult.label);
                    if (typeof searchResult.hideunwanted != "undefined") {
                        if (finalvalue.indexOf("</b>") >= 0)
                            return '<li class="ac-list" data-original="' + searchResult.label + '"><a>' + finalvalue + '</a></li>';
                        else
                            return '';
                    }
                    else {
                        return '<a>' + finalvalue + '</a>';
                    }
                })
            },
            search: function (acDataToSearch) {

                var completeUrl = this.domain + `/api/search/${acDataToSearch}`;

                fetch(completeUrl)
                    .then(response => response.json())
                    .then(responseJson => {
                        this.showPopularSearches = false;
                        if (responseJson.count > 0) {
                            this.searchResults = responseJson.elearnSearchResults;
                        } else {
                            this.searchResults = [{ title: "No search Results" }];
                        }

                    })
            },
            OnSuggestionClick: function (event) { //to do

                //let nodeIndex = this.indexInParent(event.target)
                //let itemData = this.searchResults[nodeIndex];

            },
            indexInParent: function (node) {
                var currentNode = node.parentNode;
                var children = currentNode.parentNode.childNodes;
                var num = 0;
                for (var i = 0; i < children.length; i++) {
                    if (children[i] == currentNode) return num;
                    if (children[i].nodeType == 1) num++;
                }
                return -1;
            },
            onKeyUp: function (event) {
                this.acDataToSearch = event.currentTarget.value;
            },
            onBlur: function (event) {
                this.showSearchResults = false;
                this.showPopularSearches = false;
                this.showComponent = false;
                this.acDataToSearch = "";
                if (!this.isMobile)
                    this.searchElement.value = "";
                else
                    event.currentTarget.value = "";
                if (document.body.classList.contains('slkno-scroll'))
                    document.body.classList.remove('slkno-scroll');
                document.getElementsByClassName("header-section")[0].classList.remove("minus-z-index");
            }
        },
        watch: {
            acDataToSearch: function () {
                if (this.acDataToSearch.length > 2) {
                    this.search(this.acDataToSearch);
                } else {
                    this.showSearchResults = false;
                    this.showPopularSearches = true;
                    this.searchResults = [];
                }
            },
            searchResults: function () {
                if (this.searchResults.length > 0) {
                    this.showSearchResults = true;
                    this.renderItem(this.searchResults, this.acDataToSearch);
                }
            }
        },
    }
</script>
<template>
    <div class="modal fade show" id="Openwindow" style="display: block" ref="base">
        <svg class="d-none">
            <defs>
                <g id="chevronBack">
                    <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path>
                </g>
            </defs>
        </svg>
        <div class="modal-dialog multistep-quiz-form">
            <div ref="scrollHookTop"></div>
            <div class="modal-content">
                <div class="modal-header align-items-center">
                    <svg viewBox="0 0 64 64"
                         id="back"
                         class="svg-icon medium d-sm-none back hide">
                        <use xlink:href="#chevronBack"></use>
                    </svg>
                    <h6 class="quiz-popup-title">Fill the Gap</h6>
                    <button type="button" class="close" @click="onClickClose">×</button>
                </div>
                <div class="modal-body">
                    <div class="quiz-box">
                        <div class="form">
                            <QuizIntroScreen :introClassName="introClassName" :introName="introName"
                                             v-on:onNext="onClickIntroNext">
                            </QuizIntroScreen>
                            <div v-for="(detail, index) in quizDetails"
                                 class="step next-slide"
                                 :key="'step' + index"
                                 id="step2"
                                 :ref="'step' + index">
                                <div class="group">
                                    <div class="quiz-step-start mb-3">
                                        <div class="quiz-questions">
                                            <span class="achieved-score big">{{ index + 1 }}</span>
                                            <span class="outof-score">/{{ quizDetails.length }}</span>
                                        </div>
                                        <div class="cap-count-number">
                                            <span class="tag-item">
                                                <img class="cap-count-image"
                                                     src="https://lscdn.blob.core.windows.net/elearn/cap.png" />
                                                <span class="earn-cap-count">{{ totalCaps }} Points</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="quiz-qustion-box" ref="body">
                                        <!--class = quiz-scroll-y -->
                                        <div class="">

                                            <div class="question-explain">
                                                <!--<p></p>-->
                                                <p v-html="detail.sentence"></p>
                                                <!-- <p>My boss expects everyone in the meeting to be <span class="all-ears-text-color">all ears</span> when he speaks; he does not tolerate interruptions.</p> -->
                                                <h6 v-html="detail.question">
                                                    <!-- {{ detail.question }}
                <span class="all-ears-text-color">{{detail.word}}</span> -->
                                                </h6>
                                                <p v-if="totalAnsCount(detail.answer) > 1" class="text-primary">Multiple words match, choose the right options</p>
                                            </div>
                                            <ul class="main-questions">
                                                <li class=""
                                                    @click="onSelectOption"
                                                    data-option="A">
                                                    <span class="check-correct-icon d-none" data-id="answer-icon">
                                                        <svg class="correct-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#ffffff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="wrong-icon d-none" data-id="answer-icon">
                                                        <svg class="wrong-close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <line x1="200" y1="56" x2="56" y2="200" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                            <line x1="200" y1="200" x2="56" y2="56" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                        </svg>
                                                    </span>{{ detail.optionA }}
                                                </li>
                                                <li class=""
                                                    @click="onSelectOption"
                                                    data-option="B">
                                                    <span class="check-correct-icon d-none" data-id="answer-icon">
                                                        <svg class="correct-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#ffffff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="wrong-icon d-none" data-id="answer-icon">
                                                        <svg class="wrong-close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <line x1="200" y1="56" x2="56" y2="200" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                            <line x1="200" y1="200" x2="56" y2="56" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                        </svg>
                                                    </span>{{ detail.optionB }}
                                                </li>
                                                <li class=""
                                                    @click="onSelectOption"
                                                    data-option="C">
                                                    <span class="check-correct-icon d-none" data-id="answer-icon">
                                                        <svg class="correct-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#ffffff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="wrong-icon d-none" data-id="answer-icon">
                                                        <svg class="wrong-close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <line x1="200" y1="56" x2="56" y2="200" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                            <line x1="200" y1="200" x2="56" y2="56" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                        </svg>
                                                    </span>{{ detail.optionC }}
                                                </li>
                                                <li class=""
                                                    @click="onSelectOption"
                                                    data-option="D">
                                                    <span class="check-correct-icon d-none" data-id="answer-icon">
                                                        <svg class="correct-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#ffffff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="wrong-icon d-none" data-id="answer-icon">
                                                        <svg class="wrong-close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" viewBox="0 0 256 256">
                                                            <rect width="256" height="256" fill="none"></rect>
                                                            <line x1="200" y1="56" x2="56" y2="200" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                            <line x1="200" y1="200" x2="56" y2="56" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                                        </svg>
                                                    </span>{{ detail.optionD }}
                                                </li>

                                            </ul>

                                        </div>
                                        <div class="view-answers mt-2">
                                            <p v-if="correctAnswers.length > 0"
                                               class="select-caps-winner"
                                               data-id="">
                                                Correct answer is {{ correctAnswers.join(', ') }}
                                            </p>
                                        </div>
                                        <div class="view-explanation-button text-center mt-2 d-none"
                                             data-id="next-btn">
                                            <button type="button"
                                                    id="next"
                                                    class="btn btn-primary next-form-btn mb-2"
                                                    tabindex="-1"
                                                    @click="onClickNext"
                                                    ref="nextPage">
                                                Next
                                            </button>
                                            <p v-if="showWellDoneMessage" class="text-center select-caps-winner">
                                                You have earned {{ wellDonePoints }} points
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="step next-slide" id="step7" ref="congratulation">
                                <div class="group">
                                    <div class="final-quiz-qustion-box">
                                        <div class="question-explain mt-4">
                                            <h3 v-if="congratsMsg" class="final-explanation-title">{{congratsMsg}}</h3>
                                            <p v-if="congratsMsg" class="text-center mt-3">{{ userName }}</p>
                                            <p class='text-center' v-html="getCongratsContent()"></p>
                                            <!-- <p class='text-center' v-if="comparisonText">{{comparisonText}}</p> -->
                                        </div>
                                        <div v-if="!hasReTriedWholeQuiz" class="final-exam-score s-score text-center mt-4">
                                            <img class="final-screen-cap" src="https://lscdn.blob.core.windows.net/elearn/cap.png">
                                            <div class="circle-center-score">
                                                <p>Rewarded</p>
                                                <p class="final-achieved-score big">{{ totalCaps }}</p>
                                                <p>Points</p>
                                                <!-- <span class="final-achieved-score big">{{
                                                  totalScore
                                                }}</span>
                                                <span class="final-outof-score"
                                                  >/{{ quizDetails.length }}</span
                                                > -->
                                            </div>
                                        </div>
                                        <div class="try-again final-screen-footer">
                                            <button v-if="playNextCount > 0"
                                                    type="button"
                                                    id="next"
                                                    class="btn btn-primary next-form-btn"
                                                    tabindex="-1"
                                                    @click="getNextQuiz">
                                                Play Next Game
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref="scrollHookBottom"></div>
        </div>
    </div>
</template>
<script>
import Loader from '../../wwwroot/js/Loader';
import QuizIntroScreen from "./QuizIntroScreen";

export default {
  data() {
    return {
      currentSlideNumber: -1,
      currentWordId: 0,
      quizDetails: [],
      isClickable: true,
      userName: "",
      totalScore: 0,
      totalCaps: 0,
      showWellDoneMessage: false,
      isAnswerSelected: false,
      showSavedMsg: false,
      showErrorText: false,
      introClassName: 'current-slide',
      introName: 'FillTheGap',
      hasReTriedWholeQuiz: false,
      hasCompletedQuiz: false,
      nextQuizTopic: '',
      congratsMsg : '',
      showTryAgainMsg: false,
      firstAttemptScore: 0,
      comparisonText: '',
      correctAnsClickCount: 0,
      wellDonePoints: 0,
      correctAnswers: [],
      playNextCount: 0,
    };
  },
  props : {
    userPid : Number,
    playNext : Number,
  },
  components: {
    QuizIntroScreen: QuizIntroScreen,
  },
  beforeMount: function () {
    this.getVocabularyQuiz();
    this.userName = document.getElementById("hdnUserName").value;
    this.playNextCount = this.playNext;
  },
  methods: {
    moveTo: function (position) {
      let ref = `step${this.currentSlideNumber + 1}`;

      this.$refs[ref][0].classList.remove("next-slide");
      this.$refs[ref][0].classList.add("current-slide");
      this.currentSlideNumber++;
      this.currentWordId = this.quizDetails[this.currentSlideNumber].wordId;
    },
    onClickIntroNext: function() {
        this.introClassName = "previous-slide";
        this.moveTo();
    },
    onSelectOption: function (event) {
      let option = event.currentTarget.getAttribute("data-option");
      if (!event.currentTarget.classList.contains("clickedAnswer")) {
      if (this.isClickable) {
         this.correctAnsClickCount++;
         if(this.correctAnsClickCount == 1)
         {
            this.wellDonePoints = 0;
            this.showWellDoneMessage = true;
         }
        if (this.quizDetails[this.currentSlideNumber].answer.includes(option)) {
          event.currentTarget.querySelector(".check-correct-icon").classList.remove("d-none");
          event.currentTarget.classList.add("correct-answer");
          event.currentTarget.classList.add("clickedAnswer");
          if (!this.hasReTriedWholeQuiz && !this.quizDetails[this.currentSlideNumber].hasReTried ) {
            //let scoreVal = this.getScoreValue(this.quizDetails[this.currentSlideNumber].answer);
            let scoreVal = 50;
            this.totalCaps = this.totalCaps + scoreVal;
            this.quizDetails[this.currentSlideNumber].isCorrectAnswer = true;
            this.wellDonePoints = this.wellDonePoints + scoreVal;
          }
        }
        else {
          event.currentTarget
            .querySelector(".wrong-icon")
            .classList.remove("d-none");
          event.currentTarget.classList.add("select-answer");
          event.currentTarget.classList.add("clickedAnswer");
        }

        this.quizDetails[this.currentSlideNumber].selectedAnswer.push(option);
           this.isAnswerSelected = true;
           this.showErrorText = false;
           let ref = `step${this.currentSlideNumber}`;
           this.quizDetails[this.currentSlideNumber].score = this.wellDonePoints;
           this.$refs[ref][0]
             .querySelector("[data-id='next-btn']")
             .classList.remove("d-none");
        let ansCount = this.totalAnsCount(this.quizDetails[this.currentSlideNumber].answer);
        if(this.correctAnsClickCount == ansCount) {
           this.isClickable = false;
           this.correctAnsClickCount = 0;
           this.getCorrectAnswers();
        }
      }
     }
    },
    getCorrectAnswers: function () {
        this.correctAnswers = [];
        let arrSplit = this.quizDetails[this.currentSlideNumber].answer.split(',');
        arrSplit.forEach((item) => {
          if (item.includes("A")) {
            this.correctAnswers.push(this.quizDetails[this.currentSlideNumber].optionA);
          }
          else if (item.includes("B")) {
            this.correctAnswers.push(this.quizDetails[this.currentSlideNumber].optionB);
          }
          else if (item.includes("C")) {
            this.correctAnswers.push(this.quizDetails[this.currentSlideNumber].optionC);
          }
          else if (item.includes("D")) {
            this.correctAnswers.push(this.quizDetails[this.currentSlideNumber].optionD);
          }
        });
    },
    getVocabularyQuiz: function () {
        let url = `api/quiz/get-vocabulary-fillthegap?userPid=${this.userPid}`;
        Loader.showAboveModal();
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              this.quizDetails = data;
              this.quizDetails.forEach((detail) => {
                        detail.selectedAnswer = [];
                })
            }
        });
        Loader.hideAboveModal();
    },
    getFormatedSentence: function (sentence, word) {
      if (sentence) {
        return sentence.replaceAll(
          word,
          `<span class="all-ears-text-color">${word}</span>`
        );
      }
    },
    hideCorrectOrWrongAnswerIcons: function (element) {
      let icons = element.querySelectorAll(
        "[data-id='answer-icon']"
      );
      if (icons) {
        icons.forEach((item) => {
          if (!item.classList.contains("d-none")) {
            item.classList.add("d-none");
          }
        });
      }
    },
    removeCorrectOrWrongAnswerClasses: function (element) {
    //   let ref = `step${this.currentSlideNumber}`;
      let options = element.querySelectorAll("[data-option]");
      if (options) {
        options.forEach((item) => {
          if (item.classList.contains("correct-answer")) {
            item.classList.remove("correct-answer");
          }
          if (item.classList.contains("select-answer")) {
            item.classList.remove("select-answer");
          }
          if (item.classList.contains("clickedAnswer")) {
            item.classList.remove("clickedAnswer");
          }
        });
      }
    },
    onClickNext: function () {
      if(this.quizDetails[this.currentSlideNumber].isCorrectAnswer)
            this.totalScore = this.totalScore + 1;

      this.correctAnsClickCount = 0;
      this.correctAnswers = [];
      let ref = `step${this.currentSlideNumber}`;
      this.$refs[ref][0]
        .querySelector("[data-id='next-btn']")
        .classList.remove("d-none");
      this.$refs[ref][0].classList.remove("current-slide");
      this.$refs[ref][0].classList.add("previous-slide");
      if (this.currentSlideNumber < this.quizDetails.length - 1) {
        this.showWellDoneMessage = false;
        this.isClickable = true;
        this.moveTo();
        this.showErrorText = false;
        this.isAnswerSelected = false;
      } else {
          if(!this.hasReTriedWholeQuiz) {
              this.firstAttemptScore = this.totalScore;
               this.onSubmitQuizResults();
          }
        this.$refs.congratulation.classList.remove("next-slide");
        this.$refs.congratulation.classList.add("current-slide");
      }
      this.isAnswerSelected = false;
    },
    onSubmitclick: function () {

      if (!this.isAnswerSelected) {
        this.showErrorText = true;
      }
    },
    onClickSaveIcon: function (event) {
      let url = ""
        if(event.currentTarget.classList.contains("active")) {
            event.currentTarget.classList.remove("active");
        } else {
            event.currentTarget.classList.add("active");
        }
      this.showSavedMsg = true;
      if(this.showSavedMsgTimerId) {
          clearTimeout(this.showSavedMsgTimerId);
      }
      this.showSavedMsgTimerId = setTimeout(() => {
          this.showSavedMsg = false;
      }, 3000)
      this.quizDetails[this.currentSlideNumber].isBookMarked = true;
    },
    onClickClose: function () {
        if(this.hasCompletedQuiz) {
          Loader.show();
          window.location.reload();
        } else {
           document.body.classList.remove("overflow-hidden");
          var vueBaseDiv = document.querySelector("#fillthegap-base-div");
          vueBaseDiv.innerHTML = "";
          var div = document.createElement("div");
          div.id = "fillthegap-div";
          vueBaseDiv.appendChild(div);
        }

    },
    setDefaultStateValues: function () {
      this.currentSlideNumber = -1;
      this.currentWordId= 0;
      this.isClickable= true;
      this.totalScore= 0;
      this.totalCaps= 0;
      this.showWellDoneMessage= false;
      this.isAnswerSelected= false;
      this.showSavedMsg= false;
      this.showErrorText= false;
      this.introClassName= 'current-slide';
    },
    setDefaultClassNamesForSlides: function () {
       for(let i=0; i< this.quizDetails.length; i++) {
           this.$refs[`step${i}`][0].classList.remove("previous-slide")
           this.$refs[`step${i}`][0].classList.add("next-slide")
           this.$refs[`step${i}`][0].querySelector("[data-id='next-btn']").classList.add("d-none");
           this.hideCorrectOrWrongAnswerIcons(this.$refs[`step${i}`][0]);
            this.removeCorrectOrWrongAnswerClasses(this.$refs[`step${i}`][0]);
       }
       this.$refs.congratulation.classList.remove("current-slide");
        this.$refs.congratulation.classList.add("next-slide");
    },
    getQuizResultsData: function () {
        let result = [];
        this.quizDetails.forEach((detail,index) => {
            result[index] = {
                userPid : this.userPid,
                WordId : detail.wordId,
                Score : detail.score,
                answer : detail.selectedAnswer.join(','),
                isCorrectAnswer : detail.isCorrectAnswer ? detail.isCorrectAnswer : false,
                QuizSummaryID : detail.quizSummaryID,
            }
        })
        return result;
    },
    onSubmitQuizResults : function () {
        let url = 'api/quiz/store-fillthegap-result';
        let requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.getQuizResultsData()),
        }
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
          this.hasCompletedQuiz = true;
        });
    },
    totalAnsCount : function (ans) {
        return ans.split(',').length;
    },
    getScoreValue : function (ans) {
        let count = this.totalAnsCount(ans);
        let score = 100;
        if(count > 1)
          score = 50;
        return score;
    },
    scrollIntoView : function (direction) {
      let hook = '';
      if(direction == 'up') {
        hook = 'scrollHookBottom';
      } else if (direction == 'down') {
        hook = 'scrollHookTop';
      }
      let div = this.$refs[hook];
      div.scrollIntoView({behavior : "smooth"});
    },
    getNextQuiz : function () {
        this.playNextCount--;
        this.hasReTriedWholeQuiz = false;
        this.setDefaultStateValues();
        this.setDefaultClassNamesForSlides();
        this.getVocabularyQuiz();
    },
    getCongratsContent : function () {
      let totalCount = this.quizDetails.length;
      const percent = Math.round((this.totalScore / totalCount) * 100);
      if(percent <= 50) {
          this.congratsMsg = "";
          this.showTryAgainMsg = true;
          return `<b>You got ${this.totalScore} out of ${this.quizDetails.length} correct.</b>`
      }
      else if (percent <= 75) {
        this.showTryAgainMsg = false;
          this.congratsMsg = "Great!!!";
          return `<b>You got ${this.totalScore} out of ${this.quizDetails.length} correct.</b>`
      } else {
        this.showTryAgainMsg = false;
        this.congratsMsg = "Congratulations!!!";
        return `<b>You got ${this.totalScore} out of ${this.quizDetails.length} correct.</b>`
      }
      if(this.hasReTriedWholeQuiz) {
          if(this.totalScore < this.firstAttemptScore) {
          this.comparisonText = 'Ohh, you played better the 1st time. Try one more time.';
        } else {
          this.comparisonText = 'Well done you have improved playing the quiz the second time.'
        }
      } else {
        this.comparisonText = '';
      }

    },
  },
};
</script>

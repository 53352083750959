var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row mx-0" },
    [
      _c("DisplayUserInfor", {
        attrs: { instructorDetails: _vm.instructorDetails }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-6 px-lg-5 pb-5" }, [
        _c("div", { staticClass: "section payment-content" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col px-lg-5" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("h4", { staticClass: "mb-3" }, [
                  _vm._v("Please pay a refundable deposit for the demo")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "caption text-success mb-3" }, [
                  _vm._v(
                    "This security deposit allows us to give you the best experience with the tutor."
                  )
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "p-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-lg caption btn-primary btn-block",
                      on: {
                        click: function($event) {
                          return _vm.redirectToPaymentPage()
                        }
                      }
                    },
                    [_vm._v("Pay Rs.100")]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-wrapper" }, [
      _c("img", {
        staticClass: "w-50",
        attrs: {
          src: "https://lscdn.blob.core.windows.net/elearn/hero.jpg",
          alt: "hero"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "pl-3" }, [
      _c("li", { staticClass: "body2 mb-3" }, [
        _vm._v(
          "The token reservation fee of Rs. 100 will be either adjusted against the purchase of a course or refunded in full upon completion of the demo within 48 hours."
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "body2 mb-5" }, [
        _vm._v(
          "Upon the completion of the demo, you will receive a 15% off (max of Rs. 500) discount coupon against the booking of this course or any other course."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal know-more-popup show",
      attrs: { "data-id": "know-more-modal" }
    },
    [
      _c("svg", { staticClass: "d-none" }, [
        _c("defs", [
          _c("g", { attrs: { id: "downArrow" } }, [
            _c("path", {
              attrs: {
                d:
                  "M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("svg", { staticClass: "d-none" }, [
        _c("defs", [
          _c("g", { attrs: { id: "chevronBack" } }, [
            _c("path", {
              attrs: {
                d:
                  "m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "modal-dialog modal-dialog-centered modal-dialog-scrollable"
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header align-items-center" }, [
              _c(
                "svg",
                {
                  staticClass: "svg-icon medium d-sm-none hide",
                  attrs: { viewBox: "0 0 64 64", id: "back" }
                },
                [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
              ),
              _vm._v(" "),
              _c("h5", { staticClass: "know-more-popup-title" }, [
                _vm._v("Why talk-time sessions are closed in 10 mins")
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "data-id": "know-more-ok-btn" },
                  on: { click: _vm.hidePopup }
                },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "know-more-descriptions" }, [
                _c("p", { attrs: { "data-id": "know-more-description" } }, [
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "\n                                We create smaller groups of 4 to 8 members in breakout rooms\n                            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n                                Helps you to participate in the discussion\n                            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n                                The coaches can help you with the conversation\n                            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n                                So we need all the members to join the session before " +
                          _vm._s(_vm.timeData) +
                          "\n                            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n                                Please join the session early to so that you do not miss the meet\n                            "
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal-footer",
                attrs: { "data-id": "know-more-ok-btn" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary know-more-btn",
                    attrs: { type: "button" },
                    on: { click: _vm.hidePopup }
                  },
                  [_vm._v("Ok")]
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
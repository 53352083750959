var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-lg-6" }, [
    _c("div", { staticClass: "row course-details-block align-items-center" }, [
      _c("div", { staticClass: "duserinfo" }, [
        _vm.instructorDetails.TutorName &&
        _vm.instructorDetails.TutorName != "" &&
        _vm.instructorDetails.TutorName != null
          ? _c("h6", { staticClass: "my-lg-3" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.getsesson()) +
                  " with " +
                  _vm._s(_vm.instructorDetails.TutorName) +
                  "\n\n            "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "course-details" }, [
          _c("div", { staticClass: "img-wrapper " }, [
            _c("img", {
              staticClass: "img-fluid bg-img fit-cover",
              attrs: { src: _vm.instructorDetails.ImageURL, alt: "" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pl-3" }, [
            _c(
              "h5",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.instructorDetails.CourseName &&
                      _vm.instructorDetails.CourseName != "" &&
                      _vm.instructorDetails.CourseName != null,
                    expression:
                      "\n          instructorDetails.CourseName &&\n          instructorDetails.CourseName != '' &&\n          instructorDetails.CourseName != null\n        "
                  }
                ]
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.instructorDetails.CourseName) +
                    "\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "caption" }, [
              _c(
                "svg",
                {
                  staticClass: "svg-icon medium c-pointer top-1",
                  attrs: { viewBox: "0 0 496 512" }
                },
                [_c("use", { attrs: { "xlink:href": "#userProfile" } })]
              ),
              _vm._v(
                "\n                        Classes by " +
                  _vm._s(_vm.instructorDetails.TutorName) +
                  "\n                    "
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
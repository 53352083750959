﻿let shortlist = {
    userPid: 0,
    courseId: 0,
    initShortlist: function () {
        if (document.querySelector('[data-shortlistpage="MyShortlistPage"]'))
            this.setPageBackground();

        let shortlistBtns = document.querySelectorAll("[data-shortlist][data-hasnoclickevent='true']");
        if (shortlistBtns) {
            shortlistBtns.forEach((shortlistBtn) => {
                shortlistBtn.setAttribute("data-hasnoclickevent", "");
                shortlistBtn.addEventListener('click', (event) => {
                    event.stopPropagation();
                    this.courseId = event.currentTarget.getAttribute("data-shortlist");
                    if (document.getElementById("hdnUserPid").value != "0") {
                        this.userPid = parseInt(document.getElementById("hdnUserPid").value)
                    }
                    if (this.userPid) {

                        if (event.currentTarget.classList.contains("active")) {
                            let removeCourse = true;
                            this.addOrRemoveCourse(removeCourse);
                            event.currentTarget.classList.remove("active");
                        } else {
                            this.addOrRemoveCourse();
                            event.currentTarget.classList.add("active");
                        }

                    } else {
                        document.getElementById("hdnShortlist").value = this.courseId;
                        console.log(document.getElementById("hdnShortlist").value)
                        document.getElementById("signInMobile").click();
                    }
                })
                
            })
        }
    },
    addOrRemoveCourse: function (removeCourse = false) {

        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                UserPid: this.userPid,
                CourseId: this.courseId,
                Removeflag: removeCourse,
            })
        }
        fetch('/api/shortlist/addOrRemoveCourse', options)
            .then(response => response)
            .then(data => {
                if (document.getElementById("hdnIsAuthenticated").getAttribute("data-isauthenticated") == "False") {
                    location.reload();
                }
            })
    },
    setPageBackground: function () {
        if (!document.querySelector(".course-card[course-card]")) {
            let pageHTML = `<div class="col-lg-12">
                                <svg class="d-none">
                                    <defs>
                                        <g id="bookmarkIcon">
                                            <path d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"></path>
                                        </g>
                                </defs>
                             </svg>
                            <div class="d-flex flex-column align-items-center pb-5">
                                <div class="container-circle bg-grey mb-5">
                                    <svg class="svg-icon xlarge" viewBox="0 0 384 512">
                                        <use xlink:href="#bookmarkIcon" />
                                    </svg>
                                </div>
                                <h2 class="text-center mb-5">
                                    Your shortlisted courses<br />appear here.
                                </h2>
                            </div>
                        </div>`
            document.querySelector('[data-shortlistpage="MyShortlistPage"]').innerHTML = pageHTML;
        }
    }
}

shortlist.initShortlist();
export default shortlist;

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "topic-filter-choose" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "filter-topic-btn mt-2" }, [
          _c(
            "button",
            {
              staticClass: "filter-border-btn",
              attrs: { type: "button", "data-id": "vue-filter-btn" },
              on: { click: _vm.openFilter }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "icon-filter-align",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "20",
                    height: "20",
                    fill: "",
                    viewBox: "0 0 256 256"
                  }
                },
                [
                  _c("line", {
                    attrs: {
                      x1: "147.99951",
                      y1: "171.99353",
                      x2: "39.99951",
                      y2: "171.99365",
                      fill: "none",
                      stroke: "",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "16"
                    }
                  }),
                  _vm._v(" "),
                  _c("line", {
                    attrs: {
                      x1: "215.99951",
                      y1: "171.99365",
                      x2: "187.99951",
                      y2: "171.99353",
                      fill: "none",
                      stroke: "",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "16"
                    }
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    attrs: {
                      cx: "167.99951",
                      cy: "171.99353",
                      r: "20",
                      fill: "none",
                      stroke: "",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "16"
                    }
                  }),
                  _vm._v(" "),
                  _c("line", {
                    attrs: {
                      x1: "83.99951",
                      y1: "83.99353",
                      x2: "39.99951",
                      y2: "83.99294",
                      fill: "none",
                      stroke: "",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "16"
                    }
                  }),
                  _vm._v(" "),
                  _c("line", {
                    attrs: {
                      x1: "215.99951",
                      y1: "83.99294",
                      x2: "123.99951",
                      y2: "83.99353",
                      fill: "none",
                      stroke: "",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "16"
                    }
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    attrs: {
                      cx: "103.99951",
                      cy: "83.99353",
                      r: "20",
                      fill: "none",
                      stroke: "",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "16"
                    }
                  })
                ]
              ),
              _vm._v("Filter")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.videoDetails, function(category) {
        return _c("div", [
          _vm.selectedTopicFilter.includes("All Videos") ||
          _vm.selectedTopicFilter.includes(category.bucket)
            ? _c("div", [
                _c("div", { staticClass: "title-filter-topic mt-4" }, [
                  _c("h5", [_vm._v(_vm._s(category.bucket))])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "filter-videos-details" },
                  _vm._l(category.clubVideos, function(details) {
                    return _c(
                      "div",
                      {
                        staticClass: "filter-topic-details",
                        attrs: {
                          "data-videoURL": details.videoURL,
                          "data-bucket": category.bucket
                        },
                        on: { click: _vm.onVideoClick }
                      },
                      [
                        _c("img", {
                          staticClass: "learn-video-thumbnil",
                          attrs: {
                            src: _vm.getThumbnailLinkFromVideoLink(
                              details.videoURL
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "play-media",
                          attrs: {
                            src:
                              "https://sulcdn.azureedge.net/content/caps/homepage/play-icon.png"
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "words mt-2" }, [
                          _c("b", [_vm._v(_vm._s(details.videoTitle))])
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      }),
      _vm._v(" "),
      _vm.showFilter
        ? _c("ClubVideoFilter", {
            attrs: {
              videoDetailsProp: _vm.videoDetails,
              selectedTopicFilterProp: _vm.selectedTopicFilter,
              selectedLanguageFilterProp: _vm.selectedLanguageFilter
            },
            on: { onClose: _vm.closeFilter, onApplyFilter: _vm.onApplyFilter }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showVideoPlayer
        ? _c("ClubVideoPlayer", {
            attrs: {
              relatedVideoDetailsProp: _vm.relatedVideoDetails,
              mainVideoURL: _vm.selectedVideoURL,
              category: _vm.selectedVideoBucket
            },
            on: { onClose: _vm.closeVideoPlayer }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-video" }, [
      _c("h3", [_vm._v("Learn Through Videos")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
﻿import Loader from "../Loader.js";

var demoFeedback = {
    studentSignupId: 0,
    feedbackId: 0,
    commentId: 0,
    otherComments: '',
    courseId: 0,
    initialFeedbackId: 0,
    showModal: function () {
        document.getElementById("hpDemoFeedbackModal").classList.add("show");
        document.body.classList.add("overflow-hidden");
    },
    hideModal: function () {
        document.getElementById("hpDemoFeedbackModal").classList.remove("show");
        document.body.classList.remove("overflow-hidden");
    },
    init: function () {
        console.log("init function ");
        demoFeedback.studentSignupId = document.getElementById("hpDemoFeedback").getAttribute("data-StudentSignupId");
        demoFeedback.courseId = parseInt(document.getElementById("hdn-demofeedback-courseid").value);
        demoFeedback.initialFeedbackId = parseInt(document.getElementById("hdn-initial-feedbackid").value);
        demoFeedback.feedbackId = demoFeedback.initialFeedbackId;
        demoFeedback.showAppropriateDiv();
        document.querySelectorAll("#hpDemoFeedback .feedback0 .smiley-img").forEach(elem => {
            elem.parentElement.addEventListener('click', function (evt) {
                demoFeedback.feedbackId = evt.target.getAttribute("data-feedbackid");
                if (evt.target.getAttribute("data-feedbackid") == null)
                    demoFeedback.feedbackId = evt.target.parentElement.getAttribute("data-feedbackid");
                if (demoFeedback.feedbackId == 1) {
                    demoFeedback.saveSmileyFeedback();
                }
                else {
                    demoFeedback.showModal();
                }
            });
        });

        if (document.getElementById("txtareafeedback")) {
            document.getElementById("txtareafeedback").addEventListener('keypress', () => {
                console.log("on change")
                demoFeedback.hideError();
            })
        }
        

        document.querySelectorAll('input[name=rdoGrpHpDemoFeedback]').forEach((radioBtn) => {
            radioBtn.addEventListener('click', (event) => {
                demoFeedback.onSelectFeedbackComment(event);
            })
        })

        document.querySelectorAll("#hpDemoFeedbackModal [data-popupclose]").forEach(elem => {
            elem.addEventListener('click', function (evt) {
                demoFeedback.hideModal();
            });
        });

        document.getElementById("hpDemoFeedbackSubmit").addEventListener('click', function (evt) {
            //if (document.querySelector('input[name=rdoGrpHpDemoFeedback]:checked') != null)
            //    demoFeedback.commentId = document.querySelector('input[name=rdoGrpHpDemoFeedback]:checked').value;
            demoFeedback.otherComments = document.getElementById("txtareafeedback").value;

            if (demoFeedback.commentId == 0) {
                //alert("Please select the Feedback")
                demoFeedback.showError("Please select the Feedback");
                return;
            }

            if(demoFeedback.otherComments.trim() == '') {
                demoFeedback.showError("Please enter your comments");
                //alert("Please enter Feedback");
                return;
            }

            if (demoFeedback.otherComments.trim().length < 25) {
                demoFeedback.showError("Comments should exceed more then 25 characters");
                return;
            }

            if (demoFeedback.otherComments.trim().length > 500) {
                demoFeedback.showError("Comments should not exceed more then 500 characters");
                return;
            }

            demoFeedback.saveCommentFeedback();
        });

        document.querySelectorAll("#redirect-relevant-course").forEach(element => {
            element.addEventListener('click', function () {
                demoFeedback.redirectToReleventCourse();
            })
        })
    },
    saveSmileyFeedback: function () {
        const url = "/api/Course/updatesessionfeedback?signupid=" + demoFeedback.studentSignupId + "&feedbackid=" + demoFeedback.feedbackId;
        Loader.showAboveModal();
        fetch(url)
            .then((resp) => resp.json())
            .then(responseJson => {
                Loader.hideAboveModal();
                /*alert(responseJson);*/
                
                demoFeedback.showAppropriateDiv();
            });
    },
    saveCommentFeedback: function () {
        var obj = {
            signupid: demoFeedback.studentSignupId,
            feedbackid: demoFeedback.feedbackId,
            feedbackcommentid: demoFeedback.commentId,
            othercomments: demoFeedback.otherComments
        }
        Loader.showAboveModal();
        fetch("/api/Course/updatesessionfeedbackcomments", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        })
            .then(response => response.json())
            .then(responseJson => {
                Loader.hideAboveModal();
                demoFeedback.hideModal();
                demoFeedback.showAppropriateDiv();
            });
    },
    redirectToReleventCourse: function () {
        console.log("function called");
        Loader.showAboveModal();
        const url = "/api/Course/fetchreleventcourseurl?courseid=" + demoFeedback.courseId;
        fetch(url)
            .then((resp) => resp.json())
            .then(function (response) {
                Loader.hideAboveModal();
                //window.location.href = "/" + response;
                window.open("/" + response, '_blank');
            })

    },
    showAppropriateDiv: function () {
        if (demoFeedback.feedbackId == 0)
            document.querySelectorAll(".next-session .feedback0").forEach((element) => {
                element.classList.remove("d-none")
            })
        else if (demoFeedback.feedbackId == 1) {
            document.querySelectorAll(".next-session .feedback0").forEach((element) => {
                element.classList.add("d-none");
            })
            document.querySelectorAll(".next-session .feedback1").forEach((element) => {
                element.classList.remove("d-none");
            })
        }
        else if (demoFeedback.feedbackId == 2) {
            document.querySelectorAll(".next-session .feedback0").forEach((element) => {
                element.classList.add("d-none");
            })
            document.querySelectorAll(".next-session .feedback2").forEach((element) => {
                element.classList.remove("d-none");
            })
        }
        else if (demoFeedback.feedbackId == 3) {
            document.querySelectorAll(".next-session .feedback0").forEach((element) => {
                element.classList.add("d-none");
            })
            document.querySelectorAll(".next-session .feedback3").forEach((element) => {
                element.classList.remove("d-none");
            })
        }
            
    },
    onSelectFeedbackComment(event) {
        demoFeedback.hideError();
        demoFeedback.commentId = event.currentTarget.value;
        console.log(demoFeedback.commentId);
        if (document.getElementById("txtareafeedback").classList.contains('d-none'))
            document.getElementById("txtareafeedback").classList.replace('d-none','d-block');

        if (document.getElementById("txtareafeedbackspan").classList.contains('d-none')) {
            document.getElementById("txtareafeedbackspan").classList.replace('d-none', 'd-block');
        }
        document.getElementById("txtareafeedbackspan").innerHTML = `Enter your comments for ${event.currentTarget.getAttribute("data-comment")}`;
    },
    showError(errorText) {
        let errorSpan = document.getElementById('txtareaspanerror');
        if (errorSpan) {
            if (errorSpan.classList.contains('d-none'))
                errorSpan.classList.replace('d-none', 'd-block');
            errorSpan.innerHTML = errorText;
        }
    },
    hideError() {
        let errorSpan = document.getElementById('txtareaspanerror');
        if (errorSpan && errorSpan.classList.contains('d-block')) {
            errorSpan.classList.replace('d-block', 'd-none');
        } 
    }
};
if (document.getElementById("hpDemoFeedback"))
    demoFeedback.init();

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "response-page", attrs: { id: "response-page-comments" } },
    [
      _c("div", { staticClass: "response-header" }, [
        _c(
          "a",
          {
            on: {
              click: function($event) {
                return _vm.closeComments()
              }
            }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "20",
                  height: "20",
                  fill: "#000000",
                  viewBox: "0 0 256 256"
                }
              },
              [_c("use", { attrs: { "xlink:href": "#backarrowm" } })]
            )
          ]
        ),
        _vm._v(" Comments\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "activity-responses" }, [
        _c("div", { staticClass: "comments-page" }, [
          _c("h5", [_vm._v(_vm._s(_vm.answeredName))]),
          _vm._v(" "),
          _c("div", { staticClass: "hour" }, [
            _vm._v(_vm._s(_vm.answeredTime))
          ]),
          _vm._v(" "),
          _vm.playButtonBlobUrl != ""
            ? _c("p", { staticClass: "answer" }, [
                _vm._v(
                  "\n                Answered: Play Audio\n                "
                ),
                _c(
                  "svg",
                  {
                    staticClass: "response-speaker",
                    attrs: {
                      onclick:
                        "document.getElementById('playAnswerAudio').play()",
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "20",
                      height: "20",
                      fill: "#ff7a21",
                      viewBox: "0 0 256 256"
                    }
                  },
                  [_c("use", { attrs: { "xlink:href": "#iconspeaker" } })]
                ),
                _vm._v(" "),
                _c(
                  "audio",
                  {
                    attrs: { controls: "", hidden: "", id: "playAnswerAudio" }
                  },
                  [
                    _c("source", {
                      attrs: { src: _vm.playButtonBlobUrl, type: "audio/mp3" }
                    })
                  ]
                )
              ])
            : _c("p", { staticClass: "answer" }, [
                _vm._v(
                  "\n                Answered: " +
                    _vm._s(_vm.answer) +
                    "\n                "
                ),
                [4, 6, 7].includes(_vm.activityTypeId) &&
                _vm.isCorrectAnswer == 1
                  ? _c(
                      "svg",
                      {
                        staticClass: "right",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 24 24",
                          fill: "#ffffff"
                        }
                      },
                      [
                        _c("use", {
                          attrs: { "xlink:href": "#iconcheckanswer" }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                [4, 6, 7].includes(_vm.activityTypeId) &&
                _vm.isCorrectAnswer == 0
                  ? _c(
                      "svg",
                      {
                        staticClass: "wrong",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "#000000",
                          viewBox: "0 0 256 256"
                        }
                      },
                      [_c("use", { attrs: { "xlink:href": "#iconcross" } })]
                    )
                  : _vm._e()
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "like-reply" }, [
            _c(
              "svg",
              {
                staticClass: "chat-icon",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "20",
                  height: "20",
                  viewBox: "0 0 24 24"
                }
              },
              [_c("use", { attrs: { "xlink:href": "#iconcomment" } })]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.replyClick(_vm.answeredName, "0")
                  }
                }
              },
              [_vm._v("Comment")]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "comments-infor" },
          [
            _vm.totalComments > 1
              ? _c("h5", [_vm._v(_vm._s(_vm.totalComments) + " Comments")])
              : _vm._e(),
            _vm._v(" "),
            _vm.totalComments == 1
              ? _c("h5", [_vm._v(_vm._s(_vm.totalComments) + " Comment")])
              : _c("h5", { attrs: { hidden: "" } }),
            _vm._v(" "),
            _vm._l(_vm.commentsData, function(result, index) {
              return _c(
                "div",
                {
                  staticClass: "comments-box",
                  class: result.level > 0 ? "reply-comment" : "",
                  attrs: { id: "comments-box-".concat(result.rowId) }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "message-comment",
                      class: result.isCoach ? "coach-comment" : ""
                    },
                    [
                      _c("span", [
                        _c("b", [
                          _vm._v(
                            _vm._s(result.isCoach ? "Coach" : "") +
                              " " +
                              _vm._s(result.name)
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "hour" }, [
                        _vm._v(_vm._s(result.dateChatFormat))
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(result.comments))])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "like-comments" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.submitLike(result.rowId, index)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "like-heart",
                            class: result.isLiked ? "active" : ""
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 256 256"
                                }
                              },
                              [_c("use", { attrs: { "xlink:href": "#like" } })]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              result.likeCount > 0 ? result.likeCount : ""
                            ) +
                              " " +
                              _vm._s(result.likeCount > 1 ? "Likes" : "Like")
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.replyClick(result.name, result.rowId)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "chat-icon",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "20",
                              height: "20",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#iconcomment" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              result.commentsCount > 0
                                ? result.commentsCount
                                : ""
                            ) +
                              " " +
                              _vm._s(
                                result.commentsCount > 1 ? "Replies" : "Reply"
                              )
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "response-bottom-sheet" }, [
              _c("div", { staticClass: "bottom-comment" }, [
                _c("p", [
                  _c("b", [_vm._v("Replying to " + _vm._s(_vm.replyingName))])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "type-comment" }, [
                  _c("textarea", {
                    attrs: {
                      id: "reply-comment",
                      placeholder: "Add your comment"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.submitComments()
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "comment-submit",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "#ff7a21",
                            viewBox: "0 0 256 256"
                          }
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#iconentercomment" }
                          })
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "text-danger mt-2",
                    class: _vm.showError ? "" : "d-none"
                  },
                  [_vm._v("Please Enter Reply and Send...")]
                )
              ])
            ])
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
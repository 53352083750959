<template>
    <div class="modal know-more-popup show" data-id="know-more-modal">
    <svg class="d-none">
        <defs>
            <g id="downArrow">
                <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
            </g>
        </defs>
    </svg> <svg class="d-none">
        <defs>
            <g id="chevronBack">
                <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path>
            </g>
        </defs>
    </svg>
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header align-items-center">
                <svg viewBox="0 0 64 64" id="back" class="svg-icon medium d-sm-none hide" ><use xlink:href="#chevronBack"></use></svg>
                <h5 class="know-more-popup-title">Why talk-time sessions are closed in 10 mins</h5> <button type="button" class="close" data-id="know-more-ok-btn" @click="hidePopup">×</button>
            </div>
            <div class="modal-body">
                <div class="know-more-descriptions">
                    <p data-id="know-more-description">
                        <ul>
                            <li>
                                We create smaller groups of 4 to 8 members in breakout rooms
                            </li>
                            <li>
                                Helps you to participate in the discussion
                            </li>
                            <li>
                                The coaches can help you with the conversation
                            </li>
                            <li>
                                So we need all the members to join the session before {{timeData}}
                            </li>
                            <li>
                                Please join the session early to so that you do not miss the meet
                            </li>
                        </ul>
                        <!--In Capshine talk-time we create smaller groups of rooms of 4 to 10 members so that all the members can participate in the discussion and the 
                    coaches are also able to help you with the conversation. For this we need all the members to join the session before the cut-off time ie. 
                    {{timeData}}. Please join the session early to so that you do not miss the meet.-->
                            </p>
                </div>
            </div>

            <div class="modal-footer" data-id="know-more-ok-btn">
                <button type="button" class="btn btn-primary know-more-btn" @click="hidePopup">Ok</button>
            </div>
        </div>
    </div>
</div>
</template>
<script>


export default {
    props: {
         timeData: String,
    },
    data() {
        return {
            content: '',
            showModalclassName: '',
        }
    },
    methods : {
        hidePopup: function () {
             
                document.body.classList.remove("overflow-hidden");
                var vueBaseDiv = document.querySelector("#know-more-base-div");
                vueBaseDiv.innerHTML = "";
                var div = document.createElement("div");
                div.id = "know-more-div";
                vueBaseDiv.appendChild(div);
        }
    }
}
</script>

﻿import FormElements from "../common/FormElements.js";
import CommonValidation from "../common/Validation.js";
import Cookie from "../common/Cookie.js";

var HomePage = {
    init: function () {
        let recentActivitiyDivs = document.querySelectorAll(".recent-activities");
        recentActivitiyDivs.forEach((recentActivity) => {
            let url = recentActivity.getAttribute("data-courseurl")
            if (url) {
                recentActivity.addEventListener('click', (event) => {
                    let url = event.currentTarget.getAttribute("data-courseurl");
                    if (url) {
                        location.href = url;
                    }

                })
                recentActivity.addEventListener('mouseover', (event) => {
                    event.currentTarget.style.cursor = "pointer";
                })
            }
        })
        this.bindClickEvent();
        this.changePageFocus();
    },
    bindClickEvent: function () {
        var acc = document.querySelectorAll(".accordion");
        acc.forEach(a => {
            a.addEventListener("click", function () {
                acc.forEach(b => b.classList.remove("open"));
                a.classList.add("open");
            })
        });
        if (document.querySelector(".faq-show-all")) {
            document.querySelector(".faq-show-all").addEventListener("click", function () {
                acc.forEach(b => b.classList.remove("d-none"));
                this.classList.add("d-none");
            });
        }
        document.querySelectorAll(".openHpVideo").forEach(item => {
            item.addEventListener("click", function () {
                document.querySelector("#Openwindowvideo iframe").setAttribute("src", document.querySelector("#Openwindowvideo iframe").getAttribute("data-src"));
                document.querySelector("#Openwindowvideo").classList.add("show");
            });
        });

        if (document.querySelector(".closeHpVideo")) {
            document.querySelector(".closeHpVideo").addEventListener("click", function () {
                document.querySelector("#Openwindowvideo").classList.remove("show");
                document.querySelector("#Openwindowvideo iframe").setAttribute("src", "");
            });
        }

        document.querySelectorAll(".openTestimonialVideo").forEach(item => {
            item.addEventListener("click", function () {
                document.querySelector("#TestimonialVideoPopup").classList.add("show");
                   
            });
        });

        if (document.querySelector(".closeTestimonialVideo")) {
            document.querySelector(".closeTestimonialVideo").addEventListener("click", function () {
                document.querySelector("#TestimonialVideoPopup").classList.remove("show");
                
                document.querySelector("#TestimonialVideoPopup iframe").setAttribute("src", document.querySelector("#TestimonialVideoPopup iframe").getAttribute("src"));
            });
        }

        if (document.querySelector("[data-id='club-videos-carousel']")) {
            document.querySelectorAll("[data-id='club-videos-carousel']").forEach((item) => {
                item.addEventListener('click', function (event) {
                    console.log("event target", event.currentTarget);
                    let videoUrl = event.currentTarget.getAttribute("data-src");
                    console.log("thumbnail clicked ");
                    document.querySelector("#clubcarouselvideopopup iframe").setAttribute("src", videoUrl);
                    document.querySelector("#clubcarouselvideopopup").classList.add("show");
                })
            })
        }

        if (document.querySelector(".closecarouselvideo")) {
            document.querySelector(".closecarouselvideo").addEventListener("click", function () {
                document.querySelector("#clubcarouselvideopopup").classList.remove("show");
                document.querySelector("#clubcarouselvideopopup iframe").setAttribute("src", '');
            });
        }

        if(document.querySelector("[data-id='category-view-all']")) {
            document.querySelectorAll("[data-id='category-view-all']").forEach((item) => {
                item.addEventListener('click', (event) => {
                    let bucket = event.currentTarget.getAttribute("data-bucket");
                    location.href = `/LearningVideos?bucket=${bucket}`;
                })
            })
        }

        //document.querySelectorAll(".openClubForm").forEach(item => {
        //    item.addEventListener("click", function () {
        //        if (document.getElementById("hdnUserPid").value != "" && document.getElementById("hdnUserPid").value != "0") {
        //            document.querySelector("#ClubSignUpForm").classList.add("show");
        //            document.body.classList.add("slkno-scroll");
        //        } else {
        //            document.getElementById("hdnLoginUrlHash").value = "clubsignup";
        //            document.getElementById("signUpMobile").click();
        //        }
        //    });
        //});

        //document.querySelectorAll(".closeClubForm").forEach(item => {
        //    item.addEventListener("click", function () {
        //        document.querySelector("#ClubSignUpForm").classList.remove("show");
        //        document.body.classList.remove("slkno-scroll");
        //    });
        //});

        document.querySelectorAll("[data-id='rdLang']").forEach((item) => {
            item.addEventListener('click', (event) => {
                if (event.currentTarget.id == "LangOthers") 
                    document.querySelector("#inpLanguageWrap").classList.replace("hide", "show")
                else 
                    document.querySelector("#inpLanguageWrap").classList.replace("show", "hide")
            })
        })


        if (document.querySelector("#clubsignupsubmit")) {
            document.querySelector("#clubsignupsubmit").addEventListener("click", function () {
                //this.clubSignupSubmit.bind(this);

                var allFieldsEntered = true;
                allFieldsEntered = FormElements.validateFormFields("textbox", "Name", true);
                if (allFieldsEntered) {
                    allFieldsEntered = CommonValidation.ValidateName(document.querySelector("#inpName").value);
                    if (!allFieldsEntered) {
                        FormElements.showError("textbox", "Name");
                    }
                }
                allFieldsEntered = FormElements.validateFormFields("textbox", "Mobile", true);
                if (allFieldsEntered) {
                    allFieldsEntered = CommonValidation.ValidateMobileNumber(document.querySelector("#inpMobile").value);
                    if (!allFieldsEntered) {
                        FormElements.showError("textbox", "Mobile");
                    }
                }
                allFieldsEntered = FormElements.validateFormFields("textbox", "Email", true);
                if (allFieldsEntered) {
                    allFieldsEntered = CommonValidation.ValidateEmail(document.querySelector("#inpEmail").value);
                    if (!allFieldsEntered) {
                        FormElements.showError("textbox", "Email");
                    }
                }
                allFieldsEntered = FormElements.validateFormFields("textbox", "Pincode", true);
                if (allFieldsEntered) {
                    allFieldsEntered = CommonValidation.ValidatePincode(document.querySelector("#inpPincode").value);
                    if (!allFieldsEntered) {
                        FormElements.showError("textbox", "Pincode");
                    }
                }

                if (document.querySelector('input[name="rdEnglishLevel"]:checked') == null) {
                    document.querySelector("#divEnglishLevel").classList.add("invalid");
                    allFieldsEntered = false;
                }
                //if (document.querySelector('input[name="rdClassTime"]:checked') == null) {
                //    document.querySelector("#divClassTime").classList.add("invalid");
                //    allFieldsEntered = false;
                //}
                if (document.querySelector('input[name="rdPreferedLanguage"]:checked') == null) {
                    document.querySelector("#divPreferedLanguage").classList.add("invalid");
                    allFieldsEntered = false;
                } else {
                    let preferedLangDiv = document.querySelector('input[name="rdPreferedLanguage"]:checked');
                    if (preferedLangDiv.value == "others") {
                        if (FormElements.validateFormFields("textbox", "Language", true))
                            preferedLangDiv.value = document.querySelector("#inpLanguage").value;
                        else
                            allFieldsEntered = false;
                    }
                }
                
                if (allFieldsEntered) {
                    //Cookie.Add("firstClubMeetTiming", document.querySelector('input[name="rdClassTime"]:checked').value, 1);
                    //alert("ThankYou");
                    let _data = {
                        UserPid: document.getElementById("hdnUserPid").value,
                        Name: document.querySelector("#inpName").value,
                        Email: document.querySelector("#inpEmail").value,
                        Mobile: document.querySelector("#inpMobile").value,
                        PinCode: document.querySelector("#inpPincode").value,
                        EnglishLevel: document.querySelector('input[name="rdEnglishLevel"]:checked').value,
                        //PreferredClassTiming: document.querySelector('input[name="rdClassTime"]:checked').value,
                        PreferredLanguage: document.querySelector('input[name="rdPreferedLanguage"]:checked').value,
                        WhatsAppOptin: document.getElementById("chkClubSignUpWO").checked == true ? "1" : "0"
                    };
                    
                    fetch('/api/student/SaveClubSignups', {
                        method: "POST",
                        body: JSON.stringify(_data),
                        headers: { "Content-type": "application/json; charset=UTF-8" }
                    })
                        .then(response => response)
                        .then(data => {
                            console.log('Success:', data);
                            window.location.href = "/clubthankyou";
                        })
                        .catch(err => console.log(err));
                }
            });
        }
    },
    changePageFocus: function () {
        var locationHash = location.hash.substr(1);
        if (locationHash != null && locationHash.toLowerCase() == "starspeaker") {
            document.body.scrollTop = document.getElementById('starspeakerheading').offsetTop;
        }
        else if (locationHash != null && locationHash.toLowerCase() == "masterclass") {
            document.body.scrollTop = document.getElementById('masterclassheading').offsetTop;
        }
    },
    clubSignupSubmit: function () {

    }
}
if (document.getElementById('hdnPageType') != null && document.getElementById('hdnPageType').value == "Home")
    HomePage.init();
<template>
    <div id="Openwindowvideofilter" class="modal filter-option-popup" style="display: block;">
        <svg class="d-none"><defs><g id="downArrow">
            <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></g></defs></svg> <svg class="d-none">
                <defs><g id="chevronBack">
                    <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path></g></defs></svg> 
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header align-items-center">
                                <svg viewBox="0 0 64 64" id="back" class="svg-icon medium d-sm-none" @click="onClose"><use xlink:href="#chevronBack"></use></svg> 
                                <h5>Filter</h5> <button type="button" class="close" @click="onClose">×</button>
                            </div> 
                            <div class="modal-body">
                                <div class="filter-option-select">
                                    <p><b>Topic</b></p>
                                    <div class="popup-filter-option">
                                        <span :class="getTopicFilterClassName('All Videos')" @click="onclickTopicFilter">All Videos</span>
                                        <span v-for="category in videoDetailsProp" :class="getTopicFilterClassName(category.bucket)" @click="onclickTopicFilter">{{category.bucket}}</span>
                                    </div>
                                </div>

                                <div class="language-option-select mt-5">
                                    <p><b>Language</b></p>
                                    <div class="popup-language-filter">
                                        <span  :class="getLanguageFilterClassName('Tamil')" @click="onClickLanguageFilter">Tamil</span>
                                        <span  :class="getLanguageFilterClassName('English')" @click="onClickLanguageFilter">English</span>
                                        <span  :class="getLanguageFilterClassName('Malayalam')" @click="onClickLanguageFilter">Malayalam</span>
                                        <!-- <span  :class="getLanguageFilterClassName('Telugu')" @click="onClickLanguageFilter">Telugu</span> -->
                                        <span  :class="getLanguageFilterClassName('Hindi')" @click="onClickLanguageFilter">Hindi</span>
                                    </div>
                                    
                                </div>

                                <div class="filter-option-select-btn mt-5">
                                    <a class="btn btn-primary filter-option-apply-btn" @click="onClickApply">Apply</a>
                                </div>
                            </div>
                    </div>
                </div>
    </div>
</template>
<script>
export default {
    name : "ClubVideoFilter",
    props : {
        selectedTopicFilterProp : Array,
        selectedLanguageFilterProp : String,
        videoDetailsProp: Array,
    },
    data() {
        return {
            // selectedTopicFilter : this.selectedTopicFilterProp ? [...this.selectedTopicFilterProp] : [],
            selectedLanguageFilter : this.selectedLanguageFilterProp ? this.selectedLanguageFilterProp : '',
            selectedTopicFilter : this.selectedTopicFilterProp ? this.selectedTopicFilterProp : '',

        }
    },
    beforeMount: function () {
        // if(this.selectedTopicFilterProp) 
        //     this.selectedTopicFilter = this.selectedTopicFilterProp;
        // if(this.selectedLanguageFilterProp)
        //     this.selectedLanguageFilter = this.selectedLanguageFilterProp;
    },
    methods: {
        onClose: function()  {
            this.$emit('onClose');
        },
        // getTopicFilterClassName: function (bucket) {
        //     if(this.selectedTopicFilter.includes(bucket))
        //         return "topic-filter mt-3 select-topic";
        //     return "topic-filter mt-3";
        // },
        getTopicFilterClassName: function (bucket) {
            if(this.selectedTopicFilter == bucket)
                return "topic-filter mt-3 select-topic";
            return "topic-filter mt-3";
        },
        getLanguageFilterClassName : function (language) {
            if(this.selectedLanguageFilter == language) 
                return "language-filter mt-3 select-topic";
            return "language-filter mt-3";
        },
        // onclickTopicFilter: function (event) {
        //     let bucket = event.currentTarget.innerText;
        //     if(event.currentTarget.classList.contains("select-topic")) {
        //         for(let i =0; i < this.selectedTopicFilter.length; i++) {
        //             if(this.selectedTopicFilter[i] == bucket) {
        //                 this.selectedTopicFilter.splice(i,1);
        //             }
        //         }
        //     } else {
        //         this.selectedTopicFilter.push(bucket);
        //     }
        // },
        onclickTopicFilter : function (event) {
            this.selectedTopicFilter = event.currentTarget.innerText;
        },
        onClickLanguageFilter: function (event) {
            this.selectedLanguageFilter = event.currentTarget.innerText;
        },
        onClickApply: function () {
            let appliedFilter = {
                selectedTopicFilter : this.selectedTopicFilter,
                selectedLanguageFilter : this.selectedLanguageFilter,
            }
            this.$emit('onApplyFilter', appliedFilter);
            this.onClose();
        }
    }
    
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "close cabso mt-2 mr-2",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      },
      [_vm._v("×")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row mx-0" },
      [
        _c("DisplayUserInfor", {
          attrs: { instructorDetails: _vm.instructorDetails }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col col-lg-6 bg-light py-3" }, [
          _c("h4", { staticClass: "py-3" }, [
            _vm._v(
              "\n                Please select a time that is convenient with you. Timezone is in Indian Standard Time\n            "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "time-slot-container" }, [
            _c(
              "div",
              { staticClass: "row-time" },
              _vm._l(_vm.demoTimeSlots, function(demoslot, rowindex) {
                return _c(
                  "div",
                  {
                    key: "selected" + rowindex,
                    staticClass: "col px-0 pt-2 day-col pb-3"
                  },
                  [
                    _c("div", { staticClass: "caption text-muted" }, [
                      _vm._v(_vm._s(demoslot.DemoDateTimeStringFormat))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "overflow-x-hidden" },
                      _vm._l(
                        _vm.filterItems(
                          demoslot.DemoTime,
                          demoslot.isSeeMore,
                          rowindex
                        ),
                        function(time, timeindex) {
                          return _c(
                            "span",
                            {
                              key: "time" + timeindex,
                              class: _vm.getTimeClass(rowindex, timeindex),
                              attrs: {
                                id: demoslot.DemoDateTimeFormat + "|" + time
                              },
                              on: {
                                click: function($event) {
                                  return _vm.selectedDateTime(
                                    $event,
                                    demoslot.DemoDateTimeFormat,
                                    time,
                                    demoslot.DemoDateTimeStringFormat,
                                    rowindex,
                                    timeindex
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(time) +
                                  "\n                                "
                              )
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    demoslot.DemoTime.length > 6
                      ? _c("div", [
                          demoslot.isSeeMore == true
                            ? _c(
                                "a",
                                {
                                  staticClass: "link caption d-block  mt-3",
                                  on: {
                                    click: function($event) {
                                      return _vm.seemoreless(
                                        "See more",
                                        rowindex
                                      )
                                    }
                                  }
                                },
                                [_vm._v("See more")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          demoslot.isSeeMore == false
                            ? _c(
                                "a",
                                {
                                  staticClass: "link caption d-block mt-3",
                                  on: {
                                    click: function($event) {
                                      return _vm.seemoreless(
                                        "See less",
                                        rowindex
                                      )
                                    }
                                  }
                                },
                                [_vm._v("See less")]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "next-button" }, [
            _c("div", [
              _vm.selectedDate != ""
                ? _c("p", [_vm._v(" " + _vm._s(_vm.selectedDate) + " ")])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "error-text",
                  class: _vm.isrequired ? "show" : ""
                },
                [_vm._v("Select Time slot")]
              )
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-block btn-primary",
                on: {
                  click: function($event) {
                    return _vm.proceedNextStep()
                  }
                }
              },
              [_vm._v("Confirm")]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
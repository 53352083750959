<template>
<div>
 <div class="topic-filter-choose">
        <div class="left-video">
            <h3>Learn Through Videos</h3>
        </div>
        <!-- <div class="right-filter">
            <p class="filter-option-popup" @click="openFilter"><b>Filter</b></p>
        </div> -->
        <div class="filter-topic-btn mt-2">
                <button type="button" class="filter-border-btn" @click="openFilter" data-id="vue-filter-btn">
                    <svg class="icon-filter-align" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="" viewBox="0 0 256 256">
                    <line x1="147.99951" y1="171.99353" x2="39.99951" y2="171.99365" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                    <line x1="215.99951" y1="171.99365" x2="187.99951" y2="171.99353" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                    <circle cx="167.99951" cy="171.99353" r="20" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle>
                    <line x1="83.99951" y1="83.99353" x2="39.99951" y2="83.99294" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                    <line x1="215.99951" y1="83.99294" x2="123.99951" y2="83.99353" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                    <circle cx="103.99951" cy="83.99353" r="20" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle>
                    </svg>Filter</button>
        </div>
    </div>



    <!-- <div class="filter-topic-title-head">
        <div class="filter-details-videos mt-4">
            <h6><b>Topic :</b></h6>
        </div>
        <span :class="getTopicFilterClassName('All Videos')" ref="allVideosBtn" @click="onClickAllVideos">All Videos</span>
        <span v-for="category in videoDetails" :class="getTopicFilterClassName(category.bucket)" @click="onclickTopicFilter">{{category.bucket}}</span>
       
    </div> -->
    <div v-for="category in videoDetails">
        <div v-if="selectedTopicFilter.includes('All Videos') ||selectedTopicFilter.includes(category.bucket)">
            <div class="title-filter-topic mt-4">
                <h5>{{category.bucket}}</h5>
            </div>

            <div class="filter-videos-details">
                <div v-for="details in category.clubVideos" class="filter-topic-details" :data-videoURL="details.videoURL" :data-bucket="category.bucket" @click="onVideoClick">
                <img class="learn-video-thumbnil" :src="getThumbnailLinkFromVideoLink(details.videoURL)">
                    <img class="play-media" src="https://sulcdn.azureedge.net/content/caps/homepage/play-icon.png">
                    <p class="words mt-2"><b>{{details.videoTitle}}</b></p>
                    <!-- <p><span class="like-counts"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M31.99414,104h48a0,0,0,0,1,0,0V208a0,0,0,0,1,0,0h-48a8,8,0,0,1-8-8V112A8,8,0,0,1,31.99414,104Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M79.99414,104l40-80a32,32,0,0,1,32,32V80h61.87549a16,16,0,0,1,15.87644,17.98456l-12,96A16,16,0,0,1,201.86963,208H79.99414" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg> 10K</span></p> -->
                </div>
            </div>
        </div>
        

    </div>
    
    <ClubVideoFilter v-if="showFilter" v-on:onClose="closeFilter"
    v-bind:videoDetailsProp="videoDetails"
    v-bind:selectedTopicFilterProp="selectedTopicFilter"
    v-bind:selectedLanguageFilterProp="selectedLanguageFilter"
    v-on:onApplyFilter="onApplyFilter"></ClubVideoFilter>

    <ClubVideoPlayer v-if="showVideoPlayer"
    :relatedVideoDetailsProp="relatedVideoDetails"
    :mainVideoURL="selectedVideoURL"
    :category="selectedVideoBucket"
    v-on:onClose="closeVideoPlayer">
    </ClubVideoPlayer>
</div>

   
</template>

<script>
import ClubVideoFilter from "./ClubVideoFilter.vue";
import ClubVideoPlayer from "./VideoPlayerPopup.vue";
import Loader from "../../wwwroot/js/Loader.js";
    export default {
        name: "LearningVideoSection",
        props: {
            bucket: String,
            language: String,
        },
        data() {
            return {
                showFilter : false,
                showVideoPlayer: false,
                videoDetails: [],
                selectedTopicFilter: [],
                selectedLanguageFilter: '',
                relatedVideoDetails: [],
                selectedVideoURL: '',
                selectedVideoBucket: '',
            }
        },
        beforeMount: function () {
            let videoDetailsString = document.getElementById("club-videos-data-json").innerText;
            this.videoDetails = JSON.parse(videoDetailsString);
            console.log("the video details ", this.videoDetails);
            if(this.bucket) {
                this.selectedTopicFilter.push(this.bucket);
            } else {
                this.selectedTopicFilter.push("All Videos");
            }
            if(this.videoDetails) 
                this.selectedLanguageFilter = this.videoDetails[0].language;
        },
        components: {
            ClubVideoFilter : ClubVideoFilter,
            ClubVideoPlayer : ClubVideoPlayer,
        },
        methods: {
            openFilter : function () {
                document.body.classList.add("overflow-hidden");
                this.showFilter = true;
            },
            closeFilter: function () {
                document.body.classList.remove("overflow-hidden");
                this.showFilter = false;
            },
            openVideoPlayer: function() {
                document.body.classList.add("overflow-hidden");
                this.showVideoPlayer = true;
            },
            closeVideoPlayer : function () {
                document.body.classList.remove("overflow-hidden");
                this.showVideoPlayer = false;
            },
            getVideoId: function (url) {
                if(url) {
                    return url.replace("https://youtu.be/","");
                }   
            },
            getThumbnailLinkFromVideoLink: function (videoLink) {
                 if(videoLink) {
                    return `https://img.youtube.com/vi/${this.getVideoId(videoLink)}/sddefault.jpg`;
                }
            },
            getTopicFilterClassName: function (bucket) {
                if(this.selectedTopicFilter.includes(bucket))
                    return "filter-title-name mt-3 selected-topic";
                return "filter-title-name mt-3";
            },
            onclickTopicFilter: function(event) {
                let topicFilter  = event.currentTarget.innerText;
               
                if(event.currentTarget.classList.contains("selected-topic")) {
                    for(let i =0; i < this.selectedTopicFilter.length; i++) {
                        if(this.selectedTopicFilter[i] == topicFilter) {
                            this.selectedTopicFilter.splice(i,1);
                        }
                    }
                } else {
                    this.removeAllVideosFromSelectedFilters();
                    this.selectedTopicFilter.push(topicFilter);
                    
                }
            },
            onClickAllVideos: function() {
                this.selectedTopicFilter = [];
                this.selectedTopicFilter.push("All Videos");
            },
            removeAllVideosFromSelectedFilters: function () {
                for(let i =0; i < this.selectedTopicFilter.length; i++) {
                        if(this.selectedTopicFilter[i] == "All Videos") {
                            this.selectedTopicFilter.splice(i,1);
                            return;
                        }
                    }
            },
            onApplyFilter: function (appliedFilters) {
                let oldLanguageFilter = this.selectedLanguageFilter;
                console.log("the old lang filter ", oldLanguageFilter);
                if(appliedFilters) {
                    this.selectedTopicFilter = appliedFilters.selectedTopicFilter;
                    this.selectedLanguageFilter = appliedFilters.selectedLanguageFilter;
                }
                if(appliedFilters.selectedTopicFilter) {
                     this.removeAllVideosFromSelectedFilters();
                }
                if(appliedFilters.selectedLanguageFilter != oldLanguageFilter ) {
                    this.getVideosForLanguage(this.selectedLanguageFilter);
                }
            },
            onVideoClick: function (event) {
                this.selectedVideoURL = event.currentTarget.getAttribute("data-videoURL");
                this.selectedVideoBucket = event.currentTarget.getAttribute("data-bucket");
                for(let i=0; i<this.videoDetails.length; i++) {
                    if(this.videoDetails[i].bucket == this.selectedVideoBucket) {
                        this.relatedVideoDetails = [...this.videoDetails[i].clubVideos];
                    }
                }
                this.openVideoPlayer();
            },
            getVideosForLanguage: function () {
                let url = `/api/myclub/GetclubVideosForLanguage?language=${this.selectedLanguageFilter}`;
                Loader.show();
                fetch(url)
                .then(response => response.json())
                .then(data => {
                    if(data) {
                        console.log("videos for language", data);
                        this.videoDetails = data;
                        Loader.hide();
                    }
                })
            }
        },
        watch: {
            selectedTopicFilter : function (value) {
                if(value.length == 0) {
                    this.selectedTopicFilter.push("All Videos");
                    //this.$refs.allVideosBtn.classList.add("selected-topic");
                }
            },
        }
    }
</script>
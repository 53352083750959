<template>
    <div>
        <button class="btn btn-primary btn-block mb-5" @click="onClickAddNew" style="width: 161px;">Add new schedule</button>
        <AddNewClubSchedule v-if="showForm"
                            :onClose="onFormClose"
                            :clubScheduleDetails="clubScheduleDetails"
                            :availableClubNames="availableClubNames"
                            :availableMeetingTypes="availableMeetingTypes"
                            :coaches="coaches"
                            :hosts="hosts"
                            :coHosts="coHosts">
            
        </AddNewClubSchedule>
        <div class="row" >
            <div class="col-4" v-for="schedule in upcomingSchedules" style="border-style: groove;">
                <table>
                    <tr>
                        <td><label>Day : </label></td>
                        <td><span>{{schedule.day}} </span></td>
                    </tr>
                    <tr>
                        <td><label>Date : </label></td>
                        <td><span>{{schedule.date}}</span></td>
                    </tr>
                    <tr>
                        <td><label>Meeting Type : </label></td>
                        <td><span>{{schedule.meetingType}}</span></td>
                    </tr>
                    <tr>
                        <td><label>Club Name : </label></td>
                        <td><span>{{schedule.clubName}}</span></td>
                    </tr>
                    <tr>
                        <td><label>Level : </label></td>
                        <td><span>{{schedule.clubLevel}}</span></td>
                    </tr>
                    <tr>
                        <td><label>Time : </label></td>
                        <td><span>{{schedule.time}}</span></td>
                    </tr>
                    <tr>
                        <td><label>Duration : </label></td>
                        <td><span>{{schedule.duration}}</span></td>
                    </tr>
                    <tr>
                        <td><label>Topic : </label></td>
                        <td><span>{{schedule.displayMeetingTopic}}</span></td>
                    </tr>
                    <tr>
                        <td><label>Coach : </label></td>
                        <td><span>{{schedule.coachName}}</span></td>
                    </tr>
                    <tr>
                        <td><label>Host : </label></td>
                        <td><span>{{schedule.host}}</span></td>
                    </tr>
                    <tr>
                        <td><label>Co-Host : </label></td>
                        <td><span>{{schedule.coHostNames}}</span></td>
                    </tr>
                    <tr>
                        <td>
                            <button class="btn btn-primary btn-block" @click="onClickEditBtn" :data-schedule-id="schedule.clubScheduleId">Edit</button>
                        </td>
                    </tr>
                </table>
            </div>
            
        </div>

    </div>
    
</template>

<script>
    import AddNewClubSchedule from "./AddNewClubSchedule.vue";
    export default {
        data() {
            return {
                showForm: false,
                text: "dummy text",
                shouldDisable: true,
                schedules: [{ name: "schedule one ", id: 213 }, { name: "schedule two ", id: 137 }],
                upcomingSchedules: [],
                clubScheduleDetails: {},
                weekDays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thurdsay", "Friday", "Saturday"],
                availableClubNames: [{ id: '0', name: 'All' }],
                availableMeetingTypes: [],
                coaches: [],
                hosts: [],
                coHosts: [],
            }
        },
        components: {
            AddNewClubSchedule: AddNewClubSchedule,
        },
        beforeMount:async function () {
            await this.getClubNames();
            this.getUpComingClubDetails();
        },
        methods: {
            onClickAddNew: function () {
                this.clubScheduleDetails = {};
                this.showForm = true;
            },
            onClickEditBtn: function (event) {
                let id = event.currentTarget.getAttribute("data-schedule-id");
                
                let scheduleDetails = this.upcomingSchedules.filter((schedule) => {
                    
                    return schedule.clubScheduleId == id;
                })
                
                this.clubScheduleDetails = scheduleDetails[0];
                this.showForm = true;
                
                
            },
            onFormClose: function () {
                this.showForm = false;
            },
            getUpComingClubDetails: function () {
                console.log("get upcoming clubb details started ");
                let url = "/api/admin/Get-upcoming-club-schedules";
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            data.forEach((schedule) => {
                                let dateTime = new Date(schedule.scheduleDateTime);
                                
                                schedule.date = dateTime.toLocaleString('en-IN', {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "numeric" })
                                schedule.day = this.weekDays[dateTime.getDay()];
                                schedule.time = dateTime.toLocaleString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true })
                                schedule.clubName = schedule.clubId == '0' ? "All" : this.getClubNameForClubId(schedule.clubId);
                                schedule.host = this.getHostNameForHostId(schedule.hostUserId);
                                schedule.coachName = this.getCoachNameForCoachId(schedule.coachUserId);
                                schedule.meetingType = this.getMeetingTypeForTypeId(schedule.meetingTypeId);
                                schedule.clubLevel = schedule.clubLevel ? schedule.clubLevel : "Both";
                                schedule.coHostNames = schedule.coHostUserids ? this.getCoHostNameForIds(schedule.coHostUserids) : '';
                            })
                            this.upcomingSchedules = data;
                            
                        }
                        console.log("get upcoming club details end ");
                    })
            },
            getClubNames: async function () {
                console.log("get club names started ");
                let url = "/api/admin/get-club-names";
                let response = await fetch(url);
                let data = await response.json();
                if (data) {
                    data.forEach((item) => {
                        if (item.tableName == "ClubMaster") {
                            this.availableClubNames.push(item);
                        }
                        else if (item.tableName == "ClubMeetingTypeMaster") {
                            this.availableMeetingTypes.push(item);
                        }
                        else if (item.tableName == "Coaches") {
                            this.coaches.push(item);
                        }
                        else if (item.tableName == "Hosts") {
                            this.hosts.push(item);
                        }
                        else if (item.tableName == "Co-Host") {
                            this.coHosts.push(item);
                        }
                    })
                }
                console.log("get club names completed ");

            },
            getClubNameForClubId: function (clubId) {
                let clubName = '';
                for (let i = 0; i < this.availableClubNames.length; i++) {
                    if (this.availableClubNames[i].id == clubId) {
                        clubName = this.availableClubNames[i].name;
                        break;
                    }
                }
                return clubName
                
            },
            getHostNameForHostId: function (hostId) {
                let hostName = '';
                for (let i = 0; i < this.hosts.length; i++) {
                    if (this.hosts[i].id == hostId) {
                        hostName = this.hosts[i].name;
                        break;
                    }
                }
                return hostName
            },
            getMeetingTypeForTypeId: function (meetingTypeId) {
                let meetingType = '';
                for (let i = 0; i < this.availableMeetingTypes.length; i++) {
                    if (this.availableMeetingTypes[i].id == meetingTypeId) {
                        meetingType = this.availableMeetingTypes[i].name;
                        break;
                    }
                }
                return meetingType
            },
            getCoachNameForCoachId: function (coachId) {
                let coachName = '';
                for (let i = 0; i < this.coaches.length; i++) {
                    if (this.coaches[i].id == coachId) {
                        coachName = this.coaches[i].name;
                        break;
                    }
                }
                return coachName
            },
            getCoHostNameForIds: function (coHostIds) {
                let coHostsIdsArray = coHostIds.split(",");
                let coHostsNameArray = [];
                let that = this;
                console.log("the co hosts ", this.coHosts)
                for (let i = 0; i < coHostsIdsArray.length; i++) {
                    for (let j = 0; j < that.coHosts.length; j++) {
                        if (that.coHosts[j].id == coHostsIdsArray[i]) {
                            coHostsNameArray.push(that.coHosts[j].name);
                            break;
                        }
                    }
                }
                return coHostsNameArray.join();

            }
        }
    }
</script>
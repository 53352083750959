<template>
    <div class="modal multistep-form" id="Openwindow" style="display: block;">
        <svg class="d-none">
            <defs>
                <g id="downArrow">
                    <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                </g>
            </defs>
        </svg>
        <svg class="d-none"><defs><g id="chevronBack"><path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path></g></defs></svg>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header align-items-center">
                    <svg v-if="currentStep != 'Welcome'" viewBox="0 0 64 64" id="back" class="svg-icon medium d-sm-none" @click="onClickBack"><use xlink:href="#chevronBack"></use></svg> <h5>Club Registration</h5> <button type="button" class="close" @click="closeClubPopup">×</button>
                </div>
                <div class="modal-body">
                    <div class="registeration-box">
                        <div class="form">
                            <div class="step current-slide" id="step1" ref="step1">
                                <div class="group">
                                    <h5 class="number-title">What's your WhatsApp number? <sup>*</sup></h5>
                                    <div class="phone-input md-field mb-3">
                                        <h6 class="country" @click="openCountrySearch()">{{ userDetails.countryCode }}
                                            <svg class="svg-icon medium" viewBox="0 0 448 512">
                                                <use xlink:href="#downArrow" />
                                            </svg></h6>
                                        <input type="number" id="mobile" name="mobile" required="required" placeholder="Your phone number" class="mobile" v-model="userDetails.mobileNo" @keyup="hideErrorText">
                                        <label class="mobile-text" alt="mobile" for="mobile"> Your phone number </label>
                                        <span class="error-text">Enter a valid phone number</span>
                                        <div class="dropdown-wrapper" :class="{ active: showCountrySearch }">
                                            <CountryCode v-model="selection" :selectionCallBack="countryCodeSelectionCallBack" :onCountryCodeBlur="onCountryCodeBlur"></CountryCode>
                                        </div>

                                    </div>
                                    <!-- <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button> -->
                                    <button type="button" id="next" class="btn btn-primary next-form-btn mt-3" @click="onClickNext">Next <svg class="check-icon-align" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="20"></polyline></svg></button>

                                    <div class="my-5"><div class="text-center separator"><h6><span>&nbsp; OR &nbsp;</span></h6></div></div>
                                    <label class="caption">Already have an account? <a href="#" class="link" @click="showLoginPopup">Login</a></label>
                                </div>
                            </div>


                            <div class="step next-slide" id="step2" ref="step2">
                                <div class="group">
                                    <h5 class="number-title">Enter the OTP</h5>
                                    <div class="row">
                                        <p class="text-muted col-sm-8 col-12 caption">We sent an OTP to your phone number <br> <b>{{userDetails.countryCode}} {{userDetails.mobileNo}}</b></p>
                                        <span class="link caption col-sm-4 col-12 text-right edit-number" @click="onClickEditNumber">Edit this number</span>
                                    </div>
                                    <div class="otp-form">
                                        <div class="otp-group otp">
                                            <div class="single-input">
                                                <div class="inputOtpCenter">
                                                    <div class="inputOtp-group phone-input">
                                                        <span class="box-1"></span>
                                                        <span class="box-2"></span>
                                                        <span class="box-3"></span>
                                                        <span class="box-4"></span>
                                                        <span class="lastbar"></span>
                                                        <input type="number" maxlength="4" @keydown="onKeyDown($event)" ref="code1" v-model="verificationCode" id="code-1">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="error-text"> Incorrect OTP </span>
                                        <!-- <a class="link" href="#" @click="onClickResendOtp">Resend Now</a> -->
                                        <label class="caption" v-if="!resetotp">
                                            Resend OTP in {{countDown}} Seconds
                                        </label>
                                        <a v-if="resetotp" class="link" @click="onClickResendOtp">Resend now</a>
                                    </div>
                                    <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button>
                                    <!-- <button type="button" id="next" class="btn btn-primary next-form-btn mt-3" @click="onClickNext">Next <svg class="check-icon-align" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg></button> -->
                                </div>

                            </div>

                            <div class="step next-slide" id="step3" ref="step3">
                                <div class="group">
                                    <h5 class="number-title">What's your first and last name? <sup>*</sup></h5>
                                    <div class="md-field mt-3 mb-3">
                                        <input type="text" name="name" id="name" required="required" placeholder="First and last name" v-model="userDetails.userName" @keyup="hideErrorText">
                                        <label alt="name" for="name" placeholder="First and last name">
                                            First and last name
                                        </label> <span class="error-text">Enter a valid name</span>
                                    </div>
                                    <!-- <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button> -->
                                    <button type="button" id="next" class="btn btn-primary next-form-btn mt-3" @click="onClickNext">Next <svg class="check-icon-align" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg></button>
                                </div>
                            </div>


                            <div class="step next-slide" id="step4" ref="step4">
                                <div class="group">
                                    <h5 class="number-title">What's your email id? <sup>*</sup></h5>
                                    <div class="md-field mt-3 mb-3">
                                        <input type="email" name="email" id="email" required="required" placeholder="Email ID" v-model="userDetails.emailId" @keyup="hideErrorText">
                                        <label alt="email" for="email"> Email ID </label>
                                        <span class="error-text">Enter a valid email</span>
                                    </div>
                                    <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button>
                                    <button type="button" id="next" class="btn btn-primary next-form-btn mt-3" @click="onClickNext">Next <svg class="check-icon-align" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg></button>
                                </div>
                            </div>


                            <div class="step next-slide" id="step5" ref="step5">
                                <div class="group">
                                    <h5 class="number-title">What's your pincode? <sup>*</sup></h5>
                                    <div class="md-field mt-3 mb-3" >
                                    <Pincode :pincodeProp="pincode" value="" :pincodeData="pincodeList"
                                                :selectionCallBack="pincodeSelectionCallBack" :onPincodeBlur="onPincodeBlur"></Pincode>
                                                 <span class="error-text">Enter a valid pincode</span>
                                    </div>
                                    <!-- <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button> -->
                                    <button type="button" id="next" class="btn btn-primary next-form-btn mt-3" @click="onClickNext">Next <svg class="check-icon-align" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg></button>
                                </div>
                            </div>

                            <div class="step next-slide" id="step6" ref="step6">
                                <div class="group">
                                    <h5 class="number-title">What is your gender? <sup>*</sup></h5>
                                    <ul class="level-content-box">
                                        <li @click="onSelectValue" data-Gender>Male</li>
                                        <li @click="onSelectValue" data-Gender>Female</li>
                                        <li @click="onSelectValue" class="" data-gender>Others</li>
                                    </ul>
                                    <span class="error-text">Select an option</span>
                                    <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button>
                                    <button type="button" id="next" class="btn btn-primary next-form-btn mt-3" @click="onClickNext">Next <svg class="check-icon-align" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg></button>
                                </div>
                                
                            </div>

                            <div class="step next-slide" id="step7" ref="step7">
                                <div class="group">
                                    <h5 class="number-title">What’s your age group? <sup>*</sup> </h5>
                                    <ul class="level-content-box">
                                        <li @click="onSelectValue" data-Age> < 20 Years</li>
                                        <li @click="onSelectValue" data-age>20 to 25 Years</li>
                                        <li @click="onSelectValue" data-Age>25 to 30 Years</li>
                                        <li @click="onSelectValue" data-Age>30 to 35 Years</li>
                                        <li @click="onSelectValue" data-Age>35 to 40 Years</li>
                                        <li @click="onSelectValue" data-Age>40 to 45 Years</li>
                                        <li @click="onSelectValue" data-Age>45 to 50 Years</li>
                                        <li @click="onSelectValue" data-Age>> 50 Years</li>
                                    </ul>
                                    <span class="error-text">Select an option</span>
                                    <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button>
                                    <button type="button" id="next" class="btn btn-primary next-form-btn mt-3" @click="onClickNext">Next <svg class="check-icon-align" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg></button>
                                </div>
                            </div>

                            <div class="step next-slide" id="step8" ref="step8">
                                <div class="group">
                                    <h5 class="number-title">What’s your mother tongue? <sup>*</sup></h5>
                                    <ul class="level-content-box">
                                        <li @click="onSelectValue" data-Language>English</li>
                                        <li @click="onSelectValue" data-language>Hindi</li>
                                        <li @click="onSelectValue" data-Language>Tamil</li>
                                        <li @click="onSelectValue" data-Language>Malayalam</li>
                                        <li @click="onSelectValue" data-Language>Telugu</li>
                                        <li @click="onSelectValue" data-Language>Kannada</li>
                                        <li @click="onSelectOther" data-other-option>Others</li>
                                        
                                        <div class="md-field mt-3 mb-3 hide" data-other>
                                            <input type="text" name="club-language" id="club-language" required="required" placeholder="Language" @keyup="onKeyupOther">
                                            <label alt="club-language" for="club-language" placeholder="Language">Enter your other language</label>
                                            <!--<span class="error-text"></span>-->
                                        </div>
                                    </ul>
                                    <span class="error-text">Select an option</span>
                                    <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button>
                                    <button type="button" id="next" class="btn btn-primary next-form-btn mt-3" @click="onClickNext">Next <svg class="check-icon-align" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg></button>
                                    <br>
                                        <br>
                                        <br>
                                        <br>
                                </div>
                            </div>

                            <div class="step next-slide" id="step9" ref="step9">
                                <div class="group">
                                    <h5 class="number-title">Select your occupation <sup>*</sup></h5>
                                    <ul class="level-content-box">
                                        <li @click="onSelectValue" data-occupation>Student</li>
                                        <li @click="onSelectValue" data-occupation>Looking for a job </li>
                                        <li @click="onSelectValue" data-occupation>Employed </li>
                                        <li @click="onSelectValue" data-occupation>Homemaker </li>
                                        <li @click="onSelectValue" data-occupation>Business Owner / Freelancer </li>
                                        <li @click="onSelectOther" data-other-option>Others</li>
                                
                                        <div class="md-field mt-3 mb-3 hide" data-other>
                                            <input type="text" name="club-occupation" id="club-occupation" required="required" placeholder="Enter you other occupation" @keyup="onKeyupOther">
                                            <!--<input type="text" name="name" id="name" required="required" placeholder="Enter your other Occupation">-->
                                            <label alt="club-occupation" for="club-occupation" placeholder="Enter your other Occupation">Enter your other Occupation</label>
                                            <!--<span class="error-text"></span>-->
                                        </div>
                                    </ul>
                                    <span class="error-text">Select an option</span>
                                    <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button>
                                    <button type="button" id="next" class="btn btn-primary next-form-btn mt-3" @click="onClickNext">Submit <svg class="check-icon-align" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="216 72.005 104 184 48 128.005" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg></button>
                                     <br>
                                        <br>
                                        <br>
                                        <br>
                                </div>
                            </div>

                            <div class="step next-slide" id="step11" ref="step10">
                                <div class="group welcome">
                                    <div class="welcome-banner d-flex">
                                        <div class="welcome-check">
                                            <img src="https://lscdn.blob.core.windows.net/content/caps/dot-circle.jpg" alt="welcome-check-image">
                                        </div>
                                        <div class="welcome-notification">
                                            <!--<p>Welcome to <br> Capshine's Learn It Together LIT <sub>TM</sub> Clubs</p>-->
                                            <p>Congratulations you are a member of {{clubName}} club</p>
                                            <p>You can now connect with {{clubMemberCount}} members of your club.</p>
                                        </div>
                                    </div>
                                    <div class="club-improve">
                                        <h5 class="number-title">How Capshine Clubs will help you improve your English</h5>
                                        <div class="improve-english-video">
                                            <iframe class="improve-video" :src="welcomeScreenVideoUrl" title="YouTube video player" allowfullscreen="" :srcdoc="lassyLoadVideoUrl">
                                            </iframe>
                                        </div>
                                        <!-- <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button> -->
                                        <button type="button" id="next" class="btn btn-primary next-form-btn  mt-3" @click="onClickNext">Upcoming Club Schedule</button>
                                    </div>
                                </div>
                            </div>

                            <div class="step next-slide" id="step11" ref="step11">
                                <div class="group welcome">
                                    <div class="welcome-banner d-flex">
                                        <!-- <div class="welcome-check">
                                            <img src="assets/images/circle-dot.png" alt="welcome-check-image">
                                        </div> -->
                                        <div class="welcome-notification">
                                            <p>Thank you! <br> For registering in Capshine Clubs</p>
                                        </div>
                                    </div>
                                    <div class="club-improve">
                                        <h5 class="number-title">You will receive a notification with the club details shortly!</h5>
                                        <!-- <div class="improve-english-video">
                                            <iframe class="improve-video" :src="welcomeScreenVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                        </div> -->
                                        <!-- <button type="button" id="back" class="btn btn-outline-primary back back-form-btn mt-3" @click="onClickBack">Back </button> -->
                                        <button type="button" id="next" class="btn btn-primary next-form-btn  mt-3"  @click="closeClubPopup">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Loader from "../../wwwroot/js/Loader.js";
    import CountryCode from "../Common/CountryCode.vue";
    import Pincode from "../Common/Pincode.vue";
    import SulGA from "../../wwwroot/js/Ga.js";

    export default {
        data() {
            return {
                //steps: ["MobileNo", "OTP", "Name", "Email", "Pincode", "Gender", "Age", "Language", "Occupation","Welcome","DayTime"],
                regexp: {
                    alpha: /[a-zA-Z ]{2,30}$/,
                    number: /[^0-9]/g,
                    email: /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i,
                    mobile1: /^[9|7|6|8]\d{8}[\d{1}]$/i,
                    internationalmobile: /^[0-9]{6,14}$/
                },
                verificationCode: "",
                otpVerification: {
                    code1: "",
                    code2: "",
                    code3: "",
                    code4: "",
                    verificationCodeErrorText: "",
                    isFormvalid: true,
                    inValidOtpErrorText: "Please enter a valid OTP",
                    countDown: 120,
                    resetotp: false
                },
                isAlreadyCapshineUser: false,
                isAlreadyClubMember: false,
                showCountrySearch: false,
                selection: '',
                availableScreens: {
                    "MobileNo": 1,
                    "OTP": 2,
                    "Name": 3,
                    "Email": 4,
                    "Pincode": 5,
                    "Gender": 6,
                    "Age": 7,
                    "Language": 8,
                    "Occupation": 9,
                    "Welcome": 10,
                    // "PreferredDayTime": 11,
                     "ThankYou" : 11,
                },
                currentStepCount: 1,
                currentStep: "MobileNo",
                firstStep: 0,
                userDetails: {
                    userName: '',
                    mobileNo: '',
                    emailId: '',
                    Pincode: '',
                    userpid: '',
                    isSignUp: true,
                    countryCode: '+91',
                },
                pincode: '',
                pincodeList: [],
                selectedValues: {
                    Gender: '',
                    Language: '',
                    Age: '',
                    Occupation: '',
                    PreferredDay: '',
                    PreferredTime: '',
                    PreferredDatyTime: '',
                    //clubId: 0,
                },
                isUserLoggedIn: false,
                showPincodeSearch: false,
                youtubeVideUrl: {
                    WelcomeScreenVideo: {
                        English : "https://youtube.com/embed/Mz_RgY9s1zg",
                        Hindi : "https://youtube.com/embed/MvlCtjNMzG0",
                        Malayalam: "https://youtube.com/embed/Wd5VwDW62Uw",
                        Tamil: "https://youtube.com/embed/uzakZP_gKUM",
                    },
                },
                welcomeScreenVideoUrl : "",
                lassyLoadVideoUrl: "",
                lassyLoadThumbnailUrl: "",
                availableClubSlotsDetails : [],
                clubId: 0,
                clubMemberCount: 0,
                clubName: '',
                countDown: 120,
                resetotp: false,
                timerId: 0,
                trueCallerUserDetailsCheckTimerId: 0,
                trueCallerUserDetailsCheckTimerCount: 0,
                trueCallerUniqueId: 0,
                isMobile : false,
            }
        },
        components: {
            CountryCode: CountryCode,
            Pincode : Pincode,
        },
        beforeMount: function () {
            let mobileOrWeb = document.getElementById('IsMobile');
            if (mobileOrWeb) {
                if (mobileOrWeb.getAttribute("data-ismobile") == "False")
                    this.isMobile = false;
                else
                    this.isMobile = true;
            }
        },
        mounted: function () {
            this.isUserLoggedIn = (document.getElementById("hdnUserPid").value != "" && document.getElementById("hdnUserPid").value != "0");
            this.userDetails.isSignUp = !this.isUserLoggedIn;
            if (this.isUserLoggedIn) {
                this.userDetails.userpid = document.getElementById("hdnUserPid").value;
                this.isAlreadyCapshineUser = true;
                this.getUserInfo();
            } else {
                if (this.isMobile) {
                    this.trueCallerVerification();
                }
                
                this.firstStep = this.availableScreens.MobileNo;
            }
            this.getPincodeDetails();
            //this.getAvailableClubSlots();
        },
        methods: {
            isValidName(name) {
                return (this.regexp.alpha.test(name));
            },
            isValidEMail(email) {
                return this.regexp.email.test(email);
            },
            isValidMobileNumber(mobileno, countrycode) {
                
                if (countrycode == undefined || countrycode == "" || countrycode == "91" || countrycode == "+91") {
                    return (this.regexp.mobile1.test(mobileno));
                }
                else {
                    return (this.regexp.internationalmobile.test(mobileno));
                }
            },
            onClickNext: async function () {
                if (this.currentStepCount == this.availableScreens.Name) {
                    if (this.userDetails.userName == "") {
                        this.showErrorText("Enter a valid name");
                        return;
                    } else
                    if (!this.isValidName(this.userDetails.userName)) {
                        this.showErrorText("Enter a valid name");
                        return;
                    } else {
                        SulGA.pushEvent(
                            "member-name",
                            `name-${this.userDetails.userName}`,
                            "member-name-click"
                        );
                    }
                }

                if (this.currentStepCount == this.availableScreens.Email) {
                    if (!this.isValidEMail(this.userDetails.emailId)) {
                        this.showErrorText("Enter a valid email id");
                        return;
                    } else {
                        if(this.isAlreadyCapshineUser){
                            let data = await this.checkAndUpdateEmail();
                            if(data == 0) {
                                this.showErrorText("This Email is already associated with another account");
                                return;
                            }
                            
                        } else {
                            let response = await this.checkAccountForEmail();
                            if(response == 0) {
                                this.showErrorText("This Email is already associated with another account");
                                return;
                            }
                        }
                        SulGA.pushEvent(
                            "emailid-next",
                            `email-${this.userDetails.emailId}`,
                            "emailid-click"
                        );
                        this.registerOrUpdateData();
                        
                         
                    }
                }

                if (this.currentStepCount == this.availableScreens.MobileNo) {
                    if (!this.isValidMobileNumber(this.userDetails.mobileNo, this.userDetails.countryCode)) {
                        this.showErrorText("Enter a valid phone number");
                        return;
                    }

                    this.requestForOtp();
                    return;
                    
                }

                if(this.currentStepCount == this.availableScreens.Pincode) {
                    if(!this.pincode || this.pincode.length != 6){
                        this.showErrorText();
                        return;
                    }
                    SulGA.pushEvent(
                            "pincode-next",
                            `pincode-${this.pincode}`,
                            "pincode-next-click"
                        );
                }


                if (this.currentStepCount == this.availableScreens.Gender) {
                    if (!this.selectedValues.Gender) {
                        this.showErrorText();
                        return;
                    }
                     SulGA.pushEvent(
                            "select-gender-next",
                            `gender-${this.selectedValues.Gender}`,
                            "select-gender-click"
                        );
                }

                if (this.currentStepCount == this.availableScreens.Age) {
                    if (!this.selectedValues.Age) {
                        this.showErrorText();
                        return;
                    }
                    SulGA.pushEvent(
                            "age-group-next",
                            `age-group-${this.selectedValues.Age}`,
                            "age-group-click"
                    );
                }
                if (this.currentStepCount == this.availableScreens.Language) {
                    if (!this.selectedValues.Language) {
                        this.showErrorText();
                        return;
                    }
                    SulGA.pushEvent(
                            "language-prefeered-next",
                            `language-${this.selectedValues.Language}`,
                            "language-prefeered-next-click"
                    );
                }
               

                if (this.currentStepCount == this.availableScreens.Occupation) {
                    if (!this.selectedValues.Occupation) {
                        this.showErrorText();
                        return;
                    }
                    SulGA.pushEvent(
                            "occupation-next",
                            `occupation-${this.selectedValues.Occupation}`,
                            "occupation-next-click"
                    );
                    if(this.isAlreadyCapshineUser && !this.isAlreadyClubMember) {
                        await this.registerForClub();
                    } else {
                        await this.updateClubDetails();
                    }
                    
                }

                if (this.currentStepCount == this.availableScreens.Welcome) {
                    let isFirstSignUp = true;
                    this.redirectToThankyouPage(isFirstSignUp);
                    
                    return;
                }
                
                this.moveToNextScreen(); 
            },
            moveToNextScreen: function () {
                
                if ((this.currentStepCount + 1) <= Object.entries(this.availableScreens).length) {
                    let currentSlide = this.$refs[`step${this.currentStepCount}`];
                    let nextSlide = this.$refs[`step${this.currentStepCount + 1}`];
                    currentSlide.classList.replace("current-slide", "previous-slide");
                    nextSlide.classList.replace("next-slide", "current-slide");
                    this.currentStepCount++;
                    this.currentStep = Object.entries(this.availableScreens)[this.currentStepCount - 1][0];
                }

                if (this.currentStepCount == this.availableScreens.Welcome) {
                    this.getClubDetailsForClubId();
                     this.getVideoUrlBasedOnLanguage();
                     SulGA.pushEvent(
                         "registration-submit",
                         "member-registered",
                         "registration-submit-click"
                     );
                     fbq("track", "SubmitApplication");
                }
               
            },
            onClickBack: function () {
                if (this.currentStepCount == this.firstStep) {
                    this.closeClubPopup();
                    return;
                }
                if(this.currentStepCount == this.availableScreens.OTP){
                    this.verificationCode = "";
                    this.hideErrorText();
                }

                if ((this.currentStepCount - 1) > 0) {
                    let currentSlide = this.$refs[`step${this.currentStepCount}`];
                    let prevSlide = this.$refs[`step${this.currentStepCount - 1}`];
                    let replaceClass = '';
                    if (prevSlide.classList.contains("previous-slide"))
                        replaceClass = "previous-slide";
                    else if (prevSlide.classList.contains("next-slide"))
                        replaceClass = "next-slide";
                    currentSlide.classList.replace("current-slide", "next-slide");
                    prevSlide.classList.replace(replaceClass, "current-slide");
                    this.currentStepCount--;
                    this.currentStep = Object.entries(this.availableScreens)[this.currentStepCount - 1][0];;
                }
               
            },
            skipScreenTo: function (stepNumber) {
                
                let currentSlide = this.$refs[`step${this.currentStepCount}`];
                currentSlide.classList.replace("current-slide", "previous-slide");
                let nextSlide = this.$refs[`step${stepNumber}`];
                nextSlide.classList.replace("next-slide", "current-slide");
                this.currentStepCount = stepNumber;
                this.currentStep = Object.entries(this.availableScreens)[this.currentStepCount - 1][0];

                if (this.currentStepCount == this.availableScreens.Name) {
                    console.log("before skipping screen for name", this.userDetails.userName)
                    if (this.userDetails.userName) {
                        this.skipScreenTo(this.availableScreens.Email);
                    }
                }
                else if (this.currentStepCount == this.availableScreens.Email) {
                    console.log("before skipping screen for email", this.userDetails.emailId)
                    if (this.userDetails.emailId) {
                        this.onClickNext();
                    }
                }
                 
            },
            getUserInfo: function () {
                Loader.showAboveModal();
                fetch(`/api/clubregistration/GetUserinfoForUserPid?userPid=${this.userDetails.userpid}`)
                    .then(respone => respone.json())
                    .then(data => {

                        this.userDetails.userName = data.firstName?.trim() + " " + data.lastName?.trim();
                        this.userDetails.emailId = (data.emailAddress && data.emailAddress.includes("@")) ? data.emailAddress : this.userDetails.emailId;
                        this.userDetails.mobileNo = data.mobileNumber;
                        this.userDetails.countryCode = data.countryCode;
                        this.isAlreadyClubMember = data.isAlreadyClubMember;

                        // this.isAlreadyClubMember = true;
                        // data.clubRegistrationDetails.pinCode = "600044";
                        // data.clubRegistrationDetails.clubID = "";

                        if (this.isAlreadyClubMember) {
                            if(data.clubRegistrationDetails && !data.clubRegistrationDetails.pinCode) {
                                this.skipScreenTo(this.availableScreens.Pincode);
                                this.firstStep = this.availableScreens.Pincode;
                            } else if (data.clubRegistrationDetails && !data.clubRegistrationDetails.clubID) {
                                this.firstStep = this.availableScreens.Pincode
                                this.skipScreenTo(this.availableScreens.Pincode);
                            }
                            else if(data.clubRegistrationDetails && data.clubRegistrationDetails.clubID) {
                                this.clubId = data.clubRegistrationDetails.clubID;
                                Loader.showAboveModal();
                                this.redirectToThankyouPage();

                            }
                        }
                        else if (!this.userDetails.emailId) {
                            this.firstStep = this.availableScreens.Email
                            this.skipScreenTo(this.availableScreens.Email);
                        } else {
                            this.firstStep = this.availableScreens.Pincode;
                            this.skipScreenTo(this.availableScreens.Pincode);
                        }
                        Loader.hideAboveModal();
                    });
            },
            checkAccountForEmail: async function() {
                let url = `/api/clubregistration/CheckAccountForEmail?emailId=${this.userDetails.emailId.trim()}`;
                Loader.showAboveModal();
                let response = await fetch(url);
                let data = await response.json();
                Loader.hideAboveModal();
                return data;
            },
            checkAndUpdateEmail:async function() {
                let requestData = {
                    emailId: this.userDetails.emailId,
                    userPid: this.userDetails.userpid,
                }
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(requestData),
                }
                Loader.showAboveModal();
                let response = await fetch("/api/userlogin/CheckAndUpdateEmail", requestOptions);
                let data = await response.json();
                Loader.hideAboveModal();
                
                return data;
                
            },
            showLoginPopup: function () {
                this.closeClubPopup();
                document.getElementById("hdnLoginUrlHash").value = "clubsignup";
                document.getElementById("signInMobile").click();
            },
            closeClubPopup: function () {
                var vueBaseDiv = document.querySelector("#club-registration-base-div");
                vueBaseDiv.innerHTML = "";
                var div = document.createElement("div");
                div.id = "club-registration-div";
                vueBaseDiv.appendChild(div);
                document.body.classList.remove("overflow-hidden");
                let hiddenUserPid = document.getElementById("hdnUserPid").value;
                if(hiddenUserPid != "" && hiddenUserPid != "0" && !this.isUserLoggedIn) {
                    window.location.reload();
                }
            },
            showErrorText: function (errorText) {
                
                let errorTextNode = this.$refs[`step${this.currentStepCount}`].querySelector(".error-text");
                if (errorText) {
                    errorTextNode.innerText = errorText;
                }
                errorTextNode.classList.add("show");
            },
            hideErrorText: function () {
                this.$refs[`step${this.currentStepCount}`].querySelector(".error-text").classList.remove("show");
            },
            openCountrySearch() {
                this.selection = '';
                this.showCountrySearch = true;
                setTimeout(() => {
                    document.querySelector("[data-id='country-code-input']")?.focus();
                }, 500)
            },
            countryCodeSelectionCallBack(countrycode) {
                this.showCountrySearch = false;
                this.userDetails.countryCode = countrycode;
            },
            // isAlreadyRegisteredMobileNo: async function () {
            //     Loader.showAboveModal();
            //     let response = await fetch(`/api/userlogin/CheckAccountForPhoneNo?mobileNumber=${this.userDetails.mobileNo}`)
            //     let data = await response.json();
            //     Loader.hideAboveModal();
            //     return data == 1;
            // },
            onKeyDown(evt) {
                if (this.verificationCode.length <= 4) {
                    var key = evt.keyCode;
                    if (key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
                        return true;
                    }
                    evt.preventDefault();
                    return;
                }
            },
            requestForOtp: function () {
                let requestData = {
                    MobileNumber: this.userDetails.mobileNo,
                    CountryCode: this.userDetails.countryCode,
                }
                let url = `/api/clubregistration/VerifyClubSignup`;
                let requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(requestData)
                }
                Loader.showAboveModal();
                fetch(url, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        
                        if (data.mobileVerificationStatus == "VerificationCodeSent") {
                            if(this.currentStepCount != this.availableScreens.OTP) {
                                this.skipScreenTo(this.availableScreens.OTP);
                            }
                            this.countDownTimer();
                        }
                        else if (data.mobileVerificationStatus == "Limit") {
                            this.showErrorText("Maximum Limit Reached");    
                        }
                         Loader.hideAboveModal();
                    });
            },
            onOtpSubmit: function () {
                
                this.verifyOtp();
            },
            verifyOtp:async function () {
                let url = "/api/userlogin/VerifyMobile";
                let requestData = {
                    MobileNumber: this.userDetails.mobileNo,
                    CountryCode: this.userDetails.countryCode,
                    VerificationCode: this.verificationCode,
                    isClubSignup: true,
                }
                let requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(requestData)
                }
                
                Loader.showAboveModal();
                fetch(url, requestOptions)
                    .then(response => response.json())
                    .then(async (data) => {
                        
                        if (data.mobileVerificationStatus == "ValidNumber") {
                             SulGA.pushEvent(
                                "confirm-otp",
                                "otp verified",
                                "confirm-otp-verify"
                            );
                            this.checkIfAlreadyRegistered();
                        }
                        else {
                            this.showErrorText();
                            Loader.hideAboveModal();
                        }
                    });
            },
            checkIfAlreadyRegistered:async function () {
                let url = `/api/clubregistration/CheckIfAlreadyRegistered?mobileNumber=${this.userDetails.mobileNo}`;
                fetch(url)
                    .then(response => response.json())
                    .then(async (data) => {
                        
                        Loader.hideAboveModal();

                       /* data.isAlreadyCapshineUser = false;*/
                        /*data.emailAddress = ''; //remove this */

                        this.isAlreadyCapshineUser = data.isAlreadyCapshineUser;
                        this.isAlreadyClubMember = data.isAlreadyClubMember;
                        this.userDetails.userpid = data.userPid;
                        if(data.isAlreadyCapshineUser) {
                            this.userDetails.userName = data.firstName?.trim() + " " + data.lastName?.trim();
                            this.userDetails.emailId = (data.emailAddress && data.emailAddress.includes("@")) ? data.emailAddress : this.userDetails.emailId;
                            this.userDetails.mobileNo = data.mobileNumber;
                            // let autoLoginResponse = await this.autoLogin(data.mobileNumber);
                            // document.getElementById("hdnUserPid").value = autoLoginResponse
                        }
                        if(data.isAlreadyCapshineUser && !this.isUserLoggedIn) {
                            await this.autoLogin(data.mobileNumber);
                            document.getElementById("hdnUserPid").value = data.userPid;
                        }

                        if (data.isAlreadyCapshineUser && data.isAlreadyClubMember) {
                            if(data.clubRegistrationDetails && !data.clubRegistrationDetails.pinCode) {
                                this.skipScreenTo(this.availableScreens.Pincode);
                                this.firstStep = this.availableScreens.Pincode;
                            } else if (data.clubRegistrationDetails && !data.clubRegistrationDetails.clubID) {
                                this.firstStep = this.availableScreens.Pincode
                                this.skipScreenTo(this.availableScreens.Pincode);
                            } else if(data.clubRegistrationDetails && data.clubRegistrationDetails.clubID) {
                                this.clubId = data.clubRegistrationDetails.clubID;
                                Loader.showAboveModal();
                                //await this.autoLogin(data.mobileNumber);
                                this.redirectToThankyouPage();
                            } 
                        }
                        else if (data.isAlreadyCapshineUser && !data.emailAddress || (data.emailAddress && !data.emailAddress.includes("@"))) {
                           this.skipScreenTo(this.availableScreens.Email);
                           this.firstStep = this.availableScreens.Email;
                        }
                        else if (data.isAlreadyCapshineUser && !data.isAlreadyClubMember) {
                            this.skipScreenTo(this.availableScreens.Pincode);
                            this.firstStep = this.availableScreens.Pincode;
                        }
                        else {
                            this.skipScreenTo(this.availableScreens.Name);
                            this.firstStep = this.availableScreens.Name;
                        }
                    });
            },
            registerOrUpdateData: function () {
                
                if (this.isAlreadyClubMember) {
                    this.updateClubDetails();
                } else {
                    this.registerForClub();
                }
            },
            registerForClub:async function () {
                
                let requestData = this.createRequestData();
                Loader.showAboveModal();
                let response = await fetch('/api/clubregistration/RegisterForCapshineAndClub', {
                    method: "POST",
                    body: JSON.stringify(requestData),
                    headers: { "Content-type": "application/json; charset=UTF-8" }
                })
                let data = await response.json();
                
                this.userDetails.userpid = data.userPid;
                this.clubId = data.clubId;
                document.getElementById("hdnUserPid").value = this.userDetails.userpid;
                this.isAlreadyCapshineUser = true;
                this.isAlreadyClubMember = true;
                this.firstStep = this.availableScreens.Pincode;
               
                Loader.hideAboveModal();    
            },
            createRequestData() {
                return {
                    CountryCode: this.userDetails.countryCode,
                    Email: this.userDetails.emailId,
                    Mobile: this.userDetails.mobileNo,
                    Name: this.userDetails.userName,
                    PinCode: this.pincode,
                    UserPid: this.userDetails.userpid ? this.userDetails.userpid : 0,
                    PreferredLanguage: this.selectedValues.Language,
                    Gender: this.selectedValues.Gender,
                    AgeGroup: this.selectedValues.Age,
                    Occupation: this.selectedValues.Occupation,
                    PreferredClassTiming: this.selectedValues.PreferredTime,
                    PreferredDay: this.selectedValues.PreferredDay,
                    isCapshineSignUp: !this.isAlreadyCapshineUser,
                    isClubSignUp: !this.isAlreadyClubMember,
                    isUserLoggedIn: this.isUserLoggedIn,
                    // clubId: this.selectedValues.clubId,
                }
            },
            updateUserDetails: function () {
                console.log("updating name and email id");
            },
            updateClubDetails:async function () {
                
                Loader.showAboveModal();
                 let requestData = this.createRequestData();
                let requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(requestData)
                }
                let response = await fetch("api/clubregistration/UpdateClubDetails",requestOptions);
                let data = await response.json();
                 
                this.clubId = data.clubId;
                this.firstStep = this.availableScreens.Welcome;
                Loader.hideAboveModal();
               

            },
            onSelectValue: function (event) {
                
                let value = event.currentTarget.innerText;
                
                document.querySelectorAll(`[data-${this.currentStep}]`).forEach((item) => {
                       item.classList.remove("level-selected");
                })
                event.currentTarget.classList.add("level-selected");
                let otherOption = this.$refs[`step${this.currentStepCount}`].querySelector("[data-other-option]");
                if (otherOption) {
                    otherOption.classList.remove("level-selected");
                }
                let otherInput = this.$refs[`step${this.currentStepCount}`].querySelector("[data-other]");
                if (otherInput) {
                    otherInput.classList.replace("show", "hide");
                }
                
                this.selectedValues[this.currentStep] = value;
                this.hideErrorText();
                this.onClickNext();
                
            },
            onSelectRadio: function (event) {
                let radioValue = document.querySelector(`input[name="${this.currentStep}"]:checked`).value;
                this.selectedValues[this.currentStep] = radioValue;
                this.hideErrorText();
                this.onClickNext();
                
            },
            onSelectOther: function (event) {
                document.querySelectorAll(`[data-${this.currentStep}]`).forEach((item) => {
                    item.classList.remove("level-selected");
                })
                event.currentTarget.classList.add("level-selected");
                this.selectedValues[this.currentStep] = "";
                let otherInput = this.$refs[`step${this.currentStepCount}`].querySelector("[data-other]");
                otherInput.classList.replace("hide", "show");
            },
            onKeyupOther: function (event) {
                document.querySelectorAll(`[data-${this.currentStep}]`).forEach((item) => {
                    item.classList.remove("level-selected");
                })
                this.selectedValues[this.currentStep] = event.currentTarget.value;
                this.hideErrorText();
            },
            pincodeSelectionCallBack(code) {
                
                this.showPincodeSearch = false;
                this.pincode = code;
                // var taglabel = code + "pincode";
                // SulGA.pushEvent(
                //     "student-details-pincode-select",
                //     taglabel,
                //     "student-details-pincode-click"
                // );
            },
            getPincodeDetails: function () {
                fetch("/api/clubregistration/GetPincodeDetails")
                .then(response => response.json())
                .then(data => {
                    this.pincodeList = data;
                })
            },
            openPincodeSearch() {
                // SulGA.pushEvent(
                //     "student-details-pincode",
                //     "pincode",
                //     "student-details-pincode-click"
                // );
                
                this.showPincodeSearch = true;
                setTimeout(() => {
                    document.querySelector(".form-control.pincodesearch").focus();
                }, 500)
                
                
            },
            onKeyDownPincode(e) {
                e.preventDefault();
                return false;
            },
            getVideoUrlBasedOnLanguage: function() {
                    if(this.selectedValues.Language) {
                        switch(this.selectedValues.Language.trim().toLowerCase()) {
                        case "english" : this.welcomeScreenVideoUrl = this.youtubeVideUrl.WelcomeScreenVideo.English;
                        break;
                        case "hindi" : this.welcomeScreenVideoUrl = this.youtubeVideUrl.WelcomeScreenVideo.Hindi;
                        break;
                        case "malayalam" : this.welcomeScreenVideoUrl = this.youtubeVideUrl.WelcomeScreenVideo.Malayalam;
                        break;
                        case "tamil" : this.welcomeScreenVideoUrl = this.youtubeVideUrl.WelcomeScreenVideo.Tamil;
                        break;
                        default: this.welcomeScreenVideoUrl = this.youtubeVideUrl.WelcomeScreenVideo.Hindi;
                        break;
                    }
                } else {
                    this.welcomeScreenVideoUrl = this.youtubeVideUrl.WelcomeScreenVideo.Hindi;
                }
                let thumbNailLink = this.welcomeScreenVideoUrl.replace("https://youtube.com/embed/","");
                
                 this.lassyLoadVideoUrl =`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}div{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgb(0 0 0 / 38%);display:flex;align-items:center;justify-content:center;}em{position:absolute;width:40px;height:40px;border-radius:50px;background:#ff7a21;overflow:hidden;display:flex;align-items:center;justify-content:center;}svg{width:18px;fill:#fff;position:relative;left:1px;}img {object-fit: cover;height: 100%;width: 100%;}</style><a href=${this.welcomeScreenVideoUrl}><img src=https://img.youtube.com/vi/${thumbNailLink}/hqdefault.jpg alt='@Card.CourseTitle'><div><em><svg viewBox='0 0 448 512'><path d='M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z'></path></svg></em></div></a>`
            },
            onClickEditNumber: function () {
                SulGA.pushEvent(
                    "otp-change-number",
                    "Change Number OTP",
                    "otp-change-number-click"
                );
                this.onClickBack();
            },
            onClickResendOtp : function () {
                 SulGA.pushEvent(
                    "otp-resend",
                    "otp resend",
                    "otp-resend-click"
                );
                
                this.requestForOtp();
            },
            redirectToThankyouPage: function (isFirstSignUp) {
                /*window.location.href = `/clubthankyou?clubId=${this.clubId}`;*/
                if (isFirstSignUp)
                    window.location.href = "/myclub?type=newsignup";
                else
                    window.location.href = "/myclub";
            },
            countDownTimer() {
                
                this.countDown = 120;
                if(this.timerId) {
                    clearInterval(this.timerId);
                }
                this.resetotp = false;
                this.timerId = setInterval(() => {
                        
                        this.countDown -= 1
                        if (this.countDown == 0) {
                            this.resetotp = true;
                            clearInterval(this.timerId);
                        }
                    }, 1000)
            },
            autoLogin: async function (mobileNumber) {
                let url = `/api/userlogin/AutoLogin?MobileNumber=${mobileNumber}`;
                let response = await fetch(url);
                let data = await response.json();
                return data;
            },
            onPincodeBlur: function () {
                this.showPincodeSearch = false;
            },
            onCountryCodeBlur: function () {
                this.showCountrySearch = false;
            },
            trueCallerVerification : function () {
                const UNIQUE_REQUEST_ID = Math.floor(10000000 + Math.random() * 90000000); // eight digit random ID
                this.trueCallerUniqueId = UNIQUE_REQUEST_ID;
                const PARTNER_KEY = "ZN1gnc5652a648e8e45c682b943c8fab24ea0";
                const APP_NAME = "capshine";
                const LANGUAGE_LOCALE = "en";
                const TITLE_STRING = "Verify your mobile number";
                const LOGIN_SUFFIX = "signin";
                const CTA_COLOR = "%23ff7a21";
                console.log("Truecaller function called ");
                console.log("the unique id is ", this.trueCallerUniqueId);
                window.location = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${UNIQUE_REQUEST_ID}&partnerKey=${PARTNER_KEY}&partnerName=${APP_NAME}&lang=${LANGUAGE_LOCALE}&title=${TITLE_STRING}&loginSuffix=${LOGIN_SUFFIX}&ctaColor=${CTA_COLOR}&skipOption=useanothernum`;


                setTimeout(() => {
                console.log("settimeout function called ");
                    if( document.hasFocus() ){
                        // Truecaller app not present on the device and you redirect the user 
                        // to your alternate verification page
                        console.log("True caller app not installed on the phone ");
                    }else{
                        // Truecaller app present on the device and the profile overlay opens
                        // The user clicks on verify & you'll receive the user's access token to fetch the profile on your 
                        // callback URL - post which, you can refresh the session at your frontend and complete the user  verification
                        Loader.showAboveModal();
                        this.trueCallerUserDetailsCheckTimerId = setInterval(() => {
                            
                            this.checkForTrueCallerUserDetails();
                        }, 3000)
                        console.log("True caller app installed !");
                    }
                }, 600);
                
                
            },
            checkForTrueCallerUserDetails:async function () {
                this.trueCallerUserDetailsCheckTimerCount++;
                console.log("checking for user details ", this.trueCallerUserDetailsCheckTimerCount);
                let data = await this.getTrueCallerDetailsForAuthId();
                if (data != null) {
                    if ( data.responseStatus == 'user_rejected' || data.responseStatus == 'used_another_number') {
                        console.log("user skipped the truecaller verification so stop the polling ");
                        Loader.hideAboveModal();
                        clearInterval(this.trueCallerUserDetailsCheckTimerId);
                    }
                    else if ((data != null && data.mobileNumber != null)) {
                        this.userDetails.mobileNo = data.mobileNumber
                        this.userDetails.emailId = data.emailId;
                        this.userDetails.countryCode = '+' + data.countryCode;
                        this.userDetails.userName = data.name;
                        clearInterval(this.trueCallerUserDetailsCheckTimerId);
                        Loader.hideAboveModal();
                        this.checkIfAlreadyRegistered();
                    }
                    //else if (this.trueCallerUserDetailsCheckTimerCount > 5) {
                    //    if (!this.userDetails.mobileNo) {
                    //        alert("True caller authentication failed, please proceed with the form");
                    //    }
                    //    Loader.hideAboveModal();
                    //    clearInterval(this.trueCallerUserDetailsCheckTimerId);
                    //}
                }
               
                
                
            },
            getTrueCallerDetailsForAuthId:async function () {
                let url = `/truecaller-sdk/GetTrueCallerDetails?authId=${this.trueCallerUniqueId}`;
                let response = await fetch(url);
                let data = await response.json();
                console.log("true caller details ", data);
                return data;
            },
            getClubDetailsForClubId: function () {
                let url = `api/clubregistration/GetClubDetailsForClubId?clubId=${this.clubId}`;
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            this.clubMemberCount = data.memberCount;
                            this.clubName = data.clubName;
                        }
                    });
            }
        },
        watch: {
            verificationCode: function (vcode) {
                /*debugger;*/
                if (vcode.length >= 4) {
                    this.onOtpSubmit();
                }
            },
            pincode: function (pincode) {
                this.hideErrorText();
            }

        }
    }
</script>

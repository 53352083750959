import { render, staticRenderFns } from "./ThankYouForPayment.vue?vue&type=template&id=7bf91d1d&"
import script from "./ThankYouForPayment.vue?vue&type=script&lang=js&"
export * from "./ThankYouForPayment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\eLearn.Student\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7bf91d1d')) {
      api.createRecord('7bf91d1d', component.options)
    } else {
      api.reload('7bf91d1d', component.options)
    }
    module.hot.accept("./ThankYouForPayment.vue?vue&type=template&id=7bf91d1d&", function () {
      api.rerender('7bf91d1d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "VueComponents/CourseSignup/ThankYouForPayment.vue"
export default component.exports
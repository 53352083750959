var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "review-screen" }, [
    _c("div", { staticClass: "modal review show" }, [
      _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-header align-items-center" }, [
            _c(
              "svg",
              {
                staticClass: "svg-icon medium d-sm-none",
                attrs: { viewBox: "0 0 64 64" },
                on: { click: _vm.onClose }
              },
              [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
            ),
            _vm._v(" "),
            _c("h5", [_vm._v("Write a Review")]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: { click: _vm.onClose }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _vm.currentStep == "stepOne"
              ? _c(
                  "div",
                  [
                    _c("WriteReviewStepOne", {
                      attrs: {
                        businessName: _vm.businessName,
                        courses: _vm.courses,
                        businessImageUrl: _vm.businessImageUrl
                      },
                      on: { courseSelected: _vm.onCourseSelected }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "stepTwo"
              ? _c(
                  "div",
                  [
                    _c("WriteReviewStepTwo", {
                      attrs: {
                        selectedCourseName: _vm.selectedCourseName,
                        selectedCourseImgUrl: _vm.selectedCourseImgUrl,
                        selectedRatingFromRoot: _vm.courses[
                          _vm.selectedCourseId
                        ]
                          ? _vm.courses[_vm.selectedCourseId].selectedRating
                          : 0,
                        selectedDescriptionFromRoot: _vm.courses[
                          _vm.selectedCourseId
                        ]
                          ? _vm.courses[_vm.selectedCourseId].enteredDescription
                          : "",
                        isFromCourseDetailsPage: _vm.isFromCourseDetailsPage
                      },
                      on: {
                        starClick: _vm.onStarClick,
                        reviewDescriptionEntered: _vm.onReviewEntered,
                        onClickBack: _vm.onClickBack,
                        reviewSubmit: _vm.onSubmit,
                        submitButtonToggle: _vm.submitButtonToggle
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "stepThree"
              ? _c(
                  "div",
                  [
                    _c("WriteReviewStepThree", {
                      attrs: {
                        selectedCourseName: _vm.selectedCourseName,
                        selectedCourseImgUrl: _vm.selectedCourseImgUrl,
                        selectedRating: _vm.selectedRating,
                        reviewDescription: _vm.reviewDescription
                      },
                      on: { onCloseEmit: _vm.onClose }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _vm.currentStep == "stepTwo" &&
            _vm.showFooterButton &&
            !_vm.isFromCourseDetailsPage
              ? _c(
                  "button",
                  {
                    staticClass: "button btn link",
                    on: { click: _vm.onClickBack }
                  },
                  [_vm._v("Back")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "stepTwo" && _vm.showFooterButton
              ? _c(
                  "button",
                  {
                    staticClass: "button btn btn-primary",
                    class: _vm.submitButtonClass,
                    on: { click: _vm.onClickSubmit }
                  },
                  [_vm._v("Submit")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "stepThree" && _vm.showFooterButton
              ? _c(
                  "button",
                  {
                    staticClass: "button btn btn-primary",
                    on: { click: _vm.onClose }
                  },
                  [
                    _vm._v(
                      "\n                        Done\n                    "
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "progress" }, [
      _c("div", {
        staticClass: "progress-bar",
        staticStyle: { width: "50%" },
        attrs: {
          role: "progressbar",
          "aria-valuenow": "50",
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
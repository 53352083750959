import { render, staticRenderFns } from "./QuestionAndAnswer.vue?vue&type=template&id=707647d8&"
import script from "./QuestionAndAnswer.vue?vue&type=script&lang=js&"
export * from "./QuestionAndAnswer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\eLearn.Student\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('707647d8')) {
      api.createRecord('707647d8', component.options)
    } else {
      api.reload('707647d8', component.options)
    }
    module.hot.accept("./QuestionAndAnswer.vue?vue&type=template&id=707647d8&", function () {
      api.rerender('707647d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "VueComponents/CourseSignup/QuestionAndAnswer.vue"
export default component.exports
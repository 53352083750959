var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal multistep-form",
      staticStyle: { display: "block" },
      attrs: { id: "Openwindow" }
    },
    [
      _c("svg", { staticClass: "d-none" }, [
        _c("defs", [
          _c("g", { attrs: { id: "downArrow" } }, [
            _c("path", {
              attrs: {
                d:
                  "M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("svg", { staticClass: "d-none" }, [
        _c("defs", [
          _c("g", { attrs: { id: "chevronBack" } }, [
            _c("path", {
              attrs: {
                d:
                  "m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "modal-dialog modal-dialog-centered modal-dialog-scrollable"
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header align-items-center" }, [
              _vm.currentStep != "Welcome"
                ? _c(
                    "svg",
                    {
                      staticClass: "svg-icon medium d-sm-none",
                      attrs: { viewBox: "0 0 64 64", id: "back" },
                      on: { click: _vm.onClickBack }
                    },
                    [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("h5", [_vm._v("Club Registration")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: { click: _vm.closeClubPopup }
                },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "registeration-box" }, [
                _c("div", { staticClass: "form" }, [
                  _c(
                    "div",
                    {
                      ref: "step1",
                      staticClass: "step current-slide",
                      attrs: { id: "step1" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "phone-input md-field mb-3" },
                          [
                            _c(
                              "h6",
                              {
                                staticClass: "country",
                                on: {
                                  click: function($event) {
                                    return _vm.openCountrySearch()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.userDetails.countryCode) +
                                    "\n                                        "
                                ),
                                _c(
                                  "svg",
                                  {
                                    staticClass: "svg-icon medium",
                                    attrs: { viewBox: "0 0 448 512" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#downArrow" }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userDetails.mobileNo,
                                  expression: "userDetails.mobileNo"
                                }
                              ],
                              staticClass: "mobile",
                              attrs: {
                                type: "number",
                                id: "mobile",
                                name: "mobile",
                                required: "required",
                                placeholder: "Your phone number"
                              },
                              domProps: { value: _vm.userDetails.mobileNo },
                              on: {
                                keyup: _vm.hideErrorText,
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.userDetails,
                                    "mobileNo",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "mobile-text",
                                attrs: { alt: "mobile", for: "mobile" }
                              },
                              [_vm._v(" Your phone number ")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "error-text" }, [
                              _vm._v("Enter a valid phone number")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-wrapper",
                                class: { active: _vm.showCountrySearch }
                              },
                              [
                                _c("CountryCode", {
                                  attrs: {
                                    selectionCallBack:
                                      _vm.countryCodeSelectionCallBack,
                                    onCountryCodeBlur: _vm.onCountryCodeBlur
                                  },
                                  model: {
                                    value: _vm.selection,
                                    callback: function($$v) {
                                      _vm.selection = $$v
                                    },
                                    expression: "selection"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary next-form-btn mt-3",
                            attrs: { type: "button", id: "next" },
                            on: { click: _vm.onClickNext }
                          },
                          [
                            _vm._v("Next "),
                            _c(
                              "svg",
                              {
                                staticClass: "check-icon-align",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "25",
                                  height: "25",
                                  fill: "#ffffff",
                                  viewBox: "0 0 256 256"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "256",
                                    height: "256",
                                    fill: "none"
                                  }
                                }),
                                _c("polyline", {
                                  attrs: {
                                    points: "216 72.005 104 184 48 128.005",
                                    fill: "none",
                                    stroke: "#ffffff",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "20"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        _c("label", { staticClass: "caption" }, [
                          _vm._v("Already have an account? "),
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "#" },
                              on: { click: _vm.showLoginPopup }
                            },
                            [_vm._v("Login")]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step2",
                      staticClass: "step next-slide",
                      attrs: { id: "step2" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _c("h5", { staticClass: "number-title" }, [
                          _vm._v("Enter the OTP")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "p",
                            {
                              staticClass: "text-muted col-sm-8 col-12 caption"
                            },
                            [
                              _vm._v("We sent an OTP to your phone number "),
                              _c("br"),
                              _vm._v(" "),
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.userDetails.countryCode) +
                                    " " +
                                    _vm._s(_vm.userDetails.mobileNo)
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "link caption col-sm-4 col-12 text-right edit-number",
                              on: { click: _vm.onClickEditNumber }
                            },
                            [_vm._v("Edit this number")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "otp-form" }, [
                          _c("div", { staticClass: "otp-group otp" }, [
                            _c("div", { staticClass: "single-input" }, [
                              _c("div", { staticClass: "inputOtpCenter" }, [
                                _c(
                                  "div",
                                  { staticClass: "inputOtp-group phone-input" },
                                  [
                                    _c("span", { staticClass: "box-1" }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "box-2" }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "box-3" }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "box-4" }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lastbar" }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.verificationCode,
                                          expression: "verificationCode"
                                        }
                                      ],
                                      ref: "code1",
                                      attrs: {
                                        type: "number",
                                        maxlength: "4",
                                        id: "code-1"
                                      },
                                      domProps: { value: _vm.verificationCode },
                                      on: {
                                        keydown: function($event) {
                                          return _vm.onKeyDown($event)
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.verificationCode =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "error-text" }, [
                            _vm._v(" Incorrect OTP ")
                          ]),
                          _vm._v(" "),
                          !_vm.resetotp
                            ? _c("label", { staticClass: "caption" }, [
                                _vm._v(
                                  "\n                                        Resend OTP in " +
                                    _vm._s(_vm.countDown) +
                                    " Seconds\n                                    "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resetotp
                            ? _c(
                                "a",
                                {
                                  staticClass: "link",
                                  on: { click: _vm.onClickResendOtp }
                                },
                                [_vm._v("Resend now")]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-primary back back-form-btn mt-3",
                            attrs: { type: "button", id: "back" },
                            on: { click: _vm.onClickBack }
                          },
                          [_vm._v("Back ")]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step3",
                      staticClass: "step next-slide",
                      attrs: { id: "step3" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("div", { staticClass: "md-field mt-3 mb-3" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userDetails.userName,
                                expression: "userDetails.userName"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "name",
                              id: "name",
                              required: "required",
                              placeholder: "First and last name"
                            },
                            domProps: { value: _vm.userDetails.userName },
                            on: {
                              keyup: _vm.hideErrorText,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.userDetails,
                                  "userName",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: {
                                alt: "name",
                                for: "name",
                                placeholder: "First and last name"
                              }
                            },
                            [
                              _vm._v(
                                "\n                                        First and last name\n                                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "error-text" }, [
                            _vm._v("Enter a valid name")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary next-form-btn mt-3",
                            attrs: { type: "button", id: "next" },
                            on: { click: _vm.onClickNext }
                          },
                          [
                            _vm._v("Next "),
                            _c(
                              "svg",
                              {
                                staticClass: "check-icon-align",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "25",
                                  height: "25",
                                  fill: "#ffffff",
                                  viewBox: "0 0 256 256"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "256",
                                    height: "256",
                                    fill: "none"
                                  }
                                }),
                                _c("polyline", {
                                  attrs: {
                                    points: "216 72.005 104 184 48 128.005",
                                    fill: "none",
                                    stroke: "#ffffff",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "16"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step4",
                      staticClass: "step next-slide",
                      attrs: { id: "step4" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _vm._m(3),
                        _vm._v(" "),
                        _c("div", { staticClass: "md-field mt-3 mb-3" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userDetails.emailId,
                                expression: "userDetails.emailId"
                              }
                            ],
                            attrs: {
                              type: "email",
                              name: "email",
                              id: "email",
                              required: "required",
                              placeholder: "Email ID"
                            },
                            domProps: { value: _vm.userDetails.emailId },
                            on: {
                              keyup: _vm.hideErrorText,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.userDetails,
                                  "emailId",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { alt: "email", for: "email" } },
                            [_vm._v(" Email ID ")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "error-text" }, [
                            _vm._v("Enter a valid email")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-primary back back-form-btn mt-3",
                            attrs: { type: "button", id: "back" },
                            on: { click: _vm.onClickBack }
                          },
                          [_vm._v("Back ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary next-form-btn mt-3",
                            attrs: { type: "button", id: "next" },
                            on: { click: _vm.onClickNext }
                          },
                          [
                            _vm._v("Next "),
                            _c(
                              "svg",
                              {
                                staticClass: "check-icon-align",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "25",
                                  height: "25",
                                  fill: "#ffffff",
                                  viewBox: "0 0 256 256"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "256",
                                    height: "256",
                                    fill: "none"
                                  }
                                }),
                                _c("polyline", {
                                  attrs: {
                                    points: "216 72.005 104 184 48 128.005",
                                    fill: "none",
                                    stroke: "#ffffff",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "16"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step5",
                      staticClass: "step next-slide",
                      attrs: { id: "step5" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _vm._m(4),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md-field mt-3 mb-3" },
                          [
                            _c("Pincode", {
                              attrs: {
                                pincodeProp: _vm.pincode,
                                value: "",
                                pincodeData: _vm.pincodeList,
                                selectionCallBack: _vm.pincodeSelectionCallBack,
                                onPincodeBlur: _vm.onPincodeBlur
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "error-text" }, [
                              _vm._v("Enter a valid pincode")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary next-form-btn mt-3",
                            attrs: { type: "button", id: "next" },
                            on: { click: _vm.onClickNext }
                          },
                          [
                            _vm._v("Next "),
                            _c(
                              "svg",
                              {
                                staticClass: "check-icon-align",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "25",
                                  height: "25",
                                  fill: "#ffffff",
                                  viewBox: "0 0 256 256"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "256",
                                    height: "256",
                                    fill: "none"
                                  }
                                }),
                                _c("polyline", {
                                  attrs: {
                                    points: "216 72.005 104 184 48 128.005",
                                    fill: "none",
                                    stroke: "#ffffff",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "16"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step6",
                      staticClass: "step next-slide",
                      attrs: { id: "step6" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _vm._m(5),
                        _vm._v(" "),
                        _c("ul", { staticClass: "level-content-box" }, [
                          _c(
                            "li",
                            {
                              attrs: { "data-Gender": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Male")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Gender": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Female")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-gender": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Others")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-text" }, [
                          _vm._v("Select an option")
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-primary back back-form-btn mt-3",
                            attrs: { type: "button", id: "back" },
                            on: { click: _vm.onClickBack }
                          },
                          [_vm._v("Back ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary next-form-btn mt-3",
                            attrs: { type: "button", id: "next" },
                            on: { click: _vm.onClickNext }
                          },
                          [
                            _vm._v("Next "),
                            _c(
                              "svg",
                              {
                                staticClass: "check-icon-align",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "25",
                                  height: "25",
                                  fill: "#ffffff",
                                  viewBox: "0 0 256 256"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "256",
                                    height: "256",
                                    fill: "none"
                                  }
                                }),
                                _c("polyline", {
                                  attrs: {
                                    points: "216 72.005 104 184 48 128.005",
                                    fill: "none",
                                    stroke: "#ffffff",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "16"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step7",
                      staticClass: "step next-slide",
                      attrs: { id: "step7" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _vm._m(6),
                        _vm._v(" "),
                        _c("ul", { staticClass: "level-content-box" }, [
                          _c(
                            "li",
                            {
                              attrs: { "data-Age": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v(" < 20 Years")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-age": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("20 to 25 Years")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Age": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("25 to 30 Years")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Age": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("30 to 35 Years")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Age": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("35 to 40 Years")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Age": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("40 to 45 Years")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Age": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("45 to 50 Years")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Age": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("> 50 Years")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-text" }, [
                          _vm._v("Select an option")
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-primary back back-form-btn mt-3",
                            attrs: { type: "button", id: "back" },
                            on: { click: _vm.onClickBack }
                          },
                          [_vm._v("Back ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary next-form-btn mt-3",
                            attrs: { type: "button", id: "next" },
                            on: { click: _vm.onClickNext }
                          },
                          [
                            _vm._v("Next "),
                            _c(
                              "svg",
                              {
                                staticClass: "check-icon-align",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "25",
                                  height: "25",
                                  fill: "#ffffff",
                                  viewBox: "0 0 256 256"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "256",
                                    height: "256",
                                    fill: "none"
                                  }
                                }),
                                _c("polyline", {
                                  attrs: {
                                    points: "216 72.005 104 184 48 128.005",
                                    fill: "none",
                                    stroke: "#ffffff",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "16"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step8",
                      staticClass: "step next-slide",
                      attrs: { id: "step8" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _vm._m(7),
                        _vm._v(" "),
                        _c("ul", { staticClass: "level-content-box" }, [
                          _c(
                            "li",
                            {
                              attrs: { "data-Language": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("English")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-language": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Hindi")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Language": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Tamil")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Language": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Malayalam")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Language": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Telugu")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-Language": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Kannada")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-other-option": "" },
                              on: { click: _vm.onSelectOther }
                            },
                            [_vm._v("Others")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "md-field mt-3 mb-3 hide",
                              attrs: { "data-other": "" }
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "club-language",
                                  id: "club-language",
                                  required: "required",
                                  placeholder: "Language"
                                },
                                on: { keyup: _vm.onKeyupOther }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    alt: "club-language",
                                    for: "club-language",
                                    placeholder: "Language"
                                  }
                                },
                                [_vm._v("Enter your other language")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-text" }, [
                          _vm._v("Select an option")
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-primary back back-form-btn mt-3",
                            attrs: { type: "button", id: "back" },
                            on: { click: _vm.onClickBack }
                          },
                          [_vm._v("Back ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary next-form-btn mt-3",
                            attrs: { type: "button", id: "next" },
                            on: { click: _vm.onClickNext }
                          },
                          [
                            _vm._v("Next "),
                            _c(
                              "svg",
                              {
                                staticClass: "check-icon-align",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "25",
                                  height: "25",
                                  fill: "#ffffff",
                                  viewBox: "0 0 256 256"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "256",
                                    height: "256",
                                    fill: "none"
                                  }
                                }),
                                _c("polyline", {
                                  attrs: {
                                    points: "216 72.005 104 184 48 128.005",
                                    fill: "none",
                                    stroke: "#ffffff",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "16"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step9",
                      staticClass: "step next-slide",
                      attrs: { id: "step9" }
                    },
                    [
                      _c("div", { staticClass: "group" }, [
                        _vm._m(8),
                        _vm._v(" "),
                        _c("ul", { staticClass: "level-content-box" }, [
                          _c(
                            "li",
                            {
                              attrs: { "data-occupation": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Student")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-occupation": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Looking for a job ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-occupation": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Employed ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-occupation": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Homemaker ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-occupation": "" },
                              on: { click: _vm.onSelectValue }
                            },
                            [_vm._v("Business Owner / Freelancer ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { "data-other-option": "" },
                              on: { click: _vm.onSelectOther }
                            },
                            [_vm._v("Others")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "md-field mt-3 mb-3 hide",
                              attrs: { "data-other": "" }
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "club-occupation",
                                  id: "club-occupation",
                                  required: "required",
                                  placeholder: "Enter you other occupation"
                                },
                                on: { keyup: _vm.onKeyupOther }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    alt: "club-occupation",
                                    for: "club-occupation",
                                    placeholder: "Enter your other Occupation"
                                  }
                                },
                                [_vm._v("Enter your other Occupation")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-text" }, [
                          _vm._v("Select an option")
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-primary back back-form-btn mt-3",
                            attrs: { type: "button", id: "back" },
                            on: { click: _vm.onClickBack }
                          },
                          [_vm._v("Back ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary next-form-btn mt-3",
                            attrs: { type: "button", id: "next" },
                            on: { click: _vm.onClickNext }
                          },
                          [
                            _vm._v("Submit "),
                            _c(
                              "svg",
                              {
                                staticClass: "check-icon-align",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "25",
                                  height: "25",
                                  fill: "#ffffff",
                                  viewBox: "0 0 256 256"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "256",
                                    height: "256",
                                    fill: "none"
                                  }
                                }),
                                _c("polyline", {
                                  attrs: {
                                    points: "216 72.005 104 184 48 128.005",
                                    fill: "none",
                                    stroke: "#ffffff",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "16"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step10",
                      staticClass: "step next-slide",
                      attrs: { id: "step11" }
                    },
                    [
                      _c("div", { staticClass: "group welcome" }, [
                        _c("div", { staticClass: "welcome-banner d-flex" }, [
                          _vm._m(9),
                          _vm._v(" "),
                          _c("div", { staticClass: "welcome-notification" }, [
                            _c("p", [
                              _vm._v(
                                "Congratulations you are a member of " +
                                  _vm._s(_vm.clubName) +
                                  " club"
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "You can now connect with " +
                                  _vm._s(_vm.clubMemberCount) +
                                  " members of your club."
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "club-improve" }, [
                          _c("h5", { staticClass: "number-title" }, [
                            _vm._v(
                              "How Capshine Clubs will help you improve your English"
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "improve-english-video" }, [
                            _c("iframe", {
                              staticClass: "improve-video",
                              attrs: {
                                src: _vm.welcomeScreenVideoUrl,
                                title: "YouTube video player",
                                allowfullscreen: "",
                                srcdoc: _vm.lassyLoadVideoUrl
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary next-form-btn  mt-3",
                              attrs: { type: "button", id: "next" },
                              on: { click: _vm.onClickNext }
                            },
                            [_vm._v("Upcoming Club Schedule")]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "step11",
                      staticClass: "step next-slide",
                      attrs: { id: "step11" }
                    },
                    [
                      _c("div", { staticClass: "group welcome" }, [
                        _vm._m(10),
                        _vm._v(" "),
                        _c("div", { staticClass: "club-improve" }, [
                          _c("h5", { staticClass: "number-title" }, [
                            _vm._v(
                              "You will receive a notification with the club details shortly!"
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary next-form-btn  mt-3",
                              attrs: { type: "button", id: "next" },
                              on: { click: _vm.closeClubPopup }
                            },
                            [_vm._v("Close")]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "number-title" }, [
      _vm._v("What's your WhatsApp number? "),
      _c("sup", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "my-5" }, [
      _c("div", { staticClass: "text-center separator" }, [
        _c("h6", [_c("span", [_vm._v("  OR  ")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "number-title" }, [
      _vm._v("What's your first and last name? "),
      _c("sup", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "number-title" }, [
      _vm._v("What's your email id? "),
      _c("sup", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "number-title" }, [
      _vm._v("What's your pincode? "),
      _c("sup", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "number-title" }, [
      _vm._v("What is your gender? "),
      _c("sup", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "number-title" }, [
      _vm._v("What’s your age group? "),
      _c("sup", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "number-title" }, [
      _vm._v("What’s your mother tongue? "),
      _c("sup", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "number-title" }, [
      _vm._v("Select your occupation "),
      _c("sup", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "welcome-check" }, [
      _c("img", {
        attrs: {
          src:
            "https://lscdn.blob.core.windows.net/content/caps/dot-circle.jpg",
          alt: "welcome-check-image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "welcome-banner d-flex" }, [
      _c("div", { staticClass: "welcome-notification" }, [
        _c("p", [
          _vm._v("Thank you! "),
          _c("br"),
          _vm._v(" For registering in Capshine Clubs")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
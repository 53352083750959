var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "close cabso mr-2 mt-2",
        staticStyle: { display: "block", padding: "0rem" },
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      },
      [_vm._v("\n        ×\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row mx-0" },
      [
        _c("DisplayUserInfor", {
          attrs: { instructorDetails: _vm.instructorDetails }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 py-3" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "next-button" }, [
            _c("div"),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.next()
                  }
                }
              },
              [
                _vm._v(
                  "                        \n                    Next\n                "
                )
              ]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "thankmain" }, [
      _c("div", { staticClass: "text-primary thankdemo" }, [
        _c("i", { staticClass: "fad fa-check-circle" }),
        _vm._v(" "),
        _c("b", { staticClass: "mb-1" }, [_vm._v("Reconfirming Demo")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "alert alert-danger" }, [
        _c("p", { staticClass: "caption mb-2" }, [
          _vm._v(
            "\n                        We have reconfirmed with the instructor is currently busy and not available at the moment.\n                        You can continue to book the demo by selecting another demo slot time .\n                    "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>

    <div>
        <button type="button" class="close cabso mt-2 mr-2" @click="close()">&times;</button>

        <div class="row mx-0">
            <!-- Left block start -->
            <!-- <LeftSidePanel :closePopup="close" :instructorDetails="instructorDetails">   </LeftSidePanel> -->
            <DisplayUserInfor :instructorDetails="instructorDetails" />
            <!-- Left block end -->
            <!-- Right block start -->
            <div class="col col-lg-6 bg-light py-3">

                <h4 class="py-3">
                    Please select a time that is convenient with you. Timezone is in Indian Standard Time
                </h4>

                <!-- Title start -->
                <!-- Title end -->
                <!-- Timeslots start -->


                <div class="time-slot-container">

                    <div class="row-time">
                        <div class="col px-0 pt-2 day-col pb-3" v-for="(demoslot,rowindex) in demoTimeSlots" :key="'selected'+rowindex">
                            <div class="caption text-muted">{{demoslot.DemoDateTimeStringFormat}}</div>
                            <div class="overflow-x-hidden">
                               
                                    <span :class="getTimeClass(rowindex,timeindex)" :key="'time'+timeindex"
                                          v-for="(time,timeindex) in filterItems(demoslot.DemoTime,demoslot.isSeeMore,rowindex)" v-bind:id="demoslot.DemoDateTimeFormat+'|'+time" v-on:click="selectedDateTime($event, demoslot.DemoDateTimeFormat, time, demoslot.DemoDateTimeStringFormat,rowindex,timeindex)">
                                        {{time}}
                                    </span>
                                
                            </div>
                            <div v-if="demoslot.DemoTime.length>6">
                                <a v-if="demoslot.isSeeMore==true" class="link caption d-block  mt-3" @click="seemoreless('See more',rowindex)">See more</a>
                                <a v-if="demoslot.isSeeMore==false" class="link caption d-block mt-3" @click="seemoreless('See less',rowindex)">See less</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="next-button">
                    <div>
                        <p v-if="selectedDate != ''"> {{selectedDate}} </p>
                           <span class="error-text" :class="isrequired?'show':''">Select Time slot</span>
                    </div>
                        <a class="btn btn-block btn-primary" v-on:click="proceedNextStep()">Confirm</a>
                   

                </div>

            </div>

        </div>
        </div>

</template>

<script>
      import DisplayUserInfor from "./DisplayUserInfor.vue";
      import SulGA from "../../wwwroot/js/Ga.js";
    export default {
        name: "TimeSlot",
            props: {
            demoSlots:{},
            instructorDetails: {},
            nextStep: { type: Function },
            closePopup: { type: Function },
            disabledTimes: [],
        },
            watch: {
     demoSlots:function(response) {
             this.demoTimeSlots=response;
    }
            },
        data() {
            return {
                selectedDate: '',
                selectedindex:-1,
                demoTimeSlots: [],
                isrequired:false
            }
        },
        methods: {
            getvalue(rowindex,timeindex){
               return ''+rowindex+''+timeindex;  
            },
            getClassName(isActive, isStartDate) {
                let className = 'cal-date';
                if (isActive) {
                    className = 'cal-date active';
                }
                if (isStartDate) {
                    className = 'cal-date month-start';
                }
                return className;
            },
            close() {
                this.closePopup();
            },
            proceedNextStep() {
                if (this.selectedDate != '') {
                     SulGA.pushEvent("demo-slot-confirm",'Slot picked', "demo-slot-confirm");
                    this.nextStep();
                } else {
                   // alert("Select the Time");
                     this.isrequired=true;
                }
            },
            selectedDateTime(event, Date, Time, DateTimeString, rowindex, timeindex) {
                if (event.currentTarget.classList.contains('disabled')) {
                    return;
                }
               this.isrequired=false;
               if ((Date != null || Date != undefined || Date != '') &&
                    (Time != null || Time != undefined || Time != '')) {
                   // document.getElementById(`${Date}|${Time}`).classList.add('selected');
                   this.selectedindex=''+rowindex+''+timeindex;
                    //this.selectedDate = DateTimeString;
                     this.selectedDate =  Date+" " + Time;

                      var taglabel = Date + "and" + Time;
                     SulGA.pushEvent("demo-slot-date-time", taglabel, "demo-slot-date-time");

                   this.$emit('set-DateTime', Date, Time, DateTimeString, this.selectedindex);
                   console.log()
                }
            },            
            filterItems(items,isSeeMore,rowindex){
                   

                if(items.length<=6){
                     return items
                }
                if(isSeeMore==true){
                  return items.slice(0,6)
                }
                 else 
                 {
                  return items
                 }
            },
            seemoreless(str,rowindex){
            this.demoTimeSlots[rowindex].isSeeMore=!this.demoTimeSlots[rowindex].isSeeMore
            },
            getTimeClass(rowindex, timeindex) {
                let index = '' + rowindex + '' + timeindex;
                

                if (this.disabledTimes &&  this.disabledTimes.includes(index)) {
                    return "time-slot c-pointer disabled";
                }
                if (('' + rowindex + '' + timeindex) == this.selectedindex) {
                    return "time-slot c-pointer selected";
                } else {
                    return "time-slot c-pointer"
                }
                
            }
           
        },
         components:{
          "DisplayUserInfor":DisplayUserInfor
        },
        mounted(){
            //   SulGA.init();
            //  SulGA.pushPageView();
            this.demoTimeSlots=this.demoSlots;
        }

    }

    </script>


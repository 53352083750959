﻿import Loader from "../Loader.js";

let DailyActivity = {
    recordClicked : false,
    audioChunks : [],
    base64Blob : [],
    audioBlob : [],
    mediaRecorder: null,
    init: function () {
        const urlParams = new URLSearchParams(window.location.search);
        const playdailyactivity = urlParams.get('playdailyactivity');

        if (document.getElementById("hdnUserPid").value != "0" && document.getElementById("hdnUserPid").value != "") {

            if (document.getElementById('hdnPageType').value == "DailyActivity")
                this.bindEvents();

            if (document.getElementById('hdnPageType').value == "Home") {
                if (playdailyactivity == "true") {
                    this.bindEvents();
                    document.querySelector("#modalDailyActivity").classList.add("show");
                }
                this.BindHomePageDailyActivityBtn();
            }
        }
        else {
            if (document.getElementById('hdnPageType').value == "DailyActivity" || 
                (document.getElementById('hdnPageType').value == "Home" && playdailyactivity == "true"))
            {
                document.getElementById("hdnLoginNextUrl").value = window.location.href;
                setTimeout(function () {
                    document.getElementById("signInMobile").click();
                    document.querySelector("#signin-base-div .close").style.display = 'none';
                    document.querySelector("#signin-base-div .signinbk").style.display = 'none';
                }, 200);
            }
        }
    },
    bindEvents: function () {
        let recordingBtn = document.getElementById("mic-recording");
        if(recordingBtn) {
                recordingBtn.addEventListener("click", function () {
                    if(DailyActivity.recordClicked)
                        DailyActivity.stopRecording();
                    else
                        DailyActivity.startRecording();
                });
        }
        let subBtn = document.getElementById("submit-btn-activity");
        if(subBtn) {
                subBtn.addEventListener("click", function () {
                        DailyActivity.saveAnswer();
                });
        }
        let subPhoneticsBtn = document.getElementById("submit-btn-phonetics-activity");
        if(subPhoneticsBtn) {
                subPhoneticsBtn.addEventListener("click", function () {
                        DailyActivity.submitRecording();
                });
        }
        let subJumbledBtn = document.getElementById("submit-btn-jumbled-activity");
        if (subJumbledBtn) {
            subJumbledBtn.addEventListener("click", function () {
                DailyActivity.GetJumbledWordAnswers(subJumbledBtn);
                DailyActivity.saveAnswer();
            });
        }
        let hintBtn = document.getElementById("jumbled-hint-btn");
        if (hintBtn) {
            let firstWordHintDone = false;
            let secondWordHintDone = false;
            var hintValue = parseInt(document.querySelector("#jumbled-hint-btn span").innerHTML);
            var answers = document.getElementById("jumbled-word-answers").value;
            document.getElementById("jumbled-word-answers").remove();
            hintBtn.addEventListener("mousedown", function () {
                if (hintValue > 0) {
                    let activeEle = document.activeElement;
                    if (activeEle) {
                        if (activeEle.tagName.toLowerCase() == 'input') {
                            let locationIndex = parseInt(activeEle.getAttribute('data-runningCount'));
                            let eleNameValue = parseInt(activeEle.name.replace('word', ''));
                            let answerArray = answers.split(',')[eleNameValue - 1].trim().split('');
                            if (answerArray.length > 0) {
                                if (eleNameValue == 1) {
                                    if (firstWordHintDone)
                                        return;
                                    else
                                        firstWordHintDone = true
                                }
                                else if (eleNameValue == 2) {
                                    if (secondWordHintDone)
                                        return;
                                    else
                                        secondWordHintDone = true
                                }
                                activeEle.value = answerArray[locationIndex];
                                hintValue--;
                                document.querySelector("#jumbled-hint-btn span").innerHTML = hintValue;
                            }
                        }
                        else {
                            document.getElementById('hint-validation-text').innerHTML = 'Please click the input & click hint...';
                            if (document.getElementById('hint-validation-text').classList.contains('d-none')) {
                                document.getElementById('hint-validation-text').classList.remove('d-none');
                                setTimeout(function () {
                                    document.getElementById('hint-validation-text').classList.add('d-none');
                                }, 5000);
                            }
                        }
                    }
                }
                else {
                    document.getElementById('hint-validation-text').innerHTML = 'Given HINT count is over...';
                    if (document.getElementById('hint-validation-text').classList.contains('d-none')) {
                        document.getElementById('hint-validation-text').classList.remove('d-none');
                        setTimeout(function () {
                            document.getElementById('hint-validation-text').classList.add('d-none');
                        }, 5000);
                    }
                }
            });
        }
        let activityAttr = document.querySelector('[data-dailyactivityTypeId]');
        if(activityAttr) {
         let activityId = activityAttr.getAttribute('data-dailyactivityTypeId');
          if(activityId) {
             if(activityId == 4 || activityId == 6|| activityId == 7) {
                    let bindData = document.querySelectorAll('#select-options li');
                    if(bindData)
                    {
                      bindData.forEach((clickitem) => { 
                        clickitem.addEventListener("click", function () {
                          bindData.forEach((e) => {
                             if(e.className.includes('selected'))
                                 e.classList.remove('selected');
                          });
                          clickitem.classList.add('selected');
                          document.getElementById('selected-answer').value = clickitem.innerHTML;
                          if (!document.getElementById('validation-text').classList.contains('d-none'))
                              document.getElementById('validation-text').classList.add('d-none');
                        });
                      });
                    }
                }
                else if(activityId == 5 || activityId == 1) {
                    let bindData = document.querySelector('#enter-answer');
                    if(bindData)
                    {
                      bindData.addEventListener("change", function () {
                          document.getElementById('selected-answer').value = bindData.value;
                          if (!document.getElementById('validation-text').classList.contains('d-none'))
                              document.getElementById('validation-text').classList.add('d-none');
                      });
                    }
                }
            }
        }
        let jumbledInp = document.querySelectorAll("[data-jumbledInput]");
        if (jumbledInp) {
            if (document.getElementById('jumbledInput1'))
                document.getElementById('jumbledInput1').focus();
            let jumbledWordEle = document.getElementById('displayWord');
            if (jumbledWordEle) {
                let jumbledWords = String(jumbledWordEle.innerText).toLowerCase().trim();
                let jumbledCharCodeArr = [];
                jumbledWords.split('').forEach(item => {
                    jumbledCharCodeArr.push(parseInt(item.charCodeAt(0)));
                    jumbledCharCodeArr.push(parseInt(item.toUpperCase().charCodeAt(0)));
                });
                let regexjumbledWords = new RegExp("/[^" + jumbledWords + jumbledWords.toUpperCase() + "]/g");
                jumbledInp.forEach(item => {
                    if (item) {
                        item.addEventListener("keyup", function (e) {
                            var kc = e.keyCode || e.which;
                            if (kc == 0 || kc == 229) { //for android chrome keycode fix
                                kc = this.value.charCodeAt(this.value.length - 1);
                            }
                            if ((jumbledCharCodeArr.includes(kc)) || kc == 46 || kc == 8 || kc == 37 || kc == 38 || kc == 39 || kc == 40) {
                                DailyActivity.GetJumbledWordAnswers(document.getElementById("submit-btn-jumbled-activity"));
                                let answer = document.getElementById('selected-answer').value.trim();
                                if (answer != '') {
                                    if (!document.getElementById('validation-text').classList.contains('d-none'))
                                        document.getElementById('validation-text').classList.add('d-none');
                                }
                                if (kc != 46 && kc != 8 && kc != 37 && kc != 38 && kc != 39 && kc != 40) {
                                    let nextId = parseInt(String(e.target.id).replace('jumbledInput', '')) + 1;
                                    var nextInput = document.getElementById("jumbledInput" + nextId + "");
                                    if (nextInput)
                                        nextInput.focus();
                                    else
                                        document.getElementById("submit-btn-jumbled-activity").focus();
                                }
                            }
                        });
                        item.addEventListener("keydown", function (e) {
                            let isAndroid = false;
                            console.log('isAndroid', isAndroid);
                            var kc = e.keyCode || e.which;
                            if (kc == 0 || kc == 229) { //for android chrome keycode fix
                                kc = this.value.charCodeAt(this.value.length - 1);
                                isAndroid = true;
                            }
                            if ((jumbledCharCodeArr.includes(kc)) || kc == 46 || kc == 8 || kc == 37 || kc == 38 || kc == 39 || kc == 40) {
                            }
                            else {
                                console.log('1', this.value);
                                if (isAndroid == false)
                                    e.preventDefault();
                                else
                                    this.value = this.value.replace(regexjumbledWords, '');
                                console.log('2', this.value);
                            }
                        });
                    }
                });
            }
        }
    },
    startRecording: function () {
        let error = document.querySelector('#error-text');
        if(error) {
        if(!error.classList.contains('d-none'))
            error.classList.add('d-none');
         error.innerHTML = '';
        }
        let audioDiv = document.querySelector('#speaker-phonetics');
        if(audioDiv) {
        if(!audioDiv.classList.contains('d-none'))
            audioDiv.classList.add('d-none');
        }
        DailyActivity.audioChunks = [];
        DailyActivity.base64Blob = [];
        navigator.mediaDevices.getUserMedia({ audio: true })
       .then(stream => {
         DailyActivity.mediaRecorder = new MediaRecorder(stream);
         DailyActivity.mediaRecorder.start();
         DailyActivity.mediaRecorder.addEventListener("dataavailable", event => {
           DailyActivity.audioChunks.push(event.data);
         });

        DailyActivity.mediaRecorder.addEventListener("stop", () => {
          DailyActivity.audioBlob = new Blob(DailyActivity.audioChunks, { type: "audio/mp3" });
          const audioUrl = URL.createObjectURL(DailyActivity.audioBlob);
          document.getElementById('audioPlayRecord').setAttribute('src', audioUrl);
           let subPhoneticsBtn = document.getElementById("submit-btn-phonetics-activity");
           if(subPhoneticsBtn) {
             subPhoneticsBtn.removeAttribute('disabled');
           }
        });
        DailyActivity.recordClicked = true;
        document.querySelector('#mic-recording svg').classList.add('record');
        let info = document.querySelector('#info-text-record');
        if(info) {
        info.innerHTML = 'Stop';
        }
       }).catch(function(err) {
        let errStrng = String(err);
        DailyActivity.mediaRecorder = null;
        DailyActivity.recordClicked = false;
        if(document.querySelector('#mic-recording svg').classList.contains('record'))
            document.querySelector('#mic-recording svg').classList.remove('record');
        if(error) {
        if(error.classList.contains('d-none'))
            error.classList.remove('d-none');
         if(errStrng.includes('Permission denied'))
         {
            error.innerHTML = 'Permission denied, Please allow mic permission in the browser...';
         }
         else if(errStrng.includes('Requested device not found'))
         {
            error.innerHTML = 'Mic not found in your Device...';
         }
         else if(errStrng.split(':').length > 1)
         {
            error.innerHTML = errStrng.split(':')[1];
         }
         else
         {
            error.innerHTML = errStrng;
         }
        }
        });
    },
    blobToBase64: function (blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    stopRecording: function () {
        DailyActivity.mediaRecorder.stop();
        DailyActivity.recordClicked = false;
        if(document.querySelector('#mic-recording svg').classList.contains('record'))
            document.querySelector('#mic-recording svg').classList.remove('record');
        let audioDiv = document.querySelector('#speaker-phonetics');
        if(audioDiv) {
        if(audioDiv.classList.contains('d-none'))
            audioDiv.classList.remove('d-none');
            let info = document.querySelector('#info-text-record');
            if(info) {
                info.innerHTML = 'Record Again';
            }
        }
    },
    submitRecording: function () {
      var base64fun = DailyActivity.blobToBase64(DailyActivity.audioBlob);
      base64fun.then(data => {
      DailyActivity.saveRecording(data);
      });
    },
    saveRecording: function (data) {
        let url = "/api/dailyactivity/save-recording";
        let activityTypeId = document.querySelector('[data-dailyactivityTypeId]').getAttribute('data-dailyactivityTypeId');
        let activityId = document.getElementById('dailyActivityId').value;
        let requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                DailyActivityType: parseInt(activityTypeId),
                RowId: parseInt(activityId),
                UserPid: parseInt(document.getElementById('hdnUserPid').value),
                Data: data.split(',')[1],
            })
        }
        Loader.show();
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data == "1")
                location.reload();
        });
    },
    saveAnswer: function () {
        let url = "/api/dailyactivity/save-answer";
        let activityTypeId = document.querySelector('[data-dailyactivityTypeId]').getAttribute('data-dailyactivityTypeId');
        let activityId = document.getElementById('dailyActivityId').value;
        let answer = document.getElementById('selected-answer').value.trim();
        if (answer == '' || answer == null)
        {
            document.getElementById('validation-text').classList.remove('d-none');
        }
        else
        {
            document.getElementById('validation-text').classList.add('d-none');
            let requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                            DailyActivityType: parseInt(activityTypeId),
                            RowId: parseInt(activityId),
                            UserPid: parseInt(document.getElementById('hdnUserPid').value),
                            Answer: answer,
                        })
            }
            Loader.show();
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    //location.reload();
                    window.location = "/dailyactivityresponse?activityId=" + activityId;
            });
        }
    },
    GetJumbledWordAnswers(subJumbledBtn) {
        let jumbledWordCount = subJumbledBtn.getAttribute('data-jumbledwordcount');
        let jumbledAnswerCount = subJumbledBtn.getAttribute('data-jumbledAnswercount');
        let answers = [];
        for (var i = 1; i <= jumbledAnswerCount; i++) {
            const answer = Array.from(document.querySelectorAll('input[name="word' + i + '[]"'), ({ value }) => value.trim()).join('');
            if (answer.length != jumbledWordCount) {
            }
            else {
                answers.push(answer.toLowerCase());
            }
        }
        if (answers.length > 0)
            if (answers.length == 1)
                document.getElementById('selected-answer').value = answers[0];
            else
                document.getElementById('selected-answer').value = answers.join(',');
        else
            document.getElementById('selected-answer').value = '';
    },
    BindHomePageDailyActivityBtn: function () {
        var viewDABtn = document.querySelector("#viewDailyActivity");
        if (viewDABtn) {
            viewDABtn.addEventListener("click", function () {
                var activityId = viewDABtn.getAttribute("data-ActivityId");
                var isActivityDone = viewDABtn.getAttribute('data-IsActivityDone');
                if (isActivityDone.toLowerCase() == "true") {
                    document.querySelector("#mbDailyActivityCompleted").classList.remove("hide");
                    document.querySelector("#modalDailyActivity").classList.add("show");
                }
                else {
                    if (!document.querySelector("#dailyActivityId")) {
                        Loader.show();
                        fetch("/api/dailyactivity/getdailyactivity?activityid=" + activityId +"&userPid="+ parseInt(document.getElementById('hdnUserPid').value))
                            .then(response => response.text())
                            .then(data => {
                                if (data == 'ActivityDone') {
                                    document.querySelector("#mbDailyActivityCompleted").classList.remove("hide");
                                    document.querySelector("#modalDailyActivity").classList.add("show");
                                    Loader.hide();
                                    return;
                                }
                                else {
                                    document.querySelector("#mbDailyActivity").innerHTML = data;
                                    DailyActivity.bindEvents();
                                    Loader.hide();
                                }
                            });
                    }
                    document.querySelector("#mbDailyActivityCompleted").classList.add("hide");
                    document.querySelector("#mbDailyActivity").classList.remove("hide");
                    document.querySelector("#modalDailyActivity").classList.add("show");
                }
            });
        }
    }
}
export default DailyActivity;
<template>
    <div>
        <div class="blurred">
            <div class="modal-title">
                <h2>Verify your phone number</h2>
            </div>
            <h4>Enter the OTP</h4>
            <div class="row">
                <h6 class="text-muted col">
                    We sent an OTP to your phone number +91 99388388388
                </h6>
            </div>
            <div class="otp-form">
                <div class="otp-group phone-input">
                    <div class="md-field">
                        <input pattern="\d*" maxlength="1" />
                    </div>
                    <div class="md-field">
                        <input pattern="\d*" maxlength="1" />
                    </div>
                    <div class="md-field">
                        <input pattern="\d*" maxlength="1" />
                    </div>
                    <div class="md-field">
                        <input pattern="\d*" maxlength="1" />
                    </div>
                </div>
                <label class="caption">
                    Resending OTP in 00:20 |
                    <a href="#" class="link">Resend now</a>
                </label>
            </div>
        </div>
        <div class="sign-in-alert">
            <h4 class="text-success">
                <i class="fa fa-check-circle"></i>
                OTP Verified successfully.
            </h4>
            <h4 class="text-success">
                <i class="fa fa-check-circle"></i>
                Successfully Signed in.
            </h4>
        </div>
    </div>
</template>
<script>
    export default {
        name: "SignInOtpVerified",
        props: {

        },
        data() {
            return {
                showpopup: false
            }
        },
        methods: {
        },
        mounted: function () {
            var urlParams = new URLSearchParams(window.location.search);
            var userid = urlParams.get("userid");
            this.userid = userid;
        },
        components: {
        },
        computed: {
            businessImgUrl: function () {

                if (!this.businessImageUrl) {

                    return "https://sulcdn.azureedge.net/content/images/avatar.jpg";
                }
                else {

                    return this.businessImageUrl;
                }
            }
        }
    }
</script>
<template>
    <div>
        <!--<div class="dropdown-wrapper" :class="{'active':openSuggestion}">-->
        <div class="search-block">
            <input class="form-control" type="text" :value="value" @input="updateValue($event.target.value)" @blur="onBlur" data-id="country-code-input"
                   @keydown.up='up'
                   @keydown.down='down'
                   @keydown.enter='enter'
                   placeholder="Search">
        </div>
        <i class="far fa-search"></i>
        <ul class="options">
            <li v-for="(suggestion, index) in matches" :key="index" countrycodesuggestion="countrycodeinput"
                v-bind:class="{'active': isActive(index)}"
                @click="suggestionClick(index)">
                {{ suggestion.countrycode }}
            </li>
        </ul>

        <!--</div>-->
    </div>
</template>

<script>
    export default {
        name: "CountryCode",
        props: {
            value: {
                type: String,
                required: true
            },
            selectionCallBack: { type: Function },
            onCountryCodeBlur: { type : Function},
        },
        data() {
            return {
                open: false,
                current: 0,
                pageX: 0,
                pageY: 0,
                suggestions: [
                    { country: 'afghanistan', code: '+93', countrycode: '+93 Afghanistan' },
                    { country: 'albania', code: '+355', countrycode: '+355 Albania' },
                    { country: 'algeria', code: '+213', countrycode: '+213 Algeria' },
                    { country: 'andorra', code: '+376', countrycode: '+376 Andorra' },
                    { country: 'angola', code: '+244', countrycode: '+244 Angola' },
                    { country: 'anguilla', code: '+1264', countrycode: '+1264 Anguilla' },
                    { country: 'antigua and barbuda', code: '+1268', countrycode: '+1268 Antigua and Barbuda' },
                    { country: 'argentina', code: '+54', countrycode: '+54 Argentina' },
                    { country: 'armenia', code: '+374', countrycode: '+374 Armenia' },
                    { country: 'aruba', code: '+297', countrycode: '+297 Aruba' },
                    { country: 'australia', code: '+61', countrycode: '+61 Australia' },
                    { country: 'austria', code: '+43', countrycode: '+43 Austria' },
                    { country: 'azerbaijan', code: '+994', countrycode: '+994 Azerbaijan' },
                    { country: 'bahamas', code: '+1242', countrycode: '+1242 Bahamas' },
                    { country: 'bahrain', code: '+973', countrycode: '+973 Bahrain' },
                    { country: 'bangladesh', code: '+880', countrycode: '+880 Bangladesh' },
                    { country: 'barbados', code: '+1246', countrycode: '+1246 Barbados' },
                    { country: 'belarus', code: '+375', countrycode: '+375 Belarus' },
                    { country: 'belgium', code: '+32', countrycode: '+32 Belgium' },
                    { country: 'belize', code: '+501', countrycode: '+501 Belize' },
                    { country: 'benin', code: '+229', countrycode: '+229 Benin' },
                    { country: 'bermuda', code: '+1441', countrycode: '+1441 Bermuda' },
                    { country: 'bhutan', code: '+975', countrycode: '+975 Bhutan' },
                    { country: 'bolivia', code: '+591', countrycode: '+591 Bolivia' },
                    { country: 'bosnia and herzegovina', code: '+387', countrycode: '+387 Bosnia and Herzegovina' },
                    { country: 'botswana', code: '+267', countrycode: '+267 Botswana' },
                    { country: 'brazil', code: '+55', countrycode: '+55 Brazil' },
                    { country: 'british indian ocean territory', code: '+246', countrycode: '+246 British Indian Ocean Territory' },
                    { country: 'british virgin islands', code: '+1284', countrycode: '+1284 British Virgin Islands' },
                    { country: 'brunei', code: '+673', countrycode: '+673 Brunei' },
                    { country: 'bulgaria', code: '+359', countrycode: '+359 Bulgaria' },
                    { country: 'burkina faso', code: '+226', countrycode: '+226 Burkina Faso' },
                    { country: 'burundi', code: '+257', countrycode: '+257 Burundi' },
                    { country: 'cambodia', code: '+855', countrycode: '+855 Cambodia' },
                    { country: 'cameroon', code: '+237', countrycode: '+237 Cameroon' },
                    { country: 'canada', code: '+1', countrycode: '+1 Canada' },
                    { country: 'cape verde', code: '+238', countrycode: '+238 Cape Verde' },
                    { country: 'cayman islands', code: '+1345', countrycode: '+1345 Cayman Islands' },
                    { country: 'central african republic', code: '+236', countrycode: '+236 Central African Republic' },
                    { country: 'chad republic', code: '+235', countrycode: '+235 Chad Republic' },
                    { country: 'chile', code: '+56', countrycode: '+56 Chile' },
                    { country: 'china', code: '+86', countrycode: '+86 China' },
                    { country: 'colombia', code: '+57', countrycode: '+57 Colombia' },
                    { country: 'comoros', code: '+269', countrycode: '+269 Comoros' },
                    { country: 'congo', code: '+242', countrycode: '+242 Congo' },
                    { country: 'congo', code: '+242', countrycode: '+242 Congo' },
                    { country: 'cook islands', code: '+682', countrycode: '+682 Cook Islands' },
                    { country: 'costa rica', code: '+506', countrycode: '+506 Costa Rica' },
                    { country: 'cote divoire', code: '+225', countrycode: '+225 Cote dIvoire' },
                    { country: 'croatia', code: '+385', countrycode: '+385 Croatia' },
                    { country: 'cuba', code: '+53', countrycode: '+53 Cuba' },
                    { country: 'cyprus', code: '+357', countrycode: '+357 Cyprus' },
                    { country: 'czech republic', code: '+420', countrycode: '+420 Czech Republic' },
                    { country: 'denmark', code: '+45', countrycode: '+45 Denmark' },
                    { country: 'djibouti', code: '+253', countrycode: '+253 Djibouti' },
                    { country: 'dominica', code: '+1767', countrycode: '+1767 Dominica' },
                    { country: 'dominican republic', code: '+1809', countrycode: '+1809 Dominican Republic' },
                    { country: 'ecuador', code: '+593', countrycode: '+593 Ecuador' },
                    { country: 'egypt', code: '+20', countrycode: '+20 Egypt' },
                    { country: 'el salvador', code: '+503', countrycode: '+503 El Salvador' },
                    { country: 'equatorial guinea', code: '+240', countrycode: '+240 Equatorial Guinea' },
                    { country: 'eritrea', code: '+291', countrycode: '+291 Eritrea' },
                    { country: 'estonia', code: '+372', countrycode: '+372 Estonia' },
                    { country: 'ethiopia', code: '+251', countrycode: '+251 Ethiopia' },
                    { country: 'falkland islands', code: '+500', countrycode: '+500 Falkland Islands' },
                    { country: 'faroe islands', code: '+298', countrycode: '+298 Faroe Islands' },
                    { country: 'fiji', code: '+679', countrycode: '+679 Fiji' },
                    { country: 'finland', code: '+358', countrycode: '+358 Finland' },
                    { country: 'france', code: '+33', countrycode: '+33 France' },
                    { country: 'french guiana', code: '+594', countrycode: '+594 French Guiana' },
                    { country: 'french polynesia', code: '+689', countrycode: '+689 French Polynesia' },
                    { country: 'gabon', code: '+241', countrycode: '+241 Gabon' },
                    { country: 'gambia', code: '+220', countrycode: '+220 Gambia' },
                    { country: 'georgia', code: '+995', countrycode: '+995 Georgia' },
                    { country: 'germany', code: '+49', countrycode: '+49 Germany' },
                    { country: 'ghana', code: '+233', countrycode: '+233 Ghana' },
                    { country: 'gibraltar', code: '+350', countrycode: '+350 Gibraltar' },
                    { country: 'greece', code: '+30', countrycode: '+30 Greece' },
                    { country: 'greenland', code: '+299', countrycode: '+299 Greenland' },
                    { country: 'grenada', code: '+1473', countrycode: '+1473 Grenada' },
                    { country: 'guam', code: '+1671', countrycode: '+1671 Guam' },
                    { country: 'guatemala', code: '+502', countrycode: '+502 Guatemala' },
                    { country: 'guernsey', code: '+441481', countrycode: '+441481 Guernsey' },
                    { country: 'guinea', code: '+224', countrycode: '+224 Guinea' },
                    { country: 'guinea-bissau', code: '+245', countrycode: '+245 Guinea-Bissau' },
                    { country: 'guyana', code: '+592', countrycode: '+592 Guyana' },
                    { country: 'haiti', code: '+509', countrycode: '+509 Haiti' },
                    { country: 'honduras', code: '+504', countrycode: '+504 Honduras' },
                    { country: 'hong kong', code: '+852', countrycode: '+852 Hong Kong' },
                    { country: 'hungary', code: '+36', countrycode: '+36 Hungary' },
                    { country: 'iceland', code: '+354', countrycode: '+354 Iceland' },
                    { country: 'india', code: '+91', countrycode: '+91 India' },
                    { country: 'indonesia', code: '+62', countrycode: '+62 Indonesia' },
                    { country: 'iran', code: '+98', countrycode: '+98 Iran' },
                    { country: 'iraq', code: '+964', countrycode: '+964 Iraq' },
                    { country: 'ireland', code: '+353', countrycode: '+353 Ireland' },
                    { country: 'isle of man', code: '+441624', countrycode: '+441624 Isle Of Man' },
                    { country: 'israel', code: '+972', countrycode: '+972 Israel' },
                    { country: 'italy', code: '+39', countrycode: '+39 Italy' },
                    { country: 'jamaica', code: '+1658 ', countrycode: '+1658  Jamaica' },
                    { country: 'japan', code: '+81', countrycode: '+81 Japan' },
                    { country: 'jersey', code: '+441534', countrycode: '+441534 Jersey' },
                    { country: 'jordan', code: '+962', countrycode: '+962 Jordan' },
                    { country: 'kazakhstan', code: '+76', countrycode: '+76 Kazakhstan' },
                    { country: 'kenya', code: '+254', countrycode: '+254 Kenya' },
                    { country: 'kiribati', code: '+686', countrycode: '+686 Kiribati' },
                    { country: 'kosovo', code: '+383', countrycode: '+383 Kosovo' },
                    { country: 'kuwait', code: '+965', countrycode: '+965 Kuwait' },
                    { country: 'kyrgyzstan', code: '+996', countrycode: '+996 Kyrgyzstan' },
                    { country: 'laos', code: '+856', countrycode: '+856 Laos' },
                    { country: 'latvia', code: '+371', countrycode: '+371 Latvia' },
                    { country: 'lebanon', code: '+961', countrycode: '+961 Lebanon' },
                    { country: 'lesotho', code: '+266', countrycode: '+266 Lesotho' },
                    { country: 'liberia', code: '+231', countrycode: '+231 Liberia' },
                    { country: 'libya', code: '+218', countrycode: '+218 Libya' },
                    { country: 'liechtenstein', code: '+423', countrycode: '+423 Liechtenstein' },
                    { country: 'lithuania', code: '+370', countrycode: '+370 Lithuania' },
                    { country: 'luxembourg', code: '+352', countrycode: '+352 Luxembourg' },
                    { country: 'macau', code: '+853', countrycode: '+853 Macau' },
                    { country: 'macedonia', code: '+389', countrycode: '+389 Macedonia' },
                    { country: 'madagascar', code: '+261', countrycode: '+261 Madagascar' },
                    { country: 'malawi', code: '+265', countrycode: '+265 Malawi' },
                    { country: 'malaysia', code: '+60', countrycode: '+60 Malaysia' },
                    { country: 'maldives', code: '+960', countrycode: '+960 Maldives' },
                    { country: 'mali', code: '+223', countrycode: '+223 Mali' },
                    { country: 'malta', code: '+356', countrycode: '+356 Malta' },
                    { country: 'marshall islands', code: '+692', countrycode: '+692 Marshall Islands' },
                    { country: 'martinique', code: '+596', countrycode: '+596 Martinique' },
                    { country: 'mauritania', code: '+222', countrycode: '+222 Mauritania' },
                    { country: 'mauritius', code: '+230', countrycode: '+230 Mauritius' },
                    { country: 'mayotte', code: '+262269', countrycode: '+262269 Mayotte' },
                    { country: 'mexico', code: '+52', countrycode: '+52 Mexico' },
                    { country: 'micronesia', code: '+691', countrycode: '+691 Micronesia' },
                    { country: 'moldova', code: '+373', countrycode: '+373 Moldova' },
                    { country: 'monaco', code: '+377', countrycode: '+377 Monaco' },
                    { country: 'mongolia', code: '+976', countrycode: '+976 Mongolia' },
                    { country: 'montenegro', code: '+382', countrycode: '+382 Montenegro' },
                    { country: 'montserrat', code: '+1664', countrycode: '+1664 Montserrat' },
                    { country: 'morocco', code: '+212', countrycode: '+212 Morocco' },
                    { country: 'mozambique', code: '+258', countrycode: '+258 Mozambique' },
                    { country: 'myanmar', code: '+95', countrycode: '+95 Myanmar' },
                    { country: 'namibia', code: '+264', countrycode: '+264 Namibia' },
                    { country: 'nauru', code: '+674', countrycode: '+674 Nauru' },
                    { country: 'nepal', code: '+977', countrycode: '+977 Nepal' },
                    { country: 'netherlands', code: '+31', countrycode: '+31 Netherlands' },
                    { country: 'netherlands', code: '+31', countrycode: '+31 Netherlands' },
                    { country: 'new caledonia', code: '+687', countrycode: '+687 New Caledonia' },
                    { country: 'new zealand', code: '+64', countrycode: '+64 New Zealand' },
                    { country: 'nicaragua', code: '+505', countrycode: '+505 Nicaragua' },
                    { country: 'niger', code: '+227', countrycode: '+227 Niger' },
                    { country: 'nigeria', code: '+234', countrycode: '+234 Nigeria' },
                    { country: 'niue', code: '+683', countrycode: '+683 Niue' },
                    { country: 'north korea', code: '+850', countrycode: '+850 North Korea' },
                    { country: 'northern mariana islands', code: '+1670', countrycode: '+1670 Northern Mariana Islands' },
                    { country: 'norway', code: '+47', countrycode: '+47 Norway' },
                    { country: 'oman', code: '+968', countrycode: '+968 Oman' },
                    { country: 'pakistan', code: '+92', countrycode: '+92 Pakistan' },
                    { country: 'palau', code: '+680', countrycode: '+680 Palau' },
                    { country: 'palestine', code: '+970', countrycode: '+970 Palestine' },
                    { country: 'panama', code: '+507', countrycode: '+507 Panama' },
                    { country: 'papua new guinea', code: '+675', countrycode: '+675 Papua New Guinea' },
                    { country: 'paraguay', code: '+595', countrycode: '+595 Paraguay' },
                    { country: 'peru', code: '+51', countrycode: '+51 Peru' },
                    { country: 'philippines', code: '+63', countrycode: '+63 Philippines' },
                    { country: 'poland', code: '+48', countrycode: '+48 Poland' },
                    { country: 'portugal', code: '+351', countrycode: '+351 Portugal' },
                    { country: 'puerto rico', code: '+1787', countrycode: '+1787 Puerto Rico' },
                    { country: 'puerto rico', code: ' +1939', countrycode: ' +1939 Puerto Rico' },
                    { country: 'qatar', code: '+974', countrycode: '+974 Qatar' },
                    { country: 'reunion', code: '+262', countrycode: '+262 Reunion' },
                    { country: 'romania', code: '+40', countrycode: '+40 Romania' },
                    { country: 'russia', code: '+7', countrycode: '+7 Russia' },
                    { country: 'rwanda', code: '+250', countrycode: '+250 Rwanda' },
                    { country: 'saint helena', code: '+290', countrycode: '+290 Saint Helena' },
                    { country: 'saint pierre and miquelon', code: '+508', countrycode: '+508 Saint Pierre and Miquelon' },
                    { country: 'samoa', code: '+685', countrycode: '+685 Samoa' },
                    { country: 'san marino', code: '+378', countrycode: '+378 San Marino' },
                    { country: 'sao tome & principe', code: '+239', countrycode: '+239 Sao Tome & Principe' },
                    { country: 'saudi arabia', code: '+966', countrycode: '+966 Saudi Arabia' },
                    { country: 'senegal', code: '+221', countrycode: '+221 Senegal' },
                    { country: 'serbia', code: '+381', countrycode: '+381 Serbia' },
                    { country: 'seychelles', code: '+248', countrycode: '+248 Seychelles' },
                    { country: 'sierra leone', code: '+232', countrycode: '+232 Sierra Leone' },
                    { country: 'singapore', code: '+65', countrycode: '+65 Singapore' },
                    { country: 'slovakia', code: '+421', countrycode: '+421 Slovakia' },
                    { country: 'slovenia', code: '+386', countrycode: '+386 Slovenia' },
                    { country: 'somalia', code: '+252', countrycode: '+252 Somalia' },
                    { country: 'south africa', code: '+27', countrycode: '+27 South Africa' },
                    { country: 'south korea', code: '+82', countrycode: '+82 South Korea' },
                    { country: 'south sudan', code: '+211', countrycode: '+211 South Sudan' },
                    { country: 'spain', code: '+34', countrycode: '+34 Spain' },
                    { country: 'sri lanka', code: '+94', countrycode: '+94 Sri Lanka' },
                    { country: 'st. kitts and nevis', code: '+1869', countrycode: '+1869 St. Kitts and Nevis' },
                    { country: 'st. lucia', code: '+1758', countrycode: '+1758 St. Lucia' },
                    { country: 'st. vincent and the grenadines', code: '+1784', countrycode: '+1784 St. Vincent and the Grenadines' },
                    { country: 'sudan', code: '+249', countrycode: '+249 Sudan' },
                    { country: 'suriname', code: '+597', countrycode: '+597 Suriname' },
                    { country: 'swaziland', code: '+268', countrycode: '+268 Swaziland' },
                    { country: 'sweden', code: '+46', countrycode: '+46 Sweden' },
                    { country: 'switzerland', code: '+41', countrycode: '+41 Switzerland' },
                    { country: 'syria', code: '+963', countrycode: '+963 Syria' },
                    { country: 'taiwan', code: '+886', countrycode: '+886 Taiwan' },
                    { country: 'tajikistan', code: '+992', countrycode: '+992 Tajikistan' },
                    { country: 'tanzania', code: '+255', countrycode: '+255 Tanzania' },
                    { country: 'thailand', code: '+66', countrycode: '+66 Thailand' },
                    { country: 'timor-leste', code: '+670', countrycode: '+670 Timor-Leste' },
                    { country: 'togo', code: '+228', countrycode: '+228 Togo' },
                    { country: 'tonga', code: '+676', countrycode: '+676 Tonga' },
                    { country: 'trinidad and tobago', code: '+1868', countrycode: '+1868 Trinidad and Tobago' },
                    { country: 'tunisia', code: '+216', countrycode: '+216 Tunisia' },
                    { country: 'turkey', code: '+90', countrycode: '+90 Turkey' },
                    { country: 'turkmenistan', code: '+993', countrycode: '+993 Turkmenistan' },
                    { country: 'turks & caicos islands', code: '+1649', countrycode: '+1649 Turks & Caicos Islands' },
                    { country: 'tuvalu', code: '+688', countrycode: '+688 Tuvalu' },
                    { country: 'uganda', code: '+256', countrycode: '+256 Uganda' },
                    { country: 'ukraine', code: '+380', countrycode: '+380 Ukraine' },
                    { country: 'united arab emirates', code: '+971', countrycode: '+971 United Arab Emirates' },
                    { country: 'united kingdom', code: '+44', countrycode: '+44 United Kingdom' },
                    { country: 'uruguay', code: '+598', countrycode: '+598 Uruguay' },
                    { country: 'usa', code: '+1', countrycode: '+1 USA' },
                    { country: 'uzbekistan', code: '+998', countrycode: '+998 Uzbekistan' },
                    { country: 'vanuatu', code: '+678', countrycode: '+678 Vanuatu' },
                    { country: 'venezuela', code: '+58', countrycode: '+58 Venezuela' },
                    { country: 'vietnam', code: '+84', countrycode: '+84 Vietnam' },
                    { country: 'wallis and futuna islands', code: '+681', countrycode: '+681 Wallis and Futuna Islands' },
                    { country: 'yemen', code: '+967', countrycode: '+967 Yemen' },
                    { country: 'zambia', code: '+260', countrycode: '+260 Zambia' },
                    { country: 'zimbabwe', code: '+263', countrycode: '+263 Zimbabwe' },
                ]
            }
        },
        mounted: function () {
            document.addEventListener('mousemove', (event) => {
                this.pageX = event.clientX;
                this.pageY = event.clientY;
                
            })
        },
        computed: {
            // Filtering the suggestion based on the input
            matches() {
                return this.suggestions.filter((obj) => {
                    return obj.country.indexOf(this.value.toLowerCase()) >= 0 || obj.code.indexOf(this.value.toLowerCase()) >= 0
                })
            },
            openSuggestion() {
                return this.selection !== '' &&
                    this.matches.length !== 0 &&
                    this.open === true
            }
        },
        methods: {
            updateValue(value) {
                if (this.open === false) {
                    this.open = true
                    this.current = 0
                }
                this.$emit('input', value)

                
                //this.selectionCallBack(this.matches[index].code);
            },
            // When enter pressed on the input
            enter() {
                this.$emit('input','')
                this.open = false
                this.selectionCallBack(this.matches[this.current].code);
            },
            // When up pressed while suggestions are open
            up() {
                if (this.current > 0) {
                    this.current--
                }
            },
            // When up pressed while suggestions are open
            down() {
                if (this.current < this.matches.length - 1) {
                    this.current++
                }
            },
            // For highlighting element
            isActive(index) {
                return index === this.current
            },
            // When one of the suggestion is clicked
            suggestionClick(index) {
                this.$emit('input', '')
                this.open = false
                this.selectionCallBack(this.matches[index].code);
            },
            onBlur() {
                if(this.onCountryCodeBlur) {
                     if (document.elementFromPoint(this.pageX, this.pageY).getAttribute("countrycodesuggestion")) {
                        return;
                    }
                    this.onCountryCodeBlur();
                }
            }
        }
    }
</script>
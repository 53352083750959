<template>
    <div>

        <div :class="showme ? 'modal show' : 'modal'" v-if="isloading == false">
            <div class="modal-dialog modal-dialog-scrollable">
                <!-- Modal content -->
                <div class="modal-content">
                    <!--<div class="close">
                      <span class="d-none d-lg-block" @click="hideme()">×</span>
                    </div>-->
                    <div class="modal-header align-items-center">
                        <svg viewBox="0 0 64 64" class="svg-icon medium d-sm-none" @click="hideme()">
                            <use xlink:href="#chevronBack"></use>
                        </svg>
                        <h5>Refer to a friend</h5>
                        <button type="button" class="close" @click="hideme()">&times;</button>
                    </div>
                    <div class="modal-body refer-friend">
                        <!--<h6 class="text-muted text-center mb-lg-3 mt-lg-0 d-none d-lg-block">
                            Refer to a friend
                        </h6>-->
                        <div class="btn btn-block btn-outline-body disabled d-none d-lg-flex justify-content-between mb-4 px-3">
                            <span class="caption" style="overflow: hidden; text-overflow: ellipsis;" id="spanmyurl">{{geturl()}}</span>
                            <a class="caption link" @click="CopyToClipboard()">Copy</a>
                        </div>
                        <div class="overflow-x-md-auto">
                            <div class="d-flex justify-content-between flex-wrap-md-none">
                                <div class="share-option-wrapper" @click="getlinkedinurl()">
                                    <img class="app-icon"
                                         src="https://sulcdn.azureedge.net/content/caps/social-share/linkedin-logo.svg"
                                         alt="app-icon" />
                                    <span class="body3">Linkedin</span>
                                </div>
                                <div class="share-option-wrapper" @click="getfacebookurl()">
                                    <img class="app-icon"
                                         src="https://sulcdn.azureedge.net/content/caps/social-share/facebook.svg"
                                         alt="app-icon" />
                                    <span class="body3">Facebook</span>
                                </div>
                                <!-- <div class="share-option-wrapper">
                                  <img class="app-icon" src="https://lscdn.blob.core.windows.net/elearn/100x100.jpg" alt="app-icon">
                                  <span class="body3">Instagram</span>
                                </div> -->
                                <!-- <div class="share-option-wrapper">
                                  <img class="app-icon" @click="getwhat" src="https://lscdn.blob.core.windows.net/elearn/100x100.jpg" alt="app-icon">
                                  <span class="body3">Whatsapp</span>
                                </div> -->
                                <div class="share-option-wrapper" @click="gettwitterurl()">
                                    <img class="app-icon"
                                         src="https://sulcdn.azureedge.net/content/caps/social-share/twitter.svg"
                                         alt="app-icon" />
                                    <span class="body3">Twitter</span>
                                </div>
                                <div class="share-option-wrapper" @click="showemailpanel()">
                                    <img class="app-icon"
                                         src="https://sulcdn.azureedge.net/content/caps/social-share/email.svg"
                                         alt="app-icon" />
                                    <span class="body3">Email</span>
                                </div>
                                <div class="share-option-wrapper" @click="showsmspanel()">
                                    <img class="app-icon"
                                         src="https://sulcdn.azureedge.net/content/caps/social-share/chat.svg"
                                         alt="app-icon" />
                                    <span class="body3">SMS</span>
                                </div>
                                <div class="share-option-wrapper">
                                    <img class="app-icon"
                                         @click="CopyToClipboard()"
                                         src="https://sulcdn.azureedge.net/content/caps/social-share/link.svg"
                                         alt="app-icon" />
                                    <span class="body3">Copy link</span>
                                </div>
                            </div>
                        </div>
                        <div class="border-bottom mb-4"></div>
                        <div class="form" v-if="showemail">
                            <div class="md-field mb-4">
                                <input type="text"
                                       v-model="username"
                                       name="name"
                                       class="name"
                                       id="name"
                                       required=""
                                       placeholder="Friend name" />
                                <label alt="name" for="name">Friend name</label>
                            </div>
                            <div class="md-field mb-4">
                                <input type="email"
                                       v-model="emailid"
                                       name="email"
                                       class="email"
                                       id="email"
                                       required=""
                                       placeholder="Friends Email ID " />
                                <label alt="email" for="email">Friends Email ID </label>
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-primary" @click="sendEmail()">Send</button>
                                <button  type="button" class="btn btn-outline-secondary ml-3"  @click="hidemail()">
                                    Cancel
                                </button>
                            </div>
                            
                        </div>

                        <div class="form mt-5" v-if="showsms">
                            <div class="md-field mb-4">
                                <input type="text"
                                       name="name"
                                       v-model="username"
                                       class="name"
                                       id="name"
                                       required=""
                                       placeholder="Friend name" />
                                <label alt="name" for="name">Friend name</label>
                            </div>

                            <div class="phone-input md-field mb-3">
                                <h6 class="country">
                                    + 91
                                    <small><i class="far fa-chevron-down"></i></small>
                                </h6>
                                <input type="email"
                                       v-model="mobileno"
                                       name="mobile"
                                       class="mobile"
                                       id="mobile"
                                       required=""
                                       placeholder="Your phone number" />
                                <label alt="email" for="mobile"> Your phone number </label>
                                <!-- Add/remove .active to show/hide dropdown -->
                                <div class="dropdown-wrapper">
                                    <div class="search-block">
                                        <input type="text" placeholder="Search for country code" />
                                    </div>
                                    <i class="far fa-search"></i>
                                    <ul class="options">
                                        <li>Australia (+61)</li>
                                        <li>Austria (+43)</li>
                                        <li>Albania (+335)</li>
                                        <li>Algeria (+213)</li>
                                        <li>Angola (+224)</li>
                                        <li>Argentina (+994)</li>
                                        <li>Austria (+43)</li>
                                        <li>Albania (+335)</li>
                                        <li>Algeria (+213)</li>
                                        <li>Angola (+224)</li>
                                        <li>Argentina (+994)</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-primary" @click="sendSms()">Send</button>
                                <button type="button" class="btn btn-outline-secondary ml-3" @click="hidesms()">
                                    Cancel
                                </button>
                            </div>
                            
                        </div>
                        <!--<button class="py-md-3 btn-cancel" @click="hideme()">
                            Close
                        </button>-->

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Loader from "../../wwwroot/js/Loader.js";
    export default {
        name: "SocialShare",
        props: {
            courseId: "",
            UserId: "",
            showpopup: "",
            callback: {
                type: Function,
            },
            shorturl: ""
        },
        data() {
            return {
                course: {},
                image: "",
                eurl: "",
                encodedurl: "",
                title: "",
                emailid: "",
                mobileno: "",
                batchid: 0,
                displaymail: "none",
                displaysms: "none",
                isloading: false,
                username: "",
                showme: true,
                showsms: false,
                showemail: false,
                shorturl: "",
                isoneononesession: false,
                tutorprofile: ""
            };
        },
        created: function () { },
        mounted: function () {
            ;
            // alert(this.courseId)
            // alert(this.UserId)
            // alert(this.showme)
            Loader.showAboveModal();
            var getcourseurl =
                "/api/course/GetCourse?CourseId=" +
                this.courseId +
                "&UserId=" +
                this.UserId;
            this.isloading = true;
            fetch(getcourseurl, { method: "GET" })
                .then((responseJson) => responseJson.json())
                .then((response) => {
                    Loader.hideAboveModal();
                    var title = response.CourseTitle;
                    var image = response.CourseImageURL;
                    this.isoneononesession = response.isoneononesession;
                    this.tutorprofile = response.tutorprofile;
                    var slug = null;
                    // var eurl = slug === null || slug === undefined ? window.location.href+"#lessonsOffered" : "https://elearn.sulekha.com" + slug;
                    var eurl =
                        slug === null || slug === undefined
                            ? "https://elearn.sulekha.com#lessonsOffered"
                            : "https://elearn.sulekha.com" + slug;
                    // this.eurl = eurl;
                    // var encodedurl = encodeURIComponent(eurl);
                    debugger;
                    this.eurl = this.getshorturl(response.shorturl, response.tutorprofile, response.isoneononesession);
                    //  this.encodedurl = encodedurl;
                    this.encodedurl = this.getshorturl(response.shorturl, response.tutorprofile, response.isoneononesession)
                    this.image = image;
                    this.title = title;
                    this.tutorname = response.TutorName;
                    this.batchid = response.BatchUniqueID;
                    this.isloading = false;
                    this.username = response.StudnetName;
                    this.shorturl = response.shorturl;
                });
        },
        methods: {
            hideme() {
                ;
                this.showme = false;
                if (this.callback) {
                    this.callback();
                }
            },
            showemailpanel() {
                this.showemail = true;
            },
            hidemail() {
                this.showemail = false;
            },
            hidesms() {
                this.showsms = false;
            },
            showsmspanel() {
                this.showsms = true;
            },
            geturl() {
                if (this.isoneononesession == true)
                    return window.location.origin + this.tutorprofile;
                else
                    return window.location.origin + this.shorturl;
                // window.open(result, '_blank');
            },
            getshorturl(url, tutorurl, isoneononesession) {
                if (isoneononesession == true)
                    return window.location.origin + tutorurl;
                else {
                    return window.location.origin + url;
                }
                // window.open(result, '_blank');
            },
            getfacebookurl: function () {

                var href =
                    "http://www.facebook.com/sharer.php?u=" +
                    this.encodedurl +
                    "&p[title]=" +
                    this.title +
                    '" target="_blank"';
                window.open(href, '_blank');
            },
            gettwitterurl: function () {
                var href =
                    "http://twitter.com/share?text=" +
                    this.title +
                    "&url=" +
                    this.encodedurl +
                    '" target="_blank"';
                window.open(href, '_blank');
                // return href;
            },
            getlinkedinurl: function () {
                var href =
                    "https://www.linkedin.com/shareArticle?mini=true&url=" +
                    this.encodedurl +
                    "&title=" +
                    this.title +
                    '&summary=&source=" target="_blank"';
                window.open(href, '_blank');
                // return href;
            },

            runEmailShare: function () {
                this.displaysms = "none";
                // this.displaymail='block'

                if (this.displaymail == "block") {
                    this.displaymail = "none";
                } else {
                    this.displaymail = "block";
                }
            },

            runSmsShare: function () {
                this.displaymail = "none";
                // this.displaysms='block'
                if (this.displaysms == "block") {
                    this.displaysms = "none";
                } else {
                    this.displaysms = "block";
                }
            },
            sendEmail: function () {
                if (!this.username) {
                    alert("Name is mandatory");
                    return;
                } else if (!this.emailid) {
                    alert("Email is mandatory");
                    return;
                }

                this.sendNotification("email", this.username, null, this.emailid);
            },
            sendSms: function () {
                if (!this.username) {
                    alert("Name is mandatory");
                    return;
                } else if (!this.mobileno) {
                    alert("Mobile No is mandatory");
                    return;
                } else if (this.mobileno.length !== 10) {
                    alert("Mobile No is invalid");
                    return;
                }

                this.sendNotification("sms", this.username, this.mobileno, null);
            },
            CopyToClipboard: function (e) {
                var copyText = document.getElementById("spanmyurl");
                var textArea = document.createElement("textarea");
                textArea.value = copyText.textContent;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("Copy");
                alert("Link Copied");
                textArea.remove();


            },
            sendNotification: function (type, userName, mobileno, emailid) {
                var sendnoificattionurl =
                    "/api/course/sendnotification?userName=" +
                    userName +
                    "&mobilenums=" +
                    mobileno +
                    "&emailids=" +
                    emailid +
                    "&title=" +
                    this.title +
                    "&by=" +
                    this.tutorname +
                    "&url=" +
                    this.eurl;

                Loader.showAboveModal();
                fetch(sendnoificattionurl, { method: "GET" })
                    .then((responseJson) => responseJson.json())
                    .then((response) => {
                        Loader.hideAboveModal();
                        if (response != null) {
                            var result = JSON.parse(response);
                            this.displaymail = "none";
                            this.displaysms = "none";
                            if (result.Success == true) {
                                alert(result.Result);
                            } else {
                                alert(result.Result);
                            }
                        }
                    });
            },
        },
    };
</script>
<template>

    <!-- Modal body start -->
    <div>
        <!-- Left block start -->
        <div class="course-details-block d-flex align-items-center">
            <!-- course details -->

            <div class="img-wrapper">
                <img :src="businessImgUrl"
                     alt=""
                     class="fit-cover" />
            </div>

            <h6 class="pl-3 ">Classes by {{businessName}}</h6>

            <!-- course details end -->
        </div>
        <!-- Left block end -->
        <!-- Right block start -->
        <div class="course-rlist">

            <b>choose the course you are writing the review for</b>
            <ul>


                <!-- select course card start -->
                <li v-for="course in courses" class="d-flex"
                    v-on:click="onCourseSelected"
                    :data-courseId="course.courseId"
                    :data-courseName="course.courseName"
                    :data-courseImageUrl="course.photoThumbnailUrl">
                    <div class="col-3 px-0">
                        <div class="img-wrapper d-flex">
                            <img :src='course.photoThumbnailUrl'
                                 alt="course.courseName"
                                 class="fit-cover" />

                        </div>
                    </div>
                    <div class="col d-flex align-items-center justify-content-between">
                        <h6>{{course.courseName}}</h6><i class="far fa-angle-right text-primary pl-2"></i>
                    </div>
                </li>
                <!-- select course card end -->

            </ul>
        </div>
        <!-- Right  block end -->
    </div>  
</template>
<script>
    export default {
        props: {
            businessName: "",
            courses: Object,
            businessImageUrl: "",
        },
        data() {
            return {

            }
        },
        methods: {
            onCourseSelected(event) {
                
                let selectedCourseDetails = {
                    "courseId": event.currentTarget.getAttribute('data-courseId'),
                    "courseName": event.currentTarget.getAttribute('data-courseName'),
                    "courseImageUrl": event.currentTarget.getAttribute('data-courseImageUrl')
                }
                this.$emit('courseSelected', selectedCourseDetails)
            }
        },
        computed: {
            businessImgUrl: function () {

                if (!this.businessImageUrl) {
                    
                    return "https://sulcdn.azureedge.net/content/images/avatar.jpg";
                }
                else {
                    
                    return this.businessImageUrl;
                }
            }
        }
    }
</script>
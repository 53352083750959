﻿import Vue from "vue"
import QuizPopup from "../../../VueComponents/Quiz/QuizPopup.vue";
import FilltheGapPopup from "../../../VueComponents/Quiz/FillTheGapPopup.vue";

let QuizListing = {
    init : function () {
        this.bindEvents();
    },
    bindEvents: function () {
        let playBtns = document.getElementById("quiz-card-div");
        if(playBtns) {
                let playquizcardData = JSON.parse(document.getElementById('playquizcard-json').innerText);
                if(parseInt(playquizcardData.TotalQuizPlayed) < parseInt(playquizcardData.TotalQuiz))
                {
                playBtns.addEventListener('click', (event) => {
                    if(document.getElementById("quiz-base-div")) {
                        document.body.classList.add("overflow-hidden");

                        new Vue({
                            el: "#quiz-div",
                            components: {
                                QuizPopup: QuizPopup,
                            },
                            render: function (h) {
                                return h(QuizPopup, {
                          props: {
                              playNext : parseInt(playquizcardData.TotalQuiz) - (parseInt(playquizcardData.TotalQuizPlayed) + 1)
                          }
                          });
                            }
                        })
                    }
                })
                }
        }
    }
}

let FilltheGap = {
    init : function () {
        this.bindEvents();
    },
    bindEvents: function () {
        let fillthegapBtn = document.getElementById("fillthegap-card-div");
        if(fillthegapBtn) {
            let playquizcardData = JSON.parse(document.getElementById('playquizcard-json').innerText);
            if(parseInt(playquizcardData.TotalFilltheGapPlayed) < parseInt(playquizcardData.TotalFilltheGap))
            {
            fillthegapBtn.addEventListener('click', (event) => {
            if(document.getElementById("quiz-base-div")) {
              document.body.classList.add("overflow-hidden");
              new Vue({
                  el: "#fillthegap-div",
                  components: {
                      FilltheGapPopup: FilltheGapPopup,
                  },
                  render: function (h) {
                      return h(FilltheGapPopup, {
                          props: {
                              userPid : parseInt(document.getElementById("hdnUserPid").value),
                              playNext : parseInt(playquizcardData.TotalFilltheGap) - (parseInt(playquizcardData.TotalFilltheGapPlayed) + 1)
                          }
                      });
                  }
              })
              }
            })
           }
      }
    }
}
QuizListing.init();

if (document.getElementById('hdnPageType') != null && document.getElementById('hdnPageType').value == "Home")
    FilltheGap.init();
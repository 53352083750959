var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade course-tab w-100  show active ",
      attrs: { id: "material" }
    },
    [
      _c(
        "div",
        { staticClass: "search-input-wrapper rounded mx-3 py-2" },
        _vm._l(_vm.materiallist, function(material, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "row pb-3 mx-0 mt-3",
              attrs: { title: "Click here to visit to course management" },
              on: {
                click: function($event) {
                  return _vm.redirecttolms()
                }
              }
            },
            [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "row mx-0 flex-column py-4 px-3 rounded-3 bg-white shadow-sm"
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("i", {
                        class:
                          material.filetype == "Video"
                            ? "col-2 mt-2 fab text-center fa-youtube"
                            : "col-2 mt-2 fas text-center fa-file-pdf",
                        on: {
                          click: function($event) {
                            return _vm.showfile(
                              material.filetype,
                              material.filePath
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("h4", { staticClass: "col-8 px-0 mb-2" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(material.bookname) +
                            "\n                  "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-8 mb-3 pl-0 caption text-muted" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(material.filetype) +
                              "\n                  "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(1, true)
                  ]
                )
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { class: _vm.showpopup ? "modal show" : "modal" }, [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              {
                staticClass: "close",
                on: {
                  click: function($event) {
                    return _vm.closepopup()
                  }
                }
              },
              [
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.closepopup()
                      }
                    }
                  },
                  [_vm._v("×")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body", staticStyle: { height: "20rem" } },
              [
                _vm.showvideo
                  ? _c("video", {
                      attrs: { width: "450", controls: "", src: _vm.videourl }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showimage
                  ? _c("img", {
                      staticClass: "img-fluid bg-img w-100 h-100",
                      attrs: { src: _vm.imageurl, alt: "" }
                    })
                  : _vm._e()
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-2 d-flex align-items-center justify-content-center justify-content-md-end pr-3"
      },
      [
        _c(
          "button",
          { staticClass: "btn-reset", attrs: { "aria-label": "options" } },
          [_c("i", {})]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-2" }, [
      _c("h6", { staticClass: "offset-2 pl-0 col-8" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
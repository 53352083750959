﻿//var lazyLoading = {
//    init: function () {
//        this.ready(function () {
//            if ('loading' in HTMLImageElement.prototype) {
//                alert("loading");
//                const images = document.querySelectorAll("img.lazyload");
//                images.forEach(img => {
//                    img.src = img.dataset.src;
//                });
//            } else {
//                alert("IntersectionObserver");
//                intersectionObserverLazyLoading.init();
//            }
//        })
//    },
//    ready: function (callback) {
//        // in case the document is already rendered
//        if (document.readyState != 'loading') callback();
//        // modern browsers
//        else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
//        // IE <= 8
//        else document.attachEvent('onreadystatechange', function () {
//            if (document.readyState == 'complete') callback();
//        });
//    }
//}
//lazyLoading.init();


var intersectionObserverLazyLoading =
{
    init: function () {
        document.addEventListener("DOMContentLoaded", function () {
            var lazyloadImages;

            if ("IntersectionObserver" in window) {
                lazyloadImages = document.querySelectorAll("img.lazyload");
                var imageObserver = new IntersectionObserver(function (entries, observer) {
                    entries.forEach(function (entry) {
                        if (entry.isIntersecting) {
                            var image = entry.target;
                            image.src = image.dataset.src;
                            image.classList.remove("lazyload");
                            imageObserver.unobserve(image);
                        }
                    });
                });

                lazyloadImages.forEach(function (image) {
                    imageObserver.observe(image);
                });
            } else {
                var lazyloadThrottleTimeout;
                lazyloadImages = document.querySelectorAll("img.lazyload");

                function lazyload() {
                    if (lazyloadThrottleTimeout) {
                        clearTimeout(lazyloadThrottleTimeout);
                    }

                    lazyloadThrottleTimeout = setTimeout(function () {
                        var scrollTop = window.pageYOffset;
                        lazyloadImages.forEach(function (img) {
                            if (img.offsetTop < (window.innerHeight + scrollTop)) {
                                img.src = img.dataset.src;
                                img.classList.remove('lazyload');
                            }
                        });
                        if (lazyloadImages.length == 0) {
                            document.removeEventListener("scroll", lazyload);
                            window.removeEventListener("resize", lazyload);
                            window.removeEventListener("orientationChange", lazyload);
                        }
                    }, 20);
                }

                document.addEventListener("scroll", lazyload);
                window.addEventListener("resize", lazyload);
                window.addEventListener("orientationChange", lazyload);
            }
        })
    }
}
intersectionObserverLazyLoading.init();
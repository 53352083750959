<template>
    <div>
        <p class="body1 fw-400 mb-3 text-dark text-green">
            <i class="fas fa-check-circle"></i>
            <span>Thanks for your review</span>
        </p>
        <div class="course-details-block d-flex align-items-center">
            <div class="img-wrapper">
                <img :src="selectedCourseImgUrl" alt="" class="fit-cover">
            </div>
            <h6 class="pl-3 ">{{selectedCourseName}}</h6>
        </div>
        <div class="review-container mb-3">
            <div class="mt-3">
                <h6>Excellent</h6>
                <div class="reviews d-flex mt-2" v-html="renderRatingStars()">
                </div>
            </div>
            <p class="mt-3">
                {{this.reviewDescription}}
            </p>
        </div>
    </div>
</template>


<script>
    export default {
        props: {
            selectedCourseName: String,
            selectedCourseImgUrl: String,
            selectedRating: Number,
            reviewDescription: String,
        },
        data() {
            return {
                
            }
        },
        mounted: function () {
            this.renderRatingStars(this.selectedRating);
        },
        methods: {
            onSubmit: function (event) {
                let textarea = document.querySelector('.review-container TEXTAREA');
                this.reviewDescription = textarea.value;
            },
            renderRatingStars: function () {
                
                let starRatings = "";
                for (let i = 0; i < 5; i++) {
                    if (i < this.selectedRating) {
                        starRatings += "<i class='fa fa-star'></i>"
                    } else {
                        starRatings += "<i class='far fa-star'></i>"
                    }
                    
                }
                return starRatings;
            },
            onClose: function () {
                this.$emit('onCloseEmit');
            }
        }
    }
</script>
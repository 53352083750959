var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("svg", { staticClass: "d-none" }, [
        _c("defs", [
          _c("g", { attrs: { id: "bookmarkIcon" } }, [
            _c("path", {
              attrs: {
                d:
                  "M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"
              }
            })
          ]),
          _vm._v(" "),
          _c("g", { attrs: { id: "tagsIcon" } }, [
            _c("g", [
              _c("path", {
                attrs: {
                  d:
                    "M340.38,23.224H206.396c-8.48,0-16.624,3.376-22.624,9.376L9.372,206.968c-12.496,12.496-12.496,32.752,0,45.264\n\t\t\tl133.984,133.984c12.496,12.496,32.752,12.496,45.248,0l174.4-174.368c6-6.016,9.376-14.16,9.376-22.656V55.224\n\t\t\tC372.38,37.544,358.06,23.224,340.38,23.224z M284.38,135.224c-13.248,0-24-10.752-24-24s10.752-24,24-24s24,10.752,24,24\n\t\t\tS297.628,135.224,284.38,135.224z"
                }
              })
            ]),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                attrs: {
                  d:
                    "M404.38,55.224l-0.016,148.944c0,7.376-2.928,14.464-8.16,19.68L218.988,401.064l2.72,2.72\n\t\t\tc12.496,12.496,32.752,12.496,45.248,0l160.032-160c6.016-6,9.392-14.144,9.392-22.624V87.224\n\t\t\tC436.38,69.544,422.06,55.224,404.38,55.224z"
                }
              })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.RecomendationCard, function(recoCard, index) {
        return [
          _c("div", { staticClass: "row w-100 mb-4 mx-auto" }, [
            _c(
              "div",
              {
                staticClass: "course-card d-flex flex-wrap w-100",
                on: {
                  click: function($event) {
                    return _vm.onCardClick(index)
                  }
                }
              },
              [
                _c("div", { staticClass: "col-lg-4 px-0" }, [
                  _c("div", { staticClass: "img-wrapper" }, [
                    _c("img", {
                      staticClass: "fit-cover fit-cover-right",
                      attrs: { src: recoCard.PhotoThumbnailURL, alt: "" }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-8" }, [
                  recoCard.AverageRating > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex pr-3 pt-3 pb-2 justify-content-between"
                        },
                        [
                          _c("div", { staticClass: "reviews" }, [
                            _c("div", { staticClass: " ratings medium" }, [
                              _c("span", {
                                class: _vm.renderRatingStars(
                                  recoCard.AverageRating
                                )
                              })
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(recoCard.AverageRating) +
                                  " (" +
                                  _vm._s(recoCard.ReviewCount) +
                                  " review(s)) "
                              )
                            ])
                          ])
                        ]
                      )
                    : _c("div"),
                  _vm._v(" "),
                  _c("div", { staticClass: "p-lg-3 py-3" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-start align-items-lg-center"
                      },
                      [
                        _c("h3", { staticClass: "card-title" }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(recoCard.CourseTitle) +
                              "\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "circle-bookmark shadow-sm",
                            class: { active: recoCard.Shortlist },
                            attrs: {
                              "data-shortlist": recoCard.CourseID,
                              "data-hasnoclickevent": "true"
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "svg-icon medium c-pointer",
                                attrs: { viewBox: "0 0 384 512" }
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#bookmarkIcon" }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v("Classes by " + _vm._s(recoCard.TutorNameShort))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted pt-2 sub-title" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(recoCard.DurationinHr) +
                          " hr/session • " +
                          _vm._s(recoCard.NumberofSessions) +
                          " sessions • " +
                          _vm._s(recoCard.CourseDuration) +
                          " weeks\n                            "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex pt-2 align-items-center" },
                      [
                        recoCard.CourseFees != recoCard.AfterDiscountCourseFees
                          ? _c(
                              "p",
                              { staticClass: "tbody-lg mr-2" },
                              [
                                _c("strike", [
                                  _vm._v(
                                    " ₹" +
                                      _vm._s(
                                        recoCard.CourseFees.toLocaleString()
                                      )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        recoCard.CourseFees != recoCard.AfterDiscountCourseFees
                          ? _c(
                              "h5",
                              { staticClass: "sub-title-lg mr-2 mb-0" },
                              [
                                _vm._v(
                                  "₹" +
                                    _vm._s(
                                      recoCard.AfterDiscountCourseFees.toLocaleString()
                                    )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        recoCard.CourseFees == recoCard.AfterDiscountCourseFees
                          ? _c(
                              "h5",
                              { staticClass: "sub-title-lg mr-2 mb-0" },
                              [
                                _vm._v(
                                  "₹" +
                                    _vm._s(recoCard.CourseFees.toLocaleString())
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("label", { staticClass: "caption mb-0" }, [
                          _vm._v(
                            "(Rs. " +
                              _vm._s(recoCard.AmountPerSession) +
                              "/session)"
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    recoCard.DiscountPercentage > 0
                      ? _c(
                          "div",
                          { staticClass: "discount-tag mt-2 caption" },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "svg-icon large green-fill",
                                attrs: { viewBox: "0 0 436.38 436.38" }
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#tagsIcon" }
                                })
                              ]
                            ),
                            _vm._v(
                              "\n                                " +
                                _vm._s(recoCard.DiscountPercentage) +
                                "% off\n                            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    recoCard.IsInstantLiveDemo == true
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn-success my-4 bookfreedemoclk",
                            class: _vm.disabledClass(
                              recoCard.IsActive,
                              recoCard.CourseEnrolledStatus
                            ),
                            attrs: {
                              "data-courseid": recoCard.CourseID,
                              "data-instantDemoEnabled":
                                recoCard.IsInstantLiveDemo
                            }
                          },
                          [
                            _c("span", { staticClass: "live-badge" }, [
                              _vm._v("LIVE")
                            ]),
                            _vm._v(
                              "\n                                Join Instant Demo\n                            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    recoCard.IsInstantLiveDemo == true &&
                    _vm.disabledClass(
                      recoCard.IsActive,
                      recoCard.CourseEnrolledStatus
                    ) == ""
                      ? _c(
                          "p",
                          { staticClass: "info-card-recommendation live-inst" },
                          [
                            _vm._v(
                              "Tutor is live for instant demo booking for free"
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    recoCard.IsInstantLiveDemo == false
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn-primary my-4 bookfreedemoclk",
                            class: _vm.disabledClass(
                              recoCard.IsActive,
                              recoCard.CourseEnrolledStatus
                            ),
                            attrs: {
                              "data-courseid": recoCard.CourseID,
                              "data-instantDemoEnabled":
                                recoCard.IsInstantLiveDemo
                            }
                          },
                          [
                            _c("span", { staticClass: "free-badge" }, [
                              _vm._v("FREE")
                            ]),
                            _vm._v(
                              "\n                                Book Demo\n                            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    recoCard.IsInstantLiveDemo == false &&
                    _vm.disabledClass(
                      recoCard.IsActive,
                      recoCard.CourseEnrolledStatus
                    ) == ""
                      ? _c(
                          "p",
                          {
                            staticClass: "info-card-recommendation free-course"
                          },
                          [
                            _vm._v(
                              "Join free demo class absolutely free... Hurry!"
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    recoCard.CourseEnrolledStatus == 5
                      ? _c(
                          "p",
                          {
                            staticClass: "info-card-recommendation text-danger"
                          },
                          [
                            _vm._v(
                              "Please attend the demo courses you have booked so far, before booking another demo."
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    recoCard.CourseEnrolledStatus == 1 ||
                    recoCard.CourseEnrolledStatus == 2
                      ? _c(
                          "p",
                          {
                            staticClass: "info-card-recommendation text-danger"
                          },
                          [_vm._v("Already enrolled for this course")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    recoCard.IsActive == 11
                      ? _c(
                          "p",
                          {
                            staticClass: "info-card-recommendation text-danger"
                          },
                          [_vm._v("Course is Full. Please check back later")]
                        )
                      : _vm._e()
                  ])
                ])
              ]
            )
          ])
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
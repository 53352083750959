import Vue from "vue";
import LearningVideoSection from "../../../VueComponents/ClubVideo/LearningVideoSection.vue";

let LearningVideosPage = {
    init: function () {
        this.bindClickEvents();
    },
    bindClickEvents: function () {
        if(document.querySelector("[data-id='category-tabs']")) {
            document.querySelectorAll("[data-id='category-tabs']").forEach((item) => {
                item.addEventListener('click', (event) => {
                    let bucket = event.currentTarget.innerText;
                    let props = {
                        bucket : bucket,
                    }
                    this.mountComponent(props);
                })
            })
        }

        if(document.querySelector("[data-id='razor-filter-btn']")) {
            document.querySelector("[data-id='razor-filter-btn']").addEventListener('click', () => {
                this.mountComponent();
                document.querySelector("[data-id='vue-filter-btn']").click();
            })
        }
    },
    mountComponent: function (props) {

        let componnetBaseDiv = "<div id='learning-videos-base-div'><div id='learning-videos-div'></div></div>";
        if(document.querySelector("[data-id='learning-videos-razor-div']")) {
            document.querySelector("[data-id='learning-videos-razor-div']").innerHTML = componnetBaseDiv;
        }
        if(document.getElementById("learning-videos-base-div")) {
            console.log("vue component loaded");
            new Vue({
                el: "#learning-videos-div",
                components: {
                    LearningVideoSection :LearningVideoSection,
                },
                render: function (h) {
                    return h(LearningVideoSection, {
                        props: {
                            bucket: props ? props.bucket : '',
                            language: props ? props.language : '',
                        }
                    });
                }
            })
        }
        
    }
}
LearningVideosPage.init();
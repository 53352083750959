var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-block mb-5",
          staticStyle: { width: "161px" },
          on: { click: _vm.onClickAddNew }
        },
        [_vm._v("Add new schedule")]
      ),
      _vm._v(" "),
      _vm.showForm
        ? _c("AddNewClubSchedule", {
            attrs: {
              onClose: _vm.onFormClose,
              clubScheduleDetails: _vm.clubScheduleDetails,
              availableClubNames: _vm.availableClubNames,
              availableMeetingTypes: _vm.availableMeetingTypes,
              coaches: _vm.coaches,
              hosts: _vm.hosts,
              coHosts: _vm.coHosts
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.upcomingSchedules, function(schedule) {
          return _c(
            "div",
            { staticClass: "col-4", staticStyle: { "border-style": "groove" } },
            [
              _c("table", [
                _c("tr", [
                  _vm._m(0, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.day) + " ")])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(1, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.date))])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(2, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.meetingType))])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(3, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.clubName))])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(4, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.clubLevel))])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(5, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.time))])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(6, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.duration))])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(7, true),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v(_vm._s(schedule.displayMeetingTopic))])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(8, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.coachName))])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(9, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.host))])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(10, true),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(schedule.coHostNames))])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block",
                        attrs: { "data-schedule-id": schedule.clubScheduleId },
                        on: { click: _vm.onClickEditBtn }
                      },
                      [_vm._v("Edit")]
                    )
                  ])
                ])
              ])
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Day : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Date : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Meeting Type : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Club Name : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Level : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Time : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Duration : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Topic : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Coach : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Host : ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("label", [_vm._v("Co-Host : ")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
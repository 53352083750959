<template>
    <div class="filterCardShowMore">
        <!-- Card Start -->
        <div v-for="Card in courseCards" class="col-sm-6 col-lg-6 col-12" v-on:click="locationHref(Card.CourseURL)">
            <div :class="'course-card  pr-0  ' + Card.CourseID +'  '+ dataLanguage(Card.Languages).toLowerCase() +'  '+ dataRating(Card.TutorAverageRating) +'  '+ Card.C2Id + '_' + Card.C3Id +'  '+ dataRange(Card).toLowerCase()"
                 :data-GroupByLable="Card.C2Id + '_' + Card.C3Id" :data-courseid="Card.C2Id + '_' + Card.C3Id" :data-ratings="dataRating(Card.TutorAverageRating)" :data-languageFtr="dataLanguage(Card.Languages)" :data-range="dataRange(Card)"
                 v-on:click="locationHref(Card.CourseURL)" :data-courseIdForReviews="Card.CourseID" :data-CourseTitle="Card.CourseTitle"
                 :data-Thumbnail="Card.PhotoThumbnailURL" course-card>
                <div class="overview-course-video" v-if="Card.PromotionalVideoURL">
                    <iframe v-if="Card.PromotionalVideoURL.includes('www.youtube.com')" :title=Card.CourseTitle :src="Card.PromotionalVideoURL"
                            :srcdoc="renderSrcDoc(Card.PromotionalVideoURL,Card.CourseTitle)">
                    </iframe>
                    <!--else-->
                    <video v-else playsinline controls muted>
                        <source :src="Card.PromotionalVideoURL" type="video/mp4">
                    </video>
                </div>
                <div v-else-if="Card.PromotionalVideoThumbnail" class="img-wrapper d-flex">
                    <img :src="Card.PromotionalVideoThumbnail"
                         :alt="Card.CourseTitle" loading="lazy"
                         class="lazyload img-fluid bg-img w-100 h-100" />
                </div>
                <div v-else-if="Card.PhotoThumbnailURL" class="img-wrapper d-flex">
                    <img :src="Card.PhotoThumbnailURL"
                         :alt="Card.CourseTitle" loading="lazy"
                         class="lazyload img-fluid bg-img w-100 h-100" />
                </div>
                <div class="p-3">
                    <div class="d-flex flex-wrap">
                        <div class="d-flex justify-content-between w-100">
                            <h4 class="course-title">
                                <a :href="Card.CourseURL" :title="Card.CourseTitle">
                                    {{Card.CourseTitle}}
                                </a>
                            </h4>
                            <div :data-shortlist="Card.CourseID" :class="bookmarkClass(Card.Shortlist)" data-hasnoclickevent="true">
                                <svg class="svg-icon medium c-pointer" viewBox="0 0 384 512">
                                    <use xlink:href="#bookmarkIcon" />
                                    <g id="bookmarkIcon">
                                        <path d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"></path>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div v-if="Card.TutorAverageRating > 0" class="reviews">
                                <div class="ratings medium">
                                    <span :class="renderRatingStars(Card.TutorAverageRating)"></span>
                                </div>
                                <span class="caption" v-if="Card.TutorAverageRating > 0 && Card.TutorReviewCount > 0"> {{Card.TutorAverageRating}}({{Card.TutorReviewCount}} reviews) </span>
                            </div>
                            <div v-if="Card.TutorReviewCount == 0"></div>
                        </div>
                    </div>
                    <p class="mt-3">By <b class="text-black">{{Card.TutorNameShort}}</b></p>
                    <small class="mt-3 d-block">Medium of Instruction: <b class="text-black">{{Card.Languages}}</b></small>
                    <ul class="high-lights">
                        <li>{{Card.DurationinHr}} hr/session</li>
                        <li>{{Card.NumberofSessions}} sessions </li>
                        <li>{{Card.CourseDuration}} week(s)</li>
                    </ul>
                    <div class="d-flex card-actions">
                        <div class="price-range">
                            <h5 v-if="Card.CourseFees != Card.AfterDiscountCourseFees" class="sub-title-lg mb-0">{{Card.RupeeSymbol}}{{parseInt(Card.AfterDiscountCourseFees)}}</h5>
                            <strike v-if="Card.CourseFees != Card.AfterDiscountCourseFees">{{Card.RupeeSymbol}}{{parseInt(Card.CourseFees)}}</strike>
                            <h5 v-else>{{Card.RupeeSymbol}}{{parseInt(Card.CourseFees)}}</h5>
                            <div class="d-flex session-count">
                                <span v-if="Card.CoursePaymentOptions == 'Monthly'">/ Month</span>
                                <label> ({{Card.RupeeSymbol}}{{Card.AmountPerSession}}/session)</label>
                                <span v-if="Card.DiscountPercentage > 0">{{parseInt(Card.DiscountPercentage)}}% off</span>
                            </div>
                        </div>
                        <template v-if="Card.BusinessId != 11145456">
                            <button v-if="Card.IsInstantLiveDemo == true" data-bookdemobtn data-hasnoclickevent='true' class="btn btn-success bookfreedemoclk" :data-courseid="Card.CourseID" :data-instantDemoEnabled="Card.IsInstantLiveDemo">
                                <span class="live-badge">LIVE</span>
                                Join Instant Demo
                            </button>
                            <p v-if="Card.IsInstantLiveDemo == true" class="info-card live-inst">Tutor is live for instant demo booking for free</p>
                            <button v-if="Card.IsInstantLiveDemo == false" data-bookdemobtn data-hasnoclickevent='true' class="btn btn-primary bookfreedemoclk" :data-courseid="Card.CourseID" :data-instantDemoEnabled="Card.IsInstantLiveDemo">
                                <span class="free-badge">FREE</span>
                                Join Demo
                            </button>
                            <p v-if="Card.IsInstantLiveDemo == false" class="info-card free-course">Join free demo class absolutely free... Hurry!</p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <!-- Card End -->
    </div>
</template>

<script>
    import ShortList from "/wwwroot/js/common/ShortList.js";
    export default {
        name: "CourseListing",
        props: {
            courseCards: Array
        },
        methods: {
            dataGroupByName: function (data) {
                var group = data.trim().split(" ").join("");
                return group;
            },
            dataLanguage: function (data) {
                var language = data.trim().split(" ").join("");
                return language;
            },
            dataRating: function (data) {
                var ratingCard = "ratings" + Math.floor(data).toString();
                return ratingCard;
            },
            locationHref: function (url) {
                window.location.href = url;
            },
            disabledClass: function (IsActive, EnrolledStatus) {
                var className = "";
                if (IsActive != 1 || EnrolledStatus > 0)
                    className = "disabled";
                return className;
            },
            bookmarkClass: function (cardShortlist) {
                var shortList = parseInt(cardShortlist);
                var bc = "circle-bookmark shadow-sm";
                if (shortList == 1) {
                    bc = "circle-bookmark shadow-sm active";
                }
                return bc;
            },
            renderSrcDoc: function (srcD, titleD) {
                var youtubeUrl = srcD;
                var title = titleD;
                var regex = /youtube.com\/embed\/([a-zA-Z0-9-_]+)?/g;
                var match = regex.exec(youtubeUrl);
                var youtubeVideoId = match[1];
                var retData = "<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}div{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgb(0 0 0 / 38%);display:flex;align-items:center;justify-content:center;}em{position:absolute;width:40px;height:40px;border-radius:50px;background:#ff7a21;overflow:hidden;display:flex;align-items:center;justify-content:center;}svg{width:18px;fill:#fff;position:relative;left:1px;}img {object-fit: cover;height: 100%;width: 100%;}</style><a href='" + youtubeUrl + "'><img src=https://img.youtube.com/vi/" + youtubeVideoId + "/hqdefault.jpg alt='" + title + "'><div><em><svg viewBox='0 0 448 512'><path d='M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z'></path></svg></em></div></a>";
                return retData;
            },
            renderRatingStars: function (rating) {
                switch (rating) {
                    case 0: return "star0"
                        break;
                    case 0.5: return "star0-5"
                        break;
                    case 1: return "star1"
                        break;
                    case 1.5: return "star1-5"
                        break;
                    case 2: return "star2"
                        break;
                    case 2.5: return "star2-5"
                        break;
                    case 3: return "star3"
                        break;
                    case 3.5: return "star3-5"
                        break;
                    case 4: return "star4"
                        break;
                    case 4.5: return "star4-5"
                        break;
                    case 5: return "star5"
                        break;
                }
            },
            tempRanges: function () {
                var tempRange = [], ranges = 0;
                var array = this.courseCards;
                if (array.length <= 5) {
                    var rangeAmtMax = Math.max.apply(Math, array.map(function (o) { return o.AfterDiscountCourseFees; }));
                    var rangeAmtMin = Math.min.apply(Math, array.map(function (o) { return o.AfterDiscountCourseFees; }));
                    var x = rangeAmtMin;
                    var y = rangeAmtMax;
                    rangeAmtMin = x % 1000 >= 500 ? x + 1000 - x % 1000 : x - x % 1000;
                    rangeAmtMax = y % 1000 >= 500 ? y + 1000 - y % 1000 : y - y % 1000;
                    tempRange.push(parseInt(rangeAmtMin));
                    tempRange.push(parseInt(rangeAmtMax));
                }
                else if (array.length <= 10) {
                    var rangeAmt = Math.max.apply(Math, array.map(function (o) { return o.AfterDiscountCourseFees; }));
                    rangeAmt = rangeAmt / 3;
                    rangeAmt = Math.round(rangeAmt / 500) * 500;
                    for (var i = 0; i < 3; i++) {
                        ranges = ranges + Math.round(rangeAmt / 500) * 500;
                        tempRange.push(parseInt(ranges));
                    }
                }
                else {
                    var rangeAmt = Math.max.apply(Math, array.map(function (o) { return o.AfterDiscountCourseFees; }));
                    rangeAmt = rangeAmt / 8;
                    rangeAmt = Math.round(rangeAmt / 500) * 500;
                    for (var i = 0; i < 8; i++) {
                        ranges = ranges + Math.round(rangeAmt / 500) * 500;
                        tempRange.push(parseInt(ranges));
                    }
                }
                //console.log("tempRange", tempRange);
                return tempRange;
            },
            dataRange: function (Card) {
                var RangeID = '', tempRangeList = [];
                tempRangeList = this.tempRanges();
                //console.log("tempRanges", tempRangeList);
                var RangeCount = tempRangeList.length;
                //console.log("RangeCount", RangeCount);
                if (Card.AfterDiscountCourseFees > 0) {
                    RangeID = '';
                    if (Card.AfterDiscountCourseFees < tempRangeList[0]) {
                        RangeID = "-" + tempRangeList[0].toString();
                    }
                    else {
                        for (var i = 1; i < RangeCount; i++) {
                            if (Card.AfterDiscountCourseFees <= tempRangeList[i] && Card.AfterDiscountCourseFees >= tempRangeList[i - 1]) {
                                RangeID = tempRangeList[i - 1].toString() + "-" + tempRangeList[i].toString();
                                break;
                            }
                        }
                    }
                    RangeID = "range" + RangeID.replace(" ", "");
                }
                return RangeID;
            },
        },
        updated: function () {

        }
    }
</script>
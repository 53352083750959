﻿let scrollToTop = {
    scrollUpElem: document.querySelector("#scroll-top"),
    lastScrollTop: 0,
    init: function () {
        scrollToTop.scrollUpElem.addEventListener('click', function (evt) {
            scrollToTop.scrollTo(document.body, 0, 300);
            evt.preventDefault();
        });
        let navBar = null;
        let navBarOffSetTop = 0;
        if (document.querySelector('.course-nav-tab')) {
            navBar = document.querySelector('.course-nav-tab');
            navBarOffSetTop = window.pageYOffset + navBar.getBoundingClientRect().top;
        }
        
        window.onscroll = function () {
            var st = document.body.scrollTop;
            if (st > scrollToTop.lastScrollTop) {
                scrollToTop.scrollUpElem.classList.remove('show');
            } else {
                if (document.body.scrollTop > 200) {
                    if (document.getElementById('hdnPageType') != null && document.getElementById('hdnPageType').value == "Listing") {
                        var Ismobile = document.getElementById('hdnIsMobile').getAttribute('data-isMobile');
                        if (document.getElementById('hdnIsMobile') == null || Ismobile != "True") {
                            scrollToTop.scrollUpElem.classList.add('show');
                        }
                    }
                    else {
                        scrollToTop.scrollUpElem.classList.add('show');
                    }
                } else {
                    scrollToTop.scrollUpElem.classList.remove('show');
                }
            }
            scrollToTop.lastScrollTop = st;


            if (document.querySelector('.course-nav-tab')) {

                const navBarHeight = navBar.clientHeight;

                const headerOffSetTop = document.querySelector('.header-section').offsetTop;
                const headerSecitonHeight = document.querySelector('.header-section').clientHeight;
                if (window.pageYOffset > navBarOffSetTop) {
                    navBar.classList.add("nav-sticky");
                } else {
                    navBar.classList.remove("nav-sticky");
                }
                const sectionList = document.querySelectorAll('div[scroll]')
                const navBarLinks = document.querySelectorAll('.course-nav-tab ul li a');
                let current = "";
                sectionList.forEach(section => {
                    const sectionTop = section.getBoundingClientRect().top;

                    const sectionHeight = section.clientHeight;
                    if (pageYOffset >= ((window.pageYOffset + sectionTop) - (navBarHeight + headerSecitonHeight) - 100)) {
                        current = section.getAttribute('id');
                    }
                })


                //let about = document.querySelector(".nav-link[data-scroll='about']");


                navBarLinks.forEach(element => {
                    element.classList.remove('active');
                    if (element.getAttribute('data-scroll') == current) {
                        element.classList.add('active');
                    }
                })
            }
        }
    },
    scrollTo: function (element, to, duration) {
        if (duration <= 0) return;
        var difference = to - element.scrollTop;
        var perTick = difference / duration * 10;

        setTimeout(function () {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop == to) return;
            scrollTo(element, to, duration - 10);
        }, 10);
    },

}
if (document.querySelector("#scroll-top") != null)
    scrollToTop.init();

export default scrollToTop;
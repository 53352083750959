<template>
    <div>
        <!--<button type="button"
                class="close cabso mr-2 mt-2"
                style="display: block; padding: 0rem"
                @click="closePopup">
            &times;
        </button>-->

        <div class="row mx-0">
            <!-- Left block start -->
            <DisplayUserInfor :instructorDetails="instructorDetails" />

            <!-- Left block end -->
            <!-- Right block start -->
            <div class="col-lg-6 py-3">
                
                <div class="thankmain">
                    
                    <div class="text-success thankdemo" style="color:#FF0000!important;">
                        <i class="fad fa-check-circle"></i>

                        <b class="mb-1">{{errorScreenDetails.errorHeadingText}}</b>
                        
                        <!--<p class="caption text-muted">
                            The instructor will be present to offer you a live demo class.
                        </p>-->
                       
                    </div>
                    <div class="alert alert-primary">
                        <!--<p class="caption fw-700 mb-2">
                            The instructors time is valuable just as yours is
                        </p>-->
                        <p class="caption mb-2" v-if="errorScreenDetails.errorType == 'TIME_CONFLICT'">
                            You have booked a demo class for another course at the same time {{errorScreenDetails.selectedDateTime}}.
                            Choose another time slot convenient to you to complete the demo booking.
                        </p>
                        <p class="caption mb-2" v-else>
                            {{errorScreenDetails.errorContent}}
                        </p>
                        <!--<p class="caption">Thank you.</p>-->
                    </div>
                </div>
                <div class="next-button">
                    <div></div>
                    <a class="btn btn-primary"
                       @click="onBtnClick">
                        {{errorScreenDetails.NextButtonText}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DisplayUserInfor from "./DisplayUserInfor.vue";
    export default {
        props: {
            errorScreenDetails: Object,
            instructorDetails: Object,
            closePopup: Function,
        },
        components: {
            DisplayUserInfor: DisplayUserInfor,
        },
        methods: {
            onBtnClick() {
                if (this.errorScreenDetails.onBtnClick) {
                    this.errorScreenDetails.onBtnClick();
                }
            },
        }
    }
</script>
<template>
  <section class="container pt-5 mt-5">
    <div class="pay-thank" v-if="showprocessing()">
      <div class="thankmain text-center">
        <div class="text-success thankdemo">
          <i class="fad fa-check-circle"></i>
          <b class="mb-1">Processing, please wait…</b>
        </div>
      </div>
    </div>

    <!-- <span v-if="showme=false">Process is going on. Please wait...</span> -->

    <ThankYouForPayment
      v-if="showme"
      :batchdate="instructorDetails.scheduledisplaytime"
      :instructorDetails="instructorDetails"
      :closePopup="closePopup"
      :ispaid="true"
      :paymentdetails="paymentdetails"
    />
  </section>
</template>
<script>
import ThankYouForPayment from "./ThankYouForPayment.vue";
import Loader from "../../wwwroot/js/Loader.js";
import SulGA from "../../wwwroot/js/Ga.js";
export default {
  name: "DemoThankyouWrapper",
  props: {
    courseId: "",
    initialPopup: "",
    showpopup: false,
  },
  data() {
    return {
      instructorDetails: {},
      showme: false,
      paymentdetails: {},
    };
  },
  created() {},
  mounted: function () {
    Loader.showAboveModal();
    var coursethankyoudetails = document.querySelector(
      "#coursethankyoudetails"
    );
    console.log("course thank you details model ", coursethankyoudetails);
    if (coursethankyoudetails.innerHTML == "") {
      //hide header and footer
      document.getElementsByClassName("header-section")[0].style.visibility =
        "hidden";
      document.getElementsByClassName("bg-dark")[0].style.visibility = "hidden";
      this.showme = false;
      return;
    }

    if (coursethankyoudetails.innerHTML != "") {
      Loader.hideAboveModal();
      this.showme = true;
      this.instructorDetails = JSON.parse(coursethankyoudetails.innerHTML);
    }
    debugger;
    var paymentdetails = document.querySelector("#paymentdetails");
    if (paymentdetails.innerHTML != "") {
      if (paymentdetails != null) {
        this.paymentdetails = JSON.parse(paymentdetails.innerHTML);
        var unrid =this.paymentdetails.Order.UnrId;
        var talabel = "paid " + unrid;
        // SulGA.init();
        // SulGA.pushPageView();
        SulGA.pushEvent("paid-booking-success", talabel, "paid-thank-you");

        
      }
    }
    // this.showme=this.showpopup;
  },
  methods: {
    closePopup() {
      this.showme = false;
    },
    showprocessing() {
      if (this.showme == false) {
        return true;
      } else return false;
    },
  },
  components: {
    ThankYouForPayment: ThankYouForPayment,
  },
};
</script>

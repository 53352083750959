var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "sdialog",
        staticStyle: { display: "block" },
        attrs: { id: "calendar" }
      },
      [
        _c(
          "div",
          { staticClass: "socialshare medium sdialog-block sdialog-show" },
          [_c("span", [_vm._v("i am calendar")])]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
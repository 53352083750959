﻿var SulGA = {
    userId: '',
    userName: '',
    gTag: '',
    pageType: '',
    c1Id: '',
    c1Name: '',
    c2Id: '',
    c2Name: '',
    c3Id: '',
    c3Name: '',
    deviceType: '',
    businessId: '',
    tutorName: '',
    courseId: '',
    courseTitle:'',
    init: function () {
        window.dataLayer = window.dataLayer || [];
        this.gtag('js', new Date());
        if (document.getElementById("hdnUserPid").value != null) {
            this.userId = document.getElementById("hdnUserPid").value;
        }
        if (document.getElementById("hdnUserName").value != null) {
            this.userName = document.getElementById("hdnUserName").value;
        }
        //if (document.getElementById("hidCityName").value != null) {
        //    this.urlCity = document.getElementById('hidCityName').value;
        //}

        if (document.getElementById("hdnC1Id").value != null) {
            this.c1Id = document.getElementById('hdnC1Id').value;
        }
        if (document.getElementById("hdnC1Name").value != null) {
            this.c1Name = document.getElementById('hdnC1Name').value;
        }
        if (document.getElementById("hdnC2Id").value != null) {
            this.c2Id = document.getElementById('hdnC2Id').value;
        }
        if (document.getElementById("hdnC2Name").value != null) {
            this.c2Name = document.getElementById('hdnC2Name').value;
        }
        if (document.getElementById("hdnC3Id").value != null) {
            this.c3Id = document.getElementById('hdnC3Id').value;
        }
        if (document.getElementById("hdnC3Name").value != null) {
            this.c3Name = document.getElementById('hdnC3Name').value;
        }

        //if (document.getElementById("hidGTag").value != null) {
        //    this.gTag = document.getElementById('hidGTag').value;
        //}
        if (document.getElementById("hdnPageType").value != null) {
            this.pageType = document.getElementById('hdnPageType').value;
        }
        if (document.getElementById("hdnDeviceType").value != null) {
            this.deviceType = document.getElementById('hdnDeviceType').value;
        }
        if (document.getElementById("hdnBusinessId").value != null) {
            this.businessId = document.getElementById('hdnBusinessId').value;
        }
        if (document.getElementById("hdnTutorName").value != null) {
            this.tutorName = document.getElementById('hdnTutorName').value;
        }
        if (document.getElementById("hdnCourseId").value != null) {
            this.courseId = document.getElementById('hdnCourseId').value;
        }
        if (document.getElementById("hdnCourseTitle").value != null) {
            this.courseTitle = document.getElementById('hdnCourseTitle').value;
        }
    },
    gtag: function () {
        window.dataLayer.push(arguments);;
    }, 
    pushPageView: function () {
        console.log('pushPageView');
        this.gtag('config', 'UA-165661548-2', {
            'dimension1': this.userId,
            'dimension2': this.pageType,
            'dimension3': window.location.href,
            'dimension4': '', //city
            'dimension5': this.c1Id.concat('^', this.c1Name),
            'dimension6': this.c2Id.concat('^', this.c2Name),
            'dimension7': this.c3Id.concat('^', this.c3Name),
            'dimension8': this.deviceType,
            'dimension9': this.businessId.concat('^', this.tutorName),
            'dimension10': '',
            'dimension11': this.courseId.concat('^', this.courseTitle)
        });
    },
    pushEvent: function (event_Category, event_Label, event_action) {
        console.log('pushEvent');
        this.gtag('event', event_action, {
            'event_category': event_Category,
            'event_label': event_Label
        });
    }
}

export default SulGA;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "close cabso mr-2 mt-2",
        staticStyle: { display: "block", padding: "0rem" },
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      },
      [_vm._v("\n        ×\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row mx-0" },
      [
        _c("DisplayUserInfor", {
          attrs: {
            instructorDetails: _vm.instructorDetails,
            liveSessionUrl: _vm.liveSessionUrl
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 py-3" }, [
          _c("div", { staticClass: "thankmain" }, [
            _vm.ispaid
              ? _c("div", { staticClass: "text-success thankdemo" }, [
                  _c("i", { staticClass: "fad fa-check-circle" }),
                  _vm._v(" "),
                  _c("b", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.showamount()) +
                        "\n                    "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.instructorDetails.singuptype == "instantdemo"
              ? _c("div", { staticClass: "instant-demothank" }, [
                  _c("span", [
                    _vm._v(
                      "Please click the below button to join the demo class."
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btn-large",
                      on: {
                        click: function($event) {
                          return _vm.gotomeeting(_vm.liveSessionUrl)
                        }
                      }
                    },
                    [_vm._v("Join the Class Now")]
                  ),
                  _vm._v(" "),
                  _vm._m(0)
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.instructorDetails.singuptype != "instantdemo"
              ? _c("div", { staticClass: "text-success thankdemo" }, [
                  _c("i", { staticClass: "fad fa-check-circle" }),
                  _vm._v(" "),
                  _vm.instructorDetails.singuptype == "demo"
                    ? _c("b", { staticClass: "mb-1" }, [
                        _vm._v("Demo scheduled succesfully")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.instructorDetails.singuptype == "regular" &&
                  _vm.instructorDetails.CourseName != "One On One Sessions"
                    ? _c("b", { staticClass: "mb-1" }, [
                        _vm._v("Regular course scheduled succesfully")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.instructorDetails.singuptype == "regular" &&
                  _vm.instructorDetails.CourseName == "One On One Sessions"
                    ? _c("b", { staticClass: "mb-1" }, [
                        _vm._v("One On One Session scheduled succesfully")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.batchdate))]),
                  _vm._v(" "),
                  _vm.instructorDetails.singuptype == "demo"
                    ? _c("p", { staticClass: "caption text-muted" }, [
                        _vm._v(
                          "\n                        The instructor will be present to offer you a live demo class.\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.instructorDetails.singuptype == "regular"
                    ? _c("p", { staticClass: "caption text-muted" }, [
                        _vm._v(
                          "\n                        The instructor will be present to offer you a live class.\n                    "
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.instructorDetails.singuptype != "instantdemo"
              ? _c("div", { staticClass: "alert alert-primary" }, [
                  _vm.instructorDetails.singuptype != "instantdemo"
                    ? _c("p", { staticClass: "caption fw-700 mb-2" }, [
                        _vm._v(
                          "\n                        The instructors time is valuable just as yours is\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.instructorDetails.singuptype == "demo"
                    ? _c("p", { staticClass: "caption mb-2" }, [
                        _vm._v(
                          "\n                        Please be present for the demo a few minutes before the start time\n                        to ensure your audio and video are functioning well.\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.instructorDetails.singuptype == "regular"
                    ? _c("p", { staticClass: "caption mb-2" }, [
                        _vm._v(
                          "\n                        Please be present for the session a few minutes before the start\n                        time to ensure your audio and video are functioning well.\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "caption" }, [_vm._v("Thank you.")])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.instructorDetails.singuptype != "instantdemo"
            ? _c("div", { staticClass: "next-button" }, [
                _c("div"),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.gotomycourse()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    Go to my courses\n                "
                    )
                  ]
                )
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-muted" }, [
      _c("b", [_vm._v("Please Note")]),
      _vm._v(" The tutor will join within 2 minutes and start the class."),
      _c("br"),
      _vm._v("\n                        This class will be conducted on zoom."),
      _c("br"),
      _vm._v(
        "\n                        Do not close the zoom window\n                    "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <!-- Left block start -->
  <!-- <DisplayUserInfor :instructorDetails="instructorDetails" /> -->

        <!-- Left block end -->
        <!-- Right block start -->
    <section class="container container-exceed pt-4 mt-5">
        <div class="pay-thank">
            <iframe :src="gatewayurl" frameborder="0" />
        </div>
        <!--<div class="safe-wrapper d-md-none">
            <span>100% Safe and Secure Payment</span>
            <img src="//lscdn.azureedge.net/content/images/payment/Image-81-2x.png" alt="Payment Safe">
        </div>-->
    </section>
    
</template>
<script>
import Sessions from "../MyCourses/Sessions.vue";
export default {
  components: { Sessions },
  name: "PaymentGateway",
  props: {},
  methods: {},
  data() {
    return {
      gatewayurl: "",
    };
  },
  mounted() {
    //alert(this.gatewayurl)
 
    var urlParams = new URLSearchParams(window.location.search);
    var oid = urlParams.get("oid");
     // if( isMobile.any() ) {
     //  this.gatewayurl =
     //    "https://payments.sulekha.com/elearnpayment.aspx?oid=" +
     //    oid +
     //    "&_source=pwa";
     //} else {
     //  this.gatewayurl =
     //    "https://payments.sulekha.com/elearnpayment.aspx?oid=" + oid;
     // }
      this.gatewayurl =
          "https://payments.sulekha.com/elearnpayment.aspx?oid=" +
          oid +
          "&_source=pwa";
   //this.gatewayurl="https://payments.sulekha.com/elearnpayment.aspx?oid=" + oid;

  },
};

var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};
</script>
﻿var CommonValidation = {
    regexp: {
        alpha: /^[a-z0-9\.\s]+$/i,
        number: /[^0-9]/g,
        email: /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i,
        mobile: /^[9|7|6|8]\d{8}[\d{1}]$/i,
        internationalmobile: /^[0-9]{6,14}$/,
        pincode: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/
    },
    ValidateName: function(name) {
        return (this.regexp.alpha.test(name) || (name.match(/\./g) != null && name.match(/\./g).length > 3) || (name.replace(/[^a-zA-Z]+/g, "").length <= 2));
    },
    ValidateEmail: function(email) {
        return this.regexp.email.test(email);
    },
    ValidateMobileNumber: function(mobileno, countrycode) {
        if (countrycode == undefined || countrycode == "" || countrycode == "91" || countrycode == "+91") {
            return (this.regexp.mobile.test(mobileno));
        }
        else {
            return (this.regexp.internationalmobile.test(mobileno));
        }
    },
    ValidatePincode: function (input) {
        return this.regexp.pincode.test(input);
    }
}

export default CommonValidation;
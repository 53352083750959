var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "blurred" }, [
        _c("div", { staticClass: "modal-title" }, [
          _c("h2", [_vm._v("Verify your phone number")])
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("Enter the OTP")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("h6", { staticClass: "text-muted col" }, [
            _vm._v(
              "\n                We sent an OTP to your phone number +91 99388388388\n            "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "otp-form" }, [
          _c("div", { staticClass: "otp-group phone-input" }, [
            _c("div", { staticClass: "md-field" }, [
              _c("input", { attrs: { pattern: "\\d*", maxlength: "1" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "md-field" }, [
              _c("input", { attrs: { pattern: "\\d*", maxlength: "1" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "md-field" }, [
              _c("input", { attrs: { pattern: "\\d*", maxlength: "1" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "md-field" }, [
              _c("input", { attrs: { pattern: "\\d*", maxlength: "1" } })
            ])
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "caption" }, [
            _vm._v(
              "\n                Resending OTP in 00:20 |\n                "
            ),
            _c("a", { staticClass: "link", attrs: { href: "#" } }, [
              _vm._v("Resend now")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sign-in-alert" }, [
        _c("h4", { staticClass: "text-success" }, [
          _c("i", { staticClass: "fa fa-check-circle" }),
          _vm._v("\n            OTP Verified successfully.\n        ")
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "text-success" }, [
          _c("i", { staticClass: "fa fa-check-circle" }),
          _vm._v("\n            Successfully Signed in.\n        ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
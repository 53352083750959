<template>
    <div>
        <button type="button" class="close cabso mr-2 mt-2" style="display:block;padding: 0rem;" @click="close()">&times;</button>


        <div class="row mx-0">

            <!-- Left block start -->
            <DisplayUserInfor :instructorDetails="instructorDetails" />

            <!-- Left block end -->
            <!-- Right block start -->
            <div class="col-lg-6 py-3">
             

                <div class="thankmain">
                    <div v-if="ispaid" class="text-success thankdemo">
                        <i class="fad fa-check-circle"></i>
                        <b>
                            <!-- {{showamount()}} -->
                        </b>
                    </div>
                    <div class="text-success thankdemo">
                        <i class="fad fa-check-circle"></i>

                        <b class="mb-1">Free course scheduled succesfully</b>

                         <span>The class starts at {{showddate()}},  {{showtime()}}</span>
                     
                        <p class="caption text-muted">
                            The instructor will be present to offer you a live  class.
                        </p>

                    </div>
                    <div class="alert alert-primary">


                        <p class="caption fw-700 mb-2">
                            The instructors time is valuable just as yours is
                        </p>
         
                        <p class="caption mb-2" >
                            Please be present for the session a few minutes before the start time
                            to ensure your audio and video are functioning well.
                        </p>
                        <p class="caption">Thank you.</p>

                    </div>



                </div>
                <!-- <div class="shadow-sm rounded p-3 mb-1">
                  <p class="body2 text-dark text-center"  v-if="instructorDetails.singuptype == 'demo'">
                    Ask your Friends to join the demo?
                  </p>
                    <p class="body2 text-dark text-center"  v-if="instructorDetails.singuptype == 'regular'">
                    Ask your Friends to join the course?
                  </p>
                  <div class="p-2">
                    <a
                      class="btn btn-lg caption btn-primary btn-block"
                      href=""
                      >Share with friendss</a
                    >
                  </div>
                </div> -->

                <div class="next-button">

                    <div> </div>
                    <a class="btn btn-primary" @click="gotomycourse()">

                        Go to my courses
                    </a>

                </div>


            </div>

            <!-- <SocialShare v-if="showpopup" :callback="hidepopup" :courseId="selectedcourseid" :UserId="userid"></SocialShare> -->

        </div>
        </div>
</template>
<script>
   import DisplayUserInfor from "../CourseSignup/DisplayUserInfor";
   import SulGA from "../../wwwroot/js/Ga.js";
export default {
  name: "ThankYouForPayment",
  props: {
    instructorDetails: {},
    batchdate: "",
    userName: "",
    closePopup: { type: Function },
    ispaid: false,
    paymentdetails_: {},
   sessionstartdisplaydate_:"",
    sessionstartdatetime_:""  
  },
  methods: {
    close() {
      this.closePopup();
    },

    showddate(){
      debugger
      return this.sessionstartdisplaydate_;
    },

      showtime(){
        debugger
      return this.sessionstartdatetime_;
    },

   gotomycourse(){
       if(this.instructorDetails.singuptype == 'regular'){
            window.location.href="/MyClasses"
       } else {
          window.location.href="/MyDemoClasses"
       }
    },
    showamount(){
          var canproceed= this.isEmpty(this.paymentdetails)
      if(this.paymentdetails!={}){
         return  "Payment of Rs."+this.paymentdetails.Order.FinalPrice +" successful"
      }
        if(canproceed==false){
         return "Payment of Rs."+this.paymentdetails.Order.FinalPrice +" successful"
        }

        return "";
     
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(obj) === JSON.stringify({});
    },
  },
  mounted(){
   // alert(6)
    //      SulGA.init();
    // SulGA.pushPageView();
     SulGA.pushEvent(
      "free-booking-success",
      "free",
      "free-thank-you"
    );
  },
   components:{
          "DisplayUserInfor":DisplayUserInfor
        }
};
</script>
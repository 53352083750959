<template>

    <div class="modal show">
        <svg class="d-none">
            <g id="fileUploadIcon">
                <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"></path>
            </g>
        </svg>
        <!-- add .modal-sm/modal-lg/modal-xl after .modal-dialog for width change -->
        <div class="modal-dialog modal-dialog-scrollable">
            <!-- Modal content -->
            <div class="modal-content">
                <div class="modal-header align-items-center">
                    <svg @click="onClose" viewBox="0 0 64 64" class="svg-icon medium d-sm-none"><use xlink:href="#chevronBack"></use></svg>
                    <h5>Do you love teaching English?</h5>
                    <button type="button" class="close" @click="onClose">×</button>
                </div>
                <div class="modal-body">
                    <form v-if="currentStep == 'stepOne'" novalidate action="" class="form-onboard">
                        <p>Check out the star attractions of becoming a tutor on Capshine!</p>
                        <div class="form">
                            <div class="md-field mb-3" :class="{invalid: errorTexts.nameErrorText != ''}">
                                <input type="text" name="fullName" id="fullName" required="" placeholder="Full name" v-model="name" @keyup="onKeyUp($event,'name')">
                                <label alt="name" for="fullName" placeholder="Full name">
                                    Full name <sup>*</sup>
                                </label>
                                <span class="error-text">{{errorTexts.nameErrorText}}</span>
                            </div>

                            <div class="phone-input md-field mb-3" :class="{invalid: errorTexts.mobileErrorText != ''}">
                                <h6 class="country">
                                    + 91
                                    <!--<small><i class="far fa-chevron-down"></i></small>-->
                                </h6>
                                <input type="number" name="mobNum" class="mobile" id="mobNum" required=""
                                       placeholder="Mobile number" v-model="phoneNumber" @keydown="onKeyDown($event,'mobileno')">
                                <label alt="mobNum" for="mobNum"> Mobile number <sup>*</sup></label>
                                <span class="error-text">{{errorTexts.mobileErrorText}}</span>
                                <!-- Add/remove .active to show/hide dropdown -->
                                <div class="dropdown-wrapper">
                                    <div class="search-block">
                                        <input type="text" placeholder="Search for country code">
                                    </div>
                                    <i class="far fa-search"></i>
                                    <ul class="options">
                                        <li>Australia (+61)</li>
                                        <li>Austria (+43)</li>
                                        <li>Albania (+335)</li>
                                        <li>Algeria (+213)</li>
                                        <li>Angola (+224)</li>
                                        <li>Argentina (+994)</li>
                                        <li>Austria (+43)</li>
                                        <li>Albania (+335)</li>
                                        <li>Algeria (+213)</li>
                                        <li>Angola (+224)</li>
                                        <li>Argentina (+994)</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="md-field mb-3" :class="{invalid: errorTexts.emailErrorText != ''}">
                                <input type="email" name="emailId" id="emailId" required="" placeholder="Full name" v-model="email" @keyup="onKeyUp($event,'email')">
                                <label for="emailId" placeholder="Full name">
                                    Email ID<sup>*</sup>
                                </label>
                                <span class="error-text">{{errorTexts.emailErrorText}}</span>
                            </div>
                            <div class="md-field file-upload mb-3" :class="{invalid: errorTexts.fileErrorText != ''}">
                                <input ref="fileInput" type="file" name="fileInput" id="fileInput" required="" @change="readFile">
                                <svg viewBox="0 0 384 512" class="svg-icon medium"><use xlink:href="#fileUploadIcon"></use></svg>
                                <span>Upload CV <sup>*</sup></span>

                            </div>
                            <span class="file-name" :class="{'d-block' : currentFileName != ''}">{{currentFileName}}</span>
                            <span class="error-text pos-top" :class="{'d-block' : errorTexts.fileErrorText != ''}">{{errorTexts.fileErrorText}}</span>
                            <!-- add class open for filename -->


                            <div class="md-field mb-3">
                                <input type="text" name="ytLink" id="ytLink" required=""
                                       placeholder="Youtube video link of your teaching:" v-model="youtubeLink">
                                <label for="ytLink" placeholder="Youtube video link of your teaching:">
                                    Youtube video link of your teaching
                                </label>
                            </div>
                        </div>
                    </form>
                    <div v-if="currentStep == 'stepTwo'" class="alert alert-success" role="alert">
                        Thank you <b>{{this.name}}</b>, you will hear from us in in the next 48 hours
                    </div>

                </div>
                <div v-if="currentStep == 'stepOne'" class="modal-footer">
                    <button class="btn btn-primary btn-block" @click="onSubmit">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import Loader from "../../wwwroot/js/Loader.js";
    export default {
        data() {
            return {
                name: '',
                phoneNumber: '',
                email: '',
                CVUrl: '',
                youtubeLink: '',
                currentFileName: '',
                currentStep: 'stepOne',
                currentFile: '',
                uploadedurl: '',
                errorTexts: {
                    nameErrorText: "",
                    emailErrorText: "",
                    mobileErrorText: "",
                    fileErrorText: "",
                },
                regexp: {
                    alpha: /^[a-z0-9\.\s]+$/i,
                    number: /[^0-9]/g,
                    email: /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i,
                    mobile1: /^[9|7|6|8]\d{8}[\d{1}]$/i,
                },
            }
        },
        beforeMount() {
            document.body.classList.add('overflow-hidden');
        },
        methods: {
            onClose: function () {
                document.body.classList.remove("overflow-hidden");
                let onboardingBaseDiv = document.querySelector("#onboarding-base-div");
                onboardingBaseDiv.innerHTML = '';
                var div = document.createElement("div");
                div.id = "onboarding-div";
                onboardingBaseDiv.appendChild(div);
            },
            onSubmit: function () {

                if (this.name == "") {
                    this.errorTexts.nameErrorText = "Please enter your first and last name !";
                }
                if (!this.isValidName(this.name)) {
                    this.errorTexts.nameErrorText = "Please enter valid first and last name !";
                }
                if (this.email == "") {
                    this.errorTexts.emailErrorText = "Please enter your emailid !";
                }
                if (!this.isValidEMail(this.email)) {
                    this.errorTexts.emailErrorText = "Please enter valid emailid !";
                }
                if (this.phoneNumber == "") {
                    this.errorTexts.mobileErrorText = "Please enter your phone number !";
                }
                if (!this.isValidMobileNumber(this.phoneNumber)) {
                    this.errorTexts.mobileErrorText = "Please enter valid phone number !";
                }
                if (!this.currentFile) {
                    this.errorTexts.fileErrorText = "Please select a file !";
                }

                if (this.isValidForm()) {
                    Loader.showAboveModal();
                    this.uploadFile();
                    
                }


            },
            isValidName(name) {
                return (this.regexp.alpha.test(name) || (name.match(/\./g) != null && name.match(/\./g).length > 3) || (name.replace(/[^a-zA-Z]+/g, "").length <= 2));
            },
            isValidEMail(email) {
                return this.regexp.email.test(email);
            },
            isValidMobileNumber(mobileno) {
                return (this.regexp.mobile1.test(mobileno));
            },
            isValidForm() {
                if (this.errorTexts.nameErrorText == "" &&
                    this.errorTexts.emailErrorText == "" &&
                    this.errorTexts.mobileErrorText == "" &&
                    this.errorTexts.fileErrorText == "") {

                    return true;
                }
            },
            postDetails() {
                let options = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        TutorName: this.name,
                        TutorMobileNo: this.phoneNumber,
                        TutorEmailId: this.email,
                        CVUrl: this.CVUrl,
                        VideoUrl: this.youtubeLink
                    })
                }

                fetch("/api/onboarding/postdetails", options)
                    .then((response) => response.json())
                    .then(data => {
                        console.log("The respones data ", data)
                        if (data == 0) {
                            this.errorTexts.mobileErrorText = "This Mobile number is already associated with an user";

                        } else {
                            this.currentStep = 'stepTwo'
                        }

                        Loader.hideAboveModal();
                    })
            },
            onKeyUp(e, refid) {
                if (refid != '' && refid == 'name') {
                    if (this.name.length > 0)
                        this.errorTexts.nameErrorText = "";
                }
                else if (refid != '' && refid == 'email') {
                    if (this.email.length > 0)
                        this.errorTexts.emailErrorText = "";
                }
            },
            onKeyDown(e, refid) {
                if (refid != '' && refid == 'mobileno') {
                    if (this.phoneNumber.length > 0)
                        this.errorTexts.mobileErrorText = "";
                }
                var key = e.which;
                if (key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
                    return true;
                }

                e.preventDefault();
                return false;
            },
            readFile(event) {
                
                if (event.target.files.length > 0) {
                    
                    this.currentFileName = event.target.files[0].name;
                    let extension = this.currentFileName
                        .substring(this.currentFileName.lastIndexOf(".") + 1)
                        .toLowerCase();
                    console.log(extension);
                    if (extension == 'pdf' || extension == 'doc' || extension == 'docx') {
                        this.currentFile = event.target.files[0];
                        this.errorTexts.fileErrorText = "";
                    } else {
                        this.errorTexts.fileErrorText = "Unsupported file format";
                    }
                    
                } else {
                    this.currentFile = '';
                    this.currentFileName = '';
                }
            },
            uploadFile() {
                // const files = event.target.files

                //var input = document.getElementById("file1");
                //var files = input.files;

                // put validation then form form data to upload
                const formData = new FormData()
                formData.append('myFile', this.currentFile)
                debugger;
                var _url = "/api/fileupload/upload"
                fetch(_url, {
                    method: 'POST',
                    body: formData
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        //alert(data);
                        this.CVUrl = data;
                        this.postDetails();
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }


            //async uploadFileToCDN() {
            //    if (this.currentFile) {
            //        //upload to cdn
            //        const accountName = "lscdn";

            //        var response = await this.GetSastoken()
            //        const sasresponse = await response.json();
            //        var sastoken = sasresponse.sastoken;

            //        const containerName = "coursematerial";

            //        const pipeline = newPipeline(new AnonymousCredential(), {
            //            retryOptions: { maxTries: 4 }, // Retry options
            //            userAgentOptions: { userAgentPrefix: "AdvancedSample V1.0.0" }, // Customized telemetry string
            //            keepAliveOptions: {
            //                // Keep alive is enabled by default, disable keep alive by setting false
            //                enable: false
            //            }
            //        });

            //        const blobServiceClient = new BlobServiceClient(
            //            `https://${accountName}.blob.core.windows.net?${sastoken}`,
            //            pipeline
            //        );
            //        var containerClient = blobServiceClient.getContainerClient(containerName);
            //        if (!containerClient.exists()) {
            //            console.log("the container does not exit");
            //            await containerClient.create();
            //        }

            //        var filename = "instructorCV/" + this.phoneNumber + this.currentFileName;
            //        const client = containerClient.getBlockBlobClient(filename);
            //        const clientResponse = await client.uploadBrowserData(this.currentFile, {
            //            blockSize: 4 * 1024 * 1024, // 4MB block size
            //            concurrency: 20, // 20 concurrency
            //            onProgress: ev => console.log(ev),
            //            blobHTTPHeaders: { blobContentType: this.currentFile.type }
            //        });
            //        console.log(clientResponse._response.status);

            //        var url = "";
            //        if (clientResponse._response.status == 201) {
            //            var extracturl = clientResponse._response.request.url.split("?");
            //            if (extracturl.length > 0) {
            //                url = extracturl[0].replace(new RegExp("%2F", "g"), "/");
            //                this.CVUrl = url;
            //                console.log("the uploaded url is ", this.CVUrl);
            //            }

            //            this.postDetails(); // post form details to DB
            //        } else {

            //            this.$refs.fileInput.value = '';
            //            this.currentFile = '';
            //            this.currentFileName = '';
            //            this.errorTexts.fileErrorText = "Cannot upload file, please try again !";
            //            Loader.hideAboveModal();
            //        }
            //    }
            //},
            //readFile(event) {
            //    console.log(event.target.files);
            //    console.log(event.target.files[0].name)
            //    if (event.target.files.length > 0) {
            //        this.currentFileName = event.target.files[0].name;
            //        this.currentFile = event.target.files[0];
            //        this.errorTexts.fileErrorText = "";
            //    } else {
            //        this.currentFile = '';
            //        this.currentFileName = '';
            //    }
            //},
            //async GetSastoken() {
            //    var getsasurl =
            //        "/api/course/GetSasToken";

            //    return fetch(getsasurl, { method: "GET" })
            //},
        },


    }

</script>
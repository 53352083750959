﻿import Loader from "../../Loader.js";

let CapshineAdmin = {

    init: function () {
        if (CapshineAdmin.checkIfUserLoggedIn()) {
            CapshineAdmin.checkIfAdminUser();
        } else {
            document.getElementById("signInMobile").click();
        }
    },
    async checkIfAdminUser() {
        let userId = document.getElementById("hdnUserPid").value;
        let url = `/api/admin/CheckSuperUserAcess?userid=${userId}`;
        await fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.issuperuser) {
                    window.CapshineAdmin = CapshineAdmin;
                    CapshineAdmin.addEventListeners();
                    CapshineAdmin.bindAutoComplete();
                    CapshineAdmin.getCapshineStudentDetails();
                } else {
                    alert("Please login with admin credentials");
                }
            });
    },
    getCapshineStudentDetails: function () {
        if (document.querySelector("[data-id='student-details']")) {
            let isShowTest = "N";
            if (document.querySelector("[data-id='include-test-student'")?.checked) {
                isShowTest = "Y";
            }
            let userPid = document.getElementById("hdnUserPid")?.value;
            Loader.show();
            let url = `/api/admin/get-student-details?isShowTest=${isShowTest}&&userPid=${userPid}`;
            fetch(url)
                .then(response => {
                    response.text().then(text => {
                        document.querySelector("[data-id='student-details']").innerHTML = text;
                    })
                })
                .then(data => {
                    Loader.hide();
                })

        }

    },
    addEventListeners: function () {
        let includeTestCheckBox = document.querySelector("[data-id='include-test-student']");
        if (includeTestCheckBox) {
            includeTestCheckBox.addEventListener('change', () => {
                CapshineAdmin.getCapshineStudentDetails();
            })
        }

        let checkBoxes = document.querySelectorAll("#statusFilter .check-box");
        if (checkBoxes) {
            checkBoxes.forEach((checkBox) => {
                checkBox.addEventListener('click', () => CapshineAdmin.iniStatusFilter());
            })
        }

        let searchButton = document.querySelector("[data-id='admin-search-btn']");
        if (searchButton) {
            searchButton.addEventListener('click', () => {
                CapshineAdmin.getCourseDetails();
            })
        }

        let capshineTab = document.querySelector("[data-id='capshine-leads-tab']")
        if (capshineTab) {
            capshineTab.addEventListener('click', (event) => {
                if (!event.currentTarget.classList.contains("active")) {
                    document.querySelector("[data-id='capshine-leads-tab']")?.classList.add("active");
                    document.querySelector("[data-id='sulekha-leads-tab']").classList.remove("active");
                    CapshineAdmin.getCapshineStudentDetails();
                }
                document.querySelector("[data-id='admin-filters']")?.classList.remove("d-none");

            })
        }

        let sulekhaTab = document.querySelector("[data-id='sulekha-leads-tab']")
        if (sulekhaTab) {
            sulekhaTab.addEventListener('click', (event) => {
                if (!event.currentTarget.classList.contains("active")) {
                    document.querySelector("[data-id='capshine-leads-tab']")?.classList.remove("active");
                    document.querySelector("[data-id='sulekha-leads-tab']").classList.add("active");
                    CapshineAdmin.getSulekhaLeads();
                }
                document.querySelector("[data-id='admin-filters']")?.classList.add("d-none");
            })
        }

        

       

    },
    iniStatusFilter: function () {
        if (document.querySelector('#changed-course').checked && document.querySelector('#new-course').checked) {
            document.querySelectorAll('.coursecards').forEach((courseCard) => {
                courseCard.classList.remove('d-none');
            })
            return;
        }

        if (!document.querySelector('#changed-course').checked && !document.querySelector('#new-course').checked) {
            document.querySelectorAll('.coursecards').forEach((courseCard) => {
                courseCard.classList.remove('d-none');
            })
            return;
        }

        if (document.querySelector('#new-course').checked) {
            document.querySelectorAll('.coursecards.Changed').forEach((courseCard) => {
                courseCard.classList.add('d-none');
            })
        }

        if (!document.querySelector('#new-course').checked) {
            document.querySelectorAll('.coursecards.Changed').forEach((courseCard) => {
                courseCard.classList.remove('d-none');
            })
        }

        if (document.querySelector('#changed-course').checked) {
            document.querySelectorAll('.coursecards.New').forEach((courseCard) => {
                courseCard.classList.add('d-none');
            })
        }


        if (!document.querySelector('#changed-course').checked) {
            document.querySelectorAll('.coursecards.New').forEach((courseCard) => {
                courseCard.classList.remove('d-none');
            })
        }


    },
    getCourseDetails: function () {
        let searchVal = document.getElementById("searchCourse").value.trim();
        let reasonElement = document.getElementById("reason-change");
        let reason = reasonElement?.value.trim();

        if (searchVal) {
            if ((!reasonElement) || reason) {
                $("#reason-change-input .error-text").hide();
                $("#search-text .error-text").hide();
                let urlSearchParams = new URLSearchParams(window.location.search);
                let params = Object.fromEntries(urlSearchParams.entries());
                let courseName = document.querySelector("[data-id='admin-course-search']").value;

                if (courseName) {
                    let url = `/api/admin/student-searchBind?courseId=${params.courseId}&studentId=${params.studentId}&courseName=${courseName.trim()}`;
                    Loader.show();
                    let _this = this;
                    fetch(url)
                        .then(response => response.json())
                        .then(data => {

                            var tabelRow = [];
                            if (data != null && data != undefined && data.length > 0) {
                                $("#session-data #error-data").hide();
                                for (var i = 0; i < data.length; i++) {
                                    var row = "<tr id='" + data[i].courseid + "'>" + "<td class='courseTitle'>" + data[i].courseName + "</td>" + "<td class='tutor-Name'>" + data[i].tutorName + "</td>" + "<td class='tutor-Number'>" + data[i].tutorMobileNo + "</td>" +
                                        "<td> Rs. " + data[i].coursefees + "</td>" + "<td class='demoDate'>" + CapshineAdmin.getDemoDateInput(data[i].courseid) + "</td>" + "<td class='demoTime'>" + CapshineAdmin.getTimeInput() + "</td>" +
                                        "<td class='demoButton'><button class='button primary' data-imageUrl='" + data[i].courseImageURL + "' data-businessId='" + data[i].businessId + "' data-mailId='" + data[i].tutorEmailId + "'onclick='CapshineAdmin.onClickEnroll(" + data[i].courseid + ")' >Enroll for Demo</button></td>"
                                    tabelRow.push(row);
                                }
                                $("#session-data #t-row").html(tabelRow);
                                $("#session-data #detail-table").show();
                                $(".datepicker").datepicker({
                                    format: "dd/mm/yyyy", todayHighlight: true, container: "body", defaultViewDate: 'today', startDate: 'today'
                                });
                                $(".demoTime").sDropdown();
                                Loader.hide();

                            } else {

                                $("#session-data #detail-table").hide();
                                $("#session-data #error-data").show();
                                Loader.hide();
                            }
                        });
                }
            } else {
                $("#search-text .error-text").hide();
                $("#reason-change-input .error-text").show();
                document.getElementById("reason-change").focus();
            }
        } else {
            $("#search-text .error-text").show();
        }
    },
    onClickEnroll: function (courseId) {
        let urlSearchParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(urlSearchParams.entries());


        if (Object.keys(params).includes("isSulekhaLeads")) {
            CapshineAdmin.enrollDemoForSulekhaLeads(courseId);

        } else {
            CapshineAdmin.enroll(courseId);
        }

    },
    getSulekhaLeadsAssignUrl: function (rowid) {
        let studentId = document.getElementById(`${rowid}hdnSlStudentId`).value;
        let studentName = document.getElementById(`${rowid}hdnSlStudentName`).value;
        let rowId = document.getElementById(`${rowid}hdnSlRowId`).value;
        let mobileNo = document.getElementById(`${rowid}hdnSlMobileNo`).value;
        let emailId = document.getElementById(`${rowid}hdnSlEmailId`).value;
        let href = `/admin/reassigncourse?courseId=0&batchId=0&studentId=${studentId}&rId=${rowId}&sName=${studentName}&sNumber=${mobileNo}&sMail=${emailId}&bId=0&isSulekhaLeads=true`;
        return href;
        
    },
    enroll: function (courseId) {
        //alert("capshine function");
        //return;
        var reason = document.getElementById("reason-change")?.value.trim();
        var courseSId = "#" + courseId;
        var demoDate = $(courseSId + " .demoDate .datepicker").val();
        var tutorName = $(courseSId + " .tutor-Name").text();
        var tutorNumber = $(courseSId + " .tutor-Number").text();
        var demoTime = $(courseSId + " .demoTime li.selected").text();
        var courseTitle = $(courseSId + " .courseTitle").text();
        var courseimageUrl = $(courseSId + " .demoButton .button").attr('data-imageUrl');
        var tutorMailId = $(courseSId + " .demoButton .button").attr('data-mailId');
        var businessId = $(courseSId + " .demoButton .button").attr('data-businessId');
        let urlSearchParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(urlSearchParams.entries());
        if (reason !== "" && reason !== null && reason !== undefined) {
            $("#reason-change-input .error-text").hide();
            if (demoDate !== "" && demoDate !== null && demoDate !== undefined) {
                $(courseSId + " .demoDate .error-text").hide();
                if (demoTime !== "" && demoTime !== null && demoTime !== undefined) {
                    if (CapshineAdmin.validateDateTime(demoDate, demoTime)) {
                        $(courseSId + " .demoTime .error-text").hide();
                        let url = `/api/admin/swap-studentCourse?toCourseId=${courseId}&demoDate=${demoDate}&demoTime=${demoTime}&reason=${reason}&businessId=${businessId}&courseId=${params.courseId}&batchId=${params.batchId}&studentId=${params.studentId}&rId=${params.rId}`;

                        Loader.show();
                        fetch(url)
                            .then(response => response.json())
                            .then(data => {
                                Loader.hide();
                                if (data === 1) {
                                    alert("New Course Enrolled " + courseTitle);
                                    CapshineAdmin.sendNotification(courseTitle, demoDate, demoTime, courseimageUrl, courseId, tutorMailId, tutorName, tutorNumber);
                                } if (data === 0) {
                                    alert("Error please try again after sometime");
                                } if (data === -10) {
                                    alert("Already session available");
                                }
                            })

                    } else {
                        alert("Schedule session more than 65 min");
                    }
                } else {
                    $(courseSId + " .demoTime .error-text").html("Select a Time");
                    $(courseSId + " .demoTime .error-text").show();
                }
            } else {
                $(courseSId + " .demoDate .error-text").show();
            }
        } else {
            document.getElementById("reason-change").focus();
            $("#reason-change-input .error-text").show();
        }
    },
    enrollDemoForSulekhaLeads: function (courseId) {


        let urlSearchParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(urlSearchParams.entries());
        let userName = params.sName;
        let mobileNo = params.sNumber;
        let email = params.sMail;
        let userPid = params.studentId;
        var courseSId = "#" + courseId;
        let demoDate = $(courseSId + " .demoDate .datepicker").val();
        let demoTime = $(courseSId + " .demoTime li.selected").text();
        let tutorName = $(courseSId + " .tutor-Name").text();
        let tutorNumber = $(courseSId + " .tutor-Number").text();
        let courseTitle = $(courseSId + " .courseTitle").text();
        let courseimageUrl = $(courseSId + " .demoButton .button").attr('data-imageUrl');
        let tutorMailId = $(courseSId + " .demoButton .button").attr('data-mailId');


        if (demoDate) {
            $(courseSId + " .demoDate .error-text").hide();
            if (demoTime) {
                if (CapshineAdmin.validateDateTime(demoDate, demoTime)) {
                    $(courseSId + " .demoTime .error-text").hide();
                    let url = "/api/admin/CreateDemoForSulekhaLeads";
                    let requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            UserName: userName,
                            MobileNo: mobileNo,
                            EmailId: email,
                            CourseId: courseId,
                            UserPid: userPid,
                            Date: demoDate,
                            Time: demoTime
                        })
                    }
                    Loader.show();
                    fetch(url, requestOptions)
                        .then(response => response.json())
                        .then(data => {

                            if (data >= 0) {
                                CapshineAdmin.sendNotification(courseTitle, demoDate, demoTime, courseimageUrl, courseId, tutorMailId, tutorName, tutorNumber);
                                alert("New Course Enrolled " + courseTitle);
                            } else {
                                alert("Already session available");
                            }
                            Loader.hide();
                        });
                } else {
                    alert("Schedule session more than 65 min");
                }
            } else {
                $(courseSId + " .demoTime .error-text").html("Select a Time");
                $(courseSId + " .demoTime .error-text").show();
            }
        } else {
            $(courseSId + " .demoDate .error-text").show();
        }

    },
    bindAutoComplete: function () {
        $(".course-tutor-search").sAutoComplete({
            minChars: 3,
            appendContainer: $('.search-auto'),
            noResults: 'No Classes or Tutor found',
            source: function (request, response) {
                var url = "/api/admin/search-courses?searchString=" + request;
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        if (data !== null) {
                            var articleNamesList = [];
                            for (var i = 0; i < data.length; i++) {
                                articleNamesList.push(data[i]);
                            }
                            response(JSON.parse(JSON.stringify(articleNamesList)));
                        }
                    });
            },
        });
    },
    validateDateTime: function (date, Time) {
        var inputDateTime = new Date(date.split('/')[1] + "/" + date.split('/')[0] + "/" + date.split('/')[2] + " " + Time);
        var presentDateTime = new Date();
        var allowedHour = presentDateTime.getHours() + 1;
        var allowedMinute = presentDateTime.getMinutes() + 5;
        if (inputDateTime <= presentDateTime || (inputDateTime.getFullYear() === presentDateTime.getFullYear() &&
            inputDateTime.getMonth() === presentDateTime.getMonth() && inputDateTime.getDay() === presentDateTime.getDay())) {
            if (inputDateTime.getHours() < allowedHour) {
                return false;
            }
            if (inputDateTime.getHours() === allowedHour) {
                if (inputDateTime.getMinutes() < allowedMinute) {
                    return false;
                }
            }
        }
        return true;
    },
    getDemoDateInput: function (courseId) {
        return '<div class="outline-input input-group form-group admininput"> <input type="text" placeholder="Select Date" data-provide="datepicker" data-date-end-date=' + "+60d" + ' data-date-start-date="0d" class="datepicker" id=' + courseId + ' onchange="CapshineAdmin.onDateChange(event,' + courseId + ')" /><span class="error-text">select a date</span></div > ';
    },
    getTimeInput: function () {
        return '<div class="sdropdown border-label-out form-group  dropdownQuestion" data-type="dropdownSingleSelect" data-mandatory="1" data-sdropdown="true"><a class="input sd-link" tabindex="0">Select Time</a><div class="sd-menu"><ul id="trm-details" data-id="available-timeslots"></ul> </div> <span class="error-text">Select a Time</span></div>';
    },
    availableTime: function (courseId, availableTimeSlots) {
        let html = "";
        availableTimeSlots.forEach((timeSlot) => {
            html += `<li><a>${timeSlot}</a></li>`;
        })
        document.getElementById(courseId).querySelector("[data-id='available-timeslots']").innerHTML = html;
        /*document.querySelector(`#${courseId}[data-id='available-timeslots']`).innerHTML = html;*/
    },
    checkIfUserLoggedIn() {
        let isAuthenticated = false;
        if (document.getElementById("hdnUserPid")) {
            if (document.getElementById("hdnUserPid").value && document.getElementById("hdnUserPid").value != "0") {
                isAuthenticated = true;
            }
        }
        return isAuthenticated;
    },

    sendNotification: function (courseTitle, demoDate, demoTime, courseImageUrl, toCourseId, tutorMailId, tutorName, tutorNumber) {
        let urlSearchParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(urlSearchParams.entries());
        let url = `/api/admin/CourseUpdateNotification?courseTitle=${courseTitle}&&courseImageUrl=${courseImageUrl}&&demoDate=${demoDate}&&demoTime=${demoTime}&&toCourseId=${toCourseId}&&tutorMailId=${tutorMailId}&&tutorName=${tutorName}&&tutorNumber=${tutorNumber}&&sName=${params.sName}&&sNumber=${params.sNumber}&&sMail=${params.sMail}`;
        fetch(url)
            .then(response => console.log("response ", response))
            .then(data => console.log("notification sent ", data));
    },
    getSulekhaLeads: async function () {

        let url = `/api/admin/GetSulekhaLeads`;
        Loader.show();
        let response = await fetch(url);
        let data = await response.text();
        document.querySelector("[data-id='student-details']").innerHTML = data;
        CapshineAdmin.addEventForDemoBtn();
        Loader.hide();
    },
    onDateChange: function (event, courseId) {
        let date = event.target.value;
        let url = `/api/admin/GetTimeSlotsForDate?courseId=${courseId}&&date=${date}`;
        let courseSId = '#' + courseId;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                CapshineAdmin.availableTime(courseId, data);
                if (data.length > 0) {
                    //CapshineAdmin.availableTime(courseId, data);
                    $(courseSId + " .demoTime .error-text").hide();
                }
                else {
                    $(courseSId + " .demoTime .error-text").html("No Slots Available");
                    $(courseSId + " .demoTime .error-text").show();
                }
            });
    },
    isValidEmail: function (email) {
        let regExp = /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i;
        return regExp.test(email);
    },
    addEventForDemoBtn: function () {
        let scheduleDemoBtn = document.querySelectorAll("[data-id='schedule-a-demo-btn']");
        console.log("the demo btns", scheduleDemoBtn);
        if (scheduleDemoBtn) {
            scheduleDemoBtn.forEach((btn) => {
                btn.addEventListener('click', (event) => {
                    event.preventDefault();
                    let parent = event.currentTarget.parentElement.parentElement;
                    console.log("event parent", event.currentTarget.parentNode);
                    let rowId = event.currentTarget.getAttribute("row-id");
                    console.log("the row id ", rowId);
                    let email = parent.querySelector("[data-id='hdnSlEmailId']")?.value
                    console.log("the email value ", email);
                    if (email && CapshineAdmin.isValidEmail(email)) {
                        window.open(CapshineAdmin.getSulekhaLeadsAssignUrl(rowId));
                        
                        console.log("The email from if  block ", email);
                    } else {
                        event.preventDefault();
                        let errorSpan = parent.querySelector("[data-id='sl-email-error']");
                        $(errorSpan).show();
                    }

                })
            })

        }

        let emailError = document.querySelectorAll("[data-id='sl-email']");
        emailError.forEach((inputDiv) => {
            inputDiv.addEventListener('input', (event) => {
                let parent = event.currentTarget.parentElement.parentElement.parentElement.parentElement;
                
                let hdnEmail = parent.querySelector("[data-id='hdnSlEmailId']");
                console.log("the hdnEmail", hdnEmail);
                hdnEmail.value = event.currentTarget.value;
                let errorSpan = parent.querySelector("[data-id='sl-email-error']");
                $(errorSpan).hide();
            })
        })
    }    
}

let CapshineClubAdmin = {
    UserPid: '0',
    initRegistration: function () {
        if (document.getElementById("hdnUserPid")) {
            if (document.getElementById("hdnUserPid").value != '' && document.getElementById("hdnUserPid").value != null) {
                this.UserPid = document.getElementById("hdnUserPid").value;
            }
        }
        this.getClubRegisDetails();
    },
    initAssign: function () {
        if (document.getElementById("hdnUserPid")) {
            if (document.getElementById("hdnUserPid").value != '' && document.getElementById("hdnUserPid").value != null) {
                this.UserPid = document.getElementById("hdnUserPid").value;
            }
        }
        if (document.querySelector("[data-clubAssignsearchBtn]")) {
            document.querySelector("[data-clubAssignsearchBtn]").addEventListener('click', (e) => {
                this.getClubAssignDetails();
            });
        }
        if (document.querySelector("[data-clubSubCommentBtn]")) {
            document.querySelector("[data-clubSubCommentBtn]").addEventListener('click', (e) => {
                this.clubAssignSubmitComment();
            });
        }
    },
    addClickEventAssignClub: function () {
        let assignButton = document.querySelectorAll("#club-registration-data .assignClubButton");
        assignButton.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                var name = e.target.getAttribute('data-name');
                var mobileNumber = e.target.getAttribute('data-mobileNumber');
                var email = e.target.getAttribute('data-email');
                var userPid = e.target.getAttribute('data-userPid');
                var languagePreferred = e.target.getAttribute('data-languagePreferred');
                var proficiencylevel = e.target.getAttribute('data-proficiencylevel');
                var city = e.target.getAttribute('data-city');
                var clubID = e.target.getAttribute('data-clubID');
                let url = `/admin/clubassign?name=${name}&mobileNumber=${mobileNumber}&email=${email}&userPid=${userPid}&languagePreferred=${languagePreferred}&proficiencylevel=${proficiencylevel}&city=${city}&clubID=${clubID}`;
                window.location.href = url;
            })
        });
    },
    addClickEventMapClub: function () {
        let assignButton = document.querySelectorAll("#club-assign-data .mapClubButton");
        assignButton.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                if (confirm('Are you sure you want to map this user to club?')) {
                    var clubID = e.target.getAttribute('data-clubID');
                    var userPid = e.target.getAttribute('data-userPid');
                    this.mapClubApiCall(clubID, userPid);
                } else {
                }
            })
        });
    },
    mapClubApiCall: function (clubID, userPid) {
        let url = `/api/admin/map-club-users?clubID=${clubID}&userPid=${userPid}`;
        Loader.show();
        let _this = this;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                Loader.hide();
                if (data == 1) {
                    alert("User Successfully Mapped to Club...");
                    let url = `/admin/clubhome`;
                    window.location.href = url;
                }
                else {
                    alert("Mapped unsuccessfully Please contact your admin...");
                    let url = `/admin/clubhome`;
                    window.location.href = url;
                }
            });
    },
    clubAssignSubmitComment: function () {
        var userPid = document.getElementById("admin-club-student-userPid").value;
        var comments = document.getElementById("commentInput").value;
        if (comments == '' || comments == null) {
            document.querySelector(".commentInputDiv .error-text").style.display = "block";
            return;
        }
        else {
            document.querySelector(".commentInputDiv .error-text").style.display = "none";
        }
        let url = `/api/admin/map-club-users?clubID=${0}&userPid=${userPid}&comments=${comments}`;
        Loader.show();
        let _this = this;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                Loader.hide();
                if (data == 1) {
                    alert("Comment Successfully Updated...");
                    let url = `/admin/clubhome`;
                    window.location.href = url;
                }
                else {
                    alert("Comment unsuccessfully Updated Please contact your admin...");
                    let url = `/admin/clubhome`;
                    window.location.href = url;
                }
            });
    },
    getClubRegisDetails: function () {
        let url = `/api/admin/club-registration-details?userPid=${this.UserPid}`;
        Loader.show();
        let _this = this;
        fetch(url)
            .then(response => response.json())
            .then(data => {

                var tabelRow = [];
                if (data != null && data != undefined && data.length > 0) {
                    $("#club-registration-data #error-data").hide();
                    for (var i = 0; i < data.length; i++) {
                        var row = "<tr id='" + data[i].userPid + "'>" + "<td class='Name'>" + data[i].name + "</td>" + "<td class='Emailid'>" + data[i].emailid + "</td>" + "<td class='Mobilenumber'>" + data[i].mobilenumber + "</td>" + "<td class='Proficiencylevel'>" + data[i].proficiencylevel + "</td>" + "<td class='City'>" + data[i].city + "</td>" + "<td class='LanguagePreferred'>" + data[i].languagePreferred + "<td class='Comments'>" + data[i].comments + "</td>" +
                            "<td class='assignClubButton'><button class='button primary' data-name='" + data[i].name + "' data-mobileNumber='" + data[i].mobilenumber + "' data-email='" + data[i].emailid + "' data-userPid='" + data[i].userPid + "'data-languagePreferred='" + data[i].languagePreferred + "'data-proficiencylevel='" + data[i].proficiencylevel + "'data-city='" + data[i].city + "'data-clubID='" + data[i].clubID + "' >Assign</button></td></tr>";
                        tabelRow.push(row);
                    }
                    $("#club-registration-data #t-row").html(tabelRow);
                    $("#club-registration-data #detail-table").show();
                    this.addClickEventAssignClub();
                    Loader.hide();
                } else {
                    $("#club-registration-data #detail-table").hide();
                    $("#club-registration-data #error-data").show();
                    Loader.hide();
                }
            });
    },
    getClubAssignDetails: function () {
        let level = document.getElementById("levelDropdown").value;
        let url = `/api/admin/club-assign-details?level=${level}`;
        Loader.show();
        let _this = this;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                var tabelRow = [];
                if (data != null && data != undefined && data.length > 0) {
                    $("#club-assign-data #error-data").hide();
                    for (var i = 0; i < data.length; i++) {
                        var row = "<tr id='" + data[i].clubID + "'>" + "<td class='Level'>" + data[i].level + "</td>" + "<td class='ClubName'>" + data[i].clubName + "</td>" + "<td class='Timing'>" + data[i].scheduleDay + "</td>" + "<td class='MemberCount'>" + data[i].membercount + "</td>" + "<td class='Availableslots'>" + data[i].availableSlots + "</td>" + "<td class='Owner'>" + data[i].owner + "</td>" + "<td class='Coach'>" + data[i].coach + "</td>" +
                            "<td class='mapClubButton'><button class='button primary' data-clubID='" + data[i].clubID + "' data-userPid='" + document.getElementById("admin-club-student-userPid").value + "' >Assign</button></td></tr>";
                        tabelRow.push(row);
                    }
                    $("#club-assign-data #t-row").html(tabelRow);
                    $("#club-assign-data #detail-table").show();
                    this.addClickEventMapClub();
                    Loader.hide();
                } else {
                    $("#club-assign-data #detail-table").hide();
                    $("#club-assign-data #error-data").show();
                    Loader.hide();
                }
            });
    },
}
window.addEventListener('load', () => {
    var pageType = document.getElementById('hdnPageType').value;
    if (pageType == "AdminHome" || pageType == "AdminReAssignCourse") {
        CapshineAdmin.init();
    }
    else if (pageType == "AdminClubHome") {
        CapshineClubAdmin.initRegistration();
    }
    else if (pageType == "AdminClubAssign") {
        CapshineClubAdmin.initAssign();
    }

})



<template>
    <section class="">
        <!-- Modal start -->
        <!-- remove .show to hide modal -->
        <div id="LCFTwo" :class="this.showQuesPopup ? 'modal show' : 'modal'">
            <!-- add .modal-sm/modal-lg/modal-xl after .modal-dialog for width change -->
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <!-- Modal content -->
                <div class="modal-content">
                    <!-- Header mobile Start -->
                    <div class="modal-header align-items-center">
                        <svg v-on:click="CloseLCF" class="svg-icon medium d-sm-none" viewBox="0 0 64 64">
                            <use xlink:href="#chevronBack" />
                        </svg>
                        <h5><span class="caption mb-2">Find Classes</span> <br /> {{this.C1Name}}</h5>
                        <button type="button" class="close" v-on:click="CloseLCF">&times;</button>
                    </div>
                    <!--<div class="bg-header-wizard">
                        <div class="container position-relative">
                            <div class="header-between">
                                <div class="d-flex flex-column ml-1">
                                    <p>Find Classes</p>
                                    <b class="lang-title">{{this.C1Name}}</b>
                                </div>
                                <a class="mr-2" href="#"><i class="far fa-times" v-on:click="CloseLCF"></i></a>
                            </div>
                        </div>
                    </div>-->

                    <!-- header mobile end -->
                    <!-- progress bar -->
                    <!--<div class="progress mt-0">
        <div class="progress-bar"
             role="progressbar"
             style="width: 50%"
             aria-valuenow="50"
             aria-valuemin="0"
             aria-valuemax="100"></div>
    </div>-->
                    <!-- progress bar end -->
                    <div class="modal-body">
                        <!-- Content goes here -->
                        <div>
                            <!--<div class="mt-4 body-lg text-dark d-none d-lg-block">
                <i class="fas fa-binoculars pr-2"></i>
                <span>Find classes |</span>
                <span>{{this.C1Name}}</span>
            </div>-->
                            <h4 :id="'ques'+CurrentQuestion[0].questionId" class="mb-0 mt-2">{{CurrentQuestion[0].question}}</h4>

                            <!-- remove select-all-applicable to hide the text -->
                            <div class="select-all-applicable pb-4">
                                <span class="body2 d-none">Select one or more applicable</span>
                            </div>
                        </div>
                        <ul v-if="CurrentQuestion[0].isMultiSelect" class="list-group">
                            <li v-for="answer in CurrentQuestion[0].answers">
                                <!--<input v-on:click="onSelect(answer)" type="checkbox" :id="'ans'+answer.answerId">
                <label :for="'ans'+answer.answerId">{{answer.answer}}</label>-->
                                <div class="material-checkbox">
                                    <input type="checkbox" v-on:click="onSelect(answer)" :id="'ans'+answer.answerId">
                                    <label :for="'ans'+answer.answerId">{{answer.answer}}</label>
                                </div>
                            </li>
                        </ul>
                        <ul v-else-if="!CurrentQuestion[0].isMultiSelect" class="list-group">
                            <li v-for="answer in CurrentQuestion[0].answers">
                                <!--<input v-on:click="onSelect(answer)" type="radio" :id="'ans'+answer.answerId" name="radiobox">
                <label :for="'ans'+answer.answerId">{{answer.answer}}</label>-->
                                <div class="material-radio">
                                    <input v-on:click="onSelect(answer)" type="radio" :id="'ans'+answer.answerId" name="radiobox">
                                    <label :for="'ans'+answer.answerId">{{answer.answer}}</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div>{{CookieSelected()}}</div>
                    <!-- add active to show orange color -->
                    <!--<div class="skip-btn-container">S
        <span class="skip-btn sub-title fw-400">Back</span>
        <span id="NextButton2" class="btn next-btn sub-title" :disabled="isLCF2ButtonDisabled" v-on:click="onClickSubmit">Next</span>
    </div>-->
                    <div class="modal-footer justify-content-between">
                        <a id="BackButton" class="skip-btn sub-title fw-400 d-none" v-on:click="onBackClick">Back</a>
                        <button type="button" id="NextButton2" class="btn btn-primary" v-on:click="onClickSubmit" disabled>Next</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal end -->
        <!-- question secction ends -->
    </section>
</template>


<script>
    export default {
        name: "LCFSecondLevel",       
        props: {
            CurrentQuestion: Array,
            showQuesPopup: { type: Boolean }, 
            onLCFAnsSelect: { type: Function },
            AnswerSubmit: { type: Function },
            SetSelectedDefault: { type: Function },
            PageType: { type: String },
            C1Name: { type: String },
            onLCFBackClick: { type: Function },
            CloseLCFPopup: { type: Function },
        },               
        methods: {            
            onSelect: function (answer) {
                this.onLCFAnsSelect(this.CurrentQuestion[0].isMultiSelect,answer);
            },
            onClickSubmit: function () {
                this.AnswerSubmit(this.CurrentQuestion[0].isMultiSelect);
            },   
            CookieSelected: function () {                
                setTimeout(() => {
                    this.SetSelectedDefault();
                }, 0);
            }, 
            CloseLCF: function () {
                this.CloseLCFPopup();
            },
            onBackClick: function () {
                this.onLCFBackClick();
            }
        }
    }

</script>
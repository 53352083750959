var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filterCardShowMore" },
    _vm._l(_vm.courseCards, function(Card) {
      return _c(
        "div",
        {
          staticClass: "col-sm-6 col-lg-6 col-12",
          on: {
            click: function($event) {
              return _vm.locationHref(Card.CourseURL)
            }
          }
        },
        [
          _c(
            "div",
            {
              class:
                "course-card  pr-0  " +
                Card.CourseID +
                "  " +
                _vm.dataLanguage(Card.Languages).toLowerCase() +
                "  " +
                _vm.dataRating(Card.TutorAverageRating) +
                "  " +
                Card.C2Id +
                "_" +
                Card.C3Id +
                "  " +
                _vm.dataRange(Card).toLowerCase(),
              attrs: {
                "data-GroupByLable": Card.C2Id + "_" + Card.C3Id,
                "data-courseid": Card.C2Id + "_" + Card.C3Id,
                "data-ratings": _vm.dataRating(Card.TutorAverageRating),
                "data-languageFtr": _vm.dataLanguage(Card.Languages),
                "data-range": _vm.dataRange(Card),
                "data-courseIdForReviews": Card.CourseID,
                "data-CourseTitle": Card.CourseTitle,
                "data-Thumbnail": Card.PhotoThumbnailURL,
                "course-card": ""
              },
              on: {
                click: function($event) {
                  return _vm.locationHref(Card.CourseURL)
                }
              }
            },
            [
              Card.PromotionalVideoURL
                ? _c("div", { staticClass: "overview-course-video" }, [
                    Card.PromotionalVideoURL.includes("www.youtube.com")
                      ? _c("iframe", {
                          attrs: {
                            title: Card.CourseTitle,
                            src: Card.PromotionalVideoURL,
                            srcdoc: _vm.renderSrcDoc(
                              Card.PromotionalVideoURL,
                              Card.CourseTitle
                            )
                          }
                        })
                      : _c(
                          "video",
                          {
                            attrs: { playsinline: "", controls: "", muted: "" },
                            domProps: { muted: true }
                          },
                          [
                            _c("source", {
                              attrs: {
                                src: Card.PromotionalVideoURL,
                                type: "video/mp4"
                              }
                            })
                          ]
                        )
                  ])
                : Card.PromotionalVideoThumbnail
                ? _c("div", { staticClass: "img-wrapper d-flex" }, [
                    _c("img", {
                      staticClass: "lazyload img-fluid bg-img w-100 h-100",
                      attrs: {
                        src: Card.PromotionalVideoThumbnail,
                        alt: Card.CourseTitle,
                        loading: "lazy"
                      }
                    })
                  ])
                : Card.PhotoThumbnailURL
                ? _c("div", { staticClass: "img-wrapper d-flex" }, [
                    _c("img", {
                      staticClass: "lazyload img-fluid bg-img w-100 h-100",
                      attrs: {
                        src: Card.PhotoThumbnailURL,
                        alt: Card.CourseTitle,
                        loading: "lazy"
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "p-3" }, [
                _c("div", { staticClass: "d-flex flex-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between w-100" },
                    [
                      _c("h4", { staticClass: "course-title" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: Card.CourseURL,
                              title: Card.CourseTitle
                            }
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(Card.CourseTitle) +
                                "\n                            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: _vm.bookmarkClass(Card.Shortlist),
                          attrs: {
                            "data-shortlist": Card.CourseID,
                            "data-hasnoclickevent": "true"
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "svg-icon medium c-pointer",
                              attrs: { viewBox: "0 0 384 512" }
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#bookmarkIcon" }
                              }),
                              _vm._v(" "),
                              _c("g", { attrs: { id: "bookmarkIcon" } }, [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"
                                  }
                                })
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    Card.TutorAverageRating > 0
                      ? _c("div", { staticClass: "reviews" }, [
                          _c("div", { staticClass: "ratings medium" }, [
                            _c("span", {
                              class: _vm.renderRatingStars(
                                Card.TutorAverageRating
                              )
                            })
                          ]),
                          _vm._v(" "),
                          Card.TutorAverageRating > 0 &&
                          Card.TutorReviewCount > 0
                            ? _c("span", { staticClass: "caption" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(Card.TutorAverageRating) +
                                    "(" +
                                    _vm._s(Card.TutorReviewCount) +
                                    " reviews) "
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    Card.TutorReviewCount == 0 ? _c("div") : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mt-3" }, [
                  _vm._v("By "),
                  _c("b", { staticClass: "text-black" }, [
                    _vm._v(_vm._s(Card.TutorNameShort))
                  ])
                ]),
                _vm._v(" "),
                _c("small", { staticClass: "mt-3 d-block" }, [
                  _vm._v("Medium of Instruction: "),
                  _c("b", { staticClass: "text-black" }, [
                    _vm._v(_vm._s(Card.Languages))
                  ])
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "high-lights" }, [
                  _c("li", [_vm._v(_vm._s(Card.DurationinHr) + " hr/session")]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(Card.NumberofSessions) + " sessions ")
                  ]),
                  _vm._v(" "),
                  _c("li", [_vm._v(_vm._s(Card.CourseDuration) + " week(s)")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex card-actions" },
                  [
                    _c(
                      "div",
                      { staticClass: "price-range" },
                      [
                        Card.CourseFees != Card.AfterDiscountCourseFees
                          ? _c("h5", { staticClass: "sub-title-lg mb-0" }, [
                              _vm._v(
                                _vm._s(Card.RupeeSymbol) +
                                  _vm._s(parseInt(Card.AfterDiscountCourseFees))
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        Card.CourseFees != Card.AfterDiscountCourseFees
                          ? _c("strike", [
                              _vm._v(
                                _vm._s(Card.RupeeSymbol) +
                                  _vm._s(parseInt(Card.CourseFees))
                              )
                            ])
                          : _c("h5", [
                              _vm._v(
                                _vm._s(Card.RupeeSymbol) +
                                  _vm._s(parseInt(Card.CourseFees))
                              )
                            ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex session-count" }, [
                          Card.CoursePaymentOptions == "Monthly"
                            ? _c("span", [_vm._v("/ Month")])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(
                              " (" +
                                _vm._s(Card.RupeeSymbol) +
                                _vm._s(Card.AmountPerSession) +
                                "/session)"
                            )
                          ]),
                          _vm._v(" "),
                          Card.DiscountPercentage > 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(parseInt(Card.DiscountPercentage)) +
                                    "% off"
                                )
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    Card.BusinessId != 11145456
                      ? [
                          Card.IsInstantLiveDemo == true
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-success bookfreedemoclk",
                                  attrs: {
                                    "data-bookdemobtn": "",
                                    "data-hasnoclickevent": "true",
                                    "data-courseid": Card.CourseID,
                                    "data-instantDemoEnabled":
                                      Card.IsInstantLiveDemo
                                  }
                                },
                                [
                                  _c("span", { staticClass: "live-badge" }, [
                                    _vm._v("LIVE")
                                  ]),
                                  _vm._v(
                                    "\n                            Join Instant Demo\n                        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          Card.IsInstantLiveDemo == true
                            ? _c("p", { staticClass: "info-card live-inst" }, [
                                _vm._v(
                                  "Tutor is live for instant demo booking for free"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          Card.IsInstantLiveDemo == false
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary bookfreedemoclk",
                                  attrs: {
                                    "data-bookdemobtn": "",
                                    "data-hasnoclickevent": "true",
                                    "data-courseid": Card.CourseID,
                                    "data-instantDemoEnabled":
                                      Card.IsInstantLiveDemo
                                  }
                                },
                                [
                                  _c("span", { staticClass: "free-badge" }, [
                                    _vm._v("FREE")
                                  ]),
                                  _vm._v(
                                    "\n                            Join Demo\n                        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          Card.IsInstantLiveDemo == false
                            ? _c(
                                "p",
                                { staticClass: "info-card free-course" },
                                [
                                  _vm._v(
                                    "Join free demo class absolutely free... Hurry!"
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
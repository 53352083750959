<template>
    <!-- <section>
      <div class="container-fluid container-md mt-5">
        <div class="row justify-content-center"> -->
    <!-- Modal start -->
    <!-- remove .show to hide modal -->
    <div :class="showme ? 'modal show' : 'modal'">
        <!-- add .modal-sm/modal-lg/modal-xl after .modal-dialog for width change -->
        <div class="modal-dialog modal-xl">
            <!-- Modal content -->
            <div class="modal-content">
                <div class="progress">
                    <div class="progress-bar"
                         role="progressbar"
                         style="width: 50%"
                         aria-valuenow="50"
                         aria-valuemin="0"
                         aria-valuemax="100"></div>
                </div>
                <!-- progress bar end -->
                <div class="row mx-0" v-if="currentStep == 'payment'">
                    <div class="col-lg-6 primary-bg-color"></div>
                    <div class="close-btn col-lg-6 d-none d-lg-flex">
                        <a @click="closePopup()">&times;</a>
                    </div>
                </div>
                <div class="modal-header align-items-center d-sm-none"
                     v-if="currentStep != 'thankYou'">
                    <svg @click="closePopup()"
                         class="svg-icon medium d-sm-none"
                         viewBox="0 0 64 64">
                        <use xlink:href="#chevronBack" />
                    </svg>
                </div>
                <div class="modal-body p-0">
                    <!--<div class="modal-header-mobile d-sm-none"  v-if="currentStep != 'thankYou'">
                <a class="h2 m-2" @click="closePopup()">
                  <i class="far fa-arrow-left" @click="closePopup()"></i>
                </a>
              </div>-->
                    <TimeSlot v-if="currentStep == 'slotBooking'"
                              :demoSlots="DemoTimeSlots"
                              :instructorDetails="instructorDetails"
                              :nextStep="nextStep"
                              @set-DateTime="selectedDateTime"
                              :closePopup="closePopup"
                              :disabledTimes="disabledTimeindexes" />

                    <QuestionAndAnswer v-if="currentStep == 'questionAndAnswer'"
                                       :instructorDetails="instructorDetails"
                                       :QuestionAndAnswers="QuestionAndAnswers"
                                       @set-selectedAnswers="selectedAnswer"
                                       :selectedAnswers="SelectedAnswers"
                                       :nextStep="nextStep"
                                       :closePopup="closePopup" />

                    <UserSignUp v-if="currentStep == 'mobileVerify'"
                                :instructorDetails="instructorDetails"
                                :userInfo="UserInfo"
                                :pincodeData="PincodeData"
                                :nextStep="nextStep"
                                :courseSignUpClientData="CourseSignUpClientData"
                                :isValidName="isValidName"
                                :isValidEMail="isValidEMail"
                                :isValidMobileNumber="isValidMobileNumber"
                                :isValidPincode="isValidPincode"
                                @post-postCourseSignUp="postCourseSignUp"
                                :selectedDate="selectedDate"
                                :closePopup="closePopup"
                                SignUpType="Demo Payment"
                                :errormsg="errormessage"
                                :enableInstantLiveOffline="enableInstantLiveOffline" />

                    <OTPVerification v-if="currentStep == 'otpVerify'"
                                     :instructorDetails="instructorDetails"
                                     :nextStep="nextStep"
                                     :invalidOtp="invalidOtp"
                                     :courseSignUpClientData="CourseSignUpClientData"
                                     :selectedDate="selectedDate"
                                     @post-postCourseSignUp="postCourseSignUp"
                                     :closePopup="closePopup"
                                     :resendotptime="resendotptime"
                                     :countrycode="UserInfo.CountryCode" />

                    <ThankYouForDemo :batchdate="selectedDate"
                                     v-if="currentStep == 'thankYou'"
                                     :instructorDetails="instructorDetails"
                                     :selectedDate="selectedDate"
                                     :userName="CourseSignUpClientData.Name"
                                     :closePopup="closePopup"
                                     :ispaid="false"
                                     :liveSessionUrl="liveSessionUrl" />
                    <!-- <DemoPayment  v-if="currentStep == 'payment'" :instructorDetails="instructorDetails" :selectedDate="selectedDate" :paymentUrl="paymentUrl" :closePopup="closePopup" /> -->
                    <DemoPayProcess v-if="currentStep == 'payment'"
                                    :instructorDetails="instructorDetails"
                                    :selectedDate="selectedDate"
                                    :paymentUrl="paymentUrl"
                                    :closePopup="closePopup" />

                    <ErrorScreen v-if="currentStep == 'errorScreen'"
                                 :instructorDetails="instructorDetails"
                                 :errorScreenDetails="errorScreenDetails"
                                 :closePopup="closePopup" />

                    <ConfirmInstantLiveDemoOffline v-if="currentStep == 'confirminstantliveoffline'"
                                       :instructorDetails="instructorDetails"
                                       :nextStep="nextStep"
                                       :closePopup="closePopup"/>

                </div>
            </div>
        </div>
    </div>
    <!-- Modal end -->
    <!-- </div>
        </div>
      </section> -->
</template>
<script>
    import TimeSlot from "./TimeSlot.vue";
    import QuestionAndAnswer from "./QuestionAndAnswer.vue";
    import UserSignUp from "./UserSignUp.vue";
    import OTPVerification from "./OTPVerification.vue";
    import ThankYouForDemo from "./ThankYouForDemo.vue";
    import DemoPayProcess from "./DemoPayProcess.vue";
    import Loader from "../../wwwroot/js/Loader.js";
    import SulGA from "../../wwwroot/js/Ga.js";
    import ErrorScreen from "./ErrorScreen.vue";
    import ConfirmInstantLiveDemoOffline from "./ConfirmInstantLiveDemoOffline.vue";

    export default {
        name: "CourseSignup",
        props: {
            courseId: "",
            initialPopup: "",
            instantDemoEnabled: 0,
            showpopup: false,
        },
        data() {
            return {
                // currentStep: "slotBooking",
                currentStep: "mobileVerify",
                getInstructorApiUrl: `/api/coursesignup/${this.courseId}`,
                postCourseSignUpApiUrl: `/api/coursesignup/register`,
                postSelectedAnswers: "/api/coursesignup/log-answers",
                paymentUrl: "",
                vMaxMissedCheck: 0,
                vMaxMissedCheckDefault: 12,
                instructorDetails: {},
                CourseSignUpClientData: {},
                QuestionAndAnswers: {},
                SelectedAnswers: [],
                DemoTimeSlots: [],
                UserInfo: {},
                CourseCard: {},
                PincodeData: [],
                regexp: {
                    alpha: /^[a-z0-9\.\s]+$/i,
                    number: /[^0-9]/g,
                    email: /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i,
                    mobile1: /^[9|7|6]\d{8}[\d{1}]$/i,
                    mobile2: /^[8]\d{8}[\d{1}]$/i,
                    othermobile: /^[1-9]\d{9}$/,
                    internationalmobile: /^[0-9]{6,14}$/,
                    //pincode: /^[0-9]{6,6}$/,
                    pincode: /^[1-9]{1}[0-9]{2}[0-9]{3}$/,
                },
                selectedDate: "",
                invalidOtp: false,
                popUp: "",
                showme: false,
                coursesignupdetails: {},
                resendotptime: false,
                errormessage: "",
                errorScreenDetails: {},
                selectedTimeIndex: "",
                isTimeConflict: false,
                disabledTimeindexes: [],
                liveSessionUrl: "",
                enableInstantLiveOffline: false,
            };
        },
        created() {
            var body = document.body;
            body.classList.add("slkno-scroll");
        },
        mounted: function () {
            // Track GA Events
            // SulGA.init();
            // SulGA.pushPageView();
            //var trackinglabel = "Join Demo " + this.courseId;
            //SulGA.pushEvent(
            //  "join-demo-free",
            //  "Join Demo" + trackinglabel,
            //  "join-demo-free-click"
            //);

            var coursesignupdetails = document.querySelector("#coursesignupdetails");
            //if (coursesignupdetails != null && this.instantDemoEnabled != 1) {
            if (coursesignupdetails != null) {
                this.coursesignupdetails = JSON.parse(coursesignupdetails.innerHTML);
                this.populateData();
            } else {
                Loader.showAboveModal();
                fetch("/api/coursesignup/" + this.courseId)
                    .then((responseJson) => responseJson.json())
                    .then((response) => {
                        this.coursesignupdetails.Result = response;
                        this.populateData();
                        Loader.hideAboveModal();
                    });
            }
            //console.log(this.coursesignupdetails);

            this.popUp = this.initialPopup;
            this.showme = this.showpopup;

            // fetch(this.getInstructorApiUrl, { method: 'GET' })
            //     .then(responseJson => responseJson.json())
            //     .then(response => {
            //         this.instructorDetails = response.Instructor;
            //         this.CourseSignUpClientData = response.CourseSignUpClientData;
            //         this.QuestionAndAnswers = response.QuestionAndAnswers;
            //         this.DemoTimeSlots = response.DemoTimeSlots;
            //         this.UserInfo = response.UserInfoModel;
            //         this.CourseSignUpClientData.AdId = this.courseId;
            //     });
        },
        methods: {
            populateData() {
                this.instructorDetails = this.coursesignupdetails.Result.Instructor;
                this.instructorDetails.singuptype = "demo";
                this.CourseSignUpClientData = this.coursesignupdetails.Result.CourseSignUpClientData;
                this.QuestionAndAnswers = this.coursesignupdetails.Result.QuestionAndAnswers;
                this.DemoTimeSlots = this.coursesignupdetails.Result.DemoTimeSlots;
                this.UserInfo = this.coursesignupdetails.Result.UserInfoModel;
                this.CourseCard = this.coursesignupdetails.Result.CourseCard;
                this.CourseSignUpClientData.AdId = this.courseId;
                this.PincodeData = this.coursesignupdetails.Result.Pincode;

                for (var row = 0; row < this.DemoTimeSlots.length; row++) {
                    if (this.DemoTimeSlots[row].DemoTime.length > 6) {
                        this.DemoTimeSlots[row].isSeeMore = true;
                    } else {
                        this.DemoTimeSlots[row].isSeeMore = false;
                    }
                }
                if (this.instantDemoEnabled == 1) {
                    this.instructorDetails.singuptype = "instantdemo";
                    //if (this.CourseSignUpClientData.IsInstantDemo == true)
                    //    this.instructorDetails.singuptype = "instantdemo";
                    //else {
                    //    this.instantDemoEnabled = 0;
                    //    this.enableInstantLiveOffline = true;
                    //}
                }
            },
            closePopup() {
                SulGA.pushEvent("popup-close", 'demo popup close', "popup-close-click");
                this.showme = false;
                var body = document.body;
                body.classList.remove("slkno-scroll");
                var demobooking = document.getElementById("demo-booking");
                demobooking.innerHTML = "";
                var div = document.createElement("div");
                div.id = "demo-signup";
                demobooking.appendChild(div);
                if (this.currentStep == "thankYou") {
                    if (this.UserInfo.Pid == 0) {
                        location.reload();
                    }
                }
            },
            nextStep() {
                // alert(this.currentStep)
                switch (this.currentStep) {
                    case "slotBooking":
                        if (this.QuestionAndAnswers && this.QuestionAndAnswers !== null) {
                            console.log("The question and answers ", this.QuestionAndAnswers);
                            console.log("before selectedAnswers", this.SelectedAnswers);
                            if (this.SelectedAnswers.length <= 0) {
                                this.QuestionAndAnswers.forEach((item) => {
                                    let questionAndAnswer = {
                                        CourseId: this.courseId,
                                        mobileno: "",
                                        Pk_QuestionId: item.QuestionId,
                                        Pk_AnswerId: "",
                                    };
                                    this.SelectedAnswers.push(questionAndAnswer);
                                });
                            }

                            this.currentStep = "questionAndAnswer";
                            break;
                        } else {
                            // this.currentStep = "mobileVerify";
                            this.postCourseSignUp(this.courseSignUpClientData);
                            break;
                        }
                    case "questionAndAnswer":
                        //  this.currentStep = "mobileVerify";
                        this.postCourseSignUp(this.courseSignUpClientData);

                        break;
                    case "confirminstantliveoffline":
                        this.postCourseSignUp(this.courseSignUpClientData);

                        break;
                }
            },
            postCourseSignUp(clientData) {
                var today = new Date();
                if (this.currentStep == "otpVerify") {
                    if (clientData.verifycode == "resetmobilenumber") {
                        this.currentStep = "mobileVerify";
                        clientData.verifycode = null;

                        return;
                    } else if (clientData.verifycode == "resendotp") {
                        //  this.currentStep = "otpVerify";
                        clientData.verifycode = null;
                        this.resendotptime = null;
                    }
                }

                // clientData.CanBook = true;
                if (this.currentStep == "confirminstantliveoffline"
                ) {
                    clientData.verifycode = null;
                    clientData.CanBook = false;
                    clientData.IsAnswered = false;
                    clientData.isverified = false;
                }
                else if (
                    this.currentStep == "mobileVerify" ||
                    this.currentStep == "otpVerify"
                ) {
                    clientData.CanBook = false;
                    clientData.IsAnswered = false;
                    clientData.isverified = false;
                } else {
                    // need not to check vefirycode once done
                    clientData.CanBook = true;
                    clientData.verifycode = null;
                    clientData.isverified = true;

                    if (this.QuestionAndAnswers && this.QuestionAndAnswers !== null) {
                        //  for(var row=0;row<this.SelectedAnswers.length;row++){
                        //      this.SelectedAnswers[row].mobileno=clientData.MobileNo
                        //      this.SelectedAnswers[row].studentuserpid=clientData.userid
                        //   }
                        //  debugger;

                        for (var row = 0; row < this.SelectedAnswers.length; row++) {

                            if (row == 0) {
                                SulGA.pushEvent("intent-q1", this.SelectedAnswers[row].Pk_AnswerId, "intent-q1-click");
                            }
                            else if (row == 1) {
                                SulGA.pushEvent("intent-q2", this.SelectedAnswers[row].Pk_AnswerId, "intent-q2-click");
                            }
                        }
                        clientData.QuestionAndAnswers = this.SelectedAnswers;
                        clientData.IsAnswered = true;
                        clientData.CanBook = true;
                    }
                }

                if (this.instantDemoEnabled == 1)
                    clientData.CanBook = true;

                this.courseSignUpClientData = clientData;
                Loader.showAboveModal();
                // Loader.hide();
                // Loader.showAboveModal();
                // Loader.hideAboveModal();
                fetch(this.postCourseSignUpApiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(clientData),
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        Loader.hideAboveModal();
                        this.signupSuccessResponse(responseJson, clientData);
                    });
            },

            signupSuccessResponse(response, clientData) {
                let status = response.Status.toLowerCase();
                let enableInstantLiveOffline = response.enableInstantLiveOffline;
                /*let status = response;*/
                this.errormessage = "";

                if (status == "already same timeslot exists") {
                    this.isTimeConflict = true;
                    this.disabledTimeindexes.push(this.selectedTimeIndex);
                }

                if (status == "already exists" || status == "maximum demo booking reached" || status == "already same timeslot exists") {
                    this.errorScreenDetails = this.getErrorScreenDetails(status);
                    this.currentStep = "errorScreen";
                }
                if (this.instantDemoEnabled == 1) {
                    this.liveSessionUrl = response.LiveSessionUrl;
                }
                if (status == "demoslot") {
                    if (enableInstantLiveOffline == true) {
                        this.instantDemoEnabled = 0;
                        this.currentStep = "confirminstantliveoffline";
                        this.instructorDetails.singuptype = "demo";
                        clientData.IsInstantDemo = false;
                        this.CourseSignUpClientData = clientData;
                    }
                    else
                        this.currentStep = "slotBooking";

                    if (this.currentStep == "otpVerify") {
                        SulGA.pushEvent("confirm-otp", "otp verified", "confirm-otp-verify");
                        return;
                    } else if (this.currentStep == "mobileVerify") {
                        // if already logged we skip the otp screen and load slot booking
                        SulGA.pushEvent("confirm-otp", "otp verified", "confirm-otp-verify");
                        return;
                    }
                }
                if (
                    status === "success" ||
                    status === "validnumber" ||
                    status === "verified" ||
                    status === "duplicate"
                ) {
                    SulGA.pushEvent("final-submit", 'final-submit-click', "Demo submit");
                    this.currentStep = "thankYou";
                }
                if (status === "verificationcodesent" || status === "verificationcode") {
                    // if (this.QuestionAndAnswers && this.QuestionAndAnswers !== null) {
                    //     this.postSelectedAnswer(clientData);
                    // }
                    this.resendotptime = true;
                    this.currentStep = "otpVerify";
                }
                //    if( this.currentStep == "otpVerify"){

                //    }
                if (status === "payment required") {
                    this.currentStep = "payment";
                    this.paymentUrl = response.sPaymentURL;
                }
                debugger;
                if (status == "invalidcode") {
                    this.invalidOtp = true;
                }
                if (status == "invalidinput") {
                    var currentdate = new Date();
                    var datetime =
                        currentdate.getDate() +
                        "/" +
                        (currentdate.getMonth() + 1) +
                        "/" +
                        currentdate.getFullYear() +
                        " @ " +
                        currentdate.getHours() +
                        ":" +
                        currentdate.getMinutes() +
                        ":" +
                        currentdate.getSeconds();
                    this.invalidOtp = true;
                    this.errormessage = response.Message + "," + datetime;
                }

                if (status === " already exists") {
                    alert(status);
                }
                if (status == "0") {
                    if (clientData.missedcall) {
                        SulGA.pushEvent(
                            "confirm-missed-call",
                            "Missed call verified",
                            "confirm-missed-call-click"
                        );

                        setTimeout(() => {
                            if (this.vMaxMissedCheck < this.vMaxMissedCheckDefault) {
                                this.postCourseSignUp(clientData);
                                this.vMaxMissedCheck = this.vMaxMissedCheck + 1;
                            }
                        }, 4000);
                    }
                }
            },
            isValidName(name) {
                return (
                    this.regexp.alpha.test(name) ||
                    (name.match(/\./g) != null && name.match(/\./g).length > 3) ||
                    name.replace(/[^a-zA-Z]+/g, "").length <= 2
                );
            },
            isValidEMail(email) {
                return this.regexp.email.test(email);
            },
            isValidMobileNumber(mobileno, CountryCode) {

                if (CountryCode == undefined || CountryCode == "" || CountryCode == "91" || CountryCode == "+91") {
                    return (
                        this.regexp.mobile1.test(mobileno) || this.regexp.mobile2.test(mobileno)
                    );
                }
                else {
                    return (
                        this.regexp.internationalmobile.test(mobileno)
                    );
                }

            },
            isValidPincode(pincode) {
                return this.regexp.pincode.test(pincode);
            },
            selectedDateTime(Datevalue, Time, DateTimeString, selectedTimeIndex) {
                var matches = /^(\d{2})[-\/](\d{2})[-\/](\d{4})$/.exec(Datevalue);
                if (matches == null) return false;
                var d = matches[1];
                var m = matches[2];
                var y = matches[3];
                var selectedDateTime = m + "/" + d + "/" + y; //new Date(m, d, y).toDateString();

                // var selectedate=new Date(Datevalue);
                //var selecteddate = new Date(dateFormat(Date, "dddd, dd-mm-yyyy"));
                this.selectedTimeIndex = selectedTimeIndex;
                this.CourseSignUpClientData.BatchStartDate = selectedDateTime;
                this.CourseSignUpClientData.BatchStartTime = Time;
                this.selectedDate = selectedDateTime + " " + Time; //DateTimeString;
            },
            postSelectedAnswer(clientData) {
                this.SelectedAnswers.forEach((item) => {
                    item.mobileno = clientData.MobileNo;
                });
                fetch(this.postSelectedAnswers, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(this.SelectedAnswers),
                }).then((response) => response.json());
            },
            // Why selecting answer we have to update in array list
            selectedAnswer(questionId, AnswerId) {
                // debugger;
                //   for(var row=0;row<this.QuestionAndAnswers.length;row++){
                //          if(this.QuestionAndAnswers[row].ischecked!=true){
                //            this.QuestionAndAnswers[row].ischecked=false;
                //          }
                //          //questionAnswer.QuestionId, answer.AnswerId
                //            for(var nesrow=0;nesrow<this.QuestionAndAnswers[row].Answers.length;nesrow++){
                //                 if( this.QuestionAndAnswers[row].Answers[nesrow].ischecked==true)
                //                    this.QuestionAndAnswers[row].Answers[nesrow].ischecked=false;
                //           }
                //       }

                this.SelectedAnswers.forEach((item) => {
                    if (item.Pk_QuestionId === questionId) {
                        item.Pk_AnswerId = AnswerId;
                    }
                });
            },
            getErrorScreenDetails(status) {
                switch (status) {
                    case "already exists": return {
                        errorHeadingText: "Demo booking failed",
                        errorContent: "You have already signed up for this course.",
                        NextButtonText: "Close",
                        onBtnClick: () => {
                            console.log(this.currentStep);
                            this.closePopup();
                        }
                    }
                        break;
                    case "maximum demo booking reached": return {
                        errorHeadingText: "Demo booking failed",
                        errorContent: "You have booked 3 demo's for different courses, please attend the demo(s) before booking one more demo. You can evaluate those courses before attending more demos.",
                        NextButtonText: "Close",
                        onBtnClick: () => {
                            console.log(this.currentStep);
                            this.closePopup();
                        }
                    }
                        break;
                    case "already same timeslot exists": return {
                        errorHeadingText: "Demo booking failed",
                        errorType: "TIME_CONFLICT",
                        selectedDateTime: this.selectedDate,
                        NextButtonText: "Choose time slot",
                        onBtnClick: () => {
                            console.log(this.currentStep);
                            this.currentStep = "slotBooking";
                        }
                    }
                        break;
                }
            }
        },
        components: {
            TimeSlot: TimeSlot,
            QuestionAndAnswer: QuestionAndAnswer,
            UserSignUp: UserSignUp,
            OTPVerification: OTPVerification,
            ThankYouForDemo: ThankYouForDemo,
            DemoPayProcess: DemoPayProcess,
            ErrorScreen: ErrorScreen,
            ConfirmInstantLiveDemoOffline: ConfirmInstantLiveDemoOffline,
        },
    };
</script>

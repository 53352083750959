var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "container container-exceed pt-4 mt-5" },
    [
      _c("div", { staticClass: "pay-thank" }, [
        _c("iframe", { attrs: { src: _vm.gatewayurl, frameborder: "0" } })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
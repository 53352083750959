<template>
    <div>
        <svg class="d-none">
            <defs>
                <g id="binocular">
                    <path d="M416 48c0-8.84-7.16-16-16-16h-64c-8.84 0-16 7.16-16 16v48h96V48zM63.91 159.99C61.4 253.84 3.46 274.22 0 404v44c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32V288h32V128H95.84c-17.63 0-31.45 14.37-31.93 31.99zm384.18 0c-.48-17.62-14.3-31.99-31.93-31.99H320v160h32v160c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-44c-3.46-129.78-61.4-150.16-63.91-244.01zM176 32h-64c-8.84 0-16 7.16-16 16v48h96V48c0-8.84-7.16-16-16-16zm48 256h64V128h-64v160z"></path>
                </g>
            </defs>
        </svg>
        <div id="LCF" class="qtn-wrapper">
            <div class="d-flex align-items-center">
                <!--<i class="fa fa-binoculars mr-3"></i>-->
                <svg class="svg-icon medium fill-black" viewBox="0 0 512 512">
                    <use xlink:href="#binocular" />
                </svg>
                <p>Answer a quick quiz to find your perfect course (2 min)</p>
            </div>
            <div class="hr-grey mb-3"></div>
            <!--<div class="d-flex mb-3">
            <i class="far fa-angle-up"></i>

        </div>-->
            <template v-if="FirstQuestion.length > 0">
                <h4 class="mb-3" :id="'ques'+FirstQuestion[0].questionId">{{FirstQuestion[0].question}}</h4>
                <ul id="answers" class="list-group">
                    <li v-for="answer in FirstQuestion[0].answers">
                        <!--<input v-on:click="onSelect(answer)" type="radio" :id="'ans'+answer.answerId" name="radiobox">
            <label :for="'ans'+answer.answerId">{{answer.answer}}</label>-->
                        <div class="material-radio">
                            <input v-on:click="onSelect(answer)" type="radio" :id="'ans'+answer.answerId" name="radiobox">
                            <label :for="'ans'+answer.answerId">{{answer.answer}}</label>
                        </div>
                    </li>
                </ul>
            </template>
            
            <!-- add active to show orange color -->
            <!--<div class="skip-btn-container">
            <span id="NextButton1" class="btn next-btn sub-title" :disabled="isLCF1ButtonDisabled" v-on:click="onNextClick">Next</span>
        </div>-->
            <button class="btn btn-primary btn-block mt-3" id="NextButton1" v-on:click="onNextClick" disabled>
                Next
            </button>
        </div>
        <LCFSecondLevel v-if="CurrentStep > 0"
                        :CurrentQuestion="CurrentQuestion"
                        :C1Name="C1Name"
                        :CookieeSelAnswerID="CookieeSelAnswerID"
                        :PageType="PageType"
                        :showQuesPopup="showQuesPopup"
                        :onLCFAnsSelect="onLCFAnsSelect"
                        :AnswerSubmit="AnswerSubmit"
                        :onLCFBackClick="onLCFBackClick"
                        :SetSelectedDefault="SetSelectedDefault"
                        :CloseLCFPopup="CloseLCFPopup">
        </LCFSecondLevel>
    </div>
</template>

<script>
    import LCFSecondLevel from "./LCFSecondLevel.vue";
    import Loader from "../../wwwroot/js/Loader.js";
    import SulGA from "../../wwwroot/js/Ga.js";

    export default {
        name: "LCFFirstLevel",
        components: {
            "LCFSecondLevel": LCFSecondLevel,
        },
        data() {
            return {
                CurrentStep: 0,
                C1ID: { type: String },
                C1Name: { type: String },
                Questions: [],
                NextQuestionID: 0,
                CookieeSelAnswerID: [],
                CurrentQuestion: [],
                QuesAndAnsArray: [],
                selectedQues: [],
                selectedAns: [],
                QuesAndAnsObj: [],
                showQuesPopup: false,
                QuesAnsMapping: [],
                SelectedAnswerID: [],
                ShowRecPage: false,
                PageType: "LCFQues",
                SelectedQuesID: [],
            }
        },
        beforeMount: function () {
            var c1ID = 0;
            if (document.getElementById('hdnC1Id') != null) {
                c1ID = document.getElementById('hdnC1Id').value;
                document.cookie = "C1ID=" + c1ID;
            }
            var c2ID = 0;
            if (document.getElementById('hdnC2Id') != null) {
                c2ID = document.getElementById('hdnC2Id').value;
                document.cookie = "C2ID=" + c2ID;
            }
            var getLCFData = "//sulcdn.azureedge.net/content/caps/lcf/" + c1ID + ".json";
            fetch(getLCFData)
                .then(response => response.json())
                .then(responseJson => {
                    if (c1ID == 11) {
                        responseJson.questions.forEach(ques => {
                            if (c2ID > 0) {
                                if (ques.questionId == 21) {
                                    ques.isFirstQuestion = false;
                                }
                                else if (ques.questionId == 23 && c2ID == 65) {
                                    ques.isFirstQuestion = true;
                                }
                                else if (ques.questionId == 22 && c2ID != 65) {
                                    ques.isFirstQuestion = true;
                                }
                            }
                        });
                    }

                    this.Questions = responseJson.questions;
                    this.C1Name = responseJson.c1Name;
                });
        },
        computed: {
            FirstQuestion: function () {
                return this.Questions.filter(Question => Question.isFirstQuestion)
            }
        },
        methods: {
            onSelect: function (answer) {
                var inputs = document.querySelectorAll('#LCF input');
                inputs.forEach((input, index) => {
                    if (input.checked) {
                        input.parentElement.parentElement.className = "selected";
                    }
                    else {
                        input.parentElement.parentElement.className = "";
                    }
                });
                this.NextQuestionID = answer.nextQuestionId;
                var checked = document.querySelectorAll('#LCF input:checked');
                if (checked.length > 0) {
                    document.getElementById('NextButton1').disabled = false;
                }
                else {
                    document.getElementById('NextButton1').disabled = true;
                }
            },
            onNextClick() {
                this.QuesAnsMapping.length = 0;
                this.SelectedAnswerID.length = 0;
                this.SelectedQuesID.length = 0;
                var input = document.querySelector('#LCF input:checked');
                if (input != null) {
                    var id = input.getAttribute('id').split('ans');
                    this.SelectedAnswerID.push(id[1]);
                    if (this.FirstQuestion.length > 0) {
                        this.QuesAnsMapping.push(this.FirstQuestion[0].questionId + ':' + this.SelectedAnswerID);
                    }
                    this.CurrentQuestion = this.Questions.filter(Question => Question.questionId == this.NextQuestionID);
                    this.showQuesPopup = true;
                    this.CurrentStep = 1;
                    document.body.classList.add('overflow-hidden');
                    SulGA.pushEvent("lcf-q1-next", 'LCF ' + document.getElementById('hdnC1Id').value + ' q1', "lcf-q1-next-click");
                }
            },
            onLCFAnsSelect: function (isMultiSelect, answer) {
                var inputs = document.querySelectorAll('#LCFTwo input');
                inputs.forEach((input, index) => {
                    if (input.checked) {
                        input.parentElement.parentElement.className = "selected";
                    }
                    else {
                        input.parentElement.parentElement.className = "";
                    }
                });

                var checked = document.querySelectorAll('#LCFTwo input:checked');
                if (checked.length > 0) {
                    document.getElementById('NextButton2').disabled = false;
                }
                else {
                    document.getElementById('NextButton2').disabled = true;
                }
                this.NextQuestionID = answer.nextQuestionId;
                //if (this.NextQuestionID == 0)
                //    document.getElementById('NextButton2').innerText = "Submit";
            },
            AnswerSubmit: function (isMultiSelect) {
                this.SelectedAnswerID.length = 0;
                var inputs = document.querySelectorAll('#LCFTwo input:checked');
                inputs.forEach((input, index) => {
                    var id = input.getAttribute('id').split('ans');
                    this.SelectedAnswerID.push(id[1]);
                });
                var checked = document.querySelectorAll('#LCFTwo input:checked');
                if (checked.length > 0) {
                    var quesFound = false;
                    this.QuesAnsMapping.forEach((QuesAns, index) => {
                        if (QuesAns.toString().includes(this.CurrentQuestion[0].questionId + ":") && this.SelectedAnswerID.length > 0) {
                            var QuesAnsArr = QuesAns.split(':');
                            var ansIDArr = QuesAnsArr[1].split(',');
                            if (JSON.stringify(ansIDArr) != JSON.stringify(this.SelectedAnswerID)) {
                                this.QuesAnsMapping[index] = this.CurrentQuestion[0].questionId + ':' + this.SelectedAnswerID;
                                this.QuesAnsMapping.length = index + 1;
                                /*this.SelectedQuesID.length = index + 1;*/
                            }
                            quesFound = true;
                        }
                    })
                    if (!quesFound && this.SelectedAnswerID.length > 0) {
                        this.QuesAnsMapping.push(this.CurrentQuestion[0].questionId + ':' + this.SelectedAnswerID);
                    }
                    if (this.NextQuestionID == 0) {
                        this.ShowRecPage = true;
                        var cookieeString = "";
                        this.QuesAnsMapping.forEach((input) => {
                            if (cookieeString == "") {
                                cookieeString = "QuesAnsMapping=" + input;
                            }
                            else {
                                cookieeString = cookieeString + "-" + input;
                            }
                        })
                        document.cookie = "QuesAnsMapping" + '=; expires=Sun, 17 Jan 1970 00:00:01 GMT;';
                        document.cookie = cookieeString;                        
                        this.CurrentStep = this.CurrentStep + 1;
                        SulGA.pushEvent("lcf-q" + this.CurrentStep + "-next", 'LCF ' + document.getElementById('hdnC1Id').value + ' q' + this.CurrentStep, "lcf-q" + this.CurrentStep + "-next-click");
                        Loader.showAboveModal();
                        window.location.replace("/CourseRecomendation");
                    }
                    else {
                        this.CurrentQuestion = this.Questions.filter(Question => Question.questionId == this.NextQuestionID);
                        this.QuesAnsMapping.forEach((quesAns) => {
                            if (quesAns.toString().includes(this.CurrentQuestion[0].questionId + ":")) {
                                var quesAnsArr = quesAns.split(':');
                                var ansIDArr = quesAnsArr[1].split(',');
                                ansIDArr.forEach((ansID) => {
                                    this.CookieeSelAnswerID.push(ansID);
                                })
                            }
                        })                        
                        this.showQuesPopup = true;
                        this.CurrentStep = this.CurrentStep + 1;
                        SulGA.pushEvent("lcf-q" + this.CurrentStep + "-next", 'LCF ' + document.getElementById('hdnC1Id').value + ' q' + this.CurrentStep, "lcf-q" + this.CurrentStep + "-next-click");
                    }
                }
            },
            DeSelectAll: function () {
                var LCFTwo = document.querySelectorAll('#LCFTwo input');
                LCFTwo.forEach((input, index) => {
                    input.checked = false;
                    input.parentElement.parentElement.className = "";
                });
                var LCF = document.querySelectorAll('#LCF input');
                LCF.forEach((input, index) => {
                    input.checked = false;
                    input.parentElement.parentElement.className = "";
                });
                if (document.getElementById('NextButton1') != null)
                    document.getElementById('NextButton1').disabled = true;
                if (document.getElementById('NextButton2') != null)
                    document.getElementById('NextButton2').disabled = true;
                document.body.classList.remove('overflow-hidden');
            },
            onLCFBackClick: function () {
                this.SelectedAnswerID.length = 0;
                var inputs = document.querySelectorAll('#LCFTwo input:checked');
                inputs.forEach((input, index) => {
                    var id = input.getAttribute('id').split('ans');
                    this.SelectedAnswerID.push(id[1]);
                });
                var quesFound = false;
                this.QuesAnsMapping.forEach((QuesAns, index) => {
                    if (QuesAns.toString().includes(this.CurrentQuestion[0].questionId + ":")) {
                        this.QuesAnsMapping[index] = this.CurrentQuestion[0].questionId + ':' + this.SelectedAnswerID;
                        quesFound = true;
                    }
                })
                if (!quesFound) {
                    this.QuesAnsMapping.push(this.CurrentQuestion[0].questionId + ':' + this.SelectedAnswerID);
                }
                this.CookieeSelAnswerID.length = 0;
                this.QuesAnsMapping.forEach((quesAns, index) => {
                    if (quesAns.toString().includes(this.CurrentQuestion[0].questionId + ":")) {
                        var PrevQuesArr = this.QuesAnsMapping[index - 1].split(':');
                        this.CurrentQuestion = this.Questions.filter(Question => Question.questionId == PrevQuesArr[0]);
                        if (PrevQuesArr.length > 1) {
                            var ansIDArr = PrevQuesArr[1].split(',');
                            ansIDArr.forEach((ansID) => {
                                this.CookieeSelAnswerID.push(ansID);
                            })
                        }
                    }
                })

                if (this.CurrentStep > 1)
                    this.CurrentStep = this.CurrentStep - 1;                   
                else
                    this.CurrentStep = 1;
            },
            CloseLCFPopup: function () {
                this.showQuesPopup = false;
                var LCFinputs = document.querySelectorAll('#LCF input');

                LCFinputs.forEach((input, index) => {
                    input.checked = false;
                    input.parentElement.parentElement.classList.remove("selected");
                })
                var LCF2inputs = document.querySelectorAll('#LCFTwo input');
                LCF2inputs.forEach((input, index) => {
                    input.checked = false;
                    input.parentElement.parentElement.classList.remove("selected");
                })
                document.body.classList.remove('overflow-hidden');
                SulGA.pushEvent("lcf-close", "LCF Close", "lcf-close-click");
            },
            SetSelectedDefault: function () {
                var LCFTwo = document.querySelectorAll('#LCFTwo input');
                LCFTwo.forEach((input, index) => {
                    input.checked = false;
                    input.parentElement.parentElement.className = "";
                });
                this.CookieeSelAnswerID.forEach((selAns) => {
                    if (document.querySelector('#LCFTwo' + " #ans" + selAns)) {
                        document.querySelector('#LCFTwo' + " #ans" + selAns).checked = true;
                        document.querySelector('#LCFTwo' + " #ans" + selAns).parentElement.parentElement.className = "selected";
                    }
                    if (this.CurrentQuestion != null && this.CurrentQuestion.length > 0) {
                        this.CurrentQuestion[0].answers.forEach((answer) => {
                            if (answer.answerId == selAns) {
                                this.NextQuestionID = answer.nextQuestionId;
                            }
                        })
                    }
                });
                var checked = document.querySelectorAll('#LCFTwo input:checked');
                if (checked.length > 0 && document.getElementById('NextButton2') != null) {
                    document.getElementById('NextButton2').disabled = false;
                }
                else {
                    document.getElementById('NextButton2').disabled = true;
                }
                this.CookieeSelAnswerID.length = 0;
                if (document.getElementById('BackButton') != null) {
                    if (!this.CurrentQuestion[0].isFirstQuestion && document.getElementById('BackButton').className.includes('d-none')) {
                        document.getElementById('BackButton').classList.remove('d-none');
                    }
                    if (this.CurrentQuestion[0].isFirstQuestion && !document.getElementById('BackButton').className.includes('d-none')) {
                        document.getElementById('BackButton').classList.add('d-none');
                    }
                }
                //if (this.SelectedQuesID[0].toString() == this.CurrentQuestion[0].questionId) {
                //    document.getElementById('BackButton').disabled = true;
                //}
                //else {
                //    document.getElementById('BackButton').disabled = false;
                //}
            },
        }
    }

</script>
<template>
    <div class="col-lg-6">
        <!-- course details -->
        <div class="row course-details-block align-items-center">
            <!-- Card Start -->
            <div class="duserinfo">
                <h6 class="my-lg-3"
                    v-if="
                      instructorDetails.TutorName &&
                      instructorDetails.TutorName != '' &&
                      instructorDetails.TutorName != null
                    ">
                    {{getsesson()}} with {{ instructorDetails.TutorName }}

                </h6>
                <div class="course-details">
                    <div class="img-wrapper ">
                        <img :src="instructorDetails.ImageURL"
                             alt=""
                             class="img-fluid bg-img fit-cover" />
                    </div>


                    <div class="pl-3">
                        <h5 v-show="
              instructorDetails.CourseName &&
              instructorDetails.CourseName != '' &&
              instructorDetails.CourseName != null
            ">
                            {{ instructorDetails.CourseName }}
                        </h5>
                        <p class="caption">
                            <!--<i class="far fa-user-circle mr-2"></i>-->
                            <svg class="svg-icon medium c-pointer top-1" viewBox="0 0 496 512">
                                <use xlink:href="#userProfile" />
                            </svg>
                            Classes by {{instructorDetails.TutorName}}
                        </p>
                    </div>
                    <!-- <h6 class="mt-2">
                      <i class="far fa-calendar mr-2"></i>
                                {{ instructorDetails.DurationinHr}} hr/session •  {{ instructorDetails.NumberofSessions}} sessions •  {{ instructorDetails.CourseDuration }}  Week
                    </h6> -->
                </div>
                <!--<h6 class="my-lg-3 text-danger"
                    v-if="enableInstantLiveOffline == true">
                    We have reconfirmed the instructor is currently not available. You can book a free demo session for the next available time slot.
                </h6>-->
            </div>
            <!-- Card End -->
        </div>
        <!-- course details end -->
    </div>




</template>

<script>
    export default {
        name: "DisplayUserInfor",
        props: {
            instructorDetails: {},
            //enableInstantLiveOffline: false,
        },
        data() {
            return {

            }
        },
        methods: {
            close() {
                this.closePopup();
            },
            getsesson() {
                if (this.instructorDetails.CourseName == "One On One Sessions") {
                    return "One On One Session"
                }
                else if (this.instructorDetails.singuptype == "demo") {
                    return "Demo"
                }
                else if (this.instructorDetails.singuptype == "instantdemo") {
                    return "Instant Demo"
                } else if (this.instructorDetails.singuptype == "regular") {
                    return "Course"
                }
                else {
                    return "One On One"
                }
            }

        },
        watch: {
            instructorDetails: function (instructorDetails) {

            },

        },

    }

</script>


﻿let Loader = {
    loaderDiv: document.querySelector(".loading"),
    show: function () {
        this.loaderDiv.classList.add("d-flex");
        document.body.classList.add("overflow-hidden");
    },
    hide: function () {
        this.loaderDiv.classList.remove("d-flex");
        document.body.classList.remove("overflow-hidden");
    },
    showAboveModal: function () {
        this.loaderDiv.classList.add("d-flex");
    },
    hideAboveModal: function () {
        this.loaderDiv.classList.remove("d-flex");
    }
}
export default Loader;
﻿import Loader from "../Loader";

var MyClub = {
    UserPid: '',
    init: function () {
        //let myClubTab = document.getElementsByClassName("myClubTab");
        let myClubTab = document.getElementById("myClubTabSelect");
        if(myClubTab)
        {
        myClubTab.addEventListener('click', (event) => {
            document.querySelector(".myClubTab").classList.add("active");
            document.querySelector(".myFeedbackTab").classList.remove("active");
            document.querySelector(".reportTab").classList.remove("active");
            document.getElementById("myClubTab").classList.remove("hide");
            document.getElementById("myFeedbackTab").classList.add("hide");
            document.getElementById("reportTab").classList.add("hide");
        });
        }
        //let myFeedbackTab = document.getElementsByClassName("myFeedbackTab");
        let myFeedbackTab = document.getElementById("myFeedbackTabSelect");
        if(myFeedbackTab)
        {
        if(!myFeedbackTab.classList.contains("disabled"))
        {
          myFeedbackTab.addEventListener('click', (event) => {
            document.querySelector(".myFeedbackTab").classList.add("active");
            document.querySelector(".myClubTab").classList.remove("active");
            document.querySelector(".reportTab").classList.remove("active");
            document.getElementById("myFeedbackTab").classList.remove("hide");
            document.getElementById("myClubTab").classList.add("hide");
            document.getElementById("reportTab").classList.add("hide");
          });
        }
        else 
        {
           document.querySelector(".myFeedbackTab").classList.add("hide");
        }
        }

        //let reportTab = document.getElementsByClassName("reportTab");
        let reportTab = document.getElementById("reportTabSelect");
        if(reportTab)
        {
        if(!reportTab.classList.contains("disabled"))
        {
          reportTab.addEventListener('click', (event) => {
            document.querySelector(".reportTab").classList.add("active");
            document.querySelector(".myClubTab").classList.remove("active");
            document.querySelector(".myFeedbackTab").classList.remove("active");
            document.getElementById("reportTab").classList.remove("hide");
            document.getElementById("myClubTab").classList.add("hide");
            document.getElementById("myFeedbackTab").classList.add("hide");
          });
        }
        else 
        {
           document.querySelector(".reportTab").classList.add("hide");
        }
        }
        

        this.UserPid = document.getElementById("hdnUserPid").value;
        let reserveSlotBtn = document.querySelectorAll(".myClubReserveSlotBtn");
        reserveSlotBtn.forEach((btn) => {
            btn.addEventListener('click', (event) => {
                let eventScdId = btn.getAttribute("data-eventscheduleid");
                let clubid = btn.getAttribute("data-clubid");
                let clubScheduleDate = btn.getAttribute("data-clubscheduledate");
                this.SubmitSlotBooking(eventScdId, clubid, clubScheduleDate, event);
                //var r = confirm("Please Confirm!");
                //if (r == true) {
                //    this.SubmitSlotBooking(eventScdId, clubid, clubScheduleDate);
                //}
            })
        });

        let joinMeetingBtn = document.querySelectorAll(".join-meeting");
        joinMeetingBtn.forEach((btn) => {
            if (btn.getAttribute("data-meetingurl").toLowerCase().indexOf("tk=") <= 0) {
                fetch(`/api/MyClub/GetClubMeetParticipantUrl?ClubScheduleId=${btn.getAttribute('data-clubscheduleid')}&UserPid=${this.UserPid}&MeetingId=${btn.getAttribute('data-meetingid')}&IsCoach=${btn.getAttribute('data-meetingid') == this.UserPid ? true : false }`)
                    .then(response => response.json())
                    .then(data => {
                        if (data != null) {
                            if (data.meetingUrl != null && data.meetingUrl != "") {
                                btn.setAttribute("data-meetingurl", data.meetingUrl);
                            }
                        }
                    });
            }

            btn.addEventListener('click', (event) => {
                window.open(btn.getAttribute("data-meetingurl"), '_blank');
            });
        });

        let scheduleIdCheck = document.querySelector(".dateOfFeedbackSelect");
        if (scheduleIdCheck)
        {
        let valu = scheduleIdCheck.value;
        if (valu != null && valu != '' && valu > 0)
            MyClub.ClubFluencyFb(valu);
        }

        let dateOfFeedbackSelect = document.querySelector(".dateOfFeedbackSelect");
        if (dateOfFeedbackSelect)
        {
        dateOfFeedbackSelect.addEventListener('change', function (e) {
            document.querySelector('.feedbackDiv h5').innerHTML = "";
            document.getElementById('feedbackContent').innerHTML = "";
            document.getElementById('feedbackCoachName').innerHTML = "";
            let scheduleId = document.querySelector(".dateOfFeedbackSelect").value;
            if (scheduleId != null && scheduleId != '' && scheduleId > 0)
                MyClub.ClubFluencyFb(scheduleId);
        });
        }

        let reportMonthPdfIcon = document.querySelector(".reportMonthPdf .report-download-icon");
        if (reportMonthPdfIcon) {
            reportMonthPdfIcon.addEventListener('click', function (e) {
                let valSelect = document.getElementById('reportMonthlyDrop').value;
                if (valSelect != null)
                    MyClub.FluencyRepPdf(valSelect);
            });
        }

        let reportMonthlyDrop = document.getElementById("reportMonthlyDrop");
        if (reportMonthlyDrop) {
            reportMonthlyDrop.addEventListener('change', function () {
                let selectVal = reportMonthlyDrop.value;
                let selectText = reportMonthlyDrop.options[reportMonthlyDrop.selectedIndex].text;
                if (selectVal != null) {
                    let splitDate = selectVal.split(',');
                    document.querySelector('.reportMonthContent h5').innerHTML = "Your Fluency Report Card of " + selectText + " Month";
                    document.querySelector('.reportMonthPdf .report-file-name p b').innerHTML = "Fluency Report - " + selectText.replace(" - ", " ") + ".pdf";
                }
            });
        }
    },
    SubmitSlotBooking: function (eventScdId, clubid, clubScheduleDate,event) {
        Loader.show();
        fetch(`/api/MyClub/SubmitSlotBooking?eventScdId=${eventScdId}&userPid=${this.UserPid}&clubid=${clubid}&clubScheduleDate=${clubScheduleDate}`)
            .then(response => response.json())
            .then(data => {
                Loader.hide();
                if (data == 1) {
                    this.hideDiv(event);
                }
                else {
                    alert("Slot Booking Failed Please contact your admin...");
                    location.reload();
                }
            });
    },
    hideDiv: function (event) {
        event.target.parentNode.classList.add("hide");
        let parent = event.target.parentNode.parentNode;
        parent.querySelector("[data-id='reserve-slot-disabled']").classList.remove("hide")
    },
    ClubFluencyFb: function (scheduleId) {
        Loader.show();
        fetch(`/api/MyClub/GetClubFluencyReportHistory?userPid=${this.UserPid}&clubScheduleId=${scheduleId}`)
            .then(response => response.json())
            .then(data => {
                if (data != null) {
                    document.querySelector('.feedbackDiv h5').innerHTML = "Coach's feedback about your performance for " + data.meetingDateTimeString + " session, Topic : " + data.topic;
                    document.getElementById('feedbackContent').innerHTML = "";
                    let feedback = data.feedback.split('\n');
                    let feedBackArr = [];
                    let feedBackValue = "";
                    feedback.forEach((val) => {
                         val = "<p>" + val + "</p>";
                         feedBackArr.push(val);
                    });
                    if(feedBackArr.length > 0){ feedBackValue = feedBackArr.join(" ") };
                    feedback = feedBackValue.split(' ');
                    feedBackArr = [];
                    feedback.forEach((val) => {
                         if(val.includes("http")) { val = "<a href='" + val.replace("<p>","").replace("</p>","") + "' target='_blank'>" + val.replace("<p>","").replace("</p>","") + "</a>"; }
                         feedBackArr.push(val);
                    });
                    if(feedBackArr.length > 0){ feedBackValue = feedBackArr.join(" ") };
                    document.getElementById('feedbackContent').innerHTML = feedBackValue;
                    document.getElementById('feedbackCoachName').innerHTML = data.coachName;
                }
                else {
                    document.getElementById('feedbackContent').innerHTML = "No Feedback found...";
                    document.getElementById('feedbackCoachName').innerHTML = "Not Found...";
                }

            });
        Loader.hide();
    },
    async FluencyRepPdf(val) {
        Loader.show();
        let valSplit = val.split(',');
        let url = `/api/DownloadPdf/Fluency-Monthly-Report?MM=${valSplit[0]}&YYYY=${valSplit[1]}&UserPid=${this.UserPid}`;
        window.location.href = url;
        Loader.hide();
    },
}
export default MyClub;
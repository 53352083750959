var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg-header-pink pt-lg-5 pt-4" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _vm._m(1),
        _vm._v(" "),
        _vm.Sessions.length > 0
          ? _c("div", { staticClass: "row mx-0 px-2 py-4" }, [
              _c("div", { staticClass: "col-12 col-lg-4 px-0" }, [
                _c("h6", [_vm._v(_vm._s(_vm.Sessions[0].title))]),
                _vm._v(" "),
                _c("h6", { staticClass: "text-muted" }, [
                  _c("img", {
                    staticClass: "circle-dp mr-2",
                    attrs: {
                      src: _vm.Sessions[0].courseimageurl,
                      alt: _vm.Sessions[0].tutorname
                    }
                  }),
                  _vm._v(
                    "\n            Classes by " +
                      _vm._s(_vm.Sessions[0].tutorname) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(2)
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "body-container bg-grey-pink-light" }, [
      _c("div", { staticClass: "tab-switcher" }, [
        _c("div", { staticClass: "d-block d-lg-none" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("ul", { staticClass: "nav nav-tabs row mx-0" }, [
              _c(
                "li",
                {
                  staticClass: "nav-item px-0 col-3",
                  on: {
                    click: function($event) {
                      return _vm.switchtotab("Sessions")
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      class:
                        _vm.ActivePage == "Sessions"
                          ? "nav-link active"
                          : "nav-link",
                      attrs: { "data-toggle": "tab" }
                    },
                    [
                      _c("span", { staticClass: "subtitle" }, [
                        _vm._v("Sessions")
                      ])
                    ]
                  )
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-2 pr-0 border-right d-none d-lg-block" },
              [
                _c(
                  "ul",
                  { staticClass: "nav nav-tabs mt-5 w-100 d-flex flex-column" },
                  [
                    _c(
                      "li",
                      {
                        class:
                          _vm.ActivePage == "Sessions"
                            ? "nav-item active"
                            : "nav-item",
                        on: {
                          click: function($event) {
                            return _vm.switchtotab("Sessions")
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            class:
                              _vm.ActivePage == "Sessions"
                                ? "side-nav-link active"
                                : "side-nav-link",
                            attrs: { "data-toggle": "tab" }
                          },
                          [
                            _c("span", { staticClass: "subtitle" }, [
                              _vm._v("Sessions ")
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-10 px-0" }, [
              _c(
                "div",
                { staticClass: "tab-content row mx-0 mt-3" },
                [
                  this.ActivePage == "Sessions"
                    ? _c("CourseSessions")
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fixed-top d-block d-lg-none" }, [
      _c("div", { staticClass: "progress ml-auto" }, [
        _c("div", {
          staticClass: "progress-bar",
          staticStyle: { width: "40%" },
          attrs: {
            role: "progressbar",
            "aria-valuenow": "25",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { "data-toggle": "tab", href: "/MyClasses" } }, [
      _c("i", { staticClass: "far fa-arrow-left d-sm-none" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-8 pt-2 px-0" }, [
      _c("div", { staticClass: "progress ml-auto" }, [
        _c("div", {
          staticClass: "progress-bar",
          staticStyle: { width: "40%" },
          attrs: {
            role: "progressbar",
            "aria-valuenow": "25",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
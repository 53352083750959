var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade show active w-100",
      attrs: { id: "sessions" }
    },
    [
      _vm._l(_vm.Sessions, function(session, index) {
        return _c(
          "div",
          { key: index, staticClass: "row border-bottom pb-3 mx-0" },
          [
            _c("div", { staticClass: "col-2 date caption active" }, [
              _vm._v(_vm._s(session.displayday))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-9 pr-0" }, [
              _c(
                "div",
                {
                  staticClass:
                    "row mx-0 flex-column py-3 rounded-4 px-3 bg-white shadow-sm"
                },
                [
                  session.nextsessionindex != session.sessionno
                    ? _c("div", { staticClass: "caption mb-3" }, [
                        _vm._v(
                          "\n           Session " +
                            _vm._s(session.sessionno) +
                            "\n         "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  session.nextsessionindex == session.sessionno
                    ? _c("div", { staticClass: "caption mb-3" }, [
                        _vm._v(
                          "\n           Next Session " +
                            _vm._s(session.sessionno) +
                            "\n         "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h4", { staticClass: "mb-2" }, [
                    _vm._v(_vm._s(session.title))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "caption text-muted mb-2" }),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  session.enablejoinclass == 0 &&
                  session.sessionId > 0 &&
                  (session.nextsessionindex > session.sessionno ||
                    session.nextsessionindex == 0)
                    ? _c(
                        "a",
                        {
                          staticClass: "text-primary d-flex align-items-center",
                          on: {
                            click: function($event) {
                              return _vm.viewrecordingurl(session)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "far fa-play-circle fa-2x" }),
                          _vm._v(" "),
                          _vm._m(0, true)
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  session.nextsessionindex == session.sessionno
                    ? _c("div", { staticClass: "join-card" }, [
                        _c("div", { staticClass: "session-in px-3 py-2" }, [
                          _c("p", { staticClass: "subtitle fw-500" }, [
                            _c("span", { staticClass: "fw-400" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(session.displaydatedayhrs)
                              ),
                              _c("br")
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              session.enablejoinclass == 1
                                ? "join-link active"
                                : "join-link"
                          },
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.JoinClass(session)
                                  }
                                }
                              },
                              [_vm._v(" Join")]
                            ),
                            _vm._v(" "),
                            _c("i", { staticClass: "fas fa-ellipsis-v" })
                          ]
                        )
                      ])
                    : _vm._e()
                ]
              )
            ])
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { class: _vm.showpopup ? "modal show" : "modal" }, [
        _c("svg", { staticClass: "d-none" }, [
          _c("defs", [
            _c("g", { attrs: { id: "chevronBack" } }, [
              _c("path", {
                attrs: {
                  d:
                    "m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header align-items-center" }, [
              _c(
                "svg",
                {
                  staticClass: "svg-icon medium d-sm-none",
                  attrs: { viewBox: "0 0 64 64" },
                  on: {
                    click: function($event) {
                      return _vm.closepopup()
                    }
                  }
                },
                [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("Session recording")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.closepopup()
                    }
                  }
                },
                [_vm._v("\n             ×\n           ")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal-body ses-record",
                staticStyle: { height: "20rem" }
              },
              [
                _vm._l(_vm.recordingdetails, function(record, index) {
                  return _c("div", { key: index }, [
                    _c("div", [
                      _vm._v(
                        " Session Video -  " +
                          _vm._s(index + 1) +
                          "\n             "
                      ),
                      _c("video", {
                        attrs: {
                          controls: "",
                          src: record.url,
                          preload: "none"
                        }
                      })
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.showmsg
                  ? _c("div", [_vm._v("Recorded Session Not Available")])
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ml-2" }, [
      _vm._v("\n             View Session Recording\n             "),
      _c("p", { staticClass: "caption text-primary" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
﻿let Faq = {
    tabLists: document.querySelectorAll('.getIDFaq'),
    titleCard: document.querySelectorAll('.faq-title-card'),
    init: function () {
        this.titleCard.forEach((title, index) => {
            this.titleCard[index].addEventListener('click', function (e) {
                if (title.children[0].textContent === "Student") {
                    document.getElementById('initStudentNavActive').classList.add('active');
                    document.querySelector('.initStudentTabActive').style.display = 'block';
                }
                else {
                    document.getElementById('initInstructorNavActive').classList.add('active');
                    document.querySelector('.initInstructorTabActive').style.display = 'block';
                }
                if (title.parentElement.classList.contains('active')) {
                    title.parentElement.classList.remove('active');

                } else {
                    document.querySelectorAll('.faq-container').forEach((faqContainer, i) => {
                        faqContainer.classList.remove('active')
                    })
                    title.parentElement.classList.add('active');
                }
            })
        })

        this.tabLists.forEach((item, index) => {
            let getAttrID = this.tabLists[index].getAttribute('data-target');
            this.tabLists[index].addEventListener('click', () => {
                this.leftTabToggleAccordion(getAttrID, item)
            })
        })
    },
    leftTabToggleAccordion: function (tabId, leftNav) {

        this.tabLists.forEach((item, index) => {
            this.tabLists[index].classList.remove('active');
        })
        leftNav.classList.add('active');


        if (tabId !== null) {
            if (!tabId.includes('question')) {
                this.faqQuestions(tabId)
            } else {
                this.faqAnswers(tabId, leftNav)
            }
        }
    },
    faqQuestions: function (tabId) {
        document.querySelectorAll('.faq-body').forEach((tabBody, index) => {
            tabBody.style.display = 'none';
        });
        document.getElementById(tabId).style.display = 'block'
        document.querySelectorAll('.faq-answer').forEach((tabBody, index) => {
            tabBody.classList.remove('open')
        });
    },

    faqAnswers: function (tabId, leftNav) {

        var offsetId = document.documentElement.scrollTop || document.body.scrollTop;
        setTimeout(() => {
            window.scroll(0, offsetId)
        }, 1000)
        document.querySelectorAll('.faq-answer').forEach((tabBody, index) => {
            tabBody.classList.remove('open')
        });
        document.getElementById(tabId).classList.add('open')
        const leftNavKeepActive = leftNav.parentElement.parentElement.getAttribute('id');
        this.tabLists.forEach((item, index) => {
            if (this.tabLists[index].getAttribute('data-target') === leftNavKeepActive) {
                if (!item.classList.contains('active')) {
                    item.classList.add('active')
                }
            }
        })
    }
};
if (document.getElementById('hdnPageType') != null && document.getElementById('hdnPageType').value == "Faq") {
    Faq.init();
}
<template>
    <div class="panel reviewblock form default">
        <div class="head"><span class="panel-x f-icon"></span> Write Review</div>
        <div class="body">
            <div class="review-step-one">
                <template v-if="courses.length > 0">
                    <template v-if="courses.length == 1">
                        <span class="reviewneed">Review for <strong>{{courses[0].courseName}}</strong></span>
                    </template>
                    <template v-else>
                        
                        <label>Select the course for which you are writing review for  <strong>{{tutorName}}</strong> </label>
                        <div class="sdropdown" id="rvwneeddropdown" data-sdropdown="true" @click="onClickDropDown">
                            <a class="input sd-link f-icon" id="ddlselecteditem" tabindex="0">Select</a>
                            <div class="sd-menu review-category" >
                                <div class="menuhead">
                                    <span class="menuclose"><i>&times;</i>Select the service</span>
                                </div>
                                <ul id="ddlCourse">
                                    <!--@foreach (var courseItem in courses)
                                        {
                                        <li id="@courseItem.Key" data-value="@courseItem.Value"><a data-value="@courseItem.Key" tabindex="0"> @courseItem.Value </a></li>
                                        index = index + 1;
                                        }-->
                                    <li v-for="course in courses" :data-courseId="course.courseId" :data-courseName="course.courseName" @click="onCourseSelect"><a data-value="course.courseId" tabindex="0">{{course.courseName}}</a></li>
                                </ul>
                            </div>
                            <span class="error-text hide rvwddlerror">
                                Select the course
                            </span>
                        </div>
                    </template>
                </template>
             
                <div class="review">
                    <label>How would you rate <strong>{{tutorName}}</strong> from a scale of 1 to 5?</label>
                    <div class="rating">
                        
                        <span class="f-icon" v-for="i in 5" :id="i" @mouseover="onStarHover" @mouseleave="onStarLeave" @click="onStarClick"></span>
                        <strong style="display: none;"> Beware!</strong> <strong style="display: none;"> Hmm...not so good</strong> <strong style="display: none;"> Just okay</strong> <strong style="display: none;"> Pretty good</strong> <strong style="display: none;"> Superb!</strong>
                        <span class="error-text ratingerror hide">Select rating</span>
                    </div>

                </div>
                <div class="form-group">
                    <p>What would you like others to know about <strong>{{tutorName}}</strong>, based on your experience?</p>

                    <div class="outline-textarea input-group text-area">
                        <textarea v-model="shortDescription" @keyup="charCount" required="" class="pop" id="txtreviewShortDesc" name="txtreviewShortDesc" rows="3" ></textarea>
                        <label for="txtreviewShortDesc">Write your review here </label>
                        <span class="reviewhint">
                            Minimum 25 characters!
                        </span>
                        <p class="count"> <em id="revtacommentcounterenq"> {{shortDescriptionLength}} / 1000</em> Characters</p>
                        <span class="error-text hide revwdeserror">
                            Write a review of minimum 25 characters
                        </span>
                    </div>
                </div>
                <div class="foot">
                    <button class="button primary postreview GAQ_C_WRITEREVIEW" data-value="@datavalue" @click="onClickSubmit">submit</button>
                </div>
            </div>
            <div class="review-step-two hide">
                <div class="success">
                    <p>@_thankyoumsg</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "WriteReview",
        data() {
            return {
                reviewMetaModel: null,
                profileData: null,
                tutorName: null,
                businessId: null,
                ratingStars: null,
                ratingText: null,
                currentRating: 0,
                shortDescription: null,
                shortDescriptionLength: 0,
                courses: [],
                reviewCourseId: null,
                reviewCourseTitle: null,
                errorStatus: null,

            }
        },
        beforeMount: function () {
            var courseCards = document.querySelectorAll('.course-wrap .courseCards');
            courseCards.forEach((card, index) => {
                console.log("id ", card.getAttribute('data-courseid'), "name ", card.getAttribute('data-courseName'));
                this.courses[index] = { "courseId": card.getAttribute('data-courseid'), "courseName": card.getAttribute('data-courseName') };
            })
            console.log("The courses are ", this.courses);
        },
        mounted: function () {
            this.businessId = document.getElementById('hdnBusinessId').value;

            /*this.reviewMetaModel = JSON.parse(reviewMetaModel.innerHTML);*/
        /*this.profileData = this.reviewMetaModel.ProfileData;*/

            this.tutorName = document.getElementById('hdnBusinessName').value;;
            console.log("The profile data ", this.profileData);

            this.ratingStars = document.querySelectorAll('.rating .f-icon');
            console.log("the stars list", this.ratingStars);

            this.ratingText = document.querySelectorAll('.rating STRONG');
            console.log("The rating texts are ", this.ratingText);

            
            
        },
        methods: {
            onStarHover: function (event) {
                this.disableStar();
                var position = event.target.getAttribute('id');
                console.log("The position is ", position, this.currentRating);
                console.log("on hover");
                this.setRating(position);
                
            },
            onStarLeave: function(event) {
                var position = event.target.getAttribute('id');
                console.log("The position is ", position, this.currentRating);
                    console.log("on leave");
                this.disableStar();
                
            },
            setRating: function (position) {
                
                this.ratingStars.forEach(function (star) {
                    star.classList.remove('highlight');
                })
                for (let i = 0; i < position; i++) {
                    this.ratingStars[i].classList.add('highlight');
                }

                this.ratingText.forEach(function (text) {
                    text.style.display = 'none';
                })
                if (position != 0) {
                    this.ratingText[position - 1].style.display = 'block';
                }
                    
            },
            disableStar: function () {
                this.setRating(this.currentRating);
            },
            onStarClick: function (event) {
                var position = event.target.getAttribute('id');
                this.currentRating = position;
                this.setRating(position);   
            },
            charCount: function () {
                this.shortDescriptionLength = this.shortDescription.length;
               
            },
            onClickDropDown: function (event) {
                
                var dropdown = document.querySelector(".review-category");
                console.log(dropdown)
                if (document.querySelector(".review-category").style.display == 'block')
                    document.querySelector(".review-category").style.display = 'none'
                else
                    document.querySelector(".review-category").style.display = 'block'
                
            },
            onCourseSelect: function (event) {
                console.log(event.currentTarget);
                this.reviewCourseTitle = event.currentTarget.getAttribute('data-courseName');
                this.reviewCourseId = event.currentTarget.getAttribute('data-courseId');
                document.querySelector('.input.sd-link.f-icon').innerText = this.reviewCourseTitle;
            },
            onClickSubmit: function () {
                if (this.validateInputs()) {
                    let tempCourseId = 100262;
                    if (courses.length == 1)
                        this.reviewCourseId = this.courses[0].courseId;
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            UserPid: 0,
                            Review: this.shortDescription,
                            Rating: this.currentRating,
                            NeedMetBusinessId: this.businessId,
                            CourseId: tempCourseId,
                            CourseTitle: "Database Management System from scratch",
                        })
                    }
                    console.log("The request options ", requestOptions);
                    fetch("http://localhost:61010/api/reviews/save-course-review", requestOptions)
                        .then(response => response.json())
                        .then(data => console.log("the response", data));
                }
                
            },
            validateInputs: function () {
                let errorStatus = "";
                if (this.courses.length > 1) {
                    if (document.querySelector('.input.sd-link.f-icon').innerText == "Select") {
                        document.querySelector(".rvwddlerror").classList.remove('hide');
                        console.log("first if ");
                        errorStatus = "error";
                    } else document.querySelector(".rvwddlerror").classList.add('hide');
                }
                console.log("The courses", this.courses);
                if (this.currentRating == 0) {
                    document.querySelector(".ratingerror").classList.remove('hide');
                    errorStatus = "error";
                    console.log("second if ");
                } else document.querySelector(".ratingerror").classList.add('hide');

                console.log("The rating ", this.currentRating);

                if (!this.shortDescription || this.shortDescriptionLength < 25) {
                    document.querySelector(".revwdeserror").classList.remove('hide');
                    console.log("third if ");
                    errorStatus = "error";
                } else document.querySelector(".revwdeserror").classList.add('hide');
                console.log("The short description ", this.shortDescription);
                console.log("The error status ", !(errorStatus == "error"))
                return !(errorStatus == "error"); 
            }
        }
    }
  
</script>
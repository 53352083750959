
<template>
    <!--<div class="modal-backdrop fade" :class="{show: showpopup}"></div>-->
   <div v-if="isAutoLogin==false">
    <div :class="showpopup?'modal show':'modal'">
        <svg class="d-none">
            <defs>
                <g id="chevronBack">
                    <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" />
                </g>
            </defs>
        </svg>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <!--<div class="progress">
                <div role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" class="progress-bar" style="width: 66.66%;"></div>
            </div>-->
                <div class="modal-header align-items-center">
                    <svg @click="hidepopup()" class="svg-icon medium d-sm-none signinbk" viewBox="0 0 64 64" >
                        <use xlink:href="#chevronBack" />
                    </svg>
                    <h5>{{headerText}}</h5>
                    <button type="button" class="close" @click="hidepopup()">&times;</button>
                </div>
                <!--<div class="bg-header-wizard">
                    <div class="container position-relative">
                        <div class="header-between">
                            <div class="d-flex flex-column ml-1">
                                <h2 class="lang-title">{{headerText}} </h2>
                            </div>
                            <a class="mr-2" @click="hidepopup()">
                                <i class="far fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>-->
            <div class="modal-body">
                <SignIn v-if="currentStep == 'SignIn'" :hidepopup="hidepopup" :onKeyDown="onKeyDown" :userDetails="userDetails" :invalidMobileNo="invalidMobileNo" :mobileNoErrorText="mobileNoErrorText" :signInSubmit="signInSubmit" :openSignUp="openSignUp" :errorText="errorText">
                </SignIn>
                <CommonVerification v-if="currentStep == 'Otp'" :hidepopup="hidepopup" :userDetails="userDetails" :invalidOtp="invalidOtp" @post-validateCode="validateCode">
                </CommonVerification>
                <SignInOtpVerified v-if="currentStep == 'OtpVerified'">
                </SignInOtpVerified>
                <EmailScreen v-if="currentStep == 'email'" :isValidEmail="isValidEMail" :userPid="userPid" :loginProcess="loginProcess">
                </EmailScreen>
            </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
    import SignIn from "./SignIn.vue";
    import CommonVerification from "../Otp/CommonVerification.vue";
    import SignInOtpVerified from "./OtpVerified.vue";
    import Loader from "../../wwwroot/js/Loader.js";
    import EmailScreen from "./EmailScreen.vue";

    export default {
        name: "SignInModal",
        props: {

        },
        data() {
            return {
                currentStep: 'SignIn',
                showpopup: true,
                invalidOtp: false,
                invalidMobileNo: false,
                mobileNoErrorText: "Please enter your phone number !",
                errorText: "",
                userPid: "",
                verifyMobileResponseData: {},
                regexp: {
                    alpha: /^[a-z0-9\.\s]+$/i,
                    number: /[^0-9]/g,
                    email: /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i,
                    mobile1: /^[9|7|6|8]\d{8}[\d{1}]$/i,
                    internationalmobile: /^[0-9]{6,14}$/
                },
                userDetails: {
                    UserName: '',
                    EmailId: '',
                    MobileNumber: '',
                    CountryCode: '+91',
                    VerificationCode: '',
                    IsMissedCallVerfication: false,
                    IsReSendCode: false,
                    IsChangeMobile: false
                },
                isAutoLogin:true
            }
        },
        methods: {
            isValidName(name) {
                return (this.regexp.alpha.test(name) || (name.match(/\./g) != null && name.match(/\./g).length > 3) || (name.replace(/[^a-zA-Z]+/g, "").length <= 2));
            },
            isValidEMail(email) {
                return this.regexp.email.test(email);
            },
            isValidMobileNumber(mobileno, countrycode) {
                if (countrycode == undefined || countrycode == "" || countrycode == "91" || countrycode == "+91") {
                    return (this.regexp.mobile1.test(mobileno));
                }
                else {
                    return (this.regexp.internationalmobile.test(mobileno));
                }
            },
            hidepopup() {
                if (this.currentStep == 'email') {
                    this.loginProcess();
                }

                this.showpopup = false;
                document.body.classList.remove("overflow-hidden");
                var vueBaseDiv = document.querySelector("#signin-base-div");
                vueBaseDiv.innerHTML = "";
                var div = document.createElement("div");
                div.id = "SignInDiv";
                vueBaseDiv.appendChild(div);
                document.getElementById("hdnLoginUrlHash") ? document.getElementById("hdnLoginUrlHash").value = "" : "";
                document.getElementById("hdnShortlist") ? document.getElementById("hdnShortlist").value = "" : "";
            },
            onKeyDown(e, refid) {
                if (refid != '' && refid == 'mobileno') { if (this.userDetails.MobileNumber.length > 0) this.invalidMobileNo = false; }
                var key = e.which;
                if (key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
                    return true;
                }

                e.preventDefault();
                return false;
            },
            signInSubmit() {

                if (this.userDetails.MobileNumber == "") {
                    this.invalidMobileNo = true;
                    this.mobileNoErrorText = "Please enter your phone number !";
                }
                else if (!this.isValidMobileNumber(this.userDetails.MobileNumber, this.userDetails.CountryCode)) {
                    this.invalidMobileNo = true;
                    this.mobileNoErrorText = "Please enter valid phone number !";
                }
                else {
                    this.invalidMobileNo = false;
                    this.signInApi(this.userDetails);
                }
            },
            validateCode(clientData) {
                if (clientData.IsReSendCode) {
                    this.reSendCodeApi(clientData);
                }
                else if (clientData.IsChangeMobile) {
                    this.userDetails.MobileNumber = "";
                    this.invalidMobileNo = false;
                    this.currentStep = "SignIn";
                }
                else {
                    this.verifyMobileApi(clientData);
                }
                //alert(JSON.stringify(clientData));
            },
            signInApi(clientData) {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(clientData)
                }
                Loader.showAboveModal();
                fetch("/api/userlogin/SignIn", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        
                        if (data.mobileVerificationStatus == "VerificationCodeSent") {
                            this.currentStep = "Otp";
                        }
                        else if (data.message != null && data.message != "") {
                            this.errorText = data.message;
                        }
                        Loader.hideAboveModal();
                    });
            },
            verifyMobileApi(clientData) {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(clientData)
                }

                Loader.showAboveModal();
                fetch("/api/userlogin/VerifyMobile", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        
                        
                        if (data.mobileVerificationStatus == "ValidNumber") {
                            this.verifyMobileResponseData = data;
                            if (data.userInfo && data.userInfo.emailAddress && data.userInfo.emailAddress.includes("@")) {
                                this.loginProcess();
                            } else {
                                Loader.hideAboveModal();
                                
                                this.userPid = data.userPid;
                                this.currentStep = "email";                               
                            }
                        } else {
                            Loader.hideAboveModal();
                            this.invalidOtp = true;
                        }
                    });
            },
            reSendCodeApi(clientData) {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(clientData)
                }
                fetch("/api/userlogin/ReSendCode", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if (data.mobileVerificationStatus == "VerificationCodeSent") {
                            this.currentStep = "Otp";
                        }
                    });
            },
            openSignUp() {
                var hdnLoginUrlHash = document.getElementById("hdnLoginUrlHash").value;
                this.hidepopup();
                document.getElementById("hdnLoginUrlHash").value = hdnLoginUrlHash;
                //document.getElementById("signUpMobile").click();
                document.querySelectorAll(".openClubForm")[0].click();
            },
            getRedirectUrl(isActiveInstructor, clubId) {
                var url = window.location.href;
                if (document.getElementById("hdnLoginNextUrl") != null && document.getElementById("hdnLoginNextUrl").value != "") {
                    url = document.getElementById("hdnLoginNextUrl").value;
                }
                else if (document.getElementById("hdnLoginUrlHash") != null && document.getElementById("hdnLoginUrlHash").value != "") {
                    if (url.indexOf("?") > -1) {
                        url = url.replace('?', '#' + document.getElementById("hdnLoginUrlHash").value + '?');
                    }
                    else {
                        url += '#' + document.getElementById("hdnLoginUrlHash").value;
                    }
                }
                else if (clubId > 0) {
                    url = "/";
                }
                else if (isActiveInstructor) {
                    url = "https://instructors.capshine.com/";
                }
                return url;
            },
            getHeaderText() {
                if (this.currentStep == "SignIn") {
                    return "Sign in to capshine"
                }
                if (this.currentStep == "Otp") {
                    return "Verify your Phone number"
                }
                if (this.currentStep == "email") {
                    return "Enter your email"
                }
            },
            loginProcess() {
                let data = this.verifyMobileResponseData;
                this.currentStep = "OtpVerified";
                let hdnLoginUrlHash = "";
                let shortlistCourseId = "";
                if (document.getElementById("hdnLoginUrlHash") != null && document.getElementById("hdnLoginUrlHash").value != "")
                    hdnLoginUrlHash = document.getElementById("hdnLoginUrlHash").value;
                if (document.getElementById("hdnShortlist") != null && document.getElementById("hdnShortlist").value != null) {
                    shortlistCourseId = document.getElementById("hdnShortlist").value
                }
                this.hidepopup();
                if (document.getElementById("hdnUserPid"))
                    document.getElementById("hdnUserPid").value = data.userPid;
                Loader.hideAboveModal();
                if (hdnLoginUrlHash != '') {
                    if (hdnLoginUrlHash == "writereview")
                        document.querySelector('.write-review-btn').click();
                    if (hdnLoginUrlHash == "clubsignup") {
                        var inpName = document.querySelector("#inpName");
                        inpName.value = data.userInfo.firstName;
                        if (inpName.value != "")
                            inpName.disabled = true;
                        var inpMobile = document.querySelector("#inpMobile");
                        inpMobile.value = data.userInfo.mobileNumber;
                        if (inpMobile.value != "")
                            inpMobile.disabled = true;
                        var inpEmail = document.querySelector("#inpEmail");
                        inpEmail.value = data.userInfo.emailAddress;
                        if (inpEmail.value != "")
                            inpEmail.disabled = true;
                        document.querySelector(".openClubForm").click();
                    }
                }

                else if (shortlistCourseId != "") {
                    let shortlistBtn = document.querySelector(`[data-shortlist='${shortlistCourseId}']`);
                    shortlistBtn.click();
                }
                //else if (data.isActiveInstructor)
                //window.location.href = "https://instructors.capshine.com/";
                else {
                    var url = this.getRedirectUrl(data.isActiveInstructor, data.clubId);
                    window.location.href = url;
                }
            },

             getsessionType(url) {
      var sessiontype = 0;

      if (url.toLocaleLowerCase().indexOf("joinclass") > -1) {
        sessiontype = 1;
      } else if (
        url.toLocaleLowerCase().indexOf("practisesession") > -1
      ) {
        sessiontype = 2;
      } else if (
       url.toLocaleLowerCase().indexOf("groupcalling") > -1
      ) {
        sessiontype = 3;
      }
      else if (
       url.toLocaleLowerCase().indexOf("liveclass") > -1
      ) {
        sessiontype = 5;
      }
       else if (
       url.toLocaleLowerCase().indexOf("talktimemainroom") > -1
      ) {
        sessiontype = 6;
      }
       else if (
       url.toLocaleLowerCase().indexOf("talktimebreakuproom") > -1
      ) {
        sessiontype = 7;
      }

      
      return sessiontype;
    },

    getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
},

    AutoLoginifSourceIsFromAgora(){
        
     var url = this.getRedirectUrl(false);
     var sessionType = this.getsessionType(url);
     if(sessionType==0){
         this.isAutoLogin=false;
     }
   else if (sessionType > 0) { // find whether source is from agora session
     var mobileno =this.getParameterByName("mobileno",url);
    if (mobileno != null && mobileno != undefined) {          // force auto login
    var getcourseurl = "/api/userlogin/AutoLogin?MobileNumber="+ mobileno;
    this.isloading = true;
    fetch(getcourseurl, { method: "GET" })
      .then((responseJson) => responseJson.json())
      .then((response) => {  
        if (response !== null) {
             window.location.href = url;
        } else {
      
        }
      });
      } else {
           this.isAutoLogin=false;   /// Source is fronm Agora page but  not from recording user config     
      }
    }
    }
            
        },
        mounted: function () {
             this.AutoLoginifSourceIsFromAgora();   
        },
        components: {
            SignIn: SignIn,
            CommonVerification: CommonVerification,
            SignInOtpVerified: SignInOtpVerified,
            EmailScreen: EmailScreen,
        },
        computed: {
            headerText: function () {
                return this.getHeaderText();
            }
        }

    }
</script>

<template>
    <!-- Content goes here -->

    <div>
        <button type="button" class="close cabso" @click="close()">&times;</button>

        <div class="row mx-0">
            <!-- Left block start -->
            <DisplayUserInfor :instructorDetails="instructorDetails" />
            <!-- Left block end -->
            <!-- Right block start -->
            <div class="col col-lg-6 py-3">
                
                <div class="qablock">
                    <!-- Title start -->
                    <h4>When do you want to start the classes?</h4>
                    <!-- Title end -->
                    <div class="intent-block">
                        <div v-for="(questionAnswer, index) in QuestionAndAnswers"
                             :key="'q' + index">
                            <label>
                                <span class="required">*</span>{{ questionAnswer.Question }}
                            </label>
                            <div id="questionOne">
                                <span :class="answer.ischecked?'chip checked':'chip'"
                                      v-on:click="
                  selectedAnswer(questionAnswer.QuestionId, answer.AnswerId,index,j)
                "
                                      v-for="(answer, j) in questionAnswer.Answers"
                                      :key="j">
                                    {{ answer.AvailableAnswer }}
                                </span>
                            </div>
                            <span class="error-text"
                                  v-bind:id="'ques' + questionAnswer.QuestionId"
                                  style="display: none">Please choose a specific answer</span>
                            <!-- <span class="error-text" style="position: relative;">Please choose a specific answer</span> -->
                        </div>
                    </div>
                </div>
                <div class="next-button">
                    
                    <div> </div>
                        <a class="btn btn-primary" @click="submitQuestionAnswer()">
                            Submit
                        </a>
                   
                </div>
            </div>
            <!-- Right  block end -->
        </div>
        </div>
</template>


<script>
import DisplayUserInfor from "./DisplayUserInfor.vue";
export default {
  name: "QuestionAndAnswer",
  props: {
    QuestionAndAnswers: {},
    nextStep: { type: Function },
    instructorDetails: {},
    closePopup: { type: Function },
    selectedAnswers: {},
  },
  methods: {
    close() {
      this.closePopup();
    },
  
    selectedAnswer(questionId, AnswerId,i,j) {
      if (
        (questionId != null || questionId != "" || questionId != undefined) &&
        (AnswerId != null || AnswerId != "" || AnswerId != undefined)
      ) {

            // for(var row=0;row<this.QuestionAndAnswers.length;row++){
            //    this.QuestionAndAnswers[i].ischecked=false;
            //      for(var nesrow=0;nesrow<this.QuestionAndAnswers[i].Answers.length;nesrow++){                
            //       this.QuestionAndAnswers[i].Answers[nesrow].ischecked=false;
            //     }
            // }

        this.$emit("set-selectedAnswers", questionId, AnswerId);
         
         this.QuestionAndAnswers[i].ischecked=true;
          this.QuestionAndAnswers[i].Answers[j].ischecked=true;

             for(var row=0;row<this.QuestionAndAnswers.length;row++){
              // this.QuestionAndAnswers[i].ischecked=false;
                 for(var nesrow=0;nesrow<this.QuestionAndAnswers[i].Answers.length;nesrow++){                
                   if(nesrow!=j)
                  this.QuestionAndAnswers[i].Answers[nesrow].ischecked=false;
                }
            }

         // this.QuestionAndAnswers[i].Answers[j].ischecked=true;
      }
      //  this.proceedNextStep();
    },
    submitQuestionAnswer() {
      // clear error text
      this.selectedAnswers.filter((item) => {
        document.getElementById("ques"+item.Pk_QuestionId).style.display = "none";
      });

      let questionIds = this.selectedAnswers.filter((item) => {
        return item.Pk_AnswerId == "";
      });

      if (questionIds.length == 0) {        
        this.nextStep();
      } else {
        questionIds.forEach((item) => {
          document.getElementById("ques" + item.Pk_QuestionId).style.display =
            "block";
        });
      }
    },
  },
  components:{
          "DisplayUserInfor":DisplayUserInfor
        }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "step show",
      class: _vm.introClassName,
      attrs: { id: "step1" }
    },
    [
      _c("div", { staticClass: "group" }, [
        _c("div", { staticClass: "quiz-qustion-box mt-5" }, [
          _c("div", { staticClass: "question-explain-title" }, [
            _vm.introName == "FillTheGap"
              ? _c("h5", { staticClass: "quiz-title" }, [
                  _vm._v("Fill the Gap contains")
                ])
              : _c("h5", { staticClass: "quiz-title" }, [
                  _vm._v("Quiz contains")
                ]),
            _vm._v(" "),
            _c("ul", [
              _vm.introName == "FillTheGap"
                ? _c("li", [
                    _vm._v("A sentence with a word blank to be chosen")
                  ])
                : _c("li", [_vm._v("A sentence with a word boldened")]),
              _vm._v(" "),
              _c("li", [_vm._v("A question")]),
              _vm._v(" "),
              _c("li", [_vm._v("4 Options")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "right-answer-box mt-3" }, [
            _c("div", { staticClass: "right-answer-title" }, [
              _vm.introName == "FillTheGap"
                ? _c("h5", [
                    _vm._v("Every right answer you earn minimum of 50 points")
                  ])
                : _c("h5", [_vm._v("Every right answer you earn 100 points")]),
              _vm._v(" "),
              _c("p", [_vm._v("No points for wrong answer")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "caps-image-count" }, [
              _c("img", {
                staticClass: "right-cap-icon",
                attrs: {
                  src: "https://lscdn.blob.core.windows.net/elearn/quiz-cap.png"
                }
              }),
              _vm._v(" "),
              _vm.introName == "FillTheGap"
                ? _c("span", { staticClass: "quiz-badge-count-box" }, [
                    _vm._v("50")
                  ])
                : _c("span", { staticClass: "quiz-badge-count-box" }, [
                    _vm._v("100")
                  ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text-center mt-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary next-form-btn mt-3 mb-3",
                attrs: { type: "button", id: "next", tabindex: "-1" },
                on: { click: _vm.onClickStart }
              },
              [_vm._v("\n          Start\n        ")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <button type="button" class="close cabso mr-2 mt-2" @click="close()">&times;</button>



        <div class="row mx-0">
            <!-- Left block start -->
            <DisplayUserInfor :instructorDetails="instructorDetails" />
            <!-- Left block end -->
            <!-- Right block start -->
            <div class="col col-lg-6">
                
                <div class="form course-sign">
                   
                        <h4 class="pb-3">Verify your Phone number</h4>
                    
                    <h5>Enter the OTP</h5>
                   
                    <h6 class="text-muted">
                        We sent an OTP to your phone number<br /> {{courseSignUpClientData.CountryCode}}-{{courseSignUpClientData.MobileNo}}
                    </h6>
                        <!-- <a class="link caption col-4" @click="editnumber()">Change Number</a> -->

                        <span class="link caption" @click="editnumber()">Change Number</span>
                 

                    <div class="otp-form">
                        <div class="otp-group otp">

                            <div class="single-input">
                                <div class="inputOtpCenter">
                                    <div class="inputOtp-group">
                                        <span class="box-1"></span>
                                        <span class="box-2"></span>
                                        <span class="box-3">
                                        </span><span class="box-4"></span>

                                        <span class="lastbar"></span><input type="number"
                                                                            @keydown="onKeyDown($event)" ref="code1" v-model="verificationCode" maxlength="4">
                                    </div>
                                </div>
                            </div>
                            <span :class="invalidOtp ? 'error-text show' : 'error-text'">
                                {{
            verificationCodeErrorText
                                }}
                            </span>

                            <!-- <div class="md-field">
                          <input
                            pattern="\d*"
                            id="txtcode1"
                            @keyup="goToNextInput($event, 1)"
                              @keydown="onKeyDown($event)"
                            tabindex="1"
                            maxlength="1"
                            v-model="code1"
                          />
                        </div>
                        <div class="md-field">
                          <input
                            pattern="\d*"
                            id="txtcode2"
                            @keyup="goToNextInput($event, 2)"
                            @keydown="onKeyDown($event)"
                            tabindex="2"
                            maxlength="1"
                            v-model="code2"
                          />
                        </div>
                        <div class="md-field">
                          <input
                            pattern="\d*"
                            id="txtcode3"
                            @keyup="goToNextInput($event, 3)"
                            @keydown="onKeyDown($event)"
                            tabindex="3"
                            maxlength="1"
                            v-model="code3"
                          />
                        </div>
                        <div class="md-field">
                          <input
                            pattern="\d*"
                            id="txtcode4"
                            @keyup="goToNextInput($event, 4)"
                            @keydown="onKeyDown($event)"
                            tabindex="4"
                            maxlength="1"
                            v-model="code4"
                            type="number"
                          />
                        </div> -->
                        </div>

                        <label class="caption">
                            Resending OTP in 00:{{ countDown }}
                            <a v-if="resetotp" class="link" @click="resendotp()">| Resend now</a>
                        </label>
                    </div>

                    <div class="my-4" v-if="courseSignUpClientData.CountryCode==91">
                        <div class="text-center separator">
                            <h6>
                                <span>&nbsp; OR &nbsp;</span>
                            </h6>
                        </div>
                    </div>
                    <div class="shadow-sm rounded p-3" v-if="courseSignUpClientData.CountryCode==91">
                        <!-- <label class="mb-2">
                      Give a missed call from your phone to <br />
                      1800-122-222
                    </label> -->


                        <label class="mb-2">
                            Give a missed call from your phone to <br />
                            <a class="link" href="tel:1800-120-457-896">  1800-120-457-896 </a>
                        </label>


                        <a v-on:click="missedCallClick()" class="btn btn-outline-primary">Click here after call</a>
                    </div>
                </div>
            </div>
            <!-- Right  block end -->
        </div>
    </div>
</template>
<script>
import DisplayUserInfor from "./DisplayUserInfor.vue";
import SulGA from "../../wwwroot/js/Ga.js";
export default {
  name: "OTPVerification",
  props: {
    instructorDetails: {},
    selectedDate: "",
    courseSignUpClientData: {},
    nextStep: { type: Function },
    invalidOtp: { type: Boolean },
    closePopup: { type: Function },
    resendotptime:false
  },
  watch: {
    // resendotptime: function (response) {
    //    alert(6);
    //   this.countDownTimer();
    //   this.$refs.code1.focus();
    // },
    invalidOtp: function (response) {
      debugger;
      if (response == true) {
        this.verificationCodeErrorText = "Please enter valid OTP";
      }
      this.$refs.code1.focus();
    },

  verificationCode: function(vcode) {
  if (vcode.length >= 4) {
      this.onSubmit();
  }
    }
  },
  data() {
    return {
      verificationCode: "",
      code1: "",
      code2: "",
      code3: "",
      code4: "",
      verificationCodeErrorText:"Please enter valid OTP",
      isFormvalid: true,
      countDown:30,
      resetotp: false,
      resentitreation:0
    };
  },
  mounted() {
    //  SulGA.init();
    // SulGA.pushPageView();
    this.$refs.code1.focus();
   // document.getElementById("txtcode1").focus();
    this.countDownTimer();
  },
  //      watch: {
  //      verificationCode: function(vcode) {
  // if (vcode.length >= 4) {
  //     this.onSubmit();
  // }
  //   }
  //      },
  methods: {
      onKeyDown(evt) {
     if (this.verificationCode.length >= 4) {
           if (evt.keyCode >= 48 && evt.keyCode <= 90) {
        evt.preventDefault();
        return;
      }
    }
  },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          if (this.countDown == 0) {
             this.resetotp = true;
          }
          this.countDownTimer();
        }, 1000);
      }
    },
    close() {
      this.closePopup();
    },
    proceedNextStep() {
      this.nextStep();
    },
    // onKeyDown(event) {
    //     if (this.verificationCode.length >= 4) {
    //         if (event.keyCode >= 48 && event.keyCode <= 90) {
    //             event.preventDefault();
    //             return;
    //         }
    //     }
    // },

    getKeyCode(str) {
      return str.charCodeAt(str.length - 1);
    },

    goToNextInput(e, code) {
      var key = e.which;

      alert("key=" + key + ", code=" + code);

      var inputValue = e.target.value;
      alert(this.getKeyCode(inputValue));

      //for android chrome keycode fix
      if (navigator.userAgent.match(/Android/i)) {
        var charKeyCode = e.keyCode || e.which;
        if (charKeyCode == 0 || charKeyCode == 229) {
          charKeyCode = this.getKeyCode(inputValue);
          alert(charKeyCode + " key Pressed");
        } else {
          alert(charKeyCode + " key Pressed");
        }
        key = charKeyCode;
      }

      var isvalidkey = false;
      if (key == 8 || key == 46) {
        isvalidkey = true;
      }
      if (key === 9 || (key >= 48 && key <= 57)) {
        isvalidkey = true;
      }

      if (isvalidkey == false) {
        // if (code == 1) {
        //   setTimeout(function(){document.getElementById("txtcode1").value=""},250);

        //   // this.$refs.code2.focus();
        // } else if (code == 2) {
        //   document.getElementById("txtcode2").focus();
        //   //this.$refs.code3.focus();
        // } else if (code == 3) {
        //   document.getElementById("txtcode3").focus();
        //   // this.$refs.code4.focus();
        // } else if (code == 4) {
        //   document.getElementById("txtcode4").focus();
        // }

        e.preventDefault();
        return false;
      }

      // window.event.keyCode = 9;

      if (key == 8 || key == 46) {
        if (key == 8) {
          // back space

          if (code == 1) {
            // this.$refs.code2.focus();
          } else if (code == 2) {
            document.getElementById("txtcode1").focus();
            // this.$refs.code1.focus();
          } else if (code == 3) {
            document.getElementById("txtcode2").focus();
            // this.$refs.code2.focus();
          } else if (code == 4) {
            document.getElementById("txtcode3").focus();
            //this.$refs.code3.focus();
          }
        }
        return true;
      }

      if (code == 1) {
        document.getElementById("txtcode2").focus();
        // this.$refs.code2.focus();
      } else if (code == 2) {
        document.getElementById("txtcode3").focus();
        //this.$refs.code3.focus();
      } else if (code == 3) {
        document.getElementById("txtcode4").focus();
        // this.$refs.code4.focus();
      } else if (code == 4) {
        this.onSubmit();
      }

      if (key === 9) {
        return true;
      }
    },

    // onKeyDown(e) {
    //   var key = e.which;

    //   // var inputValue = e.target.value;
    //   // alert(inputValue);
    //   //for android chrome keycode fix
    //   // if (navigator.userAgent.match(/Android/i)) {
    //   //   var charKeyCode = e.keyCode || e.which;
    //   //   if (charKeyCode == 0 || charKeyCode == 229) {
    //   //     charKeyCode = getKeyCode(inputValue);
    //   //     alert(charKeyCode + " key Pressed");
    //   //   } else {
    //   //     alert(charKeyCode + " key Pressed");
    //   //   }
    //   //   key = charKeyCode;
    //   // }

    //   if (key == 8 || key == 46) {
    //     return true;
    //   }
    //   if (key === 9 || (key >= 48 && key <= 57)) {
    //     return true;
    //   }

    //   e.preventDefault();
    //   return false;
    // },

    editnumber() {
	
SulGA.pushEvent(
        "otp-change-number",
        "Change Number OTP",
        "otp-change-number-click"
      );

      this.courseSignUpClientData.verifycode = "resetmobilenumber";
      this.$emit("post-postCourseSignUp", this.courseSignUpClientData);
    },
    resendotp() {
       SulGA.pushEvent(
        "otp-resend",
        "otp resend",
        "otp-resend-click"
      );
      this.courseSignUpClientData.verifycode = "resendotp";
      this.countDown =30;
      this.resetotp = false;
      this.$emit("post-postCourseSignUp", this.courseSignUpClientData);
      this.resentitreation=this.resentitreation+1;

      if(this.resentitreation<3){
       this.countDownTimer();
      }
    },

    missedCallClick() {
      this.courseSignUpClientData.IsNri = false;
      this.courseSignUpClientData.missedcall = true;
      this.$emit("post-postCourseSignUp", this.courseSignUpClientData);
    },
    onSubmit() {
    //  this.verificationCode = `${this.code1.trim()}${this.code2.trim()}${this.code3.trim()}${this.code4.trim()}`;
      if (this.verificationCode.length == 4) {
        this.courseSignUpClientData.verifycode = this.verificationCode.trim();
        this.courseSignUpClientData.IsNri = false;
        this.courseSignUpClientData.missedcall = this.missedCall;
        this.courseSignUpClientData.TrueCaller = this.trueCaller;
        this.$emit("post-postCourseSignUp", this.courseSignUpClientData);
      } else {
        this.isFormvalid = false;
        this.verificationCodeErrorText = "Enter a valid 4 digit OTP";
      }
      if (this.verificationCode.length == 0) {
        this.isFormvalid = false;
        this.verificationCodeErrorText = "Please enter a OTP";
      }
    },
  },
  components: {
    DisplayUserInfor: DisplayUserInfor,
  },
};
</script>


<template>
    <div>


        <!-- Left block start -->
       <!-- <DisplayUserInfor :instructorDetails="instructorDetails" /> -->

        <!-- Left block end -->
        <!-- Right block start -->
        <div class="pay-thank">



            <div class="thankmain text-center">
               <!-- <div v-if="ispaid" class="text-success thankdemo">
                    <i class="col-1 fad fa-check-circle text-success"></i>
                    <b>
                        {{showamount()}}
                    </b>
                </div>-->

                <div class="text-success thankdemo">
                    <i class="fad fa-check-circle"></i>
                    <b class="mb-1" v-if="instructorDetails.singuptype == 'demo'">Demo scheduled succesfully</b>
                    <b class="mb-1" v-if="instructorDetails.singuptype == 'regular' && instructorDetails.CourseName!='One On One Sessions'">Regular course scheduled succesfully</b>
                    <b class="mb-1" v-if="instructorDetails.singuptype == 'regular' && instructorDetails.CourseName=='One On One Sessions'">One On One Session scheduled succesfully</b>
                    <span>{{ batchdate }}</span>
                    <p class="caption text-muted" v-if="instructorDetails.singuptype == 'demo'">
                        The instructor will be present to offer you a live demo class.
                    </p>
                    <p class="caption text-muted" v-if="instructorDetails.singuptype == 'regular'">
                        The instructor will be present to offer you a live  class.
                    </p>


                </div>

                <div class="alert alert-primary">
                    <p class="caption fw-700 mb-2">
                        The instructors time is valuable just as yours is
                    </p>
                    <p class="caption mb-2" v-if="instructorDetails.singuptype == 'demo'">
                        Please be present for the demo a few minutes before the start time
                        to ensure your audio and video are functioning well.
                    </p>
                    <p class="caption  mb-2" v-if="instructorDetails.singuptype == 'regular'">
                        Please be present for the session a few minutes before the start time
                        to ensure your audio and video are functioning well.
                    </p>
                    <p class="caption mb-2">Thank you.</p>
                </div>
                <a class="btn btn-primary" @click="gotomycourse()">

                    Go to my courses
                </a>
            </div>

         

        </div>

        <!-- <SocialShare v-if="showpopup" :callback="hidepopup" :courseId="selectedcourseid" :UserId="userid"></SocialShare> -->

    </div>
</template>
<script>
   import DisplayUserInfor from "./DisplayUserInfor.vue";
export default {
  name: "ThankYouForDemo",
  props: {
    instructorDetails: {},
    batchdate: "",
    userName: "",
    closePopup: { type: Function },
    ispaid: false,
    paymentdetails: {},
  },
  methods: {
    close() {
      this.closePopup();
    },
  	gotomycourse(){
       if(this.instructorDetails.singuptype == 'regular'){
            window.location.href="/MyClasses"
       } else {
          window.location.href="/MyDemoClasses"
       }
    },
    showamount(){
          var canproceed= this.isEmpty(this.paymentdetails)
      if(this.paymentdetails!={}){
         return  "Payment of Rs."+this.paymentdetails.Order.FinalPrice +" successful"
      }
        if(canproceed==false){
         return "Payment of Rs."+this.paymentdetails.Order.FinalPrice +" successful"
        }

        return "";
     
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(obj) === JSON.stringify({});
    },
  },
  mounted(){
   // alert(6)
  },
   components:{
          "DisplayUserInfor":DisplayUserInfor
        }
};
</script>
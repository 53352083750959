﻿var hamburger = {
    init: function () {
        document.querySelector(".more-svg").addEventListener('click', function () {
            document.body.classList.toggle('mobile-menu-open');
        });
        document.body.addEventListener("click", function (e) {
            if (!document.querySelector(".site-main-mobile-menu-inner").contains(e.target)
                && !document.querySelector(".more-svg").contains(e.target)) {
                document.body.classList.remove('mobile-menu-open');
            }
        });
        document.querySelectorAll(".mobile-menu-list .hamburgerBack").forEach(elem => {
            elem.addEventListener('click', function (evt) {

                var parentElem = evt.target.parentElement;
                if (evt.target.classList.contains('hamburgerBack'))
                    parentElem = evt.target.parentElement;
                else if (evt.target.parentElement.classList.contains('hamburgerBack'))
                    parentElem = evt.target.parentElement.parentElement;
                else if (evt.target.parentElement.parentElement.classList.contains('hamburgerBack'))
                    parentElem = evt.target.parentElement.parentElement.parentElement;

                var type = parentElem.getAttribute("data-type");
                if (type == "hamburgerAllCat") {
                    document.querySelector("#hamburgerHome").classList.remove("d-none");
                    document.querySelector("#hamburgerAllCat").classList.add("d-none");
                }
                //if (type == "hamburger4") {
                //    document.querySelector("#hamburger4").classList.add("d-none");
                //    document.querySelector("#hamburgerAllCat").classList.remove("d-none");
                //}
                //if (type == "hamburger6") {
                //    document.querySelector("#hamburger6").classList.add("d-none");
                //    document.querySelector("#hamburgerAllCat").classList.remove("d-none");
                //}
                else {
                    //console.log("type", type);
                    document.querySelector("#" + type + "").classList.add("d-none");
                    document.querySelector("#hamburgerAllCat").classList.remove("d-none");
                }
            });
        });
        document.querySelectorAll(".mobile-menu-list .menu-toggle").forEach(elem => {
            if (elem.parentElement.getAttribute("data-type") == "hamburgerAllCat") {
                //console.log("the parent div ", elem.parentElement);
                elem.parentElement.addEventListener('click', (event) => {
                    document.querySelector("#hamburgerHome").classList.add("d-none");
                    document.querySelector("#hamburgerAllCat").classList.remove("d-none");
                })
            }
            else {
                elem.addEventListener('click', function (evt) {

                    var parentElem = evt.target.parentElement;
                    if (parentElem.classList.contains('menu-toggle'))
                        parentElem = parentElem.parentElement;
                    var type = parentElem.getAttribute("data-type");
                    //if (type == "hamburgerAllCat") {
                    //    document.querySelector("#hamburgerHome").classList.add("d-none");
                    //    document.querySelector("#hamburgerAllCat").classList.remove("d-none");
                    //}
                    //if (type == "hamburger4") {
                    //    document.querySelector("#hamburger4").classList.remove("d-none");
                    //    document.querySelector("#hamburgerAllCat").classList.add("d-none");
                    //}
                    //if (type == "hamburger6") {
                    //    document.querySelector("#hamburger6").classList.remove("d-none");
                    //    document.querySelector("#hamburgerAllCat").classList.add("d-none");
                    //}
                    //else {
                    //    if (parentElem.classList.contains("has-children")) {
                    //        if (parentElem.classList.contains("open")) {
                    //            parentElem.classList.remove("open");
                    //            parentElem.querySelector(".sub-menu").classList.remove("d-block");
                    //        }
                    //        else {
                    //            parentElem.classList.add("open");
                    //            parentElem.querySelector(".sub-menu").classList.add("d-block");
                    //        }
                    //    }
                    //}
                    if (type == null || type == "") {
                        if (parentElem.classList.contains("has-children")) {
                            if (parentElem.classList.contains("open")) {
                                parentElem.classList.remove("open");
                                parentElem.querySelector(".sub-menu").classList.remove("d-block");
                            }
                            else {
                                parentElem.classList.add("open");
                                parentElem.querySelector(".sub-menu").classList.add("d-block");
                            }
                        }
                    }
                    else {
                        document.querySelector("#" + type + "").classList.remove("d-none");
                        document.querySelector("#hamburgerAllCat").classList.add("d-none");
                    }
                });
            }

        });
    }
}
if (document.querySelector(".more-svg") != null)
    hamburger.init();
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "otp-form" }, [
      _c("div", { staticClass: "otp-group otp" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          staticClass: "mobile",
          attrs: {
            type: "email",
            name: "mobile",
            id: "mobile",
            required: "",
            placeholder: "Your email id"
          },
          domProps: { value: _vm.email },
          on: {
            keyup: function($event) {
              return _vm.resetErrorText()
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          class: _vm.isInvalidEmail ? "error-text mb-2 show" : "error-text mb-2"
        },
        [_vm._v(" " + _vm._s(_vm.errorText) + " ")]
      )
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary btn-block",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.onSubmitClick()
          }
        }
      },
      [_vm._v("Update")]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("p", { staticClass: "text-muted col-sm-8 col-12 caption" }, [
        _vm._v(
          "\n            Update your email id to your account, so that you do not miss any of the notifications that is being sent.\n        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
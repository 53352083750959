<template>
    <div>

        <!--<h5>Enter the OTP</h5>-->
        <div class="row">
            <p class="text-muted col-sm-8 col-12 caption">
                Update your email id to your account, so that you do not miss any of the notifications that is being sent.
            </p>
            
        </div>
        <div class="otp-form">
            <div class="otp-group otp">
                <input type="email" name="mobile" class="mobile" id="mobile" v-model="email" required placeholder="Your email id" @keyup="resetErrorText()"/>
            </div>
            <span :class="isInvalidEmail ? 'error-text mb-2 show':'error-text mb-2'"> {{errorText}} </span>
        </div>
        <button type="button" class="btn btn-primary btn-block" @click="onSubmitClick()">Update</button>
    </div>
</template>

<script>
    import Loader from "../../wwwroot/js/Loader.js";

    export default {
        name: "EmailScreen",
        props: {
            isValidEmail: { type: Function },
            userPid: String,
            loginProcess: { type: Function },
        },
        data() {
            return {
                email: '',
                isInvalidEmail: false,
                errorText : '',
            }
        },
        methods: {
            onSubmitClick() {
                
                if (this.isValidEmail(this.email)) {
                    this.updateEmail();
                } else {
                    this.errorText = "Please enter valid email id";
                    this.isInvalidEmail = true;
                }
            },
            resetErrorText() {
                this.isInvalidEmail = false;
            },
            updateEmail: function () {
               
 
                let requestData = {
                    emailId: this.email,
                    userPid: this.userPid,
                }
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(requestData),
                }
                Loader.showAboveModal();
                fetch("/api/userlogin/CheckAndUpdateEmail", requestOptions)
                    .then(respone => respone.json())
                    .then(data => {
                        if (data == 0) {
                            this.errorText = "this email is already associated with another account";
                            this.isInvalidEmail = true;
                        }
                        else {
                            if (document.querySelector("#inpEmail")) {
                                document.querySelector("#inpEmail").value = this.email;
                            }
                            this.loginProcess();
                        }
                        Loader.hideAboveModal();
                    });
            }
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.showme ? "modal show" : "modal" }, [
    _c("div", { staticClass: "modal-dialog modal-xl" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm.currentStep == "payment"
          ? _c("div", { staticClass: "row mx-0" }, [
              _c("div", { staticClass: "col-lg-6 primary-bg-color" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "close-btn col-lg-6 d-none d-lg-flex" },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closePopup()
                        }
                      }
                    },
                    [_vm._v("×")]
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.currentStep != "thankYou"
          ? _c(
              "div",
              { staticClass: "modal-header align-items-center d-sm-none" },
              [
                _c(
                  "svg",
                  {
                    staticClass: "svg-icon medium d-sm-none",
                    attrs: { viewBox: "0 0 64 64" },
                    on: {
                      click: function($event) {
                        return _vm.closePopup()
                      }
                    }
                  },
                  [_c("use", { attrs: { "xlink:href": "#chevronBack" } })]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-body p-0" },
          [
            _vm.currentStep == "slotBooking"
              ? _c("TimeSlot", {
                  attrs: {
                    demoSlots: _vm.DemoTimeSlots,
                    instructorDetails: _vm.instructorDetails,
                    nextStep: _vm.nextStep,
                    closePopup: _vm.closePopup,
                    disabledTimes: _vm.disabledTimeindexes
                  },
                  on: { "set-DateTime": _vm.selectedDateTime }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "questionAndAnswer"
              ? _c("QuestionAndAnswer", {
                  attrs: {
                    instructorDetails: _vm.instructorDetails,
                    QuestionAndAnswers: _vm.QuestionAndAnswers,
                    selectedAnswers: _vm.SelectedAnswers,
                    nextStep: _vm.nextStep,
                    closePopup: _vm.closePopup
                  },
                  on: { "set-selectedAnswers": _vm.selectedAnswer }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "mobileVerify"
              ? _c("UserSignUp", {
                  attrs: {
                    instructorDetails: _vm.instructorDetails,
                    userInfo: _vm.UserInfo,
                    pincodeData: _vm.PincodeData,
                    nextStep: _vm.nextStep,
                    courseSignUpClientData: _vm.CourseSignUpClientData,
                    isValidName: _vm.isValidName,
                    isValidEMail: _vm.isValidEMail,
                    isValidMobileNumber: _vm.isValidMobileNumber,
                    isValidPincode: _vm.isValidPincode,
                    selectedDate: _vm.selectedDate,
                    closePopup: _vm.closePopup,
                    SignUpType: "Demo Payment",
                    errormsg: _vm.errormessage,
                    enableInstantLiveOffline: _vm.enableInstantLiveOffline
                  },
                  on: { "post-postCourseSignUp": _vm.postCourseSignUp }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "otpVerify"
              ? _c("OTPVerification", {
                  attrs: {
                    instructorDetails: _vm.instructorDetails,
                    nextStep: _vm.nextStep,
                    invalidOtp: _vm.invalidOtp,
                    courseSignUpClientData: _vm.CourseSignUpClientData,
                    selectedDate: _vm.selectedDate,
                    closePopup: _vm.closePopup,
                    resendotptime: _vm.resendotptime,
                    countrycode: _vm.UserInfo.CountryCode
                  },
                  on: { "post-postCourseSignUp": _vm.postCourseSignUp }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "thankYou"
              ? _c("ThankYouForDemo", {
                  attrs: {
                    batchdate: _vm.selectedDate,
                    instructorDetails: _vm.instructorDetails,
                    selectedDate: _vm.selectedDate,
                    userName: _vm.CourseSignUpClientData.Name,
                    closePopup: _vm.closePopup,
                    ispaid: false,
                    liveSessionUrl: _vm.liveSessionUrl
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "payment"
              ? _c("DemoPayProcess", {
                  attrs: {
                    instructorDetails: _vm.instructorDetails,
                    selectedDate: _vm.selectedDate,
                    paymentUrl: _vm.paymentUrl,
                    closePopup: _vm.closePopup
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "errorScreen"
              ? _c("ErrorScreen", {
                  attrs: {
                    instructorDetails: _vm.instructorDetails,
                    errorScreenDetails: _vm.errorScreenDetails,
                    closePopup: _vm.closePopup
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep == "confirminstantliveoffline"
              ? _c("ConfirmInstantLiveDemoOffline", {
                  attrs: {
                    instructorDetails: _vm.instructorDetails,
                    nextStep: _vm.nextStep,
                    closePopup: _vm.closePopup
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "progress" }, [
      _c("div", {
        staticClass: "progress-bar",
        staticStyle: { width: "50%" },
        attrs: {
          role: "progressbar",
          "aria-valuenow": "50",
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("svg", { staticClass: "d-none" }, [
      _c("g", { attrs: { id: "ratingStarIcon" } }, [
        _c("path", {
          attrs: {
            d:
              "m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "course-details-block d-flex align-items-center" },
      [
        _c("div", { staticClass: "img-wrapper" }, [
          _c("img", {
            staticClass: "fit-cover",
            attrs: { src: _vm.selectedCourseImgUrl, alt: "" }
          })
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "pl-3 " }, [
          _vm._v(" " + _vm._s(_vm.selectedCourseName))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "py-3" }, [
      _c("div", { staticClass: "review-container" }, [
        _c("h6", [
          _vm._v("\n                Please give a rating\n            ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex align-items-center star-option" },
          _vm._l(5, function(i) {
            return _c(
              "svg",
              {
                staticClass: "svg-icon",
                attrs: {
                  id: i,
                  viewBox: "0 -10 511.98685 511",
                  "data-svg": "star-icon"
                }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#ratingStarIcon" },
                  on: {
                    mouseleave: _vm.onStarLeave,
                    click: _vm.onStarClick,
                    mouseover: _vm.onStarHover
                  }
                })
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("hr", { staticClass: "dashed my-4" }),
        _vm._v(" "),
        _c("h6", [
          _vm._v("\n                Please write a review\n            ")
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.reviewDescription,
              expression: "reviewDescription"
            }
          ],
          attrs: { name: "", id: "", cols: "30", rows: "5" },
          domProps: { value: _vm.reviewDescription },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.reviewDescription = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "caption pt-3" }, [
          _vm._v(
            "\n                Min character count 25 and max 2500\n            "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "review-labels d-flex justify-content-between mt-3" },
      [
        _c("span", { staticClass: "caption text-center" }, [
          _vm._v("\n                    Needs improvement\n                ")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "caption" }, [_vm._v("Excellent")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <button type="button"
                class="close cabso mr-2 mt-2"
                style="display: block; padding: 0rem"
                @click="close()">
            &times;
        </button>

        <div class="row mx-0">
            <!-- Left block start -->
            <DisplayUserInfor :instructorDetails="instructorDetails" :liveSessionUrl="liveSessionUrl" />

            <!-- Left block end -->
            <!-- Right block start -->
            <div class="col-lg-6 py-3">
                <!--<h2 v-if="instructorDetails.singuptype == 'demo'" class="py-4 mb-4">
                                Demo scheduled successful
                              </h2>
                              <h2 v-if="instructorDetails.singuptype == 'regular' && instructorDetails.CourseName=='One On One Sessions'" class="py-4 mb-4">
                                One On One Session scheduled successful
                              </h2>

                                 <h2 v-if="instructorDetails.singuptype == 'regular' && instructorDetails.CourseName!='One On One Sessions'" class="py-4 mb-4">
                                Regular scheduled successful
                              </h2>

                        -->
                <div class="thankmain">
                    <div v-if="ispaid" class="text-success thankdemo">
                        <i class="fad fa-check-circle"></i>
                        <b>
                            {{ showamount() }}
                        </b>
                    </div>



                    <div  v-if="instructorDetails.singuptype == 'instantdemo'" class="instant-demothank">
                        
                        <span>Please click the below button to join the demo class.</span>

                        <a @click="gotomeeting(liveSessionUrl)" class="btn btn-primary btn-large">Join the Class Now</a>

                        <p class="text-muted">
                            <b>Please Note</b> The tutor will join within 2 minutes and start the class.<br />
                            This class will be conducted on zoom.<br />
                            Do not close the zoom window
                        </p> 
                    </div>


                    <div v-if="instructorDetails.singuptype != 'instantdemo'" class="text-success thankdemo">
                        <i class="fad fa-check-circle"></i>

                        <b class="mb-1" v-if="instructorDetails.singuptype == 'demo'">Demo scheduled succesfully</b>
                        <!--<b class="mb-1" v-if="instructorDetails.singuptype == 'instantdemo'">Instant Demo</b>-->
                        <b class="mb-1"
                           v-if="
                instructorDetails.singuptype == 'regular' &&
                instructorDetails.CourseName != 'One On One Sessions'
              ">Regular course scheduled succesfully</b>
                        <b class="mb-1"
                           v-if="
                instructorDetails.singuptype == 'regular' &&
                instructorDetails.CourseName == 'One On One Sessions'
              ">One On One Session scheduled succesfully</b>
                        <span>{{ batchdate }}</span>
                        <p class="caption text-muted"
                           v-if="instructorDetails.singuptype == 'demo'">
                            The instructor will be present to offer you a live demo class.
                        </p>

                        <!--<p class="caption text-muted"
           v-if="instructorDetails.singuptype == 'instantdemo'">
            Please click on the below link to join the demo class.
        </p>-->

                        <p class="caption text-muted"
                           v-if="instructorDetails.singuptype == 'regular'">
                            The instructor will be present to offer you a live class.
                        </p>
                    </div>
                    <div v-if="instructorDetails.singuptype != 'instantdemo'" class="alert alert-primary">
                        <p v-if="instructorDetails.singuptype != 'instantdemo'" class="caption fw-700 mb-2">
                            The instructors time is valuable just as yours is
                        </p>
                        <p class="caption mb-2"
                           v-if="instructorDetails.singuptype == 'demo'">
                            Please be present for the demo a few minutes before the start time
                            to ensure your audio and video are functioning well.
                        </p>
                        <!--<p class="caption mb-2"
           v-if="instructorDetails.singuptype == 'instantdemo'">
            The tutor will join within 2 minutes and start the class. This class will be conducted on zoom.
        </p>-->
                        <p class="caption mb-2"
                           v-if="instructorDetails.singuptype == 'regular'">
                            Please be present for the session a few minutes before the start
                            time to ensure your audio and video are functioning well.
                        </p>
                        <p class="caption">Thank you.</p>
                    </div>
                </div>
                <!-- <div class="shadow-sm rounded p-3 mb-1">
                          <p class="body2 text-dark text-center"  v-if="instructorDetails.singuptype == 'demo'">
                            Ask your Friends to join the demo?
                          </p>
                            <p class="body2 text-dark text-center"  v-if="instructorDetails.singuptype == 'regular'">
                            Ask your Friends to join the course?
                          </p>
                          <div class="p-2">
                            <a
                              class="btn btn-lg caption btn-primary btn-block"
                              href=""
                              >Share with friendss</a
                            >
                          </div>
                        </div> -->

                <div v-if="instructorDetails.singuptype != 'instantdemo'" class="next-button">
                    <div></div>
                    <!--<a v-if="instructorDetails.singuptype == 'instantdemo'" class="btn btn-primary" @click="gotomeeting(liveSessionUrl)">                        
                        Live Demo Class
                    </a>-->
                    <a class="btn btn-primary" @click="gotomycourse()">
                        Go to my courses
                    </a>
                </div>
            </div>

            <!-- <SocialShare v-if="showpopup" :callback="hidepopup" :courseId="selectedcourseid" :UserId="userid"></SocialShare> -->
        </div>
    </div>
</template>
<script>
    import DisplayUserInfor from "./DisplayUserInfor.vue";
    import SulGA from "../../wwwroot/js/Ga.js";
    export default {
        name: "ThankYouForPayment",
        props: {
            instructorDetails: {},
            liveSessionUrl: "",
            batchdate: "",
            userName: "",
            closePopup: { type: Function },
            ispaid: false,
            paymentdetails: {},
        },
        methods: {
            close() {
                this.closePopup();
            },

            gotomycourse() {
                if (this.instructorDetails.singuptype == "regular") {
                    window.location.href = "/MyClasses";
                } else {
                    window.location.href = "/MyDemoClasses";
                }
            },

            gotomeeting(url) {
                window.open(url, '_blank');
            },
            showamount() {
                var canproceed = this.isEmpty(this.paymentdetails);
                if (this.paymentdetails != {}) {
                    return (
                        "Payment of Rs." +
                        this.paymentdetails.Order.FinalPrice +
                        " successful"
                    );
                }
                if (canproceed == false) {
                    return (
                        "Payment of Rs." +
                        this.paymentdetails.Order.FinalPrice +
                        " successful"
                    );
                }

                return "";
            },
            isEmpty(obj) {
                for (var prop in obj) {
                    if (obj.hasOwnProperty(prop)) {
                        return false;
                    }
                }
                return JSON.stringify(obj) === JSON.stringify({});
            },

            readCookie(name) {
                var nameEQ = name + "=";
                var ca = document.cookie.split(";");
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == " ") c = c.substring(1, c.length);
                    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                }
                return null;
            },
        },
        mounted() {
            //      SulGA.init();
            // SulGA.pushPageView();
            SulGA.pushEvent("demo-booking-success", "demo", "demo-thank-you");
            //  window.dataLayer = window.dataLayer || [];
            //     function gtag() { dataLayer.push(arguments); }
            //     gtag('js', new Date());
            //     gtag('config', 'AW-1044658860');
            var sourcetrack = this.readCookie("strSEOTrack");
            if (sourcetrack != null) {
                if (sourcetrack.toLowerCase() == "sem") {
                    gtag("event", "conversion", {
                        send_to: "AW-1044658860/QukBCMfhneoBEKz1kPID",
                    });
                    fbq("track", "SubmitApplication");
                }
            }
        },
        components: {
            DisplayUserInfor: DisplayUserInfor,
        },
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.reviews, function(review) {
      return _c("div", { staticClass: "review col-lg-6" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c("figure", { staticClass: "user-img-circle" }, [
            review.Contributor
              ? _c("span", [_vm._v(_vm._s(review.Contributor.substring(0, 1)))])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "review-content" }, [
            _c("div", [
              _c("h6", { staticClass: "sub-title text-muted" }, [
                _vm._v(_vm._s(review.Contributor))
              ]),
              _vm._v(" "),
              review.Rating > 0
                ? _c("div", { staticClass: "reviews" }, [
                    _c("div", { staticClass: "ratings medium" }, [
                      _c("span", {
                        class: _vm.renderRatingStars(review.Rating)
                      })
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "caption mt-2" }, [
              _vm._v(_vm._s(review.CourseTitle))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "review-text line-clamp" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(review.ReviewDescription) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                ref: "readMore",
                refInFor: true,
                staticClass: "link d-none",
                attrs: { id: "read-more-in-pagination" },
                on: { click: _vm.onClickReadMore }
              },
              [_vm._v("read more")]
            )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="row">
        <div class="review col-lg-6" v-for="review in reviews">
            <div class="d-flex">
                <figure class="user-img-circle">
                    <span v-if="review.Contributor">{{review.Contributor.substring(0, 1)}}</span>
                </figure>
                <div class="review-content">
                    <div>
                        <h6 class="sub-title text-muted">{{review.Contributor}}</h6>
                        <div v-if="review.Rating > 0" class="reviews">
                            <div class="ratings medium">
                                <span :class="renderRatingStars(review.Rating)"></span>
                            </div>
                        </div>
                        
                    </div>
                    <label class="caption mt-2">{{review.CourseTitle}}</label>
                    <p class="review-text line-clamp">
                        {{review.ReviewDescription}}
                    </p>
                    <span class="link d-none" v-on:click="onClickReadMore" ref="readMore" id="read-more-in-pagination">read more</span>
                    <!--<p class="caption text-muted mt-2">
                        Is this review helpful? &nbsp;
                        <i class="far fa-thumbs-up"></i> 6 &nbsp;
                        <i class="far fa-thumbs-down"></i> 1
                    </p>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name: "Review",
        props: {
            reviews: null,
        },
        methods: {
            renderRatingStars: function (rating) {
                switch (rating) {
                    case 0: return "star0"
                        break;
                    case 0.5: return "star0-5"
                        break;
                    case 1: return "star1"
                        break;
                    case 1.5: return "star1-5"
                        break;
                    case 2: return "star2"
                        break;
                    case 2.5: return "star2-5"
                        break;
                    case 3: return "star3"
                        break;
                    case 3.5: return "star3-5"
                        break;
                    case 4: return "star4"
                        break;
                    case 4.5: return "star4-5"
                        break;
                    case 5: return "star5"
                        break;
                }
            },
            onClickReadMore: function (event) {
                if (event.target.previousElementSibling.classList.contains('line-clamp')) {
                    event.target.previousElementSibling.classList.remove('line-clamp');
                    event.target.style.display = 'none';
                }
            }
        },
        updated: function () {
            document.querySelectorAll("#read-more-in-pagination").forEach((button) => {
                if (button.previousElementSibling.scrollHeight > button.previousElementSibling.clientHeight)
                    button.classList.remove("d-none");
            })
        }
    }
</script>